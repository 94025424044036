import { setRegisterIndex } from "../../../../../store/compoIndex";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { RegisterFormContainer } from "../styled/register";
import { SubmitButton } from "../../../styled/mypage";
import RegisterItem from "./registerForm/registerItem";
import SelectTime from "./registerForm/selectTime";
import { useMediaQuery } from "react-responsive";

export default function RegisterForm() {
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const [data, setData] = useState({
    logoImage: "",
    logoText: "",
    csTimeStart: 9,
    csTimeEnd: 18,
    breakTimeStart: 12,
    breakTimeEnd: 13,
    csNumber: "",
    facebookUrl: "",
    facebookChk: "Y",
    instagramUrl: "",
    instagramChk: "Y",
    cmpnyIntroduce: "",
  });
  const [logo, setLogo] = useState();
  const [isImage, setIsImage] = useState();
  const [isAllTime, setIsAllTime] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  // 데이터 조회
  useEffect(() => {
    getMySettingData();
  }, []);

  const getMySettingData = async () => {
    try {
      const myData = await getMySetting();
      if (myData.logoImage) {
        setIsImage(true);
      }
      const filtered = Object.fromEntries(
        Object.entries(myData).filter(([key, value]) => value !== null),
      );
      setData((info) => ({ ...info, ...filtered }));
    } catch (error) {
      console.log(error);
    }
  };

  const getMySetting = async () => {
    const url = "/api/infoSettingLoad";
    const request = getDataCondition();
    const response = await axios.post(url, request);
    return response.data;
  };

  const getDataCondition = () => {
    return {
      cmpnyNo: user.cmpnyNo,
      delYn: "N",
    };
  };

  // 데이터 변경시
  const changeInput = (e) => {
    const { name, value, type } = e.target;

    console.log(e);

    if (type === "checkbox") {
      const value = e.target.value === "Y" ? "Y" : "N";
      setData({ ...data, [name]: value });
    }

    if (type === "text") {
      setData({ ...data, [name]: value });
    }

    if (type === "select-one") {
      // 시작시간
      if (name.endsWith("Start")) {
        const end = name.slice(0, -5).concat("End");
        const startValue = Number(value);
        const endValue = (startValue + 1) % 24;

        setData((prevData) => ({
          ...prevData,
          [name]: startValue.toString(),
          [end]: endValue.toString(),
        }));
      }
      // 종료시간
      if (name.endsWith("End")) {
        const endValue = Number(value);

        setData((prevData) => ({
          ...prevData,
          [name]: endValue.toString(),
        }));
      }
    }
  };

  // 로고 이미지 추가
  const addLogo = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      const imageUrl = reader.result;
      setData((data) => ({
        ...data,
        logoImage: imageUrl,
      }));

      setLogo(file);
      e.target.value = "";
    };
  };

  // 이미지값 반환
  const uploadLogoImage = async (item) => {
    if (!item) {
      return data.logoImage;
    }
    const form = new FormData();
    form.append("file", item);
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFile";
    const res = await axios.post(url, form);

    return res?.data?.responseData?.fileName;
  };

  // 저장
  const onSave = async (e) => {
    e.preventDefault();
    if ((isImage && !data.logoImage) || (!isImage && !data.logoText)) {
      toast.error("로고를 설정해주세요.");
      return;
    }
    if (data.facebookChk === "N" && !data.facebookUrl) {
      toast.error("페이스북 URL을 입력해주세요.");
    }
    if (data.instagramChk === "N" && !data.instagramUrl) {
      toast.error("인스타그램 URL을 입력해주세요.");
    }

    const url = "/api/infoSettingSave";
    const uploaded = await uploadLogoImage(logo);

    if (isImage && logo && !uploaded) {
      return;
    }

    const isValue = (key, value) => {
      if (key.includes("Time")) {
        return hours.includes(value.toString()) ? value : null;
      }
      if (key.includes("logoImage")) {
        return isImage ? value : null;
      }
      if (key.includes("logoText")) {
        return !isImage ? value : null;
      }

      return value !== "" && value !== null && value !== undefined ? value : null;
    };

    const updatedData = Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: isValue(key, value),
      }),
      {},
    );

    const request = {
      cmpnyNo: user.cmpnyNo,
      createMberId: user.userName,
      updateMberId: user.userName,
      ...updatedData,
      logoImage: isImage ? uploaded : null,
    };

    if (isAllTime) {
      request.csTimeStart = 0;
      request.csTimeEnd = 0;
    }

    await axios.put(url, request);
    dispatch(setRegisterIndex(1));
    toast.success("변경사항이 저장되었습니다.");
  };

  const hours = Array.from({ length: 24 }, (_, index) => index.toString());

  const TextInput = ({ placeholder, value, onChange, name }) => (
    <input type="text" placeholder={placeholder} value={value} name={name} onChange={onChange} />
  );

  return (
    <RegisterFormContainer className={setting?.mode}>
      <ul>
        <RegisterItem
          title="로고 설정"
          label="로고 이미지 유무"
          checked={isImage}
          onCheckboxChange={setIsImage}
          className="cmpnyLogo">
          {isImage ? (
            <div className="file">
              <div className="preview">
                {data?.logoImage && <img src={imgHandler(data?.logoImage)} alt="" />}
              </div>
              <label>
                파일{isMobile && <br />}찾기
                <input type="file" name="logoImage" accept="image/*" onChange={addLogo} />
              </label>
            </div>
          ) : (
            <TextInput
              placeholder="※ 로고 이미지가 없을 경우 입력한 내용으로 텍스트 로고가 생성됩니다."
              value={data?.logoText}
              name="logoText"
              onChange={changeInput}
            />
          )}
        </RegisterItem>
        <RegisterItem
          title="상담운영시간"
          label="24시간"
          checked={isAllTime}
          onCheckboxChange={setIsAllTime}
          className="csTime">
          <div className="timeArea">
            <SelectTime
              label="시작시간"
              name="csTimeStart"
              value={data?.csTimeStart}
              hours={hours}
              onChange={changeInput}
            />
            <SelectTime
              label="종료시간"
              name="csTimeEnd"
              value={data?.csTimeEnd}
              hours={hours}
              onChange={changeInput}
            />
          </div>
        </RegisterItem>
        <RegisterItem title="브레이크타임" className="breakTime">
          <div className="timeArea">
            <SelectTime
              label="시작시간"
              name="breakTimeStart"
              value={data?.breakTimeStart}
              hours={hours}
              onChange={changeInput}
            />
            <SelectTime
              label="종료시간"
              name="breakTimeEnd"
              value={data?.breakTimeEnd}
              hours={hours}
              onChange={changeInput}
            />
          </div>
        </RegisterItem>
        <RegisterItem
          title="페이스북"
          label="사용 안함"
          checked={data?.facebookChk === "Y"}
          onCheckboxChange={(checked) =>
            changeInput({
              target: { name: "facebookChk", value: checked ? "Y" : "N", type: "checkbox" },
            })
          }
          className="facebookChk">
          <TextInput
            placeholder="※ 페이스북 접속 후 주소창의 URL을 복사/붙여넣기 해주세요"
            value={data?.facebookUrl}
            name="facebookUrl"
            onChange={changeInput}
          />
        </RegisterItem>
        <RegisterItem
          title="인스타그램"
          label="사용 안함"
          checked={data?.instagramChk === "Y"}
          onCheckboxChange={(checked) =>
            changeInput({
              target: {
                name: "instagramChk",
                value: checked ? "Y" : "N",
                type: "checkbox",
              },
            })
          }
          className="instagramChk">
          <TextInput
            placeholder="※ 인스타그램 접속 후 주소창의 URL을 복사/붙여넣기 해주세요"
            value={data?.instagramUrl}
            name="instagramUrl"
            onChange={changeInput}
          />
        </RegisterItem>

        <RegisterItem title="CS 번호" className="csNumber">
          <TextInput
            placeholder="※ 예시) 0505-466-0909"
            value={data?.csNumber}
            name="csNumber"
            onChange={changeInput}
          />
        </RegisterItem>
      </ul>
      <SubmitButton>
        <button type="submit" onClick={onSave}>
          저장하고 다음 단계
        </button>
      </SubmitButton>
    </RegisterFormContainer>
  );
}

import React from "react";
import { useOutletContext } from "react-router";
import { Modal } from "../../../common/style/modal";
import { IoClose } from "react-icons/io5";

const Confirmation = ({ title, message, handleConfirm, handleCancel }) => {
  const { theme } = useOutletContext();

  return (
    <Modal className={`theme-${theme}`}>
      <div className="modal">
        <div className="info">
          <span>{title}</span>
          <button onClick={handleCancel}>
            <IoClose />
          </button>
        </div>
        <div className="message">
          {message.map((item) => (
            <span>{item}</span>
          ))}
        </div>
        <div className="actions">
          <button onClick={handleCancel}>취소</button>
          <button onClick={handleConfirm}>확인</button>
        </div>
      </div>
    </Modal>
  );
};

export default Confirmation;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { formatCommas, status } from "../../function/store";
import { useMediaQuery } from "react-responsive";
import Products from "../../common/products";

const OrderItem = ({ item, index, contentRefs, toggleItem, selected }) => {
  const isSelected = (item) => selected?.id === item.id;
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRefs.current[index]) {
      setContentHeight(contentRefs.current[index].scrollHeight);
    }
  }, [selected]);

  return (
    <li className={isSelected(item) ? "active" : "inactive"} onClick={() => toggleItem(item)}>
      <div className="toggle">
        <button>
          <FaChevronRight />
        </button>
      </div>
      <div className="index">{item?.id}</div>
      <div className={`status ${item?.status}`}>
        <span>{status[item?.status]}</span>
      </div>
      <div className="createdAt">{moment(item?.createdAt).format("YYYY.MM.DD HH:mm")}</div>
      <div className="price">{formatCommas(item?.price)}원</div>
      {item?.products && (
        <div
          className="products"
          ref={(el) => (contentRefs.current[index] = el)}
          style={{
            maxHeight: isSelected(item) ? `${contentHeight}px` : "0",
          }}>
          {item.cancelReason && (
            <div className="tit cancelReason">취소사유: {item.cancelReason}</div>
          )}
          <span className="tit">주문상품</span>
          <Products data={item.products} />
        </div>
      )}
    </li>
  );
};

export default OrderItem;

import React from "react";
import { Container } from "./styled/login";
import { useNavigate } from "react-router";
import { PageContainer } from "./styled/common";
import Message from "./message";

const TypeJoin = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Message />
      <Container>
        <div className="joinTypeBox" onClick={() => navigate("/join")}>
          <div className="tit">
            <img src="/img/icon_application_1.png" alt="" />
            <p>일반회원</p>
          </div>
          <span>
            개인적인 목적으로 서비스를 이용하실 분은
            <br />이 옵션을 선택해 주세요.
          </span>
          <button>가입하기</button>
        </div>
        <div className="joinTypeBox" onClick={() => navigate("/businessJoin")}>
          <div className="tit">
            <img src="/img/icon_function_5.png" alt="" />
            <p>기업회원</p>
          </div>
          <span>
            비즈니스 또는 조직 대표로 서비스를 <br />
            이용하실 분은 이 옵션을 선택해 주세요.
          </span>
          <button>가입하기</button>
        </div>
      </Container>
    </PageContainer>
  );
};

export default TypeJoin;

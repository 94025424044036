import React from "react";
import moment from "moment";

import { useSelector } from "react-redux";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router";

import { PageInfoContainer } from "./styled/mypage";
import { weatherStates } from "./scheduler/component/function/dateInfo";

const PageInfo = ({ label, todayWeather = null }) => {
  const navigate = useNavigate();
  const setting = useSelector((state) => state?.user?.setting);

  const isWeather = (weather) => {
    if (!weather) return null;

    const state = weatherStates[weather?.weather];
    if (!state) return null;
    const iconChar = String.fromCodePoint(parseInt(state.icon, 16));

    return (
      <div className="weather">
        <span className="icon">{iconChar}</span>
        <span>
          {weather?.temMin}℃/{weather?.temMax}℃
        </span>
      </div>
    );
  };

  return (
    <PageInfoContainer className={setting.mode}>
      <button onClick={() => navigate("/mypage")}>
        <FaAngleLeft />
      </button>
      <div className="info">
        <h3>{label}</h3>
        <div className="overview">
          {todayWeather && <span>{moment().format("YYYY년 M월 D일")}</span>}
          {todayWeather && isWeather(todayWeather)}
        </div>
      </div>
    </PageInfoContainer>
  );
};

export default PageInfo;

import React from "react";
import { FaConciergeBell } from "react-icons/fa";
import { SideMenuContainer } from "../styled/common";
import { useSelector } from "react-redux";

const SideMenu = ({ type, tabs, active, setActive }) => {
  const company = useSelector((state) => state?.company?.data) || [];

  return (
    <SideMenuContainer>
      <div className="store">
        <span>상생매장 사장님</span>
        <div className="selected">
          <div className="icon">
            <img src="/img/store/public_opinion.png" alt="" />
          </div>
          <select name="" id="">
            {company.map((cmpny) => (
              <option>{cmpny.cmpnyNm}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="tabs">
        <ul>
          {Object.keys(tabs).map((tab) => (
            <li
              key={tab}
              onClick={() => setActive(tab)}
              className={active === tab ? "active" : "inactive"}>
              <div className="icon">
                <img src={`/img/store/${type}_${tab}.png`} alt="" />
              </div>
              <span>{tabs[tab]}</span>
            </li>
          ))}
        </ul>
      </div>
    </SideMenuContainer>
  );
};

export default SideMenu;

import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import useQueryString from "../../hooks/useQueryString";
import { useSelector } from "react-redux";
import { BoardTableContainer } from "../styled/common";

const BoardTable = ({ notices, isShop, url, queryString, setting }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const queryPage = Number(useQueryString("page"));

  const moveToPage = (noticeNo) => {
    const path = !isShop
      ? `/mypage/registerNoticeView/${noticeNo}`
      : `/shop/${url}/shoppingNoticeView/${noticeNo}`;
    const state = queryString ? { keyword: queryString } : { page: queryPage };
    navigate(path, isShop ? { state } : undefined);
  };

  return (
    <BoardTableContainer className={setting?.mode ? setting.mode : ""}>
      <caption>공지사항</caption>
      {!isMobile && (
        <colgroup>
          <col style={{ width: isShop ? "5%" : "10%" }} />
          <col style={{ width: isShop ? "10%" : "20%" }} />
          <col style={{ width: isShop ? "60%" : "40%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>
      )}
      <thead>
        <tr>
          {boardFaq.map((item, i) => (
            <th scope="col" key={i}>
              {item.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {notices.length ? (
          notices.map((item, index) => (
            <tr key={index} onClick={() => moveToPage(item.noticeNo)}>
              {!isMobile && <td className="noticeNo">{item.noticeNo}</td>}
              <td className="noticeCl">{item.noticeCl}</td>
              <td className="noticeSj">{item.noticeSj}</td>
              {!isMobile && <td className="createMberId">{item.createMberId}</td>}
              <td className="createDt">{item.updateDt || item.createDt}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5} className="table_data_empty">
              등록된 공지사항이 없습니다
            </td>
          </tr>
        )}
      </tbody>
    </BoardTableContainer>
  );
};

export default BoardTable;

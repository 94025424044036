import React, { useEffect, useState } from "react";
import { parseHTMLContent } from "../../function/shopping";
import { useNavigate } from "react-router";

const OurHistory = ({ homepageInfo, url }) => {
  const navigate = useNavigate();
  const temp = Array.from({ length: 4 });
  const [info, setInfo] = useState({ image: null, text: null });

  useEffect(() => {
    if (homepageInfo && homepageInfo.cmpnyIntroduce) {
      const cmpnyIntroduce = parseHTMLContent(homepageInfo.cmpnyIntroduce);
      setInfo({ ...cmpnyIntroduce });
    }
  }, [homepageInfo]);

  return (
    <div className="history">
      <div className="info">OUR HISTORY</div>
      <div className="content">
        {info.text ? (
          <div className="contentInfo" dangerouslySetInnerHTML={{ __html: info.text }}></div>
        ) : (
          <div className="contentInfo">
            안녕하세요,
            <br />
            {homepageInfo?.cmpnyNm ? `${homepageInfo?.cmpnyNm}입니다.` : ""}
          </div>
        )}
        <div className="wrapper">
          {temp.map((_) => (
            <div className="box" onClick={() => navigate(`/shop/${url}/company`)}>
              <img src="/img/shop_network.png" alt="" />
              <span>ABOUT US</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurHistory;

import React, { useEffect, useRef, useState } from "react";
import EventListItem from "../../common/eventListItem";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/ko";

import { CiAlarmOn, CiBellOn, CiGrid42, CiHashtag } from "react-icons/ci";
import { PiUsersFour, PiUser, PiProjectorScreenChart } from "react-icons/pi";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { RiArrowLeftUpLine } from "react-icons/ri";
import { IoHappyOutline } from "react-icons/io5";

import IconPicker from "../../common/iconPicker";

import { hexToRgb } from "../../function/common";
import { transformDate } from "../../function/scheduler";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";

const ScheduleFormBasic = ({
  isAllTime,
  formData,
  setFormData,
  setIsAllTime,
  myAffil,
  changeInput,
  changeGbn,
  changeOrgNo,
  date,
  setDate,
  saveToLocalStorage,
  projectList,
  changeProject,
  setActSelType,
  changeScheduleNm,
  showTitles,
  titles,
  titlesPosition,
  setShowTitles,
  setTitlesPosition,
  titleWidth,
  toggleSideForm,
  isEditable,
  eventInfo,
  projectName,
  setProjectName,
}) => {
  const projectRef = useRef();
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const { mySchedSet } = useSelector((state) => state?.scheduler);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const colors = ["139859", "FFC455", "8F2D56", "A7A3D9", "38C6BD", "B350C3"];
  const [isPalette, setIsPalette] = useState(false);
  const [isIcon, setIsIcon] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);

  const navigate = useNavigate();

  useOutsideClick(projectRef, () => {
    setIsProjectOpen(false);
  });

  const pushMinutes = {
    "5분전": 5,
    "15분전": 15,
    "30분전": 30,
    "1시간전": 60,
    하루전: 1440,
  };

  const allTimePushMinutes = {
    "하루 전 오전 9시": 900,
    "하루 전 오후 12시": 720,
    "당일 오전 9시": -540,
    "당일 오후 12시": -720,
  };

  moment.updateLocale("ko", {
    weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
  });

  // custom select box
  const getHourOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      const value = i < 10 ? `0${i}` : i;
      options.push(<option value={value}>{value}</option>);
    }
    return options;
  };

  const getMinuteOptions = () => {
    const options = [];
    for (let i = 0; i < 60; i += 5) {
      const value = i < 10 ? `0${i}` : i;
      options.push(<option value={value}>{value}</option>);
    }
    return options;
  };

  // 하루종일 변경 시
  const chageAllTimeChk = () => {
    const isAll = isAllTime;

    if (isAll) {
      setDate((prev) => {
        return {
          ...prev,
          timeStartMinute: "30",
          timeEndMinute: "30",
        };
      });
      setFormData((prev) => {
        return { ...prev, pushMinute: 60 };
      });
    } else {
      setFormData((prev) => {
        return { ...prev, pushMinute: 900 };
      });
    }
    setIsAllTime(!isAllTime);

    const formDataDate = transformDate(date, !isAllTime);
    saveToLocalStorage({ ...formDataDate, pushMinute: isAll ? 60 : 900 });
  };

  // 제목 미리보기 클릭 시
  const handleTitleClick = (item) => {
    setFormData((data) => {
      let updated = { ...data, title: item };
      saveToLocalStorage(updated);
      return updated;
    });

    setShowTitles(false);
    setTitlesPosition({});
  };

  const clickWorkersInput = (state) => {
    if (formData.orgNo) {
      toggleSideForm(true);
      setActSelType(state);
    } else {
      toast.error(`${formData.gbn === "G" ? "모임을" : "회사를"} 선택해주세요.`);
    }
  };

  const changeProjectName = (e) => {
    const projectLabel = e.target.value;
    const projectValue = Object.entries(projectList).find(([label]) => label === projectLabel)?.[1];

    setProjectName(projectLabel);
    setFormData((prev) => ({ ...prev, projectNo: projectValue || "notProject" }));
  };

  const clickProject = (selected) => {
    setProjectName(selected.value === "notProject" ? "" : selected.key);
    changeProject(selected.value);
    setIsProjectOpen(false);
  };

  return (
    <ul className="font_16">
      <li>
        <>
          {formData.gbn === "P" ? (
            <div className="tit palette">
              <div
                className="current"
                style={{ background: `#${formData.color}` }}
                onClick={() => setIsPalette(!isPalette)}></div>
              <div className={`colors${isPalette ? " open" : ""}`}>
                {colors.map((item) => (
                  <>
                    <input
                      type="radio"
                      id={item}
                      name="color"
                      value={item}
                      style={{
                        backgroundColor: `#${item}`,
                        boxShadow:
                          item === formData.color
                            ? `rgba(${hexToRgb(item)}, 0.4) 0px 2px 4px 0px, rgba(${hexToRgb(
                                item,
                              )}, 0.6) 0px 2px 16px 0px`
                            : null,
                      }}
                      checked={formData.color === item}
                      onChange={(e) => {
                        changeInput(e);
                        setIsPalette(false);
                      }}
                    />
                  </>
                ))}
              </div>
            </div>
          ) : (
            <div className="tit">
              <CiHashtag />
              <span>제목</span>
            </div>
          )}
          <div
            className="content title"
            style={{
              opacity: formData.gbn === "P" && isPalette ? 0 : 1,
            }}>
            {formData.icon && (
              <img
                src={`/img/scheduler/scheduler_icon_${formData.icon}`}
                className="selectedIcon"
              />
            )}
            <input
              type="text"
              placeholder="일정 제목"
              value={formData.title}
              name="title"
              onChange={(e) => changeScheduleNm(e)}
              style={{
                padding: formData.icon ? "7px 30px" : "7px 30px 7px 14px",
              }}
            />
            <button className="iconSelect" onClick={() => setIsIcon(true)}>
              <IoHappyOutline />
            </button>
            {titles.length > 0 && showTitles && (
              <div className="searchBg" onClick={() => setShowTitles(false)}>
                <ul
                  style={{ top: titlesPosition.top, left: titlesPosition.left, width: titleWidth }}
                  className={`${isDarkMode}`}>
                  {titles.map((item) => (
                    <li onClick={() => handleTitleClick(item)}>
                      <div className="icon">
                        <IoIosSearch />
                      </div>
                      {item}
                      <div className="icon arrow">
                        <RiArrowLeftUpLine />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {isIcon && (
              <IconPicker
                setIsIcon={setIsIcon}
                setFormData={setFormData}
                saveToLocalStorage={saveToLocalStorage}
              />
            )}
          </div>
        </>
      </li>
      <EventListItem
        icon={<CiAlarmOn />}
        title="날짜"
        addClass={`date ${isAllTime ? "allTime" : ""}`}>
        <div className="dtBox">
          <input
            type="date"
            value={date.dateStart}
            onChange={(e) => changeInput(e)}
            name="dateStart"
            day={moment(date.dateStart).format("ddd")}
          />
          {!isAllTime && (
            <div className="timeBox">
              <select
                name="timeStartHour"
                onChange={(e) => changeInput(e)}
                value={date.timeStartHour}>
                {getHourOptions()}
              </select>
              <span>:</span>
              <select
                name="timeStartMinute"
                onChange={(e) => changeInput(e)}
                value={date.timeStartMinute}>
                {getMinuteOptions()}
              </select>
            </div>
          )}
          <span>~</span>
          <input
            type="date"
            value={date.dateEnd}
            onChange={(e) => changeInput(e)}
            name="dateEnd"
            day={moment(date.dateEnd).format("ddd")}
          />
          {!isAllTime && (
            <div className="timeBox">
              <select name="timeEndHour" onChange={(e) => changeInput(e)} value={date.timeEndHour}>
                {getHourOptions()}
              </select>
              <span>:</span>
              <select
                name="timeEndMinute"
                onChange={(e) => changeInput(e)}
                value={date.timeEndMinute}>
                {getMinuteOptions()}
              </select>
            </div>
          )}
        </div>
        <div className="timeCheckBox">
          <label htmlFor="allTime">
            <input type="checkbox" id="allTime" onChange={chageAllTimeChk} checked={isAllTime} />
            하루 종일
          </label>
        </div>
      </EventListItem>
      <EventListItem icon={<CiBellOn />} title="알림" addClass="push">
        <select
          name="pushMinute"
          value={formData.pushMinute}
          onChange={(e) => changeInput(e)}
          style={{ width: isMobile ? "100%" : "50%" }}>
          <option value="notPush">선택안함</option>
          {isAllTime
            ? Object.entries(allTimePushMinutes).map(([label, value]) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))
            : Object.entries(pushMinutes).map(([label, value]) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
        </select>
      </EventListItem>
      <EventListItem icon={<CiGrid42 />} title="구분" addClass="category">
        <select
          value={formData.gbn}
          name="gbn"
          onChange={(e) =>
            isEditable ? toast.error("일정 수정에서는 구분값을 변경할 수 없습니다.") : changeGbn(e)
          }>
          <option value="P">개인</option>
          {mySchedSet.companyYnList.length > 0 && <option value="C">업무</option>}
          {mySchedSet.groupYnList.length > 0 && <option value="G">모임</option>}
        </select>
        {formData.gbn !== "P" && (
          <select
            value={formData.orgNo}
            className="company"
            name="orgNo"
            onChange={(e) =>
              isEditable
                ? toast.error("일정 수정에서는 구분값을 변경할 수 없습니다.")
                : changeOrgNo(e)
            }>
            {myAffil.length === 1 ? (
              <option value={formData.gbn === "C" ? myAffil[0].cmpnyNo : myAffil[0].groupNo}>
                {formData.gbn === "C" ? myAffil[0].cmpnyNm : myAffil[0].groupName}
              </option>
            ) : (
              <>
                <option value="notOptional">선택</option>
                {myAffil?.map((item) => (
                  <option value={formData.gbn === "C" ? item.cmpnyNo : item.groupNo}>
                    {formData.gbn === "C" ? item.cmpnyNm : item.groupName}
                  </option>
                ))}
              </>
            )}
          </select>
        )}
      </EventListItem>
      {formData.gbn !== "P" && (
        <>
          <EventListItem
            icon={<PiProjectorScreenChart />}
            title="프로젝트"
            addClass={`project ${isDarkMode}`}>
            <input
              type="text"
              className="selected"
              onClick={() => setIsProjectOpen(!isProjectOpen)}
              onChange={changeProjectName}
              value={projectName}
            />
            {isProjectOpen && (
              <ul ref={projectRef}>
                <li
                  value="notProject"
                  onClick={() => clickProject({ key: "선택안함", value: "notProject" })}>
                  선택안함
                </li>
                {Object.entries(projectList)
                  .filter(([label]) => label.includes(projectName))
                  .map(([label, value]) => (
                    <li key={label} onClick={() => clickProject({ key: label, value })}>
                      <div className="icon">
                        <IoIosSearch />
                      </div>
                      {label}
                      <div className="icon arrow">
                        <RiArrowLeftUpLine />
                      </div>
                    </li>
                  ))}
              </ul>
            )}
            <button
              onClick={() =>
                navigate("/mypage/projectCreate", {
                  state: { eventInfo: { ...formData, ...transformDate(date, isAllTime) } },
                })
              }>
              프로젝트 추가
            </button>
          </EventListItem>
          <EventListItem
            icon={<PiUser />}
            title={formData.gbn !== "G" ? "담당자" : "주최자"}
            addClass="">
            <p className="readOnly" onClick={() => clickWorkersInput(true)}>
              {formData.managerNm}
            </p>
          </EventListItem>
          <EventListItem
            icon={<PiUsersFour />}
            title={formData.gbn !== "G" ? "실무자" : "참여자"}
            addClass="workers">
            <div className="readOnly" onClick={() => clickWorkersInput(false)}>
              {formData.workerList &&
                Object.values(formData.workerList).length > 0 &&
                Object.values(formData.workerList).map((mberNm, i, arr) => (
                  <span key={i}>
                    {mberNm}
                    {i !== arr.length - 1 && ", "}
                  </span>
                ))}
            </div>
          </EventListItem>
          <EventListItem
            icon={formData.publicYn === "Y" ? <FaRegEye /> : <FaRegEyeSlash />}
            title="공개여부"
            addClass="public">
            <div className="radio-input" style={{ width: isMobile ? "100%" : "50%" }}>
              <label>
                <input
                  value="Y"
                  name="publicYn"
                  type="radio"
                  checked={formData.publicYn === "Y"}
                  onChange={(e) => setFormData({ ...formData, publicYn: e.target.value })}
                />
                <span>전체공개</span>
              </label>
              <label>
                <input
                  value="N"
                  name="publicYn"
                  type="radio"
                  checked={formData.publicYn === "N"}
                  onChange={(e) => setFormData({ ...formData, publicYn: e.target.value })}
                />
                <span>참여자공개</span>
              </label>
              <span class="selection"></span>
            </div>
          </EventListItem>
        </>
      )}
    </ul>
  );
};

export default ScheduleFormBasic;

import MypageBanner from "../mypageBanner";
import HomePageSettingMenu from "./component/common/homepageSettingMenu";
import HomePageSettingForm from "./component/homepage/homePageSettingForm";
import { Container } from "../styled/mypage";
import { useSelector } from "react-redux";
import PageInfo from "../pageInfo";

const HomepageSetting = () => {
  const setting = useSelector((state) => state?.user?.setting);
  return (
    <Container className={setting?.mode}>
      <PageInfo label="첫 화면 설정" />
      <div className="content">
        <HomePageSettingMenu />
        <HomePageSettingForm />
      </div>
    </Container>
  );
};

export default HomepageSetting;

import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

const Notice = ({ cmpnyNo, homepageInfo, url }) => {
  console.log(homepageInfo);

  const navigate = useNavigate();
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getNotices(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getNotices = async (cmpnyNo) => {
    const url = "/api/noticeList";
    const body = {
      cmpnyNo,
      gbn: "N",
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: isTablet ? 2 : 3,
      paged: true,
    };
    try {
      const res = await axios.post(url, body);
      setNotices(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  return notices && Array.isArray(notices) && notices.length > 0 ? (
    <div className="notice">
      <div className="info">
        <span>NEWS</span>
        <span>{homepageInfo?.cmpnyNm}의 소식을 알려드릴게요</span>
      </div>
      <ul className="notices">
        {notices.map((item) => (
          <li onClick={() => navigate(`/shop/${url}/shoppingNoticeView/${item.noticeNo}`)}>
            <div className="noticeInfo">
              <span className="noticeCl">{item.noticeCl}</span>
              <span className="noticeSj">{item.noticeSj}</span>
              <span className="date">{moment(item.createDt).format("YYYY-MM-DD")}</span>
            </div>
          </li>
        ))}
      </ul>
      <button onClick={() => navigate(`/shop/${url}/shoppingNotice?page=0`)}>
        더 많은 소식 보러가기
      </button>
    </div>
  ) : null;
};

export default Notice;

import React from "react";
import { NoticeListContainer } from "../styled/notice";

const NoticeList = ({ label = "", data = [] }) => {
  return (
    <NoticeListContainer>
      {data.length > 0 ? (
        data.map(() => <li>제목입니다.</li>)
      ) : (
        <li className="not">{label}이 존재하지 않습니다.</li>
      )}
    </NoticeListContainer>
  );
};

export default NoticeList;

import styled from "styled-components";

export const RegisterTitleContainer = styled.div`
  margin: 0px auto 50px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  text-align: center;
  &.dark {
    color: rgba(255, 255, 255, 0.5);
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

export const RegisterMenuContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  text-align: center;
  &.dark {
    ul li {
      background: #000;
      &.active {
        background: rgba(37, 99, 235, 0.7);
      }
    }
  }
  ul {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    li {
      background: rgba(255, 255, 255, 0.5);
      width: 200px;
      height: 50px;
      line-height: 50px;
      border-radius: 10px;
      &.active {
        background: #fff;
      }
      a {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  @media screen and (max-width: 767px) {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
    ul {
      width: max-content;
      li {
        width: 125px;
        font-size: 14px;
        height: 35px;
        line-height: 35px;
      }
    }
  }
`;

export const RegisterFormContainer = styled.div`
  &.dark {
    ul li {
      background: #000;
      color: #eee;
      .timeArea > div select,
      .info label .checkmark,
      input[type="text"],
      .file label {
        background: #333;
        color: #eee;
      }
      .info label .checkmark {
        border: 2px solid #333;
      }
      input:checked ~ .checkmark::after {
        border-color: rgba(62, 118, 238, 1);
      }
    }
  }
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  @media screen and (max-width: 767px) {
  }
`;

export const RegisterItemContainer = styled.li`
  width: calc(50% - 10px);
  height: 180px;
  background: rgba(255, 255, 255, 0.5);
  padding: 30px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .file {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
    input[type="file"] {
      display: none;
    }
    label {
      width: 160px;
      border: none;
      background: #fff;
      height: 52px;
      line-height: 52px;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
    .preview {
      max-width: calc(100% - 165px);
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      img {
        width: 104px;
        height: 52px;
        object-fit: contain;
      }
    }
  }
  .info {
    font-weight: 700;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    label {
      width: auto;
      display: flex;
      gap: 5px;
      align-items: center;
      .checkmark {
        position: relative;
        width: 20px;
        height: 20px;
        background: #fff;
      }
    }
    .label {
      font-size: 20px;
      font-weight: 300;
    }
  }
  input[type="text"] {
    border: none;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 52px;
    padding: 0 20px;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
  .timeArea {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 10px;
    > div {
      width: calc(50% - 5px);
      span {
        display: block;
        text-align: center;
        margin-bottom: 5px;
      }
      select {
        background-color: #fff;
        width: 100%;
        height: 52px;
        border-radius: 10px;
        text-align: center;
        border: none;
        &:focus {
          outline: none;
          border: none !important;
          box-shadow: none;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    font-size: 14px;
    height: 120px;
    .info {
      font-size: 20px;
      .label {
        font-size: 12px;
      }
    }
    .timeArea > div select,
    input[type="text"] {
      height: 30px;
    }
    .file {
      align-items: center;
      justify-content: space-between;
      .preview {
        max-width: none;
        width: calc(100% - 110px);
        text-align: center;
      }
      label {
        width: 100px;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const RegisterIntroFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    .ck {
      color: #eee;
    }
  }
  .ck .ck-toolbar,
  .ck.ck-editor__main > .ck-editor__editable {
    background: transparent;
    border: 1px solid #fff;
  }
  .ck .ck-toolbar {
    border-radius: 0 15px 0 15px;
  }
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    box-shadow: none;
  }
`;

export const RegisterPortFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    .font_color_black {
      color: #eee;
    }
    .actions .buttons button:last-child {
      background: #000;
      color: #eee;
    }
  }
  .actions {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    .buttons {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      gap: 20px;
      button {
        height: 40px;
        padding: 0 20px;
        border: none;
        &:first-child {
          background: rgba(37, 99, 235, 0.7);
          color: #fff;
        }
        &:last-child {
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .pagination {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1050px) {
    .actions .buttons {
      top: 150%;
      transform: translateY(50%);
      gap: 10px;
      button {
        height: 30px;
        border-radius: 5px;
        font-size: 12px;
      }
    }
  }
`;

export const RegisterNoticeFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    color: #eee;
    .actions .buttons button:last-child {
      background: #000;
      color: #eee;
    }
  }
  .actions {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    .buttons {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      gap: 20px;
      button {
        height: 40px;
        padding: 0 20px;
        border: none;
        &:first-child {
          background: rgba(37, 99, 235, 0.7);
          color: #fff;
        }
        &:last-child {
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .pagination {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1050px) {
    .actions {
      margin-top: 0px;
      .buttons {
        top: 150%;
        transform: translateY(50%);
        gap: 10px;
        button {
          height: 30px;
          border-radius: 5px;
          font-size: 12px;
        }
      }
    }
  }
`;

export const BoardSearchContainer = styled.div`
  &.dark {
    select,
    button,
    input[type="text"] {
      background: #eee;
      color: #000;
    }
  }
  .basic {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  select,
  button,
  input[type="text"] {
    background: rgba(255, 255, 255, 0.5);
    height: 55px;
    border: none;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.5);
    &:focus {
      outline: none;
      border: none !important;
      box-shadow: none;
    }
  }
  select {
    width: 230px;
    padding: 0 20px;
    cursor: pointer;
  }
  input[type="text"] {
    width: 310px;
    padding: 0 20px;
  }
  button {
    width: 55px;
  }
  @media screen and (max-width: 767px) {
    .basic {
      flex-wrap: wrap;
    }
    select,
    button,
    input[type="text"] {
      height: 30px;
      font-size: 14px;
    }
    select {
      width: 50%;
    }
    button {
      width: 20%;
    }
    input[type="text"] {
      width: calc(80% - 10px);
    }
  }
`;

export const RegisterPortViewFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    color: #fff;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button {
      border: none;
      border-radius: 10px;
      padding: 10px 0;
      width: 100px;
      background: #555;
      &:last-child {
        margin-left: auto;
        background: rgba(37, 99, 235, 0.7);
      }
    }
  }
`;

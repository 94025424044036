import React from "react";
import { RequestsContainer } from "../styled/pickup";

const Requests = ({ remark }) => {
  return (
    <RequestsContainer>
      <span className="label">요청사항</span>
      <div className="remark">
        <span>{remark && remark !== "null" ? remark : "\u00A0"}</span>
      </div>
    </RequestsContainer>
  );
};

export default Requests;

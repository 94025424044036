import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";

import LoginForm from "./component/loginForm";

import { handleLogin } from "../../store/user";
import { getSetting } from "../../store/scheduler";

import { isSScenterMbers, onFullscreen } from "../mypage/kiosk/component/function/kiosk";

const Login = () => {
  const user = useSelector((state) => state?.user?.data[0]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(handleLogin(user));
      navigate("/mypage");
    }
  }, []);

  // 캘린더 설정 조회
  const getSchedulerSetting = async (mber) => {
    const url = "/api/toktokSettingList";
    const body = { mberNo: mber.mberNo };
    const res = await axios.post(url, body);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
      if (isSScenterMbers(mber.mberNo)) {
        onFullscreen();
        navigate("/mypage/kiosk/salesCheck");
      } else {
        navigate("/mypage");
      }
    }
  };

  return (
    <>
      <LoginForm getSchedulerSetting={getSchedulerSetting} />
    </>
  );
};

export default Login;

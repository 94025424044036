import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";

import { IoCloseSharp } from "react-icons/io5";
import { CiImageOn } from "react-icons/ci";

import Modal from "../../../../../common/modal";
import { ChangeUserInfoFormContainer } from "../styled/modifyProfile";
import { SubmitButton } from "../../../styled/mypage";

const ChangeUserInfoForm = ({}) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const isMobile = useMediaQuery({ query: "(max-width:640px)" });
  const [formData, setFormData] = useState({
    username: "",
    mberNm: "",
    moblphonNo: "",
    email: "",
  });
  const [image, setImage] = useState();
  const [isDuplicate, setIsDuplicate] = useState({ moblphonNo: true, email: true });
  const [verifyPw, setVerifyPw] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!user) return;
    getMyProfile();
  }, []);

  // 전체 체크
  const validateFormData = async () => {
    const fieldLabels = {
      username: "아이디",
      mberNm: "고객명",
      moblphonNo: "휴대폰 번호",
      email: "이메일",
    };

    for (let key in fieldLabels) {
      if (textNotExist(fieldLabels[key])) {
        const label = fieldLabels[key] || "";
        toast.error(`${chooseParticle(label, "을를")} 입력해주세요.`);
        return;
      }
    }

    for (let key in isDuplicate) {
      if (!isDuplicate[key]) {
        const label = fieldLabels[key] || "";
        toast.error(`${label} 중복 확인을 해주세요.`);
        return;
      }
    }

    const regKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if (regKor.test(formData.email)) {
      toast.error("이메일 양식이 일치하지 않습니다.");
      return;
    }

    if (!verifyPw || !verifyPw.trim().length) {
      toast.error("비밀번호가 존재하지 않습니다.");
      return;
    }

    const isUser = await isUserVerify();

    if (isUser) {
      handleModalOpen();
    }
  };

  // 계정 정보 조회
  const getMyProfile = async () => {
    const url = "/api/mber";
    const body = {
      mberNo: user.mberNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      setFormData(res.data);
    }
  };

  const changeInput = (e) => {
    const { name, value } = e.target;

    setFormData((data) => ({ ...data, [name]: value }));

    if (isDuplicate.hasOwnProperty(name) && isDuplicate[name]) {
      setIsDuplicate((state) => ({ ...state, [name]: false }));
    }
  };

  // 이미지 추가
  const addImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      const imageUrl = reader.result;
      setFormData((data) => ({
        ...data,
        imgPath: imageUrl,
      }));

      setImage(file);
      e.target.value = "";
    };
  };

  // 이미지값 반환
  const uploadLogoImage = async (item) => {
    if (!item) {
      return data.logoImage;
    }
    const form = new FormData();
    form.append("file", item);
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFile";
    const res = await axios.post(url, form);

    return res?.data?.responseData?.fileName;
  };

  // 모달
  const handleModalOpen = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">회원 정보 수정</h2>
        <button onClick={handleModalOpen} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          회원 정보를 변경하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={handleSubmit}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  // 중복검사
  const alreadyExists = async (type) => {
    const types = {
      moblphonNo: {
        url: "/api/duplMoblphonNo",
        body: { moblphonNo: formData.moblphonNo },
        checkDuplicate: (data) => !data.success,
        displayName: "휴대폰 번호",
      },
      email: {
        url: "/api/duplEmail",
        body: { email: formData.email },
        checkDuplicate: (data) => !data.success,
        displayName: "이메일",
      },
    };

    try {
      const { url, body, checkDuplicate, displayName } = types[type];
      const res = await axios.post(url, body);

      if (checkDuplicate(res.data)) {
        toast.error(`이미 사용 중인 ${displayName}입니다.`);
        setIsDuplicate((data) => ({ ...data, [type]: false }));
      } else {
        toast.success(`사용 가능한 ${displayName}입니다.`);
        setIsDuplicate((data) => ({ ...data, [type]: true }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 계정 정보 저장
  const handleSubmit = async () => {
    const url = "/api/mber";
    const body = {
      ...formData,
      username: formData.username,
      password: verifyPw,
      mberNm: formData.mberNm,
      moblphonNo: formData.moblphonNo,
      email: formData.email,
      createMberId: formData.username,
      updateMberId: formData.username,
      rgsCl: "MBRS04",
      delYn: "N",
    };

    if (image) {
      const uploaded = await uploadLogoImage(image);
      if (!uploaded) {
        setModalOpen(false);
        return;
      }
      body.imgPath = uploaded;
    }

    const res = await axios.put(url, body);

    if (res.data.success) {
      toast.success("회원 정보가 수정되었습니다.");
      navigate("/mypage");
    }
  };

  // 비밀번호 확인
  const isUserVerify = async () => {
    const url = "/api/signIn";
    const body = {
      id: user.userName,
      pwd: verifyPw,
    };

    const res = await axios.post(url, body);

    console.log(body);
    console.log(res);
    if (res.data.success === false) {
      toast.error("비밀번호가 일치하지 않습니다.");
      return false;
    } else {
      return true;
    }
  };

  // 유효성 검사
  const validationCheck = (type) => {
    const types = {
      moblphonNo: {
        reg: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
        msg: "유효하지 않은 휴대폰 번호입니다.",
      },
      email: {
        reg: /([A-Za-z0-9_.-]+(\.[A-Za-z0-9_.-]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
        msg: "유효하지 않은 이메일 주소입니다.",
      },
    };

    const { reg, msg } = types[type];
    const inputValue = formData[type] || undefined;

    if (inputValue && reg.test(inputValue)) {
      alreadyExists(type);
    } else {
      toast.error(msg);
    }
  };

  return (
    <ChangeUserInfoFormContainer className={setting?.mode}>
      <span>※ 모두 필수 입력 정보입니다.</span>
      <div className="viewer">
        <ul>
          <li className="imgPath">
            <div class="writing_tit">프로필 이미지</div>
            <div class="writing_cont">
              <label htmlFor="fileInput" tabIndex={1}>
                {formData.imgPath ? (
                  <>
                    <img src={imgHandler(formData.imgPath)} />
                    <button
                      className="deleteBtn"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setFormData((data) => ({ ...data, imgPath: "" }));
                        setImage(null);
                      }}>
                      <IoCloseSharp />
                    </button>
                  </>
                ) : (
                  <div className="notImage">
                    <CiImageOn />
                  </div>
                )}
              </label>
              <input type="file" id="fileInput" accept="image/*" onChange={addImage} />
            </div>
          </li>
          <li>
            <div className="writing_tit">아이디</div>
            <div className="writing_cont">
              <input
                type="text"
                name="username"
                placeholder="아이디 (영문 + 숫자, 6~15자리)"
                value={formData.username}
                disabled
                tabIndex={2}
              />
            </div>
          </li>
          <li>
            <div className="writing_tit">비밀번호</div>
            <div className="writing_cont">
              <input
                type="password"
                name="password"
                placeholder="비밀번호"
                value={verifyPw}
                onChange={(e) => setVerifyPw(e.target.value)}
                tabIndex={3}
              />
              {/* <button type="button" name="button" className="btn_line gray btn_duplicate no_cusor">
                <i className="icon_secure"></i>
              </button> */}
            </div>
          </li>
          <li>
            <div className="writing_tit">고객명</div>
            <div className="writing_cont">
              <input
                type="text"
                name="mberNm"
                placeholder="고객명을 입력하세요"
                value={formData.mberNm}
                disabled
                tabIndex={4}
              />
            </div>
          </li>
          <li className="moblphonNo">
            <div className="writing_tit">고객 휴대전화</div>
            <div className="writing_cont">
              <input
                type="text"
                name="moblphonNo"
                placeholder="휴대폰 번호(-없이 입력)"
                value={formData.moblphonNo}
                onChange={changeInput}
                tabIndex={5}
              />
              <button type="button" onClick={() => validationCheck("moblphonNo")} tabIndex={7}>
                <span>중복 확인</span>
              </button>
            </div>
          </li>
          <li className="email">
            <div className="writing_tit">이메일</div>
            <div className="writing_cont">
              <input
                type="text"
                className=""
                name="email"
                placeholder="이메일"
                value={formData.email}
                onChange={changeInput}
                tabIndex={6}
              />
              <button type="button" onClick={() => validationCheck("email")} tabIndex={7}>
                <span>중복 확인</span>
              </button>
            </div>
          </li>
        </ul>
      </div>

      <SubmitButton>
        <button onClick={validateFormData}>
          <span>저장</span>
        </button>
      </SubmitButton>
      <Modal
        modalState={modalOpen}
        handleModalState={handleModalOpen}
        html={ModalForm()}
        w={isMobile ? "70%" : "17%"}
        h="auto"
      />
    </ChangeUserInfoFormContainer>
  );
};

export default ChangeUserInfoForm;

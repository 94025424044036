import React, { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import { useMediaQuery } from "react-responsive";

import { IoIosSearch } from "react-icons/io";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { CiMap, CiTextAlignLeft } from "react-icons/ci";
import { GoCheckbox } from "react-icons/go";

import { IsDetails } from "../../styled/calendarEntry";

import EventListItem from "../../common/eventListItem";
import TodoScreen from "./todoScreen";

const ScheduleFormDetails = ({
  formData,
  setFormData,
  changeInput,
  openPostCode,
  saveToLocalStorage,
  todos,
  setTodos,
  // isRecurring
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [isDetails, setIsDetails] = useState(false);
  const todoInputRefs = useRef([]);
  const [todoAdded, setTodoAdded] = useState(false);
  const [memo, setMemo] = useState("");

  useEffect(() => {
    if (todoAdded) {
      const lastTodoIndex = formData.todo.length - 1;
      const lastTodoRef = todoInputRefs.current[lastTodoIndex];
      if (lastTodoRef && typeof lastTodoRef.focus === "function") {
        lastTodoRef.focus();
      }
      setTodoAdded(false);
    }
  }, [formData.todo, todoAdded]);

  const repeat = {
    선택안함: "notRepeat",
    매일: "DAILY",
    매주: "WEEKLY",
    매월: "MONTHLY",
    매년: "YEARLY",
  };

  const addTodos = () => {
    const newId = uuid();
    const newTodo = {
      todoNo: null,
      tempId: newId,
      title: "",
      completeYn: "N",
      delYn: "N",
    };

    setFormData((data) => {
      const updated = { ...data, todo: [...data.todo, newTodo] };
      saveToLocalStorage(updated);
      return updated;
    });

    setTodoAdded(true);
    todoInputRefs.current = todoInputRefs.current.concat(newId);
  };

  const changeTodo = (e) => {
    const { name, value } = e.target;

    setFormData((data) => {
      const updated = {
        ...data,
        todo: data.todo.map((item) => (item.tempId === name ? { ...item, title: value } : item)),
      };
      saveToLocalStorage(updated);
      return updated;
    });
  };

  const deleteTodo = (id) => {
    setFormData((data) => {
      const updatedTodos = data.todo.filter((item) => item.tempId !== id);
      saveToLocalStorage({ ...data, todo: updatedTodos });

      const deletedIndex = data.todo.findIndex((item) => item.tempId === id);
      todoInputRefs.current.splice(deletedIndex, 1);

      return { ...data, todo: updatedTodos };
    });
  };

  // const changeRecur = (e) => {
  //   const { name, value } = e.target;

  //   if (isRecurring && name === "freq") {
  //     toast.error("반복 일정은 반복 수정이 불가합니다.");
  //     return;
  //   }

  //   setFormData((data) => ({
  //     ...data,
  //     recur: {
  //       ...data.recur,
  //       [name]: value,
  //     },
  //   }));
  // };

  return (
    <>
      <IsDetails>
        <span onClick={() => setIsDetails(!isDetails)}>
          {isDetails ? "접기" : "상세설정"}
          {isDetails ? <SlArrowUp /> : <SlArrowDown />}
        </span>
      </IsDetails>
      {isDetails && (
        <ul className={`font_16 ${isDetails ? "open" : ""}`}>
          <EventListItem icon={<CiMap />} title="위치" addClass="addr">
            <input
              type="text"
              value={formData.place}
              readOnly
              placeholder="주소를 추가해주세요"
              onClick={openPostCode}
            />
            <div>
              <input
                type="text"
                name="placeDetail"
                placeholder="상세주소"
                value={formData.placeDetail}
                onChange={changeInput}
              />
              <button onClick={openPostCode}>
                <IoIosSearch />
              </button>
            </div>
          </EventListItem>
          <EventListItem icon={<CiTextAlignLeft />} title="메모" addClass="memo">
            <TodoScreen formData={formData} todos={todos} setTodos={setTodos} />
          </EventListItem>
        </ul>
      )}
    </>
  );
};

export default ScheduleFormDetails;

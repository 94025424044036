import PortfolioList from "./portfolioPage/portfolioList";
import { useOutletContext } from "react-router";

import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

import { ChicPortfolioContainer } from "../shopping/component/styled/chicTheme";
import { ModernProductsContainer } from "../shopping/component/styled/modernTheme";
import { CuteProductsContainer } from "../shopping/component/styled/cuteTheme";
import { SimplePortfolioContainer } from "../shopping/component/styled/simpleTheme";
import { SmPortfolioContainer } from "../shopping/component/styled/smTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";

export default function PortfolioPage() {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme.toString()) {
      case "0":
        return (
          <DefaultContaienr>
            <PortfolioList />
          </DefaultContaienr>
        );
      case "1":
        return (
          <ChicPortfolioContainer>
            <PortfolioList />
          </ChicPortfolioContainer>
        );
      case "2":
        return (
          <ModernProductsContainer>
            <ModernBanner />
            <PortfolioList />
          </ModernProductsContainer>
        );
      case "3":
        return (
          <CuteProductsContainer>
            <CuteBanner />
            <PortfolioList />
          </CuteProductsContainer>
        );
      case "4":
        return (
          <SimplePortfolioContainer>
            <SimpleBanner />
            <PortfolioList />
          </SimplePortfolioContainer>
        );
      case "sm":
        return (
          <SmPortfolioContainer>
            <PortfolioList />
          </SmPortfolioContainer>
        );
      default:
        return <PortfolioList />;
    }
  };

  return <>{renderComponent()}</>;
}

import axios from "axios";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";

import { IoMdOptions } from "react-icons/io";

import ProductFilter from "./common/productFilter";
import ProductTable from "./common/productTable";
import ProductList from "./common/prodoctList";

import { toast } from "react-toastify";
import { IoCheckmark } from "react-icons/io5";
import { ProductFormContainer } from "../styled/product";
import { setProductIndex } from "../../../../../store/compoIndex";

export default function ProductSyncForm({ categories, categoriesObj, synced, getSyncProduct }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [pages, setPages] = useState({ limit: 10, page: 1 });
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [filtered, setFiltered] = useState({
    minBasePrice: "",
    maxBasePrice: "",
    brand: "",
    name: "",
    categoryId: null,
  });

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    console.log(filtered);
  }, [filtered]);

  const getProduct = async (limit = 10, page = 1) => {
    try {
      const url = "/erp/cloudProducts/get";
      const body = { limit, offset: limit * (page - 1) };

      Object.entries(filtered).forEach(([key, value]) => {
        if (!!value) {
          if (key === "minBasePrice" || key === "maxBasePrice") {
            body[key] = parseFloat(value.toString().replace(/,/g, ""));
          } else {
            body[key] = value;
          }
        }
      });

      const res = await axios.post(url, body);
      console.log(body);
      console.log(res);

      if (res.status === 200) {
        setTotal(res.data.totalCount);
        setProducts(res.data.products);
        setSelectedProducts([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changePages = ({ target, value }) => {
    setPages((data) => {
      const updatedPages = { ...data, [target]: value };
      if (target === "limit") updatedPages.page = 1;

      getProduct(updatedPages.limit, updatedPages.page);

      return updatedPages;
    });
  };

  const handleSync = async () => {
    if (!selectedProducts || !selectedProducts.length) return;
    const url = "/erp/vendorProducts/create";
    const body = {
      cmpnyNo: user?.cmpnyNo,
      cloudProductId: selectedProducts.map((item) => parseInt(item.id, 10)).filter(Boolean),
    };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success("상품이 성공적으로 연동되었습니다.");
        await getSyncProduct();
        dispatch(setProductIndex(0));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheck = (prd) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.some((item) => item.id === prd.id)
        ? prevSelected.filter((item) => item.id !== prd.id)
        : [...prevSelected, prd],
    );
  };

  const handleAllCheck = (isChecked) => {
    if (isChecked) {
      const allItems = products.filter((item) => !synced?.[item?.id]);
      setSelectedProducts(allItems);
    } else {
      setSelectedProducts([]);
    }
  };

  return (
    <ProductFormContainer className={setting?.mode}>
      <div className="products">
        <span className="interlocked">
          <i>
            <IoCheckmark />
          </i>
          조회한 제품 총 {total}개
        </span>
        <ProductFilter
          filtered={filtered}
          categories={categories}
          categoriesObj={categoriesObj}
          setFiltered={setFiltered}
          pages={pages}
          getProduct={getProduct}
          isProductSync={true}
          handleSync={handleSync}
          changePages={changePages}
        />
        {isMobile ? (
          <ProductList
            products={products}
            total={total}
            pages={pages}
            changePages={changePages}
            selectedProducts={selectedProducts}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
            synced={synced}
            categoriesObj={categoriesObj}
            isSync={true}
          />
        ) : (
          <ProductTable
            products={products}
            total={total}
            pages={pages}
            changePages={changePages}
            selectedProducts={selectedProducts}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
            synced={synced}
            categoriesObj={categoriesObj}
            isSync={true}
          />
        )}
      </div>
    </ProductFormContainer>
  );
}

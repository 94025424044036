import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRegex } from "../../../../../hooks/useRegex";
import { HomepageEditContainer } from "../styled/common";

export default function HomepageEdit() {
  useEffect(() => {
    getCompanyInfo();
    checkedDomain();
  }, []);

  const cmpnyNo = useSelector((state) => state?.user?.data[0]?.cmpnyNo);
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const { BussinessFormat } = useRegex();

  const [companyInfo, setCompanyInfo] = useState({});
  const [res, setRes] = useState({});

  const getCompanyInfo = async () => {
    const url = "/api/cmpnyInfo";
    const body = {
      cmpnyNo,
    };
    const result = await axios.post(url, body);
    setCompanyInfo(result.data);
  };

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
      cmpnyNo,
    };
    const response = await axios.post(url, body);
    setRes(response);
  };

  const openNewTab = () => {
    window.open(`http://1472.ai/shop/${res.data.domain}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <HomepageEditContainer className={setting?.mode}>
      <div className="info">다음의 홈페이지를 수정합니다.</div>
      <ul>
        <li>
          <span>홈페이지 주소</span>
          <span>
            <Link onClick={openNewTab}>https://1472.ai/shop/{res?.data?.domain}</Link>
          </span>
        </li>
        <li>
          <span>회사이름</span>
          <span>{companyInfo.cmpnyNm}</span>
        </li>
        <li>
          <span>사업자번호</span>
          <span>{BussinessFormat(companyInfo.bizrno)}</span>
        </li>
        <li>
          <span>생성타입</span>
          <span>제품연동</span>
        </li>
      </ul>
    </HomepageEditContainer>
  );
}

import { useSelector } from "react-redux";
import { HomepageEndTitleContainer } from "./styled/homepageEnd";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export default function HomepageEndTitle() {
  const company = useSelector((state) => state?.company?.data);
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const [res, setRes] = useState({});

  useEffect(() => {
    if (!company) return;
    checkedDomain();
  }, []);

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
      cmpnyNo: company?.[0]?.cmpnyNo,
    };
    const res = await axios.post(url, body);
    console.log(res);
    setRes(res);
  };

  return (
    <HomepageEndTitleContainer className={setting?.mode}>
      <p>
        <strong>{user?.name}</strong>님<br />
        홈페이지 생성이 완료되었습니다.
        <br />
      </p>
      <span>지금 접속하여 회사정보, 제품정보 등 자세한 사항을 수정해보세요.</span>
      <ul>
        <li>
          <span>유형</span>
          <span>{company?.[0]?.cmpnySe}</span>
        </li>
        <li>
          <span>사업자번호</span>
          <span>{company?.[0]?.bizrno}</span>
        </li>
        <li>
          <span>회사이름</span>
          <span>{company?.[0]?.cmpnyNm}</span>
        </li>
        <li>
          <span>도메인설정</span>
          <span>
            <a href={`https://1472.ai/shop/${res?.data?.domain}`}>
              https://1472.ai/shop/{res?.data?.domain}
            </a>
          </span>
        </li>
      </ul>

      <div className="buttons">
        <a href={`https://1472.ai/shop/${res?.data?.domain}`}>
          <span>생성된 홈페이지 바로가기</span>
        </a>
        <a href="/mypage/homepageSetting">
          <span>홈페이지 설정 바로가기</span>
        </a>
      </div>
    </HomepageEndTitleContainer>
  );
}

import HomepageEndForm from "./component/homepageEndForm";
import MypageBanner from "../mypageBanner";
import { Container } from "../styled/mypage";
import { useSelector } from "react-redux";

export default function HomepageEnd() {
  const setting = useSelector((state) => state?.user?.setting);
  return (
    <Container className={setting?.mode}>
      <HomepageEndForm />
    </Container>
  );
}

import MypageBanner from "../mypageBanner";
import CompanyInfoForm from "./component/companyInfoForm";
import CompanyInfoTitle from "./component/companyInfoTitle";
import ApplicationSecForm from "../../application/component/applicationSecForm";
import { useSelector } from "react-redux";
import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";

const CompanyInfo = () => {
  const index = useSelector((state) => state.compoIndex.company);
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <Container className={setting.mode}>
      <PageInfo label="기업 정보" />
      <div className="content">
        <CompanyInfoTitle index={index} />
        <Components index={index} />
      </div>
    </Container>
  );
};

export default CompanyInfo;

function Components({ index }) {
  return <>{[<CompanyInfoForm />, <ApplicationSecForm />][index]}</>;
}

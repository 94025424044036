import React, { useEffect, useState } from "react";
import HomepageSubMenu from "./component/homepageSubMenu";
import HomepageForm from "./component/homepageForm";
import CompanyAddForm from "./component/companyAddForm";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";
import { useSelector } from "react-redux";

export default function Hompage() {
  const setting = useSelector((state) => state?.user?.setting);
  const [activeMenu, setActiveMenu] = useState("/homepage");

  const handleChangeComponent = (link) => {
    setActiveMenu(link);
  };

  const location = useLocation();
  const saveMessage = location.state?.saveMessage;

  useEffect(() => {
    if (saveMessage) {
      toast.success("홈페이지가 생성되었습니다.");
    }
  }, [saveMessage]);

  const components = [
    { link: "/homepage", component: <HomepageForm /> },
    { link: "/companyAdd", component: <CompanyAddForm /> },
  ];

  return (
    <Container className={setting?.mode}>
      <div className="content">
        <PageInfo label="홈페이지 목록" />
        <div>
          <HomepageSubMenu activeMenu={activeMenu} handleChangeComponent={handleChangeComponent} />
          {components.map((item) =>
            item.link === activeMenu ? (
              <React.Fragment key={item.link}>{item.component}</React.Fragment>
            ) : null,
          )}
        </div>
      </div>
    </Container>
  );
}

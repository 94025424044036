import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import Modal from "../../../common/modal";
import ChangeUserInfoForm from "./component/modifyProfilePage/changeUserInfoForm";
import ChangePwForm from "./component/modifyProfilePage/changePwForm";
import MembershipWithdrawal from "./component/modifyProfilePage/membershipWithdrawal";
import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";
import { HomePageSettingMenuContainer } from "../homepageSetting/component/styled/common";
import { TabMenu } from "./component/styled/modifyProfile";

const ModifyProfilePage = () => {
  const location = useLocation();
  const setting = useSelector((state) => state?.user?.setting);
  const tab = location?.state?.tab;
  const [tabIndex, setTabIndex] = useState(tab ? 2 : 0);

  // 탭 메뉴
  const tabs = ["계정정보변경", "비밀번호변경", "회원탈퇴"];

  function Components({ index }) {
    return <>{[<ChangeUserInfoForm />, <ChangePwForm />, <MembershipWithdrawal />][index]}</>;
  }

  return (
    <Container className={setting?.mode}>
      <PageInfo label="계정정보" />
      <TabMenu className={setting?.mode}>
        <ul>
          {tabs.map((item, idx) => (
            <li onClick={() => setTabIndex(idx)} className={idx === tabIndex ? "active" : ""}>
              {item}
            </li>
          ))}
        </ul>
      </TabMenu>
      {<Components index={tabIndex} />}
    </Container>
  );
};

export default ModifyProfilePage;

import styled from "styled-components";

export const ProductMenuContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  text-align: center;
  &.dark {
    li {
      background: #000;
      color: #eee;
      &.active {
        background: rgba(37, 99, 235, 0.7);
      }
    }
  }
  ul {
    display: flex;
    gap: 5px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    li {
      background: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      width: 200px;
      height: 50px;
      line-height: 50px;
      border-radius: 10px;
      &.active {
        background: #fff;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    font-size: 14px;
    margin: 0px auto 30px;
    ul li {
      width: 134px;
      height: 38px;
      line-height: 38px;
    }
  }
  @media screen and (max-width: 767px) {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    ul {
      width: max-content;
      overflow-x: auto;
      li {
        width: 125px;
        font-size: 14px;
        height: 35px;
        line-height: 35px;
      }
    }
  }
`;

export const ProductFormContainer = styled.div`
  &.dark .products {
    color: #eee;
    .interlocked {
      color: #ddd;
    }
  }
  .products {
    margin: 0px auto 70px;
    max-width: 1440px;
    padding: 0px 20px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    .interlocked {
      display: flex;
      align-items: center;
      gap: 5px;
      i svg {
        display: block;
      }
    }
  }
`;

export const ProductTableContainer = styled.div`
  &.dark {
    table {
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      tr th,
      tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      }
      tr td {
        .buttons button:last-child {
          background: #000;
          color: #eee;
        }
      }
      .checkmark {
        background: rgb(0, 0, 0);
        border: 2px solid rgb(85, 85, 85);
      }
    }
  }
  table {
    border-collapse: collapse;
    letter-spacing: -1px;
    font-size: 16px;
    text-align: center;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    .checkmark {
      background: #fff;
    }
    tr th {
      height: 60px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      font-size: 20px;
      letter-spacing: -1px;
    }
    tr td {
      height: 70px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      img {
        max-width: 50px;
        height: 50px;
        object-fit: contain;
      }
      span {
        display: block;
        &.name {
          position: relative;
          &[data-description] {
            cursor: pointer;
            &::after {
              content: attr(data-description);
              position: absolute;
              transform: translate(-50%, 0);
              left: 50%;
              top: 100%;
              background-color: rgba(0, 0, 0, 0.4);
              opacity: 0;
              transition: 0.3s;
              font-size: 12px;
              padding: 5px;
              border-radius: 4px;
              white-space: nowrap;
              color: white;
            }
            &:hover::after {
              opacity: 1;
            }
          }
        }
      }
      .buttons {
        button {
          width: calc(50% - 5px);
          max-width: 100px;
          font-size: 16px;
          border-radius: 5px;
          padding: 5px 0;
          border: none;
          &:first-child {
            margin-right: 10px;
            background-color: rgba(37, 99, 235, 0.7);
            color: #fff;
          }
          &:last-child {
            background-color: #fff;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    table tr {
      td,
      th {
        font-size: 14px;
      }
    }
  }
`;

export const EditModalContainer = styled.div`
  height: min-content;
  letter-spacing: -1px;
  &.dark {
    label {
      border: 1px solid #6b6b6b;
      color: #6b6b6b;
    }
    .form li .writing_tit em.point::after {
      color: #6b6b6b;
    }
    .form li .writing_cont {
      input[type="text"],
      input[type="number"],
      .selected {
        background: rgb(51, 51, 51);
        color: #eee;
        border: 1px solid rgb(51, 51, 51);
      }
    }
    .actions button {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .modalInfo {
    display: flex;
    align-items: center;
    font-size: 24px;
    justify-content: space-between;
    margin-bottom: 26px;
    h3 {
      font-weight: normal;
    }
    button {
      border: none;
      background: transparent;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    li {
      width: 100%;
      &.half_li {
        width: calc(50% - 8px);
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .writing_tit {
        font-size: 20px;
        font-weight: normal;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 5px;
        .tit {
          font-weight: normal;
        }
        em.point {
          font-size: 0;
          font-style: normal;
        }
        em.point::after {
          width: 12px;
          height: 12px;
          display: inline;
          content: "*";
          font-size: 18px;
          font-weight: 500;
          color: #0074ca;
          margin-left: 8px;
        }
      }
      .preview li {
        width: calc((100% - 40px) / 5);
      }
      .writing_cont {
        input[type="text"],
        input[type="number"],
        .selected {
          width: 100%;
          color: rgba(0, 0, 0, 0.7);
          border: 1px solid #a9a9a9;
          height: 50px;
          border-radius: 10px;
          padding: 0 20px;
          font-size: 20px;
          outline: none;
          &:focus {
            border: 1px solid #a9a9a9 !important;
            box-shadow: none;
          }
        }
        .category {
          position: relative;
          .selected {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            font-weight: normal;
            button {
              border: none;
              background: #7d7d7d;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              color: #fff;
              svg {
                display: block;
                margin: auto;
              }
            }
          }
          .categories {
            position: absolute;
            top: calc(100% + 5px);
            left: 0;
            background: #fff;
            font-size: 20px;
            font-weight: normal;
            width: 100%;
            height: 200px;
            border: 1px solid #a9a9a9;
            border-radius: 10px;
            overflow: hidden;
            ul {
              width: 100%;
              height: 100%;
              overflow-y: auto;
              z-index: 3;
              &::-webkit-scrollbar {
                width: 10px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #5a5a5a;
                border-radius: 10px;
                border: 2px solid #d9d9d9;
              }
              &::-webkit-scrollbar-track {
                background-color: #d9d9d9;
                border-radius: 10px;
              }
              li {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 5px;
                transition: 0.3s;
                color: rgba(0, 0, 0, 0.5);
                &:hover {
                  background: #efefef;
                }
              }
            }
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 50px;
    button {
      width: 272px;
      height: 40px;
      border-radius: 5px;
      border: none;
      font-size: 16px;
      color: #fff;
      background: rgba(0, 0, 0, 0.3);
      &:last-child {
        color: #fff;
        background: rgba(37, 99, 235, 0.7);
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .modalInfo {
      font-size: 20px;
    }
    .form {
      gap: 10px;
      li {
        .writing_tit {
          font-size: 16px;
        }
        .writing_cont {
          input[type="text"],
          input[type="number"],
          .selected {
            height: 30px;
            border-radius: 10px;
            padding: 0 10px;
            font-size: 14px;
          }
          .selected button {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .actions {
      button {
        width: 40%;
        height: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .modalInfo {
      font-size: 14px;
      margin-bottom: 14px;
    }
    .form {
      gap: 10px;
      li {
        .writing_tit {
          font-size: 12px;
          em.point::after {
            font-size: 12px;
          }
        }
        .writing_cont {
          input[type="text"],
          input[type="number"],
          .selected {
            height: 30px;
            border-radius: 10px;
            padding: 0 10px;
            font-size: 12px;
          }
        }
      }
    }
    .actions {
      margin-top: 24px;
      button {
        font-size: 12px;
      }
    }
  }
`;

export const DetailProductContainer = styled.div`
  height: min-content;
  letter-spacing: -1px;
  .modalInfo {
    display: flex;
    align-items: center;
    font-size: 24px;
    justify-content: space-between;
    margin-bottom: 26px;
    h3 {
      font-weight: normal;
    }
    button {
      border: none;
      background: transparent;
    }
  }
  ul {
    font-size: 20px;
    font-weight: normal;
    li {
      margin-bottom: 15px;
      .tit {
        display: inline-block;
        width: 130px;
      }
      .content {
        display: inline-block;
        width: calc(100% - 130px);
        color: #7d7d7d;
      }
      &.media {
        .content {
          width: 100%;
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          overflow: hidden;
          img {
            border-radius: 10px;
            border: 1px solid #a9a9a9;
            background: rgba(0, 0, 0, 0.1);
            width: calc((100% - 90px) / 10);
            aspect-ratio: 1;
            object-fit: contain;
          }
        }
      }
    }
  }
  .actions {
    text-align: center;
    margin-top: 50px;
    button {
      width: 272px;
      height: 40px;
      border-radius: 5px;
      border: none;
      font-size: 16px;
      color: #fff;
      background: rgba(37, 99, 235, 0.7);
    }
  }
  @media screen and (max-width: 1050px) {
    .modalInfo {
      font-size: 20px;
    }
    ul {
      font-size: 16px;
    }

    .actions {
      button {
        width: 40%;
        height: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .modalInfo {
      font-size: 14px;
      margin-bottom: 14px;
    }
    ul {
      font-size: 12px;
      li {
        margin-bottom: 10px;
      }
      li.media .content img {
        width: calc((100% - 40px) / 5);
      }
    }
    .actions {
      margin-top: 24px;
      button {
        font-size: 12px;
      }
    }
  }
`;

export const ProductAddFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    color: #eee;
    .info {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      .xlsxButtons {
        button {
          background: rgba(0, 0, 0, 0.5);
          color: #eee;
        }
        label {
          background: #000;
        }
      }
    }
    .form .inputField > li {
      &.thumbnail .writing_tit label {
        border: 1px solid #eee;
        color: #eee;
      }
      .writing_cont {
        input[type="text"],
        input[type="number"],
        .selected {
          background: #000;
          color: #eee;
          border: 1px solid #000;
          &:focus {
            border: 1px solid #000 !important;
          }
        }
      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 15px;
    margin-bottom: 15px;
    .xlsxButtons {
      button,
      label {
        width: 112px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        border-radius: 5px;
      }
      button {
        background: #fff;
        border: none;
        margin-right: 10px;
      }
      label {
        cursor: pointer;
        text-align: center;
        display: inline-block;
        background: rgba(37, 99, 235, 0.7);
        color: #fff;
      }
      input[type="file"] {
        display: none;
      }
    }
  }
  .form {
    .inputField {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      > li {
        width: 100%;
        display: flex;
        .preview li {
          width: calc((100% - 90px) / 10);
          border: 1px solid rgba(255, 255, 255, 0.5);
        }
        &.thumbnail {
          flex-wrap: wrap;
          .writing_tit,
          .writing_cont {
            width: 100%;
            label {
              border: 1px solid rgba(0, 0, 0, 0.5);
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
        &.half_li {
          width: calc(50% - 8px);
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .writing_tit {
          width: 150px;
          font-size: 20px;
          font-weight: normal;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 5px;
          .tit {
            font-weight: normal;
          }
          em.point {
            font-size: 0;
            font-style: normal;
          }
          em.point::after {
            width: 12px;
            height: 12px;
            display: inline;
            content: "*";
            font-size: 18px;
            font-weight: 500;
            margin-left: 8px;
          }
        }
        .writing_cont {
          width: calc(100% - 150px);
          input[type="text"],
          input[type="number"],
          .selected {
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            background: transparent;
            width: 100%;
            height: 55px;
            line-height: 55px;
            padding: 0 20px;
            &:focus {
              border: 1px solid rgba(255, 255, 255, 0.5) !important;
              box-shadow: none;
              outline: none;
            }
          }
        }
        .category {
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    font-size: 14px;
    .info {
      flex-wrap: wrap;
      margin-bottom: 30px;
      span {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    .form .inputField > li {
      .writing_tit {
        width: 95px;
        font-size: 14px;
      }
      .writing_cont {
        width: calc(100% - 95px);
        input[type="text"],
        input[type="number"],
        .selected {
          height: 30px;
          line-height: 30px;
          padding: 0px 10px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .form .inputField > li {
      flex-wrap: wrap;
      &.half_li {
        width: 100%;
      }
      .writing_tit,
      .writing_cont {
        width: 100%;
      }
      .preview li {
        width: calc(50% - 5px);
      }
    }
  }
`;

export const Category = styled.div`
  &.dark {
    .categories {
      background: rgb(51, 51, 51);
      border: 1px solid transparent;
      ul {
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #adadad;
          border-radius: 10px;
          border: 2px solid rgb(51, 51, 51);
        }
        &::-webkit-scrollbar-track {
          background-color: rgb(51, 51, 51);
          border-radius: 10px;
        }
        li {
          color: #fff;
          &:hover {
            background: rgba(0, 0, 0, 0.5);
          }
          label {
            border: none;
          }
          .checkmark {
            background: rgb(0, 0, 0);
            border: 2px solid rgb(85, 85, 85);
          }
        }
      }
    }
  }
  position: relative;
  .selected {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-weight: normal;
    button {
      border: none;
      background: #7d7d7d;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: #fff;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
  .categories {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background: #fff;
    font-size: 20px;
    font-weight: normal;
    width: 100%;
    height: 200px;
    border: 1px solid #a9a9a9;
    border-radius: 10px;
    overflow: hidden;
    ul {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      z-index: 3;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5a5a5a;
        border-radius: 10px;
        border: 2px solid #d9d9d9;
      }
      &::-webkit-scrollbar-track {
        background-color: #d9d9d9;
        border-radius: 10px;
      }
      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: 0.3s;
        color: rgba(0, 0, 0, 0.5);
        &:hover {
          background: #efefef;
        }
      }
    }
  }
`;

export const ProductListContainer = styled.div`
  letter-spacing: -1px;
  width: 100%;
  font-size: 14px;
  &.dark {
    ul li {
      background: #000;
      span.tit {
        color: rgba(255, 255, 255, 0.5);
      }
      div.buttons button {
        background: rgba(255, 255, 255, 0.2);
        color: #eee;
      }
    }
  }
  ul {
    li {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      margin-bottom: 10px;
      position: relative;
      label.block {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      > div {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        &:last-child {
          margin-bottom: 0;
        }
        &.media {
          .con {
            /* margin-top: 10px;
            width: 100%; */
            background: rgba(255, 255, 255, 0.5);
            width: 100px;
            height: 100px;
            border-radius: 10px;
            overflow: hidden;
          }
          img,
          video {
            width: 100%;
            height: 100%;
            aspect-ratio: 1;
            object-fit: contain;
          }
        }
        &.buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          button {
            border: none;
            background: rgba(255, 255, 255, 1);
            color: rgba(0, 0, 0, 0.5);
            padding: 5px 0;
            border-radius: 10px;
            width: calc(50% - 5px);
            &:last-child {
              background: rgba(37, 99, 235, 0.7);
              color: rgba(255, 255, 255, 1);
            }
          }
        }
        &.isSync {
          .con {
            color: rgba(37, 99, 235, 1);
          }
        }
      }
      span {
        display: inline-block;
        &.tit {
          width: 70px;
          color: rgba(0, 0, 0, 0.7);
        }
        &.con {
          width: calc(100% - 70px);
        }
      }
    }
  }
`;

export const ProductFilterContainer = styled.div`
  margin-bottom: 70px;
  &.dark {
    table {
      th,
      td {
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      }
      th {
        background: rgba(0, 0, 0, 0.5);
      }
      td input {
        color: #fff;
      }
      td {
        .selected .deleteBtn {
          background: #000;
        }
        .categories {
          background: #000;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #5a5a5a;
            border-radius: 10px;
            border: 2px solid #000;
          }
          &::-webkit-scrollbar-track {
            background-color: #000;
            border-radius: 10px;
          }
          li {
            &:hover,
            &.active {
              background: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
    .filterHandler .itemsPerPage label .checkmark {
      background: rgb(0, 0, 0);
      border: 2px solid rgb(85, 85, 85);
    }
    .filterHandler .actions button {
      background: #000;
      color: #fff;
    }
  }
  table {
    font-size: 20px;
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    th,
    td {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
    th {
      background: rgba(255, 255, 255, 0.5);
      width: 10%;
    }
    td {
      width: 40%;
      position: relative;
      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          width: 45%;
        }
      }
      input {
        border: none !important;
        width: 100%;
        height: 100%;
        padding: 20px;
        background: transparent;
        &:focus {
          border: none !important;
          box-shadow: none;
        }
      }
      .selected {
        padding: 0px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .not {
          color: #666;
        }
        .category {
          color: #0074ca;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .deleteBtn {
          border-radius: 50%;
          border: none;
          background: #efefef;
          width: 20px;
          height: 20px;
          svg {
            margin: auto;
            display: block;
          }
        }
      }
      .categories {
        width: 100%;
        position: absolute;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        background: #fff;
        z-index: 33;
        height: 200px;
        overflow-y: auto;
        top: 102%;
        left: 0;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #0074ca;
          border: 2px solid #fff;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        li {
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          &:hover,
          &.active {
            background: #efefef;
          }
        }
      }
    }
  }
  .filterHandler {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .itemsPerPage {
      display: flex;
      align-items: center;
      gap: 20px;
      label {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        input {
          display: none;
        }
        .checkmark {
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 2px solid rgb(204, 204, 204);
          background: #fff;
          border-radius: 3px;
          transition: 0.3s;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            display: none;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg) scale(0);
            width: 7px;
            height: 13px;
            border-style: solid;
            border-color: rgb(255, 255, 255);
            border-width: 0px 3px 3px 0px;
          }
        }
        input:checked ~ .checkmark::after {
          display: block;
          transform: translate(-50%, -50%) rotate(45deg) scale(1);
          border-color: rgb(0, 116, 202);
        }
      }
    }
    .actions {
      button {
        width: 60px;
        height: 30px;
        background: #fff;
        color: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        border: none;
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    &.dark {
      table {
        border: 1px solid rgba(0, 0, 0, 0.5);
        tr th,
        tr td {
          border: none;
        }
      }
    }
    table {
      border: 1px solid rgba(255, 255, 255, 0.5);
      tr {
        display: flex;
        flex-wrap: wrap;
        th,
        td {
          height: 50px;
          line-height: 50px;
          border: none;
        }
        th {
          width: 40%;
          border-bottom: none;
          font-weight: normal;
        }
        td {
          width: 60%;
          input,
          .price input,
          .selected {
            padding: 0;
            text-align: center;
          }
          .selected {
            justify-content: center;
          }
        }
      }
    }
    .categories {
      line-height: normal;
    }
  }
  @media screen and (max-width: 767px) {
    table {
      font-size: 14px;
      tr {
        td,
        th {
          height: 40px;
          line-height: 40px;
        }
        th {
          width: 40%;
          font-weight: normal;
        }
        td {
          width: 60%;
        }
      }
    }
    .filterHandler {
      flex-wrap: wrap;
      font-size: 14px;
      gap: 20px;
      .itemsPerPage {
        gap: 10px;
      }
      .actions {
        width: 100%;
        text-align: right;
      }
    }
  }
`;

import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import DaumPostcode from "react-daum-postcode";

import { CompanyInfoFormContainer } from "./styled/companyInfo";
import { handleChangeUser } from "../../../../store/user";

import { AddrSearchModal } from "../../scheduler/component/styled/calendarEntry";
import { SubmitButton } from "../../styled/mypage";

export default function CompanyInfoForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);
  const [formData, setFormData] = useState({
    cmpnyNm: "",
    rprNm: "",
    bizrno: "",
    adres: "",
    dtlAdres: "",
  });
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [showPostCode, setShowPostCode] = useState(false);

  const openPostCode = () => setShowPostCode(true);
  const closePostCode = () => setShowPostCode(false);

  // 작성
  const handleCreateCmpny = async () => {
    if (!validateFormData()) {
      return;
    }

    const url = "/api/cmpny";
    const body = {
      ...formData,
      createMberId: user.userName,
      updateMberId: user.userName,
      useYn: "Y",
    };

    const res = await axios.put(url, body);
    if (res.data.success) {
      await handleUpdateMber(res.data.message);
    }
  };

  const handleUpdateMber = async (cmpnyNo) => {
    const url = "/api/mber";
    const body = {
      mberNo: user.mberNo,
      mberNm: user.name,
      cmpnyNo,
      username: user.userName,
      moblphonNo: user.moblphonNo,
      email: user.email,
      rgsCl: "MBRS04",
      delYn: "N",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    const res = await axios.put(url, body);
    if (res.status === 200) {
      dispatch(handleChangeUser({ cmpnyNo }));
      toast.success("회사가 추가되었습니다.");
      navigate("/mypage/company");
    }
  };

  // 중복 확인 여부 체크, 빈값 체크
  const validateFormData = () => {
    const fieldLabels = {
      bizrno: "사업자 번호",
      cmpnyNm: "회사 이름",
      rprNm: "대표자명",
      adres: "주소",
    };

    for (let key in fieldLabels) {
      if (textNotExist(formData[key])) {
        const label = fieldLabels[key] || "";
        toast.error(`${chooseParticle(label, "을를")} 입력해주세요.`);
        return false;
      }
    }

    if (!isDuplicate) {
      toast.error("사업자번호 중복 확인을 해주세요.");
      return false;
    }

    return true;
  };

  // 사업자번호 중복 확인
  const alreadyExists = async () => {
    const bizrnoRegex = /^[0-9]{10}$/;
    if (!formData.bizrno || !bizrnoRegex.test(formData.bizrno)) {
      toast.error("유효하지 않은 사업자 번호입니다.");
      return;
    }

    const url = "/api/duplBizrNo";
    const body = { bizrno: formData.bizrno };
    const res = await axios.post(url, body);

    const message = res.data.message;

    if (message === "isNotExist") {
      toast.success("사용할 수 있는 사업자 번호입니다.");
      setIsDuplicate(true);
    } else {
      if (message === "isExist") {
        toast.error(`이미 사용 중인 사업자 번호입니다.`);
        setIsDuplicate(false);
      } else {
        toast.error(message);
        setIsDuplicate(false);
      }
    }
  };

  const changeInput = (e) => {
    const { name, value } = e.target;
    if (name === "bizrno" && isDuplicate) {
      setIsDuplicate(false);
    }
    setFormData((data) => ({ ...data, [name]: value }));
  };

  // 카카오 주소
  const userAddress = (data) => {
    const extraAddress = [data.bname, data.buildingName].filter(Boolean).join(", ");
    const fullAddress = data.address + (extraAddress ? ` (${extraAddress})` : "");

    setFormData((data) => {
      const updated = { ...data, adres: fullAddress };
      return updated;
    });

    closePostCode();
  };

  const fields = [
    {
      label: "사업자번호",
      name: "bizrno",
      placeholder: "사업자등록번호(숫자만 입력)",
      button: { text: "중복확인", onClick: alreadyExists },
    },
    { label: "회사명", name: "cmpnyNm", placeholder: "회사명을 입력해주세요." },
    { label: "대표자명", name: "rprNm", placeholder: "대표자명을 입력해주세요." },
  ];

  return (
    <CompanyInfoFormContainer className={setting?.mode}>
      {showPostCode && (
        <AddrSearchModal
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closePostCode();
            }
          }}>
          <div className="modalContent">
            <DaumPostcode onComplete={userAddress} />
            <button onClick={closePostCode}>닫기</button>
          </div>
        </AddrSearchModal>
      )}
      <div className="form">
        <ul className="viewer">
          {fields.map(({ label, name, placeholder, button }) => (
            <li key={name} className={name}>
              <strong className="tit">{label}</strong>
              <div className="con">
                <input
                  type="text"
                  name={name}
                  placeholder={placeholder}
                  value={formData[name]}
                  onChange={changeInput}
                />
                {button && (
                  <button className="btn_line" onClick={button.onClick}>
                    {button.text}
                  </button>
                )}
              </div>
            </li>
          ))}
          <li className="adres">
            <strong className="tit">주소</strong>
            <div className="con">
              <input
                type="text"
                id="wk_addr1"
                name="wk_addr1"
                placeholder="주소를 입력해주세요."
                value={formData.adres}
                readOnly
              />
              <button type="button" className="btn_line gray wk_post" onClick={openPostCode}>
                <span>주소찾기</span>
              </button>
              <input
                type="text"
                id="wk_addr3"
                name="dtlAdres"
                placeholder="상세주소를 입력해주세요."
                value={formData.dtlAdres}
                onChange={changeInput}
              />
            </div>
          </li>
        </ul>
        <SubmitButton>
          <button onClick={handleCreateCmpny}>
            <span>완료</span>
          </button>
        </SubmitButton>
      </div>
    </CompanyInfoFormContainer>
  );
}

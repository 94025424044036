import React from "react";
import { ReviewItemCotnainer } from "../styled/review";
import Rating from "./rating";
import UpdatedAt from "./updatedAt";
import { FaCheck } from "react-icons/fa6";

const ReviewItem = ({ item, clickReview }) => {
  return (
    <ReviewItemCotnainer onClick={() => clickReview(item.id)}>
      <div className="reviewInfo">
        <div>
          <span className="mberNm">{item.mberNm}님의 리뷰</span>
          <Rating rating={item.rating} />
          <UpdatedAt item={item} />
        </div>
        {item?.ownerReply?.content && (
          <span className="check">
            <FaCheck />
          </span>
        )}
      </div>
      <div className="reviewContent">
        {item.photos && item.photos.length > 0 && (
          <div className="images">
            {item.photos.map((photo) => (
              <img src={imgHandler(photo)} />
            ))}
          </div>
        )}
        <span className="cont">{item.content}</span>
      </div>
    </ReviewItemCotnainer>
  );
};

export default ReviewItem;

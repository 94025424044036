import { weatherStates } from "./dateInfo";

// 작성자 동일여부 확인
export const isSameUser = (user, writer) => {
  if (!user || !writer || !user.mberNo || !writer.mberNo) return false;
  return user.mberNo.toString() === writer.mberNo.toString();
};

// 하루종일 여부 확인
export const isEventAllTime = (item) => {
  const { startDt, endDt } = item;
  if (!startDt || !endDt) return false;

  const [dateStart, timeStart] = startDt.split(" ");
  const [dateEnd, timeEnd] = endDt.split(" ");

  return timeStart === "00:00:00" && timeEnd === "23:59:59";
};

// 색상 추출
export const hexToRgb = (hex) => {
  if (!hex) return "";

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
};

// 외부업체 체크
export const isOutsideCmpny = (item, user) => {
  if (!item || !user) return false;
  if (item.gbn !== "C") {
    return false;
  }
  if (item.orgNo === user.cmpnyNo) {
    return false;
  }
  return true;
};

export const isWeather = (weather) => {
  const state = weatherStates[weather];
  if (!state) return <></>;

  const iconChar = String.fromCodePoint(parseInt(state.icon, 16));

  return <span style={{ color: state.color }}>{iconChar}</span>;
};

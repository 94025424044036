import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import axios from "axios";

import Modal from "../../../../../common/modal";
import { RegisterPortFormContainer } from "../styled/register";

export default function RegisterPortForm() {
  const setting = useSelector((state) => state?.user?.setting);

  const location = useLocation();
  const navigate = useNavigate();

  const [portModal, setPortModal] = useState();
  const [portfolio, setPortfolio] = useState([]);
  const user = useSelector((state) => state?.user?.data[0]);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);

  const isUpload = location?.state?.uploadPort;
  const isDeleted = location?.state?.deletePort;
  const isEdit = location?.state?.editPort;

  let count = 0;

  useEffect(() => {
    getToast();
    // getPortfolio();
  }, []);

  useEffect(() => {
    if (page) {
      getPortfolio(page - 1);
    }
  }, [page]);

  const getToast = () => {
    count++;
    if (count === 1 && isUpload) {
      toast.success("포트폴리오가 등록 되었습니다.");
    }
    if (count === 1 && isDeleted) {
      toast.success("포트폴리오가 삭제 되었습니다.");
    }
    if (count === 1 && isEdit) {
      toast.success("포트폴리오가 수정 되었습니다.");
    }
  };

  const handlePortModal = () => {
    setPortModal(!portModal);
  };

  const getPortfolio = async (page = 0) => {
    const apiUrl = "/api/portMgmtList";
    const body = {
      cmpnyNo: user?.cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 12 * page,
      pageNumber: page,
      pageSize: 12,
      paged: true,
    };

    console.log(body);

    const res = await axios.post(apiUrl, body);
    console.log(res);
    const portDatas = res.data.content;

    setPortfolio(portDatas);
    setTotalElements(res.data.totalElements);
  };

  const RegisterPortmodal = () => {
    return (
      <div className="">
        <div className="">
          <h2 className="font_20 font500 font_color_white">카테고리 설정</h2>
          <button className="hp_step_setting_wrap_close" onClick={handlePortModal}>
            <i className="fa fa-times" aria-hidden="true" />
          </button>
          <span className="font_18 font300 font_color_gray pdt_20 text_center">
            ※ 원하는 카테고리를 추가 및 삭제할 수 있습니다.
          </span>
          <div className="hp_step_setting_wrap_input">
            <input type="text" defaultValue placeholder="카테고리를 입력하세요." />
            <button type="submit">
              <i className="fa fa-plus" aria-hidden="true" />
              추가
            </button>
          </div>
          <ul className="hp_step_setting_cate_list">
            <li>
              <span className="font_18 font300 font_color_gray text_center">cate name here</span>
              <button>삭제</button>
            </li>
          </ul>
          <button className="hp_step_setting_cate_save">저장하기</button>
        </div>
      </div>
    );
  };

  return (
    <RegisterPortFormContainer className={setting?.mode}>
      <section className="port_wrapper">
        {portfolio?.map((item) => (
          <div key={item.portNo} className="port_img_wrapper">
            <a
              className="port_link"
              onClick={() => navigate(`/mypage/registerPortView/${item.portNo}`)}>
              <div className="port_img_aspect">
                <img className="port_img" src={imgHandler(item.imgUrl)} onError={onErrorImg} />
              </div>
              <div className="port_title">
                <span className="font_18 font400 font_color_black">{item.title}</span>
              </div>
            </a>
          </div>
        ))}
      </section>
      <div className="actions">
        <Pagination
          activePage={page}
          itemsCountPerPage={12}
          totalItemsCount={totalElements}
          pageRangeDisplayed={3}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={(page) => setPage(page)}
        />
        <div className="buttons">
          <button onClick={() => navigate("/mypage/product")}>
            <span>다음 단계</span>
          </button>
          <button
            onClick={() => navigate("/mypage/registerPhotoUpload")}
            type="button"
            className="btn_write">
            포트폴리오 등록
          </button>
        </div>
      </div>
      <Modal
        modalState={portModal}
        handleModalState={handlePortModal}
        html={RegisterPortmodal()}
        className=""
      />
    </RegisterPortFormContainer>
  );
}

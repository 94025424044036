import styled from "styled-components";

export const InfoContainer = styled.div`
  &.group,
  &.project {
    .infoSection:nth-child(even) {
      background: #f2f4f6;
    }
  }
  &.shop,
  &.scheduler {
    .infoSection:nth-child(odd) {
      background: #f2f4f6;
    }
  }
  .inner {
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    min-height: 700px;
    align-items: center;
    justify-content: center;
    .content {
      span.title,
      strong {
        font-size: 36px;
        font-weight: 800;
        display: block;
      }
      span.desc {
        font-size: 20px;
        color: #747474;
        white-space: pre-line;
      }
      span.title {
        margin-bottom: 20px;
      }
      strong {
        margin: 100px 0;
      }
    }
  }
  &.shop {
    .infoSection {
      &:last-child {
        padding-bottom: 30px;
      }
      .inner {
        align-items: center;
        .img {
          height: auto;
          img {
            filter: none;
          }
        }
        .content {
          strong {
            margin: 100px 0 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    &.shop .infoSection .inner .content strong {
      margin: 50px 0 0;
    }
    .inner {
      min-height: 400px;
      .content {
        span.title,
        strong {
          font-size: 18px;
        }
        span.desc {
          font-size: 12px;
        }
        span.title {
          margin-bottom: 20px;
        }
        strong {
          margin: 70px 0;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.shop .infoSection .inner .content strong {
      margin: 20px 0 0;
    }
    .inner {
      min-height: auto;
      .content {
        strong,
        span.title {
          font-size: 14px;
        }
        strong {
          margin: 20px 0 0;
        }
      }
    }
  }
`;

export const SectionSwiperContainer = styled.div`
  background: #191f28;
  min-height: 700px;
  &.group {
    background: #fef9f3;
  }
  &.scheduler {
    background: #ffd9f2;
  }
  &.project {
    background: #fff299;
  }
  &.shop {
    background: #eaf7ed;
  }
  &.group,
  &.scheduler,
  &.project,
  &.shop {
    .inner {
      color: #000;
    }
  }
  .inner {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    color: #fff;
    padding: 100px 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 30px;
      font-weight: normal;
      span {
        white-space: pre-line;
      }
      strong {
        font-size: 48px;
        font-weight: 700;
        white-space: pre-line;
      }
      .buttons {
        display: flex;
        gap: 10px;
        button {
          border-radius: 10px;
          width: 190px;
          height: 55px;
          background: #fff;
          color: #000;
          margin-right: 10px;
          margin-bottom: 10px;
          border: none;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            user-select: none;
          }
          span {
            font-size: 14px;
            font-weight: 600;
            color: #000;
          }
        }
      }
    }
    .img {
      width: 500px;
      text-align: center;
      img {
        max-width: 100%;
        height: 500px;
        object-fit: contain;
        filter: drop-shadow(30px 30px 30px #000);
        user-select: none;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .inner {
      padding: 70px 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    min-height: auto;
    .inner {
      padding: 70px 20px;
      gap: 20px;
      min-height: auto;
      .img,
      .content {
        width: calc(50% - 10px);
        gap: 20px;
      }
      .img {
        height: 250px;
        img {
          max-height: 100%;
          filter: drop-shadow(rgba(0, 0, 0, 0.2) 7px 7px 10px);
        }
      }
      .content {
        span.title {
          font-size: 16px;
          margin-bottom: 0;
        }
        strong {
          font-size: 30px;
          margin: 0;
        }
        strong + span {
          display: none;
        }
        .buttons {
          button {
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .inner {
      padding: 30px 20px;
      .content {
        span.title {
          font-size: 12px;
        }
        strong {
          font-size: 16px;
        }
        .buttons {
          flex-direction: column;
          button {
            width: 90%;
            height: 35px;
            span {
              font-size: 12px;
              width: 75px;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
`;

export const HeroContainer = styled.div`
  &.scheduler {
    .inner .img img {
      max-width: 70%;
    }
  }
  .inner {
    gap: 100px;
    overflow: hidden;
    .img {
      height: 600px;
      width: 50%;
      img {
        max-width: 100%;
        object-fit: cover;
      }
    }
    .content {
      width: 50%;
    }
  }
  @media screen and (max-width: 1400px) {
    .inner {
      padding: 50px 20px 0 20px;
      gap: 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    .inner .img {
      height: 300px;
    }
  }
  @media screen and (max-width: 767px) {
    .inner {
      padding: 30px 20px 0 20px;
    }
  }
`;

export const MockupContainer = styled.div`
  .inner {
    gap: 100px;
    overflow: hidden;
    align-items: flex-start;
    padding-bottom: 100px;
    .img {
      height: 500px;
      width: 50%;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        filter: drop-shadow(rgba(0, 0, 0, 0.2) 7px 7px 10px);
      }
    }
    .content {
      width: 50%;
    }
  }
  @media screen and (max-width: 1400px) {
    .inner {
      padding: 50px 20px;
      gap: 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    .inner .img {
      height: 330px;
    }
  }
  @media screen and (max-width: 767px) {
    .inner {
      padding: 30px 20px;
      .img {
        height: 260px;
      }
    }
  }
`;

export const HighlightContainer = styled.div`
  &.scheduler {
    .inner {
      .img {
        position: absolute;
        transform: translateX(-50%);
        bottom: 55%;
        z-index: 33;
        width: 700px;
        height: 400px;
        img {
          object-fit: contain;
        }
      }
      .content {
        position: absolute;
        top: 50%;
      }
    }
  }
  .inner {
    padding-bottom: 100px;
    position: relative;
    flex-direction: column;
    .img,
    .content {
    }
    .img {
      left: 50%;
      text-align: center;
      max-width: 500px;
      max-height: 350px;
      overflow: hidden;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .content {
      width: 100%;
      text-align: center;
      strong {
        margin-bottom: 0;
        margin-top: 50px;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    &.scheduler {
      .inner {
        min-height: 400px;
        .img {
          bottom: 43%;
          max-width: 70%;
        }
        .content {
          top: 55%;
        }
      }
    }
    .inner {
      padding: 50px 20px;
      .img {
        max-height: 250px;
      }
      .content strong {
        margin-top: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.scheduler {
      .inner {
        min-height: auto;
        .img {
          position: relative;
          width: 100%;
          max-width: 100%;
          max-height: auto;
          transform: none;
          left: 0;
          bottom: 0;
        }
        .content {
          position: relative;
        }
      }
    }
    .inner {
      padding: 30px 20px;
    }
  }
`;

export const SecondaryMockupContainer = styled.div`
  .inner {
    gap: 100px;
    overflow: hidden;
    align-items: flex-start;
    padding-bottom: 100px;
    .img {
      height: 600px;
      width: 50%;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        filter: drop-shadow(rgba(0, 0, 0, 0.2) 7px 7px 10px);
      }
    }
    .content {
      width: 50%;
    }
  }
  @media screen and (max-width: 1400px) {
    .inner {
      padding: 50px 20px;
      gap: 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    .inner .img {
      height: 300px;
    }
  }
  @media screen and (max-width: 767px) {
    .inner {
      padding: 30px 20px;
      .img {
        height: 260px;
      }
    }
  }
`;

export const SubscribeContainer = styled.div`
  img {
    user-select: none;
  }
  section {
    padding: 80px 0;
    font-size: 20px;
    text-align: center;
    &:first-child {
      background: #191f28;
      color: #fff;
      .info span {
        margin: 0 0 15px;
      }
    }
  }
  .inner {
    max-width: 1440px;
    padding: 0px 20px;
    margin: 0 auto;
  }
  .info {
    margin: 0 0 50px;
    span {
      display: block;
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
    strong {
      font-weight: normal;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    section {
      padding: 50px 0;
    }
    .info {
      font-size: 14px;
      span {
        font-size: 20px;
      }
    }
  }
`;

export const PlanComparisonContainer = styled.div`
  max-width: 880px;
  margin: auto;
  background: #fff;
  color: #c1c1c1;
  border-radius: 10px;
  padding: 30px;
  .header,
  .row {
    display: flex;
    > div {
      width: 25%;
      text-align: center;
      font-size: 16px;
      strong {
        font-weight: normal;
        font-size: 28px;
        text-transform: capitalize;
        display: block;
        letter-spacing: -1px;
      }
      &.free {
        > strong {
          color: #000;
        }
      }
      &.plus {
        > strong {
          color: #8e60fb;
        }
      }
      &.premium {
        > strong {
          color: #ff72ad;
        }
      }
      .price {
        font-size: 14px;
        display: flex;
        justify-content: end;
        flex-direction: column;
        height: 40px;
        span.origin {
          text-decoration: line-through;
          font-size: 12px;
        }
        span:last-child {
          color: #000;
        }
      }
    }
  }
  .header {
    border-bottom: 1px solid #c1c1c1;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .row {
    padding: 10px 0;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const PlanContainer = styled.div`
  > ul {
    max-width: 880px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    gap: 20px;
    > li {
      width: calc((100% - 40px) / 3);
      padding: 30px;
      background: #fff;
      color: #000;
      border-radius: 10px;
      font-size: 14px;
      text-align: left;
      &.plus {
        > strong,
        button {
          color: #8e60fb;
        }
      }
      &.premium {
        > strong,
        button {
          color: #ff72ad;
        }
      }
      > strong {
        margin: 10px 0;
        display: block;
        text-transform: capitalize;
        font-size: 32px;
      }
      .price {
        flex-direction: column;
        display: flex;
        height: 44px;
        justify-content: end;
        border-bottom: 1px solid #c1c1c1;
        padding-bottom: 5px;
        margin-bottom: 20px;
        .origin {
          color: #c1c1c1;
          text-decoration: line-through;
        }
        span {
          font-size: 12px;
        }
        strong {
          font-weight: 500;
          font-size: 16px;
        }
      }
      .feature {
        li {
          font-size: 14px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          color: #c1c1c1;
          gap: 5px;
          svg {
            display: block;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      button {
        display: block;
        margin: 20px auto 0;
        width: 100px;
        height: 32px;
        background: transparent;
        border: 1px solid #c1c1c1;
      }
    }
  }
  > span {
    max-width: 880px;
    color: #c1c1c1;
    display: block;
    margin: 20px auto;
    text-align: right;
    font-size: 16px;
  }
  > button {
    width: 100%;
    max-width: 576px;
    height: 54px;
    border-radius: 5px;
    border: none;
    background: #3b4859;
    transition: 0.3s;
    margin-bottom: 50px;
    &:hover {
      background: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    > ul {
      flex-direction: column;
      li {
        width: 100%;
      }
    }
    > span {
      text-align: center;
      font-size: 12px;
    }
    > button {
      display: none;
    }
  }
`;

export const SectionContaier = styled.section`
  .text {
    text-align: left;
    font-size: 18px;
    strong {
      display: block;
      margin-bottom: 5px;
    }
    span {
      display: block;
      position: relative;
      color: #747474;
      padding-left: 10px;
      &::after {
        content: "";
        width: 3px;
        height: 3px;
        background: #747474;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
  &.websiteCreation {
    ul {
      display: flex;
      gap: 10%;
      height: 630px;
      li {
        .bg {
          width: 100%;
          height: 410px;
          background: #1a6dff;
          overflow: hidden;
          margin-bottom: 30px;
          border-radius: 20px;
        }
        img {
          width: 100%;
          height: 100%;
        }
        &:first-child {
          width: 30%;
          img {
            object-fit: contain;
          }
        }
        &:last-child {
          width: 60%;
          margin-top: auto;
          img {
            object-fit: cover;
          }
        }
      }
    }
  }
  &.storeService {
    ul {
      display: flex;
      gap: 80px;
      li {
        width: calc((100% - 160px) / 3);
        .bg {
          width: 100%;
          aspect-ratio: 1;
          background: #fee8ea;
          border-radius: 20px;
          margin-bottom: 30px;
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .text {
      font-size: 14px;
    }
    &.websiteCreation,
    &.storeService {
      ul {
        flex-direction: column;
        gap: 20px;
        li {
          width: 100%;
          &:first-child,
          &:last-child {
            width: 100%;
          }
          .bg {
            width: 100%;
            height: 250px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

export const StartSubscriptionContainer = styled.section`
  background-color: #1a6dff;
  color: #fff;
  background-image: url("/img/info/ellipse.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  .price {
    display: flex;
    font-size: 64px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
    img {
      height: 80px;
    }
    strong {
      font-size: 100px;
    }
  }
  .details {
    margin-bottom: 80px;
  }
  button {
    width: 258px;
    height: 80px;
    background: rgba(34, 0, 255, 0.27);
    color: #fff;
    border: none;
    border-radius: 5px;
  }
  @media screen and (max-width: 767px) {
    background-size: 200px;
    background-position: 120% 100%;
    .price {
      font-size: 40px;
      img {
        height: 40px;
      }
      strong {
        font-size: 50px;
      }
    }
    .details {
      margin-bottom: 20px;
    }
    button {
      width: 120px;
      height: 34px;
      font-size: 12px;
      margin-bottom: 180px;
    }
  }
`;

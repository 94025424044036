import moment from "moment";

export const orderType = {
  PICKUP: "픽업",
  DELIVERY: "배달",
};

export const status = {
  PENDING: "주문대기",
  CONFIRMED: "주문승인",
  READY: "상품준비",
  CANCELLED: "주문취소",
  COMPLETED: "상품준비완료",
  PICKUP: "픽업완료",
};

export const formatOrderId = (id) => {
  if (!id) return "";
  return id.toString().slice(8, id.length);
};

export const calculateTime = (data) => {
  const { pickupBy } = data;
  if (!pickupBy) return 0;

  const startTime = moment();
  const endTime = moment(pickupBy);

  const duration = endTime.diff(startTime, "minutes");

  return Math.max(duration, 0);
};

export const calculateStatus = (data) => {
  const remaining = calculateTime(data);

  if (!remaining) return "red";
  if (remaining > 30) return "green";
  if (remaining > 15) return "yellow";

  return "red";
};

export const categorizeItemsByStatus = (orders = []) => {
  const categorized = {
    PENDING: [],
    CONFIRMED: [],
    READY: [],
    CANCELLED: [],
    COMPLETED: [],
    PICKUP: [],
  };

  orders.forEach((item) => {
    switch (item.status) {
      case "PENDING":
        categorized.PENDING.push(item);
        break;
      case "CONFIRMED":
      case "READY":
      case "COMPLETED":
        categorized.READY.push(item);
        break;
      case "CANCELLED":
      case "PICKUP":
        categorized.PICKUP.push(item);
        break;
      default:
        break;
    }
  });

  return categorized;
};

export const renderStatusImage = (status) => {
  switch (status) {
    case "PENDING":
      return null;
    case "CONFIRMED":
    case "READY":
    case "COMPLETED":
      return <img src="/img/store/status_ready.png" />;
    case "CANCELLED":
      return <img src="/img/store/status_cancelled.png" />;
    case "PICKUP":
      return <img src="/img/store/status_pickup.png" />;
    default:
      return null;
  }
};

export const formatCommas = (num) => {
  if (!num) return 0;
  return Number(num).toLocaleString();
};

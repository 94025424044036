import styled from "styled-components";

export const PickUpContainer = styled.div`
  .content {
    padding: 0 20px;
    background: #fff;
  }
  .sidemenu {
    .tabs {
      ul li {
        width: 50%;
        height: 75px;
        line-height: 75px;
        text-align: center;
        display: inline-block;
        font-size: 30px;
        background: #245dab;
        color: #fff;
        font-weight: 500;
        transition: 0.3s;
        &.active {
          font-weight: 800;
          color: #245dab;
          background: transparent;
        }
      }
    }
    .date {
      font-size: 28px;
      height: 75px;
      line-height: 75px;
      text-align: center;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 1200px) {
    .sidemenu {
      width: 265px;
      .tabs {
        ul li {
          font-size: 20px;
          height: 50px;
          line-height: 50px;
        }
      }
      .date {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
    }
    .container .content {
      width: calc(100% - 265px);
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      flex-direction: column;
      .content {
        height: calc(100% - 84px);
        width: 100%;
        padding: 0;
        background: transparent;
        .order {
          height: 100%;
          padding: 10px 20px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #5a5a5a;
            border-radius: 10px;
            border: 2px solid #d9d9d9;
          }
          &::-webkit-scrollbar-track {
            background-color: #d9d9d9;
            border-radius: 10px;
          }
        }
      }
    }
    .info {
      .label {
        font-size: 20px;
        padding: 0 20px;
        height: 54px;
        line-height: 54px;
        font-weight: 700;
      }
      .tabs {
        ul {
          display: flex;
          align-items: center;
          padding: 0 20px;
          li {
            width: calc(100% / 3);
            height: 30px;
            font-size: 12px;
            border-bottom: 3px solid transparent;
            text-align: center;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #9e9e9e;
            transition: 0.2s;
            &.active {
              background: none;
              color: #000;
              border-bottom: 3px solid #255dac;
            }
          }
        }
      }
    }
  }
`;

export const OrdersSection = styled.div`
  height: calc(100% - 75px);
  overflow-y: hidden;
  font-size: 22px;
  .newOrders {
    height: 45%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    ul {
      li {
        &.none {
          color: #000;
        }
        color: #245dab;
        padding: 10px 15px;
        border-bottom: 1px solid #efefef;
        display: flex;
        align-items: center;
        gap: 10px;
        transition: 0.3s;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        .dot {
          position: absolute;
          width: 10px;
          height: 10px;
          background: #635151;
          top: 10px;
          right: 10px;
          border-radius: 50%;
        }
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        &.active {
          background: rgba(0, 0, 0, 0.2);
        }
        .orderInfo {
          width: calc(100% - 60px);
          span {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.tit {
              font-size: 26px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .inProgressOrders {
    height: 55%;
    .inProgressInfo {
      padding: 0 15px;
      height: 70px;
      line-height: 70px;
      color: #245dab;
      font-weight: 600;
    }
    ul {
      max-height: calc(100% - 70px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5a5a5a;
        border-radius: 10px;
        border: 2px solid #d9d9d9;
      }
      &::-webkit-scrollbar-track {
        background-color: #d9d9d9;
        border-radius: 10px;
      }
      li {
        &:first-child {
          margin-top: 0;
        }
        &.none {
          border-radius: 0;
          margin: 0;
          border-radius: 0;
          background: transparent;
          padding: 0 15px;
        }
        padding: 15px;
        background: #efefef;
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 20px;
        transition: 0.3s;
        &.active {
          background: rgba(0, 0, 0, 0.2);
        }
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        .inProgressOrderItem {
          width: calc(100% - 140px);
          span {
            display: block;
            font-size: 20px;
            &:first-child {
              font-weight: 700;
            }
          }
        }
        .orderProgress {
          width: 60px;
          height: 60px;
          background: #14110b;
          border-radius: 50%;
          position: relative;
          display: inline-block;
          &.red {
            background: #ff7878;
          }
          &.yellow {
            background: #ffcf78;
          }
          &.green {
            background: #77b171;
          }
          .progressRemaining {
            width: 50px;
            height: 50px;
            line-height: 50px;
            background: #efefef;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            text-align: center;
            font-weight: 700;
            font-size: 18px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    height: calc(100% - 100px);
    .newOrders ul li .orderInfo span {
      font-size: 18px;
      &.tit {
        font-size: 20px;
      }
    }
    .inProgressOrders ul li {
      gap: 5px;
      margin: 10px;
      .orderProgress {
        width: 40px;
        height: 40px;
        .progressRemaining {
          width: 35px;
          height: 35px;
          line-height: 35px;
          font-size: 12px;
        }
      }
      .inProgressOrderItem {
        width: calc(100% - 80px);
        span {
          font-size: 14px;
        }
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const DetailsInfoContainer = styled.div`
  height: 110px;
  border-bottom: 1px solid #000;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .info {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: calc(100% - 360px);
    .status {
      width: 65px;
      height: 65px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .infoImage {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .infoText {
      min-width: 200px;
      span:last-child {
        font-size: 22px;
      }
    }
    span {
      display: block;
      &:first-child {
        font-size: 30px;
      }
      &:last-child {
        color: #666666;
      }
    }
  }
  .time {
    span {
      display: block;
      font-size: 20px;
    }
  }
  .actions {
    display: flex;
    gap: 10px;
    height: 70px;
    line-height: 70px;
    > button {
      border: 1px solid #666666;
      width: 120px;
      height: 100%;
      text-align: center;
      font-weight: 700;
      background: transparent;
    }
    .notify {
      height: 100%;
      border: 1px solid #666666;
      width: 170px;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      background: #255dac;
      color: #fff;
      font-size: 18px;
      font-weight: normal;
      .icon {
        font-size: 34px;
        svg {
          display: block;
        }
      }
    }
    .stepper {
      height: 100%;
      border: 1px solid #666666;
      width: 170px;
      overflow-y: hidden;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      button {
        width: 30px;
        height: 30px;
        border: none;
        background: #000;
        color: #fff;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 20px;
        }
      }
      span {
        display: inline-block;
        width: 75px;
        text-align: center;
      }
    }
    .stepper + button {
      background: #255dac;
      color: #fff;
    }
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: baseline;
    padding: 10px 0;
    justify-content: space-between;
    height: 130px;
    .info .status {
      width: 50px;
      height: 50px;
    }
    &.CANCELLED,
    &.PICKUP {
      height: 76px;
    }
    > strong {
      display: block;
      margin: auto;
    }
    .info {
      max-width: 100%;
      span {
        font-size: 20px;
        &:first-child {
          font-size: 24px;
        }
      }
    }
    .actions {
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      .notify {
        flex-direction: row;
        .icon {
          display: none;
        }
      }
      .stepper span {
        width: 50px;
      }
    }
  }
`;

export const DetailsContentContainer = styled.div`
  height: calc(100% - 110px);
  > div {
    min-height: 80px;
    display: flex;
    border-bottom: 1px solid #000;
    &:nth-child(3) {
      border-bottom: none;
    }
    &:last-child {
      min-height: auto;
      border-bottom: none;
    }
    span.label {
      font-weight: 700;
    }
  }
  .notOrder {
    align-items: center;
    justify-content: center;
    height: calc(100% - 110px);
    padding: 0;
    .bg {
      width: 100%;
      height: 340px;
      background: #dcdcdc;
      font-weight: 700;
      line-height: 340px;
      text-align: center;
    }
  }
  .pickupInfo {
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    height: calc(100% - 130px);
    &.CANCELLED,
    &.PICKUP {
      height: calc(100% - 76px);
    }
    > div {
      span.label {
        font-size: 24px;
      }
    }
    .pickupInfo {
      flex-direction: column;
      height: 120px;
      align-items: baseline;
      padding: 10px 0;
    }
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 600px;
  max-width: calc(100% - 100px);
  .bg {
    width: 100%;
    height: 12px;
    background: #e8e8e8;
    border-radius: 6px;
    position: relative;
  }
  .pointer {
    font-size: 14px;
    color: #7a7a7a;
    position: absolute;
    top: 0;
    transform: translate(-50%, -5.5px);
    text-align: center;
    .checkpoint {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid #696969;
      margin: 8px auto;
    }
    &.PENDING,
    &.CANCELLED {
      left: 10%;
    }
    &.CONFIRMED {
      left: 30%;
    }
    &.READY {
      left: 50%;
    }
    &.COMPLETED {
      left: 70%;
    }
    &.PICKUP {
      left: 90%;
    }
  }
  .line {
    top: 50%;
    left: 2px;
    position: absolute;
    height: 8px;
    width: 10%;
    transform: translateY(-50%);
    background: #255dac;
    border-radius: 4px;
    &.CANCELLED {
      background: rgb(183, 74, 74);
    }
    &.CONFIRMED {
      width: 30%;
    }
    &.READY {
      width: 50%;
    }
    &.COMPLETED {
      width: 70%;
    }
    &.PICKUP {
      width: calc(100% - 2px);
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: 767px) {
    align-items: baseline;
    height: 40px;
  }
`;

export const RequestsContainer = styled.div`
  height: 70px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .remark {
    display: flex;
    height: 100%;
    width: 600px;
    max-width: calc(100% - 100px);
    background: rgba(246, 46, 46, 0.1);
    color: #fd5959;
    font-size: 20px;
    overflow-y: auto;
    padding-left: 20px;
    span {
      margin: auto 0;
      display: inline-block;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    height: 85px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    margin: 10px 0;
    flex-wrap: wrap;
    .label {
      font-weight: 700;
    }
    .remark {
      height: auto;
      padding: 10px 0 10px 10px;
      max-width: 100%;
      font-size: 12px;
    }
  }
`;

export const OrderMenuContainer = styled.div`
  width: 100%;
  height: calc(100% - 240px);
  flex-direction: column;
  overflow-y: auto;
  &.PICKUP {
    height: calc(100% - 200px);
  }
  .foodItem {
    display: inline-block;
    width: 50%;
    .tit {
      font-weight: 700;
    }
  }
  .foodQuantity {
    display: inline-block;
    width: 20%;
    text-align: center;
  }
  .foodPrice {
    display: inline-block;
    width: 30%;
    text-align: center;
  }
  .info {
    border-bottom: 1px solid #000;
    font-weight: 700;
    padding: 10px 0;
    display: flex;
    align-items: center;
    height: 80px;
    span {
      display: inline-block;
      line-height: normal;
    }
  }
  ul {
    height: calc(100% - 80px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
  }
  .food {
    width: 100%;
    padding: 20px 0;
    height: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    font-size: 24px;
    .foodItem .foodOptions {
      font-size: 20px;
      li {
        margin: 10px 0 10px 20px;
      }
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    height: calc(100% - 250px);
    /* &.CANCELLED, */
    &.PICKUP {
      height: calc(100% - 165px);
    }
    ul {
      height: calc(100% - 61px);
    }
    .info {
      font-size: 20px;
    }
    .food {
      padding: 10px 0;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 767px) {
    .info {
      font-size: 14px;
      line-height: 0;
      padding: 0 0 10px;
      height: 31px;
      .label {
        font-size: 14px;
        padding: 0;
        height: 100%;
        line-height: normal;
        width: 40%;
      }
    }
    .foodItem.label,
    .food .foodItem {
      width: 50%;
    }
    .foodPrice {
      width: 30%;
    }
    .foodQuantity {
      width: 20%;
    }
    .food {
      font-size: 14px;
      .foodItem {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .foodOptions li {
          font-size: 12px;
          margin: 5px 0 5px 10px;
          i {
            display: none;
          }
        }
      }
    }
  }
`;

export const MobileOrderItems = styled.li`
  min-height: 108px;
  background: #fff;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  padding: 15px;
  font-size: 14px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  .dot {
    margin: 0;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #635151;
    border-radius: 50%;
  }
  > div {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  button {
    font-weight: 700;
    border: none;
    background: transparent;
  }
  .orderInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    .info {
      width: calc(100% - 50px);
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .status {
      width: 45px;
      height: 45px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .infoImage {
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .infoText {
      width: calc(100% - 95px);
      span {
        display: block;
        &:first-child {
          font-weight: 700;
        }
        &:last-child {
          font-size: 12px;
        }
      }
    }
    .orderProgress {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      position: relative;
      &.red {
        background: #ff7878;
      }
      &.yellow {
        background: #ffcf78;
      }
      &.green {
        background: #77b171;
      }
      .progressRemaining {
        width: 38px;
        height: 38px;
        line-height: 38px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
  .action {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 5px;
    font-weight: 700;
    &.stepper {
      button {
        background: #000;
        width: 20px;
        height: 20px;
        color: #fff;
        svg {
          display: block;
          margin: auto;
        }
      }
    }
    .current {
      display: inline-block;
      min-width: 40px;
      text-align: center;
    }
    .notify {
      width: 100%;
      height: 100%;
    }
  }
  .toggleButton {
    button {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    svg {
      display: block;
    }
  }
  .orderItemContent {
    overflow: hidden;
    transition: 0.3s;
    box-sizing: border-box;
  }
  .controls {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    button {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border: none;
      height: 100%;
      border-radius: 5px;
      font-weight: 700;
      &:first-child {
        flex-grow: 1;
      }
      &:last-child {
        background: #3e50da;
        color: #fff;
        flex-grow: 2;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .orderInfo .infoText {
      width: calc(100% - 80px);
    }
    &.PICKUP .orderInfo .info {
      width: 100%;
    }
    &.READY .orderInfo .info .infoImage:last-of-type {
      display: none;
    }
  }
`;

export const CustomerInfoContainer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  gap: 20px;
  background: #255dac;
  border-radius: 20px 20px 0 0;
  padding: 0 20px;
  color: #fff;
  @media screen and (max-width: 1200px) {
    height: 50px;
    font-size: 20px;
  }
  @media screen and (max-width: 767px) {
    border-radius: 0;
    font-size: 14px;
    background: transparent;
    display: block;
    color: #000;
    height: auto;
    padding: 0;
    border-bottom: 1px solid rgb(239, 239, 239);
    margin-bottom: 10px;
    padding-bottom: 10px;
    span {
      display: block;
    }
  }
`;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { CustomInput } from "../../../../homepageSetting/component/styled/common";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";

const TodoScreen = ({ formData, todos, setTodos }) => {
  const memoRef = useRef(null);
  const inputRefs = useRef([]);
  const mberRef = useRef();

  const [isMentionOpen, setIsMentionOpen] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [matchingMentions, setMatchingMentions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [focusIndex, setFocusIndex] = useState(0);
  const [activeMemo, setActiveMemo] = useState(false);

  useOutsideClick(mberRef, () => {
    setIsMentionOpen(false);
  });

  const generateUniqueWorkers = (workerList = {}, manager = null) => {
    const workers = [
      ...Object.entries(workerList || {}).map(([key, value]) => ({
        mberNo: key,
        mberNm: value,
      })),
      manager?.managerNo && { mberNo: manager.managerNo.toString(), mberNm: manager.managerNm },
    ].filter(Boolean);
    const workerIds = workers.map((worker) => worker.mberNo);

    setTodos((data) =>
      [...data].map((todoItem) => ({
        ...todoItem,
        mentionUserIds: todoItem.mentionUserIds.filter((id) => workerIds.includes(id)),
      })),
    );

    return Array.from(new Map(workers.map((worker) => [worker.mberNo, worker])).values());
  };

  const uniqueWorkers = useMemo(() => {
    return generateUniqueWorkers(formData.workerList, {
      managerNo: formData.managerNo,
      managerNm: formData.managerNm,
    });
  }, [formData.workerList, formData.managerNo, formData.managerNm]);

  useEffect(() => {
    setMentions(uniqueWorkers);
  }, [uniqueWorkers]);

  const handleMentionNavigation = (key) => {
    if (key === "ArrowUp") {
      setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : matchingMentions.length - 1));
    } else if (key === "ArrowDown") {
      setActiveIndex((prevIndex) => (prevIndex < matchingMentions.length - 1 ? prevIndex + 1 : 0));
    }
  };

  // 멘션 선택
  const handleSelectMention = (mberNo) => {
    const selectedMention = matchingMentions.find((mention) => mention.mberNo === mberNo);
    if (!selectedMention) return;

    const newTodos = [...todos];
    const index = activeIndex;

    const currentValue = newTodos[index].value || "";

    const mentionRegex = /@[\wㄱ-ㅎㅏ-ㅣ가-힣]*$/;
    const match = currentValue.match(mentionRegex);
    if (match) {
      newTodos[index].value = currentValue.slice(0, match.index).trim();
    }

    if (!newTodos[index].mentionUserIds) {
      newTodos[index].mentionUserIds = [];
    }
    if (!newTodos[index].mentionUserIds.includes(selectedMention.mberNo.toString())) {
      newTodos[index].mentionUserIds.push(selectedMention.mberNo.toString());
    }

    setTodos(newTodos);
    setIsMentionOpen(false);
    setMatchingMentions([]);
  };

  // 할일 추가
  const addNewTodo = (index) => {
    const newTodo = {
      id: uuid(),
      value: "",
      showBullet: true,
      mentionUserIds: [],
      isChecked: false,
    };

    setTodos((prevTodos) => {
      const updatedTodos = [
        ...prevTodos.slice(0, index + 1),
        newTodo,
        ...prevTodos.slice(index + 1),
      ];

      requestAnimationFrame(() => {
        inputRefs.current[index + 1]?.focus();
      });

      return updatedTodos;
    });
  };

  // 투두 삭제
  const handleDeleteTodo = (index) => {
    if (todos.length === 1) {
      if (todos[0].showBullet) setTodos([{ ...todos[0], showBullet: false, mentionUserIds: [] }]);
      requestAnimationFrame(() => {
        inputRefs.current[0]?.focus();
      });
      return;
    }

    const newTodos = [...todos];
    newTodos.splice(index, 1);
    setTodos(newTodos);

    const focusIndex = index > 0 ? index - 1 : 0;
    requestAnimationFrame(() => {
      inputRefs.current[focusIndex]?.focus();
    });
  };

  // 키보드 입력 처리
  const handleKeyDown = (event, index) => {
    setActiveIndex(index);

    if (event.key === "Enter") {
      handleEnter(event, index);
    }
    if (event.key === "Backspace" && todos[index].value === "") {
      handleDeleteTodo(index);
    }
    // if (event.key ==="Backspace") {

    // }
    if (event.key === "ArrowUp") {
      moveFocus("up", index);
    }
    if (event.key === "ArrowDown") {
      moveFocus("down", index);
    }
    if (event.key === "@" && !!todos[index].showBullet) {
      setIsMentionOpen(true);
      setMatchingMentions(mentions);
    }
    if (isMentionOpen && (event.key === "ArrowUp" || event.key === "ArrowDown")) {
      handleMentionNavigation(event.key);
    }
    if (isMentionOpen && event.key === "Enter") {
      event.preventDefault();
      handleSelectMention(index);
      setIsMentionOpen(false);
    }
  };

  // 내용 변경
  const handleChange = (event, index) => {
    const newTodos = [...todos];
    let text = event.target.value;

    if (/^\d+\.\s?$/.test(text) && !newTodos[index].showBullet) {
      newTodos[index].showBullet = true;
      text = text.replace(/^\d+\.\s?/, "");
    }

    newTodos[index].value = text;
    setTodos(newTodos);

    const cursorPosition = event.target.selectionStart;
    const mentionQuery = text.slice(0, cursorPosition).match(/@([^\s@!#$%^&*(),.?":{}|<>]*)$/);

    if (mentionQuery) {
      const query = mentionQuery[1].toLowerCase();
      const existingMentions = (newTodos[index].mentionUserIds || []).map((mention) => mention);

      const filteredMentions = mentions.filter((mention) => {
        const fullName = mention.mberNm.toLowerCase();
        const startsWithQuery = fullName.startsWith(query);

        const initials = fullName
          .split("")
          .map((char) => char.charCodeAt(0) - 44032)
          .filter((code) => code >= 0 && code <= 11172)
          .map((code) => String.fromCharCode(0x1100 + Math.floor(code / 588)));

        const initialMatches = initials.join("").startsWith(query);

        return (startsWithQuery || initialMatches) && !existingMentions.includes(mention.mberNo);
      });

      setMatchingMentions(filteredMentions);
    } else {
      setIsMentionOpen(false);
      setMatchingMentions([]);
    }
  };

  // Enter
  const handleEnter = (e, index) => {
    e.preventDefault();

    const currentTodo = todos[index];
    const cursorPosition = e.target.selectionStart;
    const beforeCursor = currentTodo.value.slice(0, cursorPosition);
    const afterCursor = currentTodo.value.slice(cursorPosition);

    if (!currentTodo.showBullet) {
      const newTodos = [...todos];
      newTodos[index].value = beforeCursor;

      newTodos.splice(index + 1, 0, {
        id: uuid(),
        value: afterCursor,
        showBullet: false,
        mentionUserIds: [],
      });

      setTodos(newTodos);

      requestAnimationFrame(() => {
        inputRefs.current[index + 1]?.focus();
      });

      return;
    }

    if (currentTodo.value === "" && currentTodo.showBullet) {
      const newTodos = [...todos];
      newTodos[index].showBullet = false;
      newTodos[index].mentionUserIds = [];
      setTodos(newTodos);

      requestAnimationFrame(() => {
        inputRefs.current[index + 1]?.focus();
      });

      return;
    }

    const newTodos = [...todos];
    newTodos[index].value = beforeCursor;

    newTodos.splice(index + 1, 0, {
      id: uuid(),
      value: afterCursor,
      showBullet: currentTodo.showBullet,
      mentionUserIds: [],
    });

    setTodos(newTodos);

    requestAnimationFrame(() => {
      inputRefs.current[index + 1]?.focus();
    });
  };

  // 방향키 위아래
  const moveFocus = (direction, index) => {
    if (direction === "up" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (direction === "down" && index < todos.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
    setIsMentionOpen(false);
  };

  // 붙여넣기 이벤트 처리
  const checkPastedBoard = (text, index, cursorPosition) => {
    const cleanedText = text.replace(/\r/g, "");
    const lines = cleanedText.split("\n");
    const newTodos = [...todos];

    if (lines.length > 0) {
      const firstLine = lines[0];
      const currentTodo = newTodos[index];
      const beforeCursor = currentTodo.value.slice(0, cursorPosition);
      const afterCursor = currentTodo.value.slice(cursorPosition);

      if (lines.length === 1) {
        const mergedValue = beforeCursor + firstLine + afterCursor;
        const isNumberedLine = /^\d+\.\s*/.test(firstLine) && beforeCursor.trim() === "";
        currentTodo.value = isNumberedLine
          ? beforeCursor + firstLine.replace(/^\d+\.\s*/, "") + afterCursor
          : mergedValue;
        currentTodo.showBullet = isNumberedLine;
        newTodos[index] = { ...currentTodo };
      } else {
        const isNumberedLine = /^\d+\.\s*/.test(firstLine) && beforeCursor.trim() === "";
        currentTodo.value = isNumberedLine
          ? beforeCursor + firstLine.replace(/^\d+\.\s*/, "")
          : beforeCursor + firstLine;
        currentTodo.showBullet = isNumberedLine;
        newTodos[index] = { ...currentTodo };

        lines.slice(1, -1).forEach((line, i) => {
          const isLineNumbered = /^\d+\.\s*/.test(line);
          newTodos.splice(index + i + 1, 0, {
            id: uuid(),
            value: isLineNumbered ? line.replace(/^\d+\.\s*/, "") : line,
            showBullet: isLineNumbered,
            mentionUserIds: [],
          });
        });

        const lastLine = lines[lines.length - 1];
        const nextIndex = index + lines.length - 1;

        if (nextIndex < newTodos.length) {
          const nextTodo = newTodos[nextIndex];
          const isLastLineNumbered = /^\d+\.\s*/.test(lastLine);
          nextTodo.value =
            (isLastLineNumbered ? lastLine.replace(/^\d+\.\s*/, "") : lastLine) +
            afterCursor +
            nextTodo.value;
          nextTodo.showBullet = isLastLineNumbered;
          newTodos[nextIndex] = { ...nextTodo };
        } else {
          const isLastLineNumbered = /^\d+\.\s*/.test(lastLine);
          newTodos.splice(nextIndex, 0, {
            id: uuid(),
            value: isLastLineNumbered
              ? lastLine.replace(/^\d+\.\s*/, "") + afterCursor
              : lastLine + afterCursor,
            showBullet: isLastLineNumbered,
            mentionUserIds: [],
          });
        }
      }
    }

    setTodos(newTodos);
  };

  // 텍스트 붙여넣기 이벤트 처리
  const handlePaste = (event, index) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text");
    const cursorPosition = event.target.selectionStart;
    checkPastedBoard(text, index, cursorPosition);
  };

  // textarea 높이 계산
  const calcTextAreaHeight = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto";

    const lineHeight = 20;
    const minHeight = lineHeight;

    const numberOfLines = textarea.value
      ? Math.max(Math.ceil(textarea.scrollHeight / lineHeight), 1)
      : 1;

    textarea.style.height = `${Math.max((numberOfLines - 1) * lineHeight, minHeight)}px`;
  };

  // 멘션 리스트 위치 계산
  const calculateMentionPosition = (index) => {
    if (!inputRefs.current[index] || !memoRef.current) return { top: 0, left: 0 };

    const inputRect = inputRefs.current[index].getBoundingClientRect();
    const memoRect = memoRef.current.getBoundingClientRect();

    return {
      top: `${inputRect.bottom - memoRect.top}px`,
      left: `${inputRect.left - memoRect.left}px`,
    };
  };

  const deleteMention = (id, mberNo) => {
    const updatedTodos = todos.map((todo) => {
      if (todo.id === id) {
        const filteredMentions = todo.mentionUserIds.filter((item) => {
          return item !== mberNo;
        });

        return { ...todo, mentionUserIds: filteredMentions };
      }
      return todo;
    });

    setTodos(updatedTodos);
  };

  const memberNameMatch = (mberNo) => {
    if (formData?.workerList?.[mberNo]) return formData.workerList[mberNo];
    if (formData.managerNo.toString() === mberNo.toString()) return formData.managerNm;

    return "";
  };

  const handleFocus = (index) => {
    setFocusIndex(index);
    setActiveMemo(true);
  };

  const handleBlur = () => {
    setActiveMemo(false);
  };

  const clickMemoContent = (e) => {
    if (e.target !== e.currentTarget) return;
    inputRefs.current[0]?.focus();
  };

  return (
    <div
      className={`memoContent ${activeMemo ? "active" : ""}`}
      ref={memoRef}
      onClick={clickMemoContent}>
      {todos.map((todo, index) => (
        <div key={todo.id} style={{ position: "relative" }}>
          {todo.showBullet && (
            <div>
              <CustomInput className="block">
                <input type="checkbox" disabled checked={todo.isChecked} />
                <span className="checkmark"></span>
              </CustomInput>
            </div>
          )}
          <textarea
            style={{ width: todo.showBullet ? "calc(100% - 30px)" : "100%" }}
            rows="1"
            type="text"
            ref={(el) => (inputRefs.current[index] = el)}
            value={todo.value}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onChange={(event) => handleChange(event, index)}
            onPaste={(event) => handlePaste(event, index)}
            onFocus={() => handleFocus(index)}
            onBlur={() => handleBlur()}
            placeholder={focusIndex === index ? "메모" : ""}
            className={todo.showBullet ? "todo" : "text"}
            onInput={(event) => (event.target.style.height = calcTextAreaHeight(event))}
          />
          {todo.mentionUserIds && todo.mentionUserIds.length > 0 && (
            <ul className="select-mention" style={{ marginLeft: todo.showBullet ? 30 : 0 }}>
              {todo.mentionUserIds.map((item) => (
                <li>
                  <span>{memberNameMatch(item)}</span>
                  <button className="del" onClick={() => deleteMention(todo.id, item)}>
                    <MdClose />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
      {isMentionOpen &&
        formData.gbn !== "P" &&
        matchingMentions.length > 0 &&
        activeIndex !== null && (
          <ul
            className="mentions"
            style={{
              ...calculateMentionPosition(activeIndex),
            }}
            ref={mberRef}>
            {matchingMentions.map((mention) => (
              <li
                key={mention.mberNo}
                className={mention.mberNo === matchingMentions[activeIndex]?.mberNo ? "active" : ""}
                onClick={() => handleSelectMention(mention.mberNo)}>
                {mention.mberNm}
              </li>
            ))}
          </ul>
        )}
    </div>
  );
};

export default TodoScreen;

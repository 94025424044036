import React from "react";

const SelectTime = ({ label, name, value, onChange, hours }) => {
  return (
    <div className={`${label === "시작시간" ? "startArea" : "endArea"}`}>
      <span>{label}</span>
      <select name={name} value={value} onChange={onChange}>
        {hours.map((time) => (
          <option key={time} value={time}>
            {time}
          </option>
        ))}
      </select>
      {/* <span className="hour">시</span> */}
    </div>
  );
};

export default SelectTime;

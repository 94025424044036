import { Link, useNavigate } from "react-router-dom";
import { PromotionFormContainer } from "../styled/promotion";
import { SubmitButton } from "../../../styled/mypage";
import { useSelector } from "react-redux";

export default function PromotionForm() {
  const navigate = useNavigate();
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <PromotionFormContainer className={setting?.mode}>
      <table>
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "80%" }} />
        </colgroup>
        <tbody>
          <tr>
            <td>
              <span>사이트이름</span>
            </td>
            <td>
              <input type="text" placeholder="사이트 이름을 입력하세요." />
            </td>
          </tr>
          <tr>
            <td>
              <span>키워드(쉼표 단위 입력)</span>
            </td>
            <td>
              <input type="text" placeholder="키워드1, 키워드2, 키워드3 ,,," />
            </td>
          </tr>
          <tr>
            <td>
              <span>설명</span>
            </td>
            <td>
              <input type="text" placeholder="예) 대전 인테리어 회사" />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="hosting">
        <span>
          ※ 기존 보유하고 있는 도메인이 있으신 경우 각 사이트 도메인 포워딩 방법에 따라 연결할 수
          있습니다.
          <br />
          보유하고 있는 도메인 사이트 버튼을 클릭하여 도메인 포워딩 방법을 확인하세요.
        </span>
        <div>
          <a href="https://www.cafe24.com/?controller=domain_vservice" target="_blank">
            <span>카페24</span>
          </a>
          <a href="https://domain.whois.co.kr/service/info_forwarding.php" target="_blank">
            <span>후이즈</span>
          </a>
          <a href="https://domain.gabia.com/service/contents" target="_blank">
            <span>가비아</span>
          </a>
        </div>
      </div>
      <SubmitButton>
        <button onClick={() => navigate("/mypage/homepageEnd")}>
          <span>저장하고 완료</span>
        </button>
      </SubmitButton>
    </PromotionFormContainer>
  );
}

import styled, { keyframes } from "styled-components";

const mainColor = "#0074CA";
const darkColor = "#BB86FC";

export const NotifyContainer = styled.div`
  .notifyInner {
    max-width: 1200px;
    padding: 15px 0;
    margin: auto;
  }
  .notify {
    .notifyHeader {
      border-bottom: 1px solid #efefef;
      .notifyInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          a img {
            height: 40px;
            object-fit: contain;
          }
        }
        .msgSetting {
          button {
            border: none;
            background: transparent;
          }
          .cancel {
            font-size: 15px;
          }
          .settingBtnArea {
            display: flex;
            gap: 10px;
            position: relative;
            > button {
              font-size: 30px;
              display: flex;
              align-items: center;
            }
            .setMenus {
              position: absolute;
              background: #fff;
              bottom: 0;
              right: 0;
              transform: translate(-40%, 105%);
              ul {
                width: 120px;
                border: 1px solid #efefef;
                text-align: center;
                border-radius: 10px;
                li {
                  padding: 7px 20px;
                  border-bottom: 1px solid #efefef;
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .notifyBody {
      .notifyInner {
        .notNotis {
          text-align: center;
          .icon {
            font-size: 35px;
            padding: 10px;
            background: #efefef;
            border-radius: 50%;
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px auto 10px;
          }
          span {
            font-size: 16px;
          }
        }
        .notifyTabMenu {
          font-size: 16px;
          margin: 20px;
          display: flex;
          font-weight: 700;
          margin: 30px 20px 45px;
          li {
            cursor: pointer;
            padding: 10px 20px;
            border: 1px solid #efefef;
            width: 150px;
            text-align: center;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background: #0074ca;
              color: #efefef;
              border: 1px solid #0074ca;
            }
          }
        }
        .section {
          margin: 20px 0;
          > ul {
            > li {
              border-radius: 20px;
              padding: 15px;
              box-sizing: border-box;
              margin: 10px 0;
              box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
              .notifyWrapper {
                display: flex;
                align-items: center;
                gap: 20px;
                position: relative;
                img {
                  width: 75px;
                  height: 75px;
                  border-radius: 50%;
                  border: 1px solid #efefef;
                  object-fit: contain;
                }
                .content {
                  width: calc(100% - 150px);
                  .infoBox {
                    display: flex;
                    gap: 10px;
                    .title {
                      font-weight: 600;
                    }
                    .date {
                      color: #999;
                    }
                  }
                }
                .deleteChk {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 0;
                  .cont {
                    display: flex;
                    align-items: center;
                    transform: scale(1);
                    input[type="checkbox"] {
                      height: 27px;
                      width: 27px;
                      margin: 5px;
                      display: inline-block;
                      appearance: none;
                      position: relative;
                      background-color: #ddd;
                      border-radius: 15%;
                      cursor: pointer;
                      overflow: hidden;
                      &::after {
                        content: "";
                        display: block;
                        height: 20px;
                        width: 10px;
                        border-bottom: 5px solid #efefef;
                        border-right: 5px solid #efefef;
                        opacity: 0;
                        transform: rotate(45deg) translate(-50%, -50%);
                        position: absolute;
                        top: 45%;
                        left: 21%;
                        transition: 0.25s ease;
                      }
                      &::before {
                        content: "";
                        display: block;
                        height: 0;
                        width: 0;
                        background-color: ${mainColor};
                        border-radius: 50%;
                        opacity: 0.5;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transition: 0.3s ease;
                      }
                      &:checked {
                        &::before {
                          height: 35px;
                          width: 35px;
                          opacity: 1;
                        }
                        &::after {
                          opacity: 1;
                        }
                      }
                    }
                  }

                  span {
                    font-size: 32px;
                  }
                }
              }
              .devNotifyWrapper {
                max-height: 35px;
                overflow-y: hidden;
                transition: 0.3s;
                cursor: pointer;
                &.active {
                  max-height: 200vh;
                }
                .title {
                  display: flex;
                  align-items: center;
                  gap: 15px;
                  position: relative;
                  img {
                    width: 35px;
                    height: 35px;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                  span {
                    &.noticeSj {
                      font-weight: 800;
                    }
                  }
                  .date {
                    color: #999;
                  }
                  .toggleCollapseButton {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border: none;
                    background: transparent;
                    font-size: 20px;
                    display: flex;
                  }
                  .editButton {
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    border: none;
                    background: #efefef;
                    padding: 5px 10px;
                    display: flex;
                    border-radius: 10px;
                    font-size: 13px;
                  }
                }
                .devNoticeContent {
                  margin-top: 10px;
                  padding-top: 10px;
                  border-top: 1px solid #efefef;
                }
              }
              .decisionBtnArea {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                align-items: center;
                overflow: hidden;
                transition: 0.3s;
                height: 0;
                padding: 0;
                button {
                  width: 50%;
                  border-radius: 20px;
                  border: none;
                  padding: 5px;
                  font-size: 14px;
                  background: #efefef;
                  &:first-child {
                    background: #0074ca;
                    color: #fff;
                  }
                }
                &.inv {
                  padding: 20px 0 0;
                  height: 47px;
                }
              }
            }
          }
        }
      }
    }
  }
  .deleteLayer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    button {
      width: 300px;
      padding: 10px 0;
      border: none;
      border-radius: 30px;
      font-size: 15px;
      &:first-child {
        background: ${mainColor};
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .notifyInner {
      padding: 15px;
    }
    .notify .notifyBody .notifyInner .section ul li .notifyWrapper .content .infoBox .title {
      max-width: calc(100% - 60px);
    }
    .notify .notifyHeader .notifyInner .msgSetting .settingBtnArea .setMenus {
      transform: translate(0, 105%);
    }
  }
  @media screen and (max-width: 768px) {
    .notify {
      .notifyBody {
        .notifyInner {
          .section {
            > ul {
              > li {
                .notifyWrapper {
                  gap: 10px;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                  .content {
                    width: calc(100% - 100px);
                    .infoBox {
                      flex-direction: column;
                      gap: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .notify .notifyHeader .notifyInner .title a img {
      width: 100px;
      height: 35px;
    }
    .notify .notifyBody .notifyInner .notifyTabMenu li {
      padding: 10px;
    }
  }
`;

export const CompanyInfoNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 10px;
  gap: 10px;
  &.btnArea {
    button {
      color: #fff;
      width: 200px;
      background: #0074ca;
      padding: 10px 0;
      font-size: 16px;
    }
  }
`;

export const CreateReleaseNotesContainer = styled.div`
  min-height: calc(-540px + 100vh);
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  position: relative;
  .content {
    box-sizing: border-box;
    max-width: 1000px;
    margin: 50px auto;
    .ck-content {
      height: 400px;
    }
    input[type="text"] {
      border: 1px solid rgb(204, 206, 209);
      padding: 10px 15px;
      width: 100%;
      resize: none;
      margin-bottom: 10px;
    }
    .btnArea {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px;
      gap: 10px;
      button {
        padding: 10px 30px;
        border: 1px solid transparent;
        font-size: 16px;
        &:first-child {
          background: #0074ca;
          color: #efefef;
        }
      }
    }
  }
`;

export const NotifyBenefitContainer = styled.div`
  .my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }
  .benefitItem {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #efefef;
    padding: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .storeContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 15px;
      .storeThumb {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        outline: 1px solid #efefef;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .storeNm {
        font-weight: 700;
      }
      .category {
        color: #999;
      }
    }
    .productContainer {
      display: flex;
      gap: 10px;
      .info {
        width: calc(100% - 110px);
        font-size: 14px;
        .title {
          font-weight: 600;
        }
        .desc {
          display: block;
          margin: 5px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .tag {
          display: inline-block;
          padding: 3px;
          border: 1px solid #efefef;
          font-size: 12px;
          color: #0074ca;
        }
      }
      .thumb {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
`;

export const CompanyPageDashboard = styled.div`
  margin-top: 3%;
  button {
    border: none;
    background: transparent;
  }
  .inviteBtnArea {
    width: 100%;
    margin: 20px auto;
    text-align: right;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    input[type="text"] {
      padding: 0 10px;
      border: 1px solid #efefef;
      height: 100%;
    }
    button {
      padding: 10px 30px;
      font-size: 14px;
      background: #efefef;
    }
  }
  table {
    width: 100%;
    margin: auto;
    border: 1px solid #efefef;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #efefef;
    }
    thead {
      tr th {
        background: #0074ca;
        color: #fff;
        padding: 10px;
        font-size: 16px;
        border-right: 1px solid #efefef;
        &:last-child {
          border-right: none;
        }
      }
    }
    tbody {
      tr td {
        font-size: 14px;
        padding: 5px;
        text-align: center;
        select {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          &:focus {
            box-shadow: none;
            border: none !important;
          }
        }
        button {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .inviteBtnArea {
      flex-wrap: wrap;
      button {
        padding: 10px 15px;
      }
    }
  }
`;

const fadeInOut = keyframes`
  0% { top: -100%; }  
  10% { top: 0; }      
  90% { top: 0; }     
  100% { top: -100%; } 
`;

export const InstallContainer = styled.div`
  &.dark {
    background: #000;
    .content button {
      background: #fff;
      color: #000;
    }
    .close {
      span {
        color: #ddd;
      }
      button {
        color: #fff;
      }
    }
  }
  z-index: 10000;
  width: 100vw;
  font-weight: 600;
  font-size: 16px;
  position: fixed;
  top: -100%;
  /* top: 0; */
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  background: #fff;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  animation: ${fadeInOut} 10s forwards;
  .content {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  button {
    border: none;
    width: max-content;
    padding: 5px 10px;
    border-radius: 5px;
    background: #000;
    color: #fff;
  }
  .close {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      color: #555;
      cursor: pointer;
    }
    button {
      border: none;
      background: transparent;
      color: #000;
      padding: 0;
      font-size: 20px;
    }
    svg {
      display: block;
    }
  }
  @media screen and (max-width: 767px) {
    border-radius: 0;
    width: 100%;
    font-size: 12px;
  }
`;

export const DashboardInfoContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px 50px;
  .infoContent {
    position: relative;
    .overview,
    .themeWeatherWidget {
      button {
        display: flex;
        align-items: center;
        gap: 5px;
        background: transparent;
        border: none;
        &:first-child {
          margin-right: 20px;
        }
        svg {
          display: block;
        }
      }
    }
    .overview {
      font-size: 16px;
      text-align: right;
      letter-spacing: -1px;
      position: absolute;
      right: 0;
      top: 0;
      > span {
        display: block;
        &:first-child {
          font-size: 20px;
        }
      }
      .buttons {
        display: flex;
        margin: 30px 0;
      }
      .weather {
        display: flex;
        align-items: center;
        justify-content: end;
        .icon {
          margin-right: 5px;
          svg {
            display: block;
          }
        }
      }
    }
    .themeWeatherWidget {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px 50px;
    .infoContent {
      display: flex;
      flex-wrap: wrap;
      .message {
        order: 1;
        width: 60%;
        span {
          letter-spacing: -1px;
          &:first-child {
            font-size: 20px;
            display: block;
            margin-bottom: 15px;
          }
          &:last-child {
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
      .overview {
        order: 2;
        width: 40%;
        position: relative;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        > span:first-child {
          font-size: 12px;
          order: 1;
        }
        .weather {
          order: 1;
        }
        .buttons {
          order: 3;
          margin: 0;
          flex-direction: column-reverse;
          align-items: end;
          gap: 5px;
          button {
            &:first-child {
              margin: 0;
            }
            span.icon {
              font-size: 16px;
            }
          }
        }
      }
      .themeWeatherWidget {
        flex-direction: column-reverse;
        align-items: end;
        gap: 5px;
        order: 2;
        button:first-child {
          margin-right: 0;
        }
      }
    }
  }
`;

export const DashboardContainer = styled.div`
  &.dark {
    .widget {
      .unreadNotifications,
      .mentions,
      .scheduler,
      .dday,
      .groupSchedule,
      .projects,
      .homepage,
      .company {
        background: #2c2d30;
        .info .label {
          color: rgba(255, 255, 255, 0.5);
        }
        .mySwiper .scheduleContent {
          background: #1e1f21;
        }
      }
      &.overlay {
        background: rgba(0, 0, 0, 0.7);
        .unreadNotifications,
        .mentions,
        .scheduler,
        .dday,
        .groupSchedule,
        .projects,
        .homepage,
        .company {
          background: transparent;
        }
      }
    }
  }
  .overlay {
    background: rgba(255, 255, 255, 0.7);
    /* border: 1px solid #ddd; */
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    height: auto;
    min-height: 150px;
    width: 100%;
    border-radius: 30px;
    padding: 0;
  }
  .widget:not(.overlay) {
    display: grid;
    grid-template-columns: repeat(4, minmax(150px, 1fr));
    grid-auto-rows: minmax(150px, auto);
    gap: 30px;
    padding: 0 20px;
  }
  .widget {
    max-width: 1440px;
    margin: 0 auto;
    /* display: flex;
    flex-wrap: wrap; */

    font-size: 20px;
    .unreadNotifications,
    .mentions,
    .scheduler,
    .dday,
    .groupSchedule,
    .projects,
    .homepage,
    .company {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 30px;
      padding: 30px;
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        .label {
          font-size: 28px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.5);
          display: inline-block;
          letter-spacing: -1px;
        }
        button {
          border: none;
          background: transparent;
          svg {
            display: block;
          }
        }
      }
    }
    .unreadNotifications {
      /* width: calc(50% - 15px); */
      width: 100%;
      ul {
        max-height: 280px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #5a5a5a;
          border-radius: 0px;
          border: 0px solid #d9d9d9;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 10px;
        }
      }
      li {
        position: relative;
        margin-left: 10px;
        padding-left: 10px;
        margin-bottom: 16px;
        span {
          display: inline-block;
          max-width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background: #6648ff;
          border-radius: 50%;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
        }
        &::before {
          content: "";
          position: absolute;
          width: 2px;
          height: 42px;
          background: #5c5c5c;
          border-radius: 50%;
          top: 120%;
          left: -6px;
          transform: translateY(-50%);
        }
        &:last-child::before {
          display: none;
        }
      }
    }
    .mentions {
      /* width: calc(50% - 15px); */
      width: 100%;
    }
    .scheduler {
      /* width: calc(50% - 15px); */
      width: 100%;
    }
    .dday {
      /* width: calc((50% - 15px) / 2 - 15px); */
      width: 100%;
    }
    .groupSchedule {
      /* width: calc((50% - 15px) / 2 - 15px); */
      width: 100%;
    }
    .projects {
      /* width: 100%; */
      width: 100%;
    }
    .homepage {
      /* width: calc(50% - 15px); */
      width: 100%;
      .cmpny {
        cursor: pointer;
      }
    }
    .company {
      /* width: calc(50% - 15px); */
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .widget {
      &:not(.overlay) {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
        gap: 20px;
      }
      .unreadNotifications,
      .mentions,
      .scheduler,
      .dday,
      .groupSchedule,
      .projects,
      .homepage,
      .company {
        width: 100%;
        font-size: 14px;
        padding: 20px;
        border-radius: 20px;
        .info {
          margin-bottom: 15px;
          .label {
            font-size: 18px;
          }
        }
      }
      .unreadNotifications ul {
        max-height: 140px;
        li::before {
          height: 38px;
        }
      }
    }
  }
`;

export const QuickLinkContainer = styled.div`
  max-width: 1440px;
  padding: 0 10px;
  height: 760px;
  display: flex;
  align-items: center;
  justify-content: center;
  .ellipse {
    width: 600px;
    position: relative;
    height: 600px;
    border: 0.5px solid #47474741;
    border-radius: 50%;
    .message {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 28px;
      span {
        display: block;
        strong {
          cursor: pointer;
        }
        &:last-child {
          font-weight: 700;
          margin-top: 20px;
        }
      }
    }
    .shortcutItem {
      width: 77px;
      height: 100px;
      position: absolute;
      transform: translate(-50%, -50%);
      .shortcutItemContent {
        position: relative;
        height: 100%;
        .menuButton {
          position: absolute;
          top: 0;
          right: 0;
          background: transparent;
          border: none;
          font-size: 20px;
          opacity: 0;
          transition: 0.3s;
          transform: translateX(100%);
        }
        &:hover .menuButton {
          opacity: 1;
        }
        .shortcut {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          position: relative;
          img {
            width: 100%;
            height: calc(100% - 23px);
            filter: drop-shadow(rgba(0, 0, 0, 0.3) 4px 4px 3px);
          }
          .not {
            position: absolute;
            top: calc(50% - 11.5px);
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 40px;
            font-weight: 900;
            color: #000;
            text-transform: uppercase;
          }
          .label {
            width: 100%;
            display: inline-block;
            text-transform: capitalize;
            font-size: 20px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    order: 3;
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
    padding: 0;
    .ellipse {
      width: 100%;
      border: none;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      border-radius: 0;
      .shortcutItem {
        position: relative;
        transform: none;
        width: calc((100% - 80px) / 5);
        height: 70px;
        .shortcutItemContent {
          .shortcut {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: calc(100% - 20px);
              object-fit: contain;
              filter: drop-shadow(rgba(0, 0, 0, 0.3) 2px 2px 2px);
            }
            .label {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;

export const ScheduleSwiperContainer = styled.div`
  .mySwiper {
    width: 100%;
    height: 94px;
    &:last-child {
      margin-right: 0;
    }
    .scheduleContent {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.5);
      height: 94px;
      cursor: pointer;
      gap: 10px;
      justify-content: space-between;
      border-radius: 5px;
      &.outside::after {
        content: "";
        width: 5px;
        height: 94px;
        background: linear-gradient(180deg, rgba(0, 116, 202, 1) 0%, rgba(237, 85, 131, 1) 100%);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px 0 0 5px;
      }
      .timeArea {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        width: 70px;
        .endOfStart,
        .projectType {
          display: block;
          font-size: 13px;
        }
        .ddayDate {
          font-weight: normal;
          font-size: 13px;
        }
      }
      .contentArea {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: calc(100% - 80px);
        span {
          &:nth-child(1) {
            font-size: 20px;
            font-weight: 500;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .mySwiper {
      height: 50px;
      .scheduleContent {
        padding: 10px;
        height: 50px;
        .timeArea {
          font-size: 12px;
          width: 50px;
          .projectType,
          .endOfstart {
            font-size: 10px;
          }
        }
        .contentArea {
          width: calc(100% - 55px);
          span:nth-child(1) {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const MenuContainer = styled.div`
  width: 130px;
  position: fixed;
  transform: translate(-50%, 30px);
  z-index: 999;
  &.dark {
    .contextMenu {
      background: #141416;
      li:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
  ul {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    padding: 10px 0;
    li {
      font-size: 14px;
      letter-spacing: -1px;
      transition: 0.3s;
      cursor: pointer;
      padding: 5px 10px;
      &:hover {
        background: rgba(217, 217, 217, 0.5);
      }
    }
  }
  @media screen and (max-width: 767px) {
    transform: translate(-70%, 20px);
  }
`;

export const MakeShortcutsContainer = styled.div`
  &.dark {
    .buttons button {
      border: 2px solid transparent;
      border-radius: 31px;
      background: rgba(255, 255, 255, 0.5);
      color: #000;
    }
    .inputField .content label input {
      background: #d9d9d9;
    }
    .selectIcon .content div {
      img {
        filter: drop-shadow(rgba(255, 255, 255, 0.1) 2px 2px 1px)
          drop-shadow(rgba(255, 255, 255, 0.1) -2px 2px 1px)
          drop-shadow(rgba(255, 255, 255, 0.1) 2px -2px 1px)
          drop-shadow(rgba(255, 255, 255, 0.1) -2px -2px 1px);
      }
      .checkmark {
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
      }
    }
  }
  .content {
    height: calc(100% - 150px);
    font-size: 20px;
  }
  .buttons {
    height: 62px;
    display: flex;
    justify-content: end;
    gap: 15px;
    button {
      font-size: 24px;
      width: 112px;
      color: #0b57d0;
      border: 2px solid #0b57d0;
      border-radius: 31px;
      background: transparent;
      &:last-child {
        background: #0b57d0;
        color: #fff;
      }
    }
  }
  .inputField {
    .content {
      .input {
        margin-bottom: 38px;
        .info {
          margin-bottom: 15px;
          letter-spacing: -1px;
          display: flex;
          gap: 10px;
          align-items: flex-end;
        }
        /* &.url {
          position: relative;
          .fixed {
            position: absolute;
            bottom: 27.5px;
            left: 20px;
            transform: translateY(50%);
            z-index: 3;
            color: #000;
          }
        } */
        .monotiService {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 200px;
          button {
            border: none;
            background: none;
            width: 35px;
            height: 35px;
          }
          img {
            width: 100%;
            height: 100%;
            filter: drop-shadow(rgba(0, 0, 0, 0.1) 1px 1px 1px)
              drop-shadow(rgba(0, 0, 0, 0.1) -1px 1px 1px)
              drop-shadow(rgba(0, 0, 0, 0.1) 1px -1px 1px)
              drop-shadow(rgba(0, 0, 0, 0.1) -1px -1px 1px);
          }
        }
        input {
          display: block;
          border: 1px solid #a9a9a9;
          border-radius: 19px 19px 0 0;
          width: 100%;
          height: 55px;
          padding: 0 20px;
          &:focus {
            border: 1px solid #d9d9d9 !important;
            box-shadow: none;
            background: #d9d9d9;
          }
        }
      }
    }
  }
  .selectIcon {
    .content {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      height: calc(100% - 200px);
      div {
        width: 86px;
        height: 86px;
        aspect-ratio: 1;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        .not {
          width: 86px;
          height: 86px;
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 25%;
          transform: translate(-50%, -50%);
          font-size: 40px;
          font-weight: 900;
          color: #000;
          text-transform: uppercase;
        }
        .checkmark {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(255, 255, 255, 0.5);
          font-size: 40px;
          color: #000;
          overflow: hidden;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          opacity: 0;
          width: 97px;
          height: 97px;
          svg {
            display: block;
          }
        }
        &.active .checkmark {
          opacity: 1;
        }
      }
      img {
        width: 100%;
        height: 100%;
        filter: drop-shadow(rgba(0, 0, 0, 0.3) 4px 4px 3px);
      }
    }
  }

  @media screen and (max-width: 767px) {
    .modal {
      max-width: 90%;
      border-right: 20px;
      height: 400px;
      .modalContent {
        padding: 20px;
      }
      .label {
        font-size: 20px;
        height: 25px;
      }
      .buttons {
        height: 35px;
        button {
          font-size: 16px;
        }
      }
      .inputField .content {
        font-size: 16px;
        label {
          font-size: 16px;
          margin-bottom: 20px;
          input {
            height: 40px;
            border-radius: 10px 10px 0px 0px;
          }
        }
      }
      .selectIcon .content {
        height: calc(100% - 100px);
        div {
          width: 59px;
          height: 59px;
        }
      }
    }
  }
`;

import { useOutletContext } from "react-router";

import CartForm from "./component/cartForm";

import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

import { ChicCartContaienr } from "../shopping/component/styled/chicTheme";
import { ModernCartContaienr } from "../shopping/component/styled/modernTheme";
import { CuteCartContaienr } from "../shopping/component/styled/cuteTheme";
import { SimpleCartContaienr } from "../shopping/component/styled/simpleTheme";
import { SmCartContaienr } from "../shopping/component/styled/smTheme";
import { DefaultCartContainer } from "../shopping/component/styled/defaultTheme";

const Cart = () => {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme.toString()) {
      case "0":
        return (
          <DefaultCartContainer>
            <CartForm />
          </DefaultCartContainer>
        );
      case "1":
        return (
          <ChicCartContaienr>
            <CartForm />
          </ChicCartContaienr>
        );
      case "2":
        return (
          <ModernCartContaienr>
            <ModernBanner />
            <CartForm />
          </ModernCartContaienr>
        );
      case "3":
        return (
          <CuteCartContaienr>
            <CuteBanner />
            <CartForm />
          </CuteCartContaienr>
        );
      case "4":
        return (
          <SimpleCartContaienr>
            <SimpleBanner />
            <CartForm />
          </SimpleCartContaienr>
        );
      case "sm":
        return (
          <SmCartContaienr>
            <CartForm />
          </SmCartContaienr>
        );
      default:
        return (
          <DefaultCartContainer>
            <CartForm />
          </DefaultCartContainer>
        );
    }
  };

  return <>{renderComponent()}</>;
};

export default Cart;

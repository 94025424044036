import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { CustomInput } from "../../styled/common";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { ProductListContainer } from "../../styled/product";

const ProductList = ({
  products,
  total,
  pages,
  changePages,
  selectedProducts,
  handleCheck,
  categoriesObj,
  handleModalState = null,
  isSync = false,
  synced = null,
}) => {
  const setting = useSelector((state) => state?.user?.setting);
  const startIndex = (pages.page - 1) * pages.limit;
  const currentProducts = isSync ? products : products.slice(startIndex, startIndex + pages.limit);

  // const isAllChecked =
  //   currentProducts.length > 0 &&
  //   selectedProducts.length > 0 &&
  //   currentProducts.every((product) =>
  //     selectedProducts.some((selected) => selected.id === product.id),
  //   );

  const renderProductDetails = (product, categoriesObj) => {
    const price = isSync ? product.basePrice : product.price;
    const details = [
      {
        key: "media",
        title: "상품이미지",
        value: <img src={imgHandler(product?.media)} alt="상품 이미지" onError={onErrorImg} />,
      },
      {
        key: "name",
        title: "상품명",
        value: (
          <span {...(product?.description && { "data-description": product.description })}>
            {product?.name}
          </span>
        ),
      },
      { key: "brand", title: "브랜드", value: product?.brand },
      {
        key: "price",
        title: "가격",
        value: price ? `${price?.toLocaleString()}원` : "가격문의",
      },
      { key: "category", title: "카테고리", value: categoriesObj[product?.categoryId] },
      { key: "specifications", title: "규격", value: product?.specifications },
    ];

    return details.map((detail, index) => (
      <div key={index} className={detail.key}>
        <span className="tit">{detail.title}</span>
        <span className="con">{detail.value ? detail.value : "-"}</span>
      </div>
    ));
  };

  return (
    <ProductListContainer className={setting?.mode}>
      <ul>
        {currentProducts.length > 0 ? (
          currentProducts.map((product) => (
            <li key={product.id}>
              <CustomInput className="block">
                <input
                  type="checkbox"
                  checked={
                    isSync
                      ? selectedProducts.some((item) => item.id === product.id) &&
                        !synced?.[product?.id]
                      : selectedProducts.some((item) => item.id === product.id)
                  }
                  onChange={() => handleCheck(product)}
                />
                <span className="checkmark"></span>
              </CustomInput>
              {renderProductDetails(product, categoriesObj)}
              {!isSync ? (
                <div className="buttons">
                  <button onClick={() => handleModalState("edit", product)}>수정</button>
                  <button onClick={() => handleModalState("detail", product)}>보기</button>
                </div>
              ) : (
                <div className="isSync">
                  <span className="tit">연동여부</span>
                  {synced?.[product?.id] && (
                    <span className="con">
                      <AiOutlineCheck />
                    </span>
                  )}
                </div>
              )}
            </li>
          ))
        ) : (
          <li>상품이 존재하지 않습니다.</li>
        )}
      </ul>
      <Pagination
        activePage={pages.page}
        itemsCountPerPage={pages.limit}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={(e) => changePages({ target: "page", value: e })}
      />
    </ProductListContainer>
  );
};

export default ProductList;

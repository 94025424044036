import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Modal from "../../../../../common/modal";
import RegisterNoticeModal from "../modal/registerNoticeModal";
import BoardSearch from "../../../../../common/board/boardSearch";
import BoardTable from "../../../../../common/board/boardTable";
import CustomPagination from "../../../../../common/customPagination";
import Pagination from "react-js-pagination";
import { RegisterNoticeFormContainer } from "../styled/register";

export default function RegisterNoticeForm() {
  const navigate = useNavigate();

  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);

  const [noticeModal, setNoticeModal] = useState();
  const [notices, setNotices] = useState([]);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [selected, setSelected] = useState("제목");

  const selectList = ["제목", "내용"];

  const location = useLocation();
  const isUpload = location?.state?.noticeUpload;
  const isDeleted = location?.state?.noticeDelete;
  const isEdit = location?.state?.noticeEdit;
  let count = 0;

  useEffect(() => {
    getToast();
  }, []);

  useEffect(() => {
    if (page) {
      getNotice(page - 1);
    }
  }, [page]);

  const getToast = () => {
    count++;
    if (count === 1 && isUpload) {
      toast.success("공지사항이 등록 되었습니다.");
    }
    if (count === 1 && isDeleted) {
      toast.success("공지사항이 삭제 되었습니다.");
    }
    if (count === 1 && isEdit) {
      toast.success("공지사항이 수정 되었습니다.");
    }
  };

  const getNotice = async (page = 0) => {
    const req = {
      gbn: "N",
      delYn: "N",
      cmpnyNo: user?.cmpnyNo,
      offset: 10 * page,
      pageNumber: page,
      pageSize: 10,
      paged: true,
    };

    if (keyword !== "") {
      switch (selected) {
        case "제목":
          req.noticeSj = keyword;
          break;
        case "내용":
          req.noticeCn = keyword;
          break;
        default:
          break;
      }
    }

    const response = await axios.post("/api/noticeList", req);
    const res = response.data;
    console.log(res);
    setNotices(res.content);
    setTotalElements(res.totalElements);
  };

  const handleNoticeModal = () => {
    setNoticeModal(!noticeModal);
  };

  return (
    <RegisterNoticeFormContainer className={setting?.mode}>
      <BoardSearch
        selectList={selectList}
        setSelected={setSelected}
        setKeyword={setKeyword}
        getData={getNotice}
      />
      <BoardTable notices={notices} setting={setting} />
      <div className="actions">
        <Pagination
          activePage={page}
          itemsCountPerPage={10}
          totalItemsCount={totalElements}
          pageRangeDisplayed={3}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={(page) => setPage(page)}
        />
        <div className="buttons">
          <button onClick={() => navigate("/mypage/product")}>
            <span>다음 단계</span>
          </button>
          <button
            type="button"
            className="btn_write"
            onClick={() => navigate("/mypage/registerNoticeWrite")}>
            공지 등록
          </button>
        </div>
      </div>
      <Modal
        modalState={noticeModal}
        handleModalState={handleNoticeModal}
        html={<RegisterNoticeModal handleNoticeModal={handleNoticeModal} />}
      />
    </RegisterNoticeFormContainer>
  );
}

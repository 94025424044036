import React from "react";

const Confirm = ({ transformValue, handleSubmit, setViewType }) => {
  return (
    <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
      <span className="info">해당 상품을 변경하시겠습니까?</span>
      <div className="actions">
        <button onClick={() => setViewType(0)}>취소</button>
        <button onClick={handleSubmit}>확인</button>
      </div>
    </div>
  );
};

export default Confirm;

import React from "react";
import { Link } from "react-router-dom";
import { HomepageSubMenuContainer } from "./styled/homepage";
import { useSelector } from "react-redux";

const HomepageSubMenu = ({ activeMenu, handleChangeComponent }) => {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <HomepageSubMenuContainer className={setting?.mode}>
      <ul>
        {homepageMenu.map((item, i) => (
          <li
            className={activeMenu === item.link ? "active" : ""}
            key={i}
            onClick={() => handleChangeComponent(item.link)}>
            <Link>{item.label}</Link>
          </li>
        ))}
      </ul>
    </HomepageSubMenuContainer>
  );
};

export default HomepageSubMenu;

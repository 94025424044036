import React from "react";
import { IoMdSearch } from "react-icons/io";
import { SearchFormContainer } from "../styled/notice";

const SearchForm = () => {
  return (
    <SearchFormContainer>
      <input type="text" placeholder="검색어를 입력해주세요." />
      <button>
        <IoMdSearch />
      </button>
    </SearchFormContainer>
  );
};

export default SearchForm;

import React from "react";
import { useOutletContext } from "react-router";

import { ChicProductsContainer } from "../shopping/component/styled/chicTheme";
import { ModernProductsContainer } from "../shopping/component/styled/modernTheme";
import { CuteProductsContainer } from "../shopping/component/styled/cuteTheme";

import CuteBanner from "../shopping/component/themes/cute/banner";
import ModernBanner from "../shopping/component/themes/modern/banner";
import ProductListForm from "./component/productListForm";
import { SimpleProductsContainer } from "../shopping/component/styled/simpleTheme";
import SimpleBanner from "../shopping/component/themes/simple/banner";
import { SmProductsContainer } from "../shopping/component/styled/smTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";

const ProductsList = () => {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme.toString()) {
      case "0":
        return (
          <DefaultContaienr>
            <ProductListForm />
          </DefaultContaienr>
        );
      case "1":
        return (
          <ChicProductsContainer>
            <ProductListForm />
          </ChicProductsContainer>
        );
      case "2":
        return (
          <ModernProductsContainer>
            <ModernBanner />
            <ProductListForm />
          </ModernProductsContainer>
        );
      case "3":
        return (
          <CuteProductsContainer>
            <CuteBanner />
            <ProductListForm />
          </CuteProductsContainer>
        );
      case "4":
        return (
          <SimpleProductsContainer>
            <SimpleBanner />
            <ProductListForm />
          </SimpleProductsContainer>
        );
      case "sm":
        return (
          <SmProductsContainer>
            <ProductListForm />
          </SmProductsContainer>
        );
      default:
        return <ProductListForm />;
    }
  };

  return <>{renderComponent()}</>;
};

export default ProductsList;

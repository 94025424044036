import React from "react";
import { PlanComparisonContainer } from "../../styled/info";
import { FaCheck } from "react-icons/fa6";

const PlanComparison = () => {
  const plans = [
    { feature: "상품 갯수", free: "5", plus: "무제한", premium: "무제한" },
    { feature: "실시간 알림 발송", free: true, plus: true, premium: true },
    { feature: "개인 도메인", free: true, plus: true, premium: true },
    { feature: "보안서버(SSL)", free: true, plus: true, premium: true },
    { feature: "광고 제거", free: true, plus: true, premium: true },
  ];

  const planHeaders = [
    { name: "free", price: 0 },
    { name: "plus", price: { origin: "10,000원/월", discount: "4,500원/월" } },
    { name: "premium", price: { origin: "20,000원/월", discount: "9,900원/월" } },
  ];

  return (
    <PlanComparisonContainer>
      <div className="header">
        <div></div>
        {planHeaders.map((plan, index) => (
          <div key={index} className={plan.name}>
            <strong>{plan.name}</strong>
            {plan.price ? (
              <div className="price">
                <span className="origin">{plan.price.origin}</span>
                <span>{plan.price.discount}</span>
              </div>
            ) : (
              <div className="price">
                <span>0원</span>
              </div>
            )}
          </div>
        ))}
      </div>
      {plans.map((plan, index) => (
        <div key={index} className="row">
          <div>{plan.feature}</div>
          {planHeaders.map(
            (header, idx) =>
              plan[header.name] && (
                <div>
                  <FaCheck />
                </div>
              ),
          )}
        </div>
      ))}
    </PlanComparisonContainer>
  );
};

export default PlanComparison;

import React from "react";
import { useNavigate } from "react-router";

const Gnb = ({ menus, links, url, homepageInfo }) => {
  const navigate = useNavigate();

  const clickWindowLink = (blog = false) => {
    const path = blog
      ? "https://blog.naver.com/ssongwonjang"
      : "https://map.naver.com/p/entry/place/13245702?placePath=%252Fhome%253Fentry%253Dplt&searchType=place&lng=127.4307144&lat=36.3321382&c=15.00,0,0,0,dh";
    window.open(path, "_blank");
  };

  return (
    <ul className="gnb">
      {menus.map((item) => (
        <li onClick={() => navigate(`/shop/${url}/${links[item.menuType]}`)}>
          {item.afterMenuName ? item.afterMenuName : item.beforeMenuName}
        </li>
      ))}
      {homepageInfo?.cmpnyNo === 29787 && (
        <>
          <li onClick={() => clickWindowLink()}>고명한의원</li>
          <li onClick={() => clickWindowLink(true)}>블로그</li>
        </>
      )}
    </ul>
  );
};

export default Gnb;

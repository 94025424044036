import React from "react";
import { IoIosSearch, IoMdMenu } from "react-icons/io";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const Tnb = ({ handleLogoutModal, url, homepageInfo, setClickSideMenu }) => {
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const user = useSelector((state) => state?.user?.data[0]);

  return (
    <ul className="tnb">
      <li>
        {user ? (
          <span onClick={handleLogoutModal}>로그아웃</span>
        ) : (
          <Link to="/shop/login">로그인</Link>
        )}
      </li>
      {user ? (
        // <li>
        //   <Link to={url ? `/shop/${url}/cart` : "/shop/cart"}>장바구니</Link>
        // </li>
        <></>
      ) : (
        <li>
          <Link to="/type">회원가입</Link>
        </li>
      )}
      <li className="tnb_btn">
        <Link to={homepageInfo ? `${url}/search?keyword=` : "search?keyword="}>
          <i className="icon">
            <IoIosSearch />
          </i>
        </Link>
      </li>
      {isTablet && (
        <li onClick={() => setClickSideMenu(true)} className="tnb_btn">
          <div style={{ cursor: "pointer" }}>
            <i className="icon">
              <IoMdMenu />
            </i>
          </div>
        </li>
      )}
    </ul>
  );
};

export default Tnb;

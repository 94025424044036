import React from "react";

const Total = () => {
  return (
    <div id="secTable">
      <div class="tb_footer pdt_30 font_18 font600 text_center">
        <div class="total">Total</div>
        <div class="price_detail">
          <dl>
            <dt class="font_color_gray_light">선택상품금액</dt>
            <dd>
              <b class="font900">1,708,000</b>
              <small>원</small>
            </dd>
          </dl>
          <i class="xi-plus font_30"></i>
          <dl>
            <dt class="font_color_gray_light">총 배송비</dt>
            <dd>
              <b class="font900">40,000</b>
              <small>원</small>
            </dd>
          </dl>
          <i class="xi-minus font_30"></i>
          <dl>
            <dt class="font_color_gray_light">할인예상금액</dt>
            <dd class="font_color_red">
              <b class="font900">719,100</b>
              <small>원</small>
            </dd>
          </dl>
        </div>
        <div class="price_sum">
          <dl>
            <dt>주문금액</dt>
            <dd>
              <b class="font900">11,620,900</b>원
            </dd>
          </dl>
        </div>
        <div class="btns">
          <button class="btn_order font_16 font700">
            <b>1</b>건 주문하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Total;

import styled from "styled-components";

const darkBg = "#111111";
const darkSubColor = "#2b2b2b";
const darkPoint = "#533b70";
const darkPointColor = "#bb86fc";
const ef = "#efefef";
const mainColor = "#0074ca";
const borderRadius = "0.25em";
const aniTime = "0.2s";
const outsideColor = "linear-gradient(rgb(0, 116, 202), rgb(237, 85, 131))";

// category color
const gbnP = "#139859";
const gbnC = "#0074ca";
const gbnG = "#f06f02";
const gbnO = "#ED5583";

// Header
export const SchdulerHeader = styled.header`
  &#schduler_header {
    padding: 10px 20px;
    box-sizing: border-box;
    z-index: 2;
    &.dark {
      background: ${darkSubColor};
      .float_right {
        .backToList {
          background: ${darkPointColor};
        }
        .menuNav .bg ul {
          background: ${darkPoint};
          box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 3px -1px;
          li button {
            color: #efefef;
          }
          li .icon {
            background: ${darkBg};
          }
        }
      }
    }
    .float_left {
      img {
        max-width: 40px;
        margin-right: 15px;
        border-radius: 5px;
      }
    }
    .float_right {
      gap: 10px;
      .schduler_header_menu li {
        margin: 0;
        font-size: 16px;
      }
      .backToList {
        font-size: 14px;
      }
      > button,
      .navBtn,
      .notify {
        font-size: 34px;
        padding: 0;
        background: transparent;
        display: flex;
        border: none;
        margin-left: 15px;
        color: ${ef};
      }
      .menuNav {
        position: relative;
        .bg {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          ul {
            position: absolute;
            top: 60px;
            right: 15px;
            background: #fff;
            display: flex;
            align-items: center;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            border-radius: ${borderRadius};
            width: 280px;
            flex-wrap: wrap;
            li {
              padding: 5px 0;
              display: flex;
              align-items: center;
              flex-direction: column;
              width: 70px;
              color: ${darkBg};
              button {
                border: none;
                background: transparent;
              }
              .icon {
                font-size: 20px;
                border-radius: 50%;
                background: ${ef};
                margin: 5px auto;
                padding: 10px;
                box-sizing: border-box;
                width: 40px;
                svg {
                  display: block;
                }
              }
              p {
                width: 100%;
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    &#schduler_header .float_left {
      width: 30%;
    }
    &#schduler_header .float_right {
      width: 70%;
    }
  }
`;
// --Header

// Scheduler
export const SchedulerPageContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  &.dark {
    background: ${darkBg};
    color: ${ef};
    a {
      color: ${ef};
    }
    #schduler_content {
      .tableEditAddBtnArea {
        .exit {
          background: ${darkPoint};
          color: #fff;
        }
      }
    }
  }
  #schduler_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-direction: row;
    .tableEditAddBtnArea {
      display: flex;
      justify-content: space-between;
      gap: 3%;
      .table_edit,
      .table_add {
        width: 46%;
        margin-bottom: 0;
        border-radius: ${borderRadius};
        cursor: pointer;
      }
      .exit {
        width: 100%;
        padding: 12px;
        background-color: ${ef};
        border-radius: ${borderRadius};
        cursor: pointer;
        border: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    #schduler_content {
      height: 100vh;
      overflow-y: auto;
    }
    #schduler_content .tableEditAddBtnArea .exit {
      width: 50%;
    }
  }
`;
// --Scheduler

// Calendar
export const CalendarContainer = styled.div`
  width: calc(100% - 500px);
  border-left: 1px solid rgb(204, 204, 204);
  border-right: 1px solid rgb(204, 204, 204);
  padding: 20px;
  box-sizing: border-box;
  .mainCalendar {
    position: relative;
  }
  &.dark {
    border-left: 1px solid ${darkSubColor};
    border-right: 1px solid ${darkSubColor};
    .todayBtn {
      background: #484848;
      color: ${ef};
    }
    .fc {
      .fc-event-time {
        display: none;
      }
      table {
        outline: 1px solid ${darkSubColor};
        thead tr th.fc-day {
          border-bottom: 1px solid ${darkSubColor};
          background: ${darkPoint};
          &:not(:last-child) {
            border-right: 1px solid ${darkSubColor};
          }
        }
        tbody tr td .fc-daygrid-day-top .fc-daygrid-day-number .date .weather {
          color: ${ef};
        }
        table {
          tbody tr td {
            border: 1px solid ${darkSubColor};
            &.selected {
              background: #464646;
            }
            &.fc-daygrid-day.fc-day-today {
              background: ${darkPoint};
              &.selected {
                background: #4f455b;
              }
              .fc-daygrid-day-number {
                color: ${ef};
              }
            }
          }
        }
      }
    }
    .searchBtn {
      button {
        background: ${darkPoint};
        color: ${ef};
      }
    }
  }
  .searchBtn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    button {
      border: none;
      background: rgb(239, 239, 239);
      border-radius: 0.25em;
      font-size: 16.5px;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.4em;
      vertical-align: middle;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      margin-right: 10px;
    }
  }
  .calendarBtnArea {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    z-index: 1;
    button {
      padding: 0.35em 0.65em;
      line-height: 1.5;
      text-align: center;
      border-radius: ${borderRadius};
      vertical-align: middle;
      border: 1px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .moveMonth {
      margin-left: 10px;
      display: flex;
      button {
        background: #2c3e50;
        color: ${ef};
        svg {
          stroke-width: 1;
        }
        &:first-child {
          border-radius: ${borderRadius} 0 0 ${borderRadius};
        }
        &:last-child {
          border-radius: 0 ${borderRadius} ${borderRadius} 0;
        }
      }
    }
  }
  .fc {
    .fc-event-time {
      display: none;
    }
    .fc-button {
      padding: 0.4em;
      font-size: 0.99em;
    }
    .fc-scrollgrid {
      border: none;
    }
    .fc-header-toolbar {
      position: relative;
      .fc-toolbar-chunk {
        &:nth-child(1) {
          margin: auto;
          height: 2em;
        }
        &:last-child {
          display: none;
        }
      }
    }
    table {
      outline: 1px solid ${mainColor};
      border-collapse: collapse;
      thead tr {
        th {
          border: none;
          &:not(:last-child) {
            border-right: 1px solid ${mainColor};
          }
          &.fc-day {
            padding: 2px 0;
            background: transparent;
            font-size: 12px;
            border-bottom: 1px solid ${mainColor};
          }
        }
      }
      tbody tr {
        td {
          border: none;
          font-size: 16px;
          cursor: pointer;
          .fc-daygrid-day-frame {
            min-height: 115px;
            height: calc((100vh - 210px) / 6);
          }
          &.fc-day-sun .fc-daygrid-day-number {
            color: #ca0000;
          }
          &.fc-day-sat .fc-daygrid-day-number {
            color: ${mainColor};
          }
          &.selected {
            background: ${ef};
            &.fc-day-today {
              background: #e8e6db;
            }
          }
          .fc-daygrid-day-top {
            .fc-daygrid-day-number {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              padding-left: 5px;
              .holiday {
                font-size: 12px;
                display: flex;
                align-items: center;
                font-weight: 600;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                img {
                  width: 15px;
                  height: 15px;
                  margin-right: 5px;
                }
              }
              .date {
                display: flex;
                align-items: center;
                gap: 1%;

                div {
                  display: flex;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-break: break-all;
                }
                span:not(.weather) {
                  width: 20px;
                  text-align: right;
                  flex-grow: 1;
                }
                .weather {
                  font-size: 11px;
                  color: ${darkBg};
                  &.dark {
                    color: ${ef};
                  }
                }
              }
            }
          }
          .fc-daygrid-day-events {
            font-size: 15px;
            margin-bottom: 0;
          }
          .fc-daygrid-day-bottom {
            pointer-events: none;
            font-size: 11px;
            .fc-daygrid-more-link {
              float: right;
            }
          }
          .fc-event.eventComplete .fc-event-title {
            text-decoration: line-through;
          }
        }
      }
      table {
        position: relative;
        width: 100%;
        outline: none;
        tbody tr td {
          border: 1px solid #eee;
          overflow-y: clip;
          &.fc-day-today {
            /* background: none; */
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px), screen and (min-width: 890px) and (max-width: 1051px) {
    .calendarView-C {
      .fc {
        table tbody tr td .fc-daygrid-day-events {
          position: relative;
          font-size: 13px;
          .fc-daygrid-event-harness {
            position: absolute;
            top: 0;
            .fc-event-title {
              padding: 2px;
            }
            &:nth-child(1) {
              left: 10px;
            }
            &:nth-child(2) {
              left: 50%;
              transform: translateX(-50%);
            }
            &:nth-child(3) {
              right: 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    width: calc(100% - 240px);
    border-right: none;
    .fc table tbody tr td {
      .fc-daygrid-day-frame {
        min-height: 100px;
      }
      .fc-daygrid-day-top .fc-daygrid-day-number .date .weather {
        display: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    border-left: none;
    padding: 20px 0;
    .searchBtn {
      left: 100px;
      &.active {
        left: 20px;
      }
    }
    .calendarBtnArea {
      right: 20px;
    }
    .fc {
      .fc-header-toolbar {
        .fc-toolbar-chunk:nth-child(1) {
          line-height: 2.3em;
          .fc-toolbar-title {
            font-size: 1em;
          }
        }
        .fc-toolbar-chunk:last-child {
          margin-right: 20px;
        }
      }
      .fc-button .fc-icon {
        font-size: 10px;
      }
      table {
        tbody tr td .fc-daygrid-day-frame {
          height: calc((100vh - 140px) / 6);
        }
        .fc-event-time,
        .fc-event-title {
          width: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 12px;
        }
        tbody tr td .fc-daygrid-day-top .fc-daygrid-day-number {
          .holiday {
            opacity: 0;
            z-index: -999;
          }
        }
      }
    }
  }
`;
// --Calendar

// SideMenu
export const SideMenuContainer = styled.div`
  width: 240px;
  &.dark {
    .writing_constract_list_wrap {
      &::-webkit-scrollbar-thumb {
        background-color: ${darkPoint};
        border: 3px solid ${darkBg};
      }
      .selectCalendarBtn {
        color: #fff;
      }
      .calendarArea {
        border-top: 1px solid ${darkSubColor};
        border-bottom: 1px solid ${darkSubColor};
        .fc {
          table {
            .fc-daygrid-day {
              &.selected .fc-daygrid-day-top {
                background: #464646;
              }
              &.fc-day-today .fc-daygrid-day-top {
                background: ${darkPoint};
              }
              &.selected.fc-day-today .fc-daygrid-day-top {
                background: #4f455b;
              }
            }
          }
        }
        .monthsBody div span:hover {
          color: ${ef};
          background: ${darkSubColor};
        }
        .calendarBtnArea .todayBtn {
          background: #484848;
          color: ${ef};
        }
      }
      .choiceListArea .choiceList li {
        label:hover {
          background: ${darkSubColor};
        }
        .more button {
          color: ${ef};
          &:hover {
            background: ${darkSubColor};
          }
        }
      }
      .choiceListArea {
        .radioContainer,
        .calendarViewContainer {
          .tabs {
            background: ${darkSubColor};
            border: 1px solid ${darkSubColor};
            .tab {
              color: ${ef};
            }
          }
          .glider {
            background: ${darkPoint};
          }
        }
      }
    }
  }
  .writing_constract_list_wrap {
    width: 100%;
    height: calc(100vh - 73px);
    overflow-y: auto;
    overflow-x: hidden;
    /* 스크롤 커스텀 */
    &::-webkit-scrollbar {
      width: 9px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${mainColor};
      border: 3px solid #ffffff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    /* 스크롤 커스텀 */
    .tableEditAddBtnArea {
      padding: 20px;
    }
    .calendarBox {
      position: relative;
      .selectCalendarBtn {
        position: absolute;
        top: 20px;
        z-index: 50;
        border: none;
        background: transparent;
        padding: 5px;
      }
    }
    .calendarArea {
      position: relative;
      padding: 20px;
      border-top: 1px solid ${ef};
      border-bottom: 1px solid ${ef};
      max-height: 300px;
      .calendarBtnArea {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        button {
          padding: 2px;
          border: 1px solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 21.92px;
          background: ${ef};
          border-radius: ${borderRadius};
          font-size: 11px;
          svg {
            display: block;
            stroke-width: 1.5;
            margin-top: 2px;
          }
        }
        .moveMonth {
          margin-left: 10px;
          display: flex;
          button {
            font-size: 10px;
            background: rgb(44, 62, 80);
            width: 21px;
            color: ${ef};
            &:first-child {
              border-radius: ${borderRadius} 0px 0px ${borderRadius};
            }
            &:last-child {
              border-radius: 0px ${borderRadius} ${borderRadius} 0px;
            }
          }
        }
      }
      .fc {
        table .fc-daygrid-day-events {
          .fc-daygrid-event-dot {
            background: ${ef};
          }
          .scheduleC .fc-daygrid-event-dot {
            background: ${gbnC};
          }
          .scheduleG .fc-daygrid-event-dot {
            background: ${gbnG};
          }
          .scheduleO .fc-daygrid-event-dot {
            background: ${gbnO};
          }
          .scheduleEM .fc-daygrid-event-dot {
            background: #139859;
          }
          .scheduleGS .fc-daygrid-event-dot {
            background: #ffc455;
          }
          .scheduleBB .fc-daygrid-event-dot {
            background: #8f2d56;
          }
          .scheduleLL .fc-daygrid-event-dot {
            background: #a7a3d9;
          }
          .scheduleTT .fc-daygrid-event-dot {
            background: #38c6bd;
          }
          .scheduleVL .fc-daygrid-event-dot {
            background: #b350c3;
          }
        }
        .fc-header-toolbar {
          .fc-toolbar-chunk:last-child {
            display: none;
          }
        }
        .fc-header-toolbar {
          height: 21.91px;
          .fc-toolbar-title {
            font-size: 13px;
            cursor: pointer;
          }
          .fc-button {
            font-size: 10px;
            padding: 2px;
          }
        }
        table {
          .fc-daygrid-day {
            &.fc-day-today {
              background: none;
              .fc-daygrid-day-top {
                background: #fffadf;
              }
              &.selected {
                .fc-daygrid-day-top {
                  background: #e8e6db;
                }
              }
            }
            &.selected {
              .fc-daygrid-day-top {
                background: ${ef};
              }
            }
          }
          border: none;
          tbody tr td,
          thead tr th {
            border: none;
          }
          tbody tr td {
            cursor: pointer;
            .fc-daygrid-event.fc-event-end,
            .fc-daygrid-event.fc-event-start {
              margin: 0;
            }
          }
          .fc-daygrid-day-top {
            justify-content: center;
          }
          .fc-daygrid-day-events {
            min-height: 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            height: 10px;
            margin: 0;
            overflow: hidden;
            .fc-event-time,
            .fc-event-title {
              display: none;
            }
            .fc-daygrid-day-bottom {
              margin: 0;
            }
            .fc-daygrid-event-dot {
              border: none;
              width: 5px;
              height: 5px;
              margin: 0px 1px;
            }
            .scheduleP .fc-daygrid-event-dot {
              background: ${gbnP};
            }
            .scheduleC .fc-daygrid-event-dot {
              background: ${gbnC};
            }
            .scheduleG .fc-daygrid-event-dot {
              background: ${gbnG};
            }
            .scheduleO .fc-daygrid-event-dot {
              background: ${gbnO};
            }
          }
        }
      }
      .monthsHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h2 {
          font-size: 13px;
          cursor: pointer;
        }
        .buttons {
          display: flex;
          align-items: center;
          button {
            font-size: 10px;
            display: flex;
            padding: 2px;
            background: #2c3e50;
            border: 1px solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 21px;
            height: 21.92px;
            color: #fff;
            &:first-child {
              border-radius: ${borderRadius} 0 0 ${borderRadius};
            }
            &:last-child {
              border-radius: 0 ${borderRadius} ${borderRadius} 0;
              margin-left: -1px;
            }
            svg {
              display: block;
              stroke-width: 1.5;
              margin-top: 2px;
            }
          }
        }
      }
      .monthsBody {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 220px;
        div {
          width: 25%;
          height: calc(100% / 3);
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            padding: 5px 7px;
            cursor: pointer;
            border-radius: ${borderRadius};
            &:hover {
              background: ${ef};
            }
          }
        }
      }
    }
    .choiceListArea {
      .choiceHead {
        margin: 10px 0;
        padding: 0 20px;
      }
      .radioContainer {
        padding: 0 20px 20px 20px;
        .tabs {
          display: flex;
          position: relative;
          background-color: #fff;
          border: 1px solid #efefef;
          padding: 5px 10px;
          border-radius: 20px;
          width: 190px;
        }
        .tabs * {
          z-index: 2;
        }
        input[type="radio"] {
          display: none;
        }
        .tab {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 50px;
          font-size: 0.8rem;
          color: black;
          font-weight: 500;
          border-radius: 99px;
          cursor: pointer;
          transition: color 0.15s ease-in;
          width: calc(100% / 3);
        }
        input[id="completeYn-D"]:checked ~ .glider {
          transform: translateX(5%);
        }
        input[id="completeYn-Y"]:checked ~ .glider {
          transform: translateX(118%);
        }
        input[id="completeYn-N"]:checked ~ .glider {
          transform: translateX(230%);
        }
        .glider {
          position: absolute;
          display: flex;
          height: 30px;
          width: 50px;
          background-color: #e6eef9;
          z-index: 1;
          border-radius: 10px;
          transition: 0.25s ease-out;
        }
      }
      .calendarViewContainer {
        padding: 0 20px 20px 20px;
        .tabs {
          display: flex;
          position: relative;
          background-color: #fff;
          border: 1px solid #efefef;
          padding: 5px 10px;
          border-radius: 20px;
          width: 190px;
        }
        .tabs * {
          z-index: 2;
        }
        input[type="radio"] {
          display: none;
        }
        .tab {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 50px;
          font-size: 0.8rem;
          color: black;
          font-weight: 500;
          border-radius: 99px;
          cursor: pointer;
          transition: color 0.15s ease-in;
          width: calc(100% / 2);
        }
        input[id="viewMode-D"]:checked ~ .glider {
          transform: translateX(0%);
        }
        input[id="viewMode-C"]:checked ~ .glider {
          transform: translateX(100%);
        }
        .glider {
          position: absolute;
          display: flex;
          height: 30px;
          width: calc(50% - 10px);
          background-color: #e6eef9;
          z-index: 1;
          border-radius: 10px;
          transition: 0.25s ease-out;
        }
      }
      .choiceList {
        > li {
          label {
            display: block;
            width: 100%;
            padding: 5px 20px;
            cursor: pointer;
            &:hover {
              background: ${ef};
            }
            input[type="checkbox"] {
              margin-right: 5px;
            }
          }
          &.personalYn {
            input[type="checkbox"] {
              accent-color: ${gbnP};
            }
          }
          &.companyYnList {
            input[type="checkbox"] {
              accent-color: ${gbnC};
            }
          }
          &.groupYnList {
            input[type="checkbox"] {
              accent-color: ${gbnG};
            }
          }
          .more {
            button {
              border: none;
              background-color: transparent;
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #333;
              padding: 5px 20px;
              width: 100%;
              span {
                font-size: 10px;
                svg {
                  display: block;
                  margin-left: 7px;
                }
              }
              &:hover {
                background: ${ef};
              }
            }
            ul {
              max-height: 0;
              transition: 0.2s;
              overflow: hidden;
              li {
                label {
                  padding-left: 40px;
                  cursor: pointer;
                }
              }
              &.active {
                max-height: 100vh;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: ${aniTime};
    z-index: 4;
    .writing_constract_list_wrap {
      height: 100vh;
      background: #fff;
      z-index: 50;
      width: 270px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    &.dark .writing_constract_list_wrap {
      background: ${darkBg};
    }
  }
`;
// --SideMenu

// Agenda
export const AgendaContainer = styled.div`
  width: 260px;
  box-sizing: border-box;
  &.dark {
    .agendaInner {
      &::-webkit-scrollbar-thumb {
        background-color: ${darkPoint};
        border: 3px solid ${darkSubColor};
      }
      .constractList li {
        background: ${darkSubColor};
        color: ${ef};
        &.dDay {
          border: 5px solid #717171;
        }
        &.notSchedule {
          button {
            background: ${darkPointColor};
          }
        }
      }
    }
  }
  .agendaInner {
    width: 100%;
    height: calc(100vh - 73px);
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    &::-webkit-scrollbar {
      width: 9px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${mainColor};
      border: 3px solid #ffffff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .lunar {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      span {
        &:first-child {
          display: inline-block;
          margin-right: 10px;
        }
      }
      div.weather {
        display: flex;
        align-items: center;
        svg {
          font-size: 17px;
        }
      }
    }
    .constractList {
      width: 100%;
      li {
        background: ${ef};
        padding: 15px;
        box-sizing: border-box;
        border-radius: 0 ${borderRadius} ${borderRadius} 0;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        &.outside {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -5px;
            width: 5px;
            height: 100%;
            background: ${outsideColor};
            z-index: 2;
          }
        }
        .title {
          display: flex;
          align-items: center;
          gap: 3px;
          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        &.advertisement {
          justify-content: space-between;
          img {
            width: 50px;
            height: 50px;
          }
          span {
            font-weight: 700;
          }
        }
        &.dDay {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          border: 5px solid #d7d7d7;
          div {
            width: 100%;
            &:first-child {
              width: 70%;
              p {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            &:last-child {
              width: 35%;
              text-align: right;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    &.dark {
      .agendaInner {
        background: ${darkSubColor};
        .constractList {
          li {
            padding: 7px 15px;
            background: ${darkBg};
            &.advertisement {
              justify-content: flex-start;
              gap: 10px;
            }
          }
        }
      }
    }
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: ${aniTime};
    .agendaInner {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 50%;
      overflow-y: auto;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
      .constractList {
        li {
          height: 70px;
        }
      }
    }
  }
`;
// --Agenda

// Calendar MobileMenu
export const MobileMenu = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: none;
  &.dark {
    button {
      background: #2c3e50;
      color: ${ef};
      &:first-child {
        background: ${darkPointColor};
      }
    }
  }
  button {
    border: none;
    background: ${ef};
    border-radius: ${borderRadius};
    font-size: 16.5px;
    font-weight: 400;
    line-height: 1.5;
    /* padding: 0.4em 0.65em; */
    padding: 0.4em;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    svg {
      display: block;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    z-index: 1;
  }
`;
// --Calendar MobileMenu

// Event Layer
export const EventLayerContainer = styled.div`
  &.dark {
    .previewContent {
      background: ${darkSubColor};
      border: 1px solid #383838;
      button {
        color: ${ef};
      }
      .btnArea {
        button {
          background: ${darkBg};
          &:hover {
            background: ${darkPointColor};
          }
        }
      }
      ul {
        &::-webkit-scrollbar-thumb {
          background: ${darkPoint};
          border: 2px solid ${darkSubColor};
        }
        li .content {
          &.workers,
          &.place,
          &.memo {
            &::-webkit-scrollbar-thumb {
              background: ${darkPoint};
              border: 2px solid ${darkSubColor};
            }
          }
        }
      }
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  .previewContent {
    width: 430px;
    position: absolute;
    background: #fff;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 1px solid transparent;
    border-radius: ${borderRadius};
    padding: 15px;
    box-sizing: border-box;
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      border: none;
      background: transparent;
      font-size: 20px;
    }
    .selectTypeArea {
      position: absolute;
      top: 15px;
      right: 45px;
      font-size: 20px;
      z-index: 1;
      button {
        background: transparent;
        border: none;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .previewTit {
      margin-bottom: 10px;
      width: calc(100% - 85px);
      h3 {
        position: relative;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        .title {
          font-size: 19px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.C.outside {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -5px;
            width: 5px;
            height: 100%;
            background: ${outsideColor};
            z-index: 2;
          }
        }
      }
      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .btnArea {
      display: flex;
      justify-content: center;
      width: 100%;
      button {
        border: none;
        padding: 10px 15px;
        margin: 0 10px;
        border-radius: ${borderRadius};
        transition: 0.3s;
        &:hover {
          background: ${mainColor};
          color: #fff;
        }
      }
    }
  }
  .imageDelBox {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    .dropdownMenu {
      position: absolute;
      z-index: 10;
      display: flex;
      flex-direction: column;
      button {
        padding: 3px 20px;
        border: none;
        background: #efefef;
        font-size: 12px;
        border: 1px solid #efefef;
        border-radius: 0.25em;
        margin: 0.5px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
// --Event Layer

export const EventContentBox = styled.div`
  overflow: hidden;
  width: 100%;
  .eventContentWrapper {
    display: flex;
    transition: transform 0.5s ease;
    width: 200%;
    height: 100%;
  }
  .eventContent {
    flex: 0 0 50%;
    width: 50%;
    height: 100%;
  }
`;

export const MobileEventContentBox = styled.div`
  overflow: hidden;
  width: 100%;
  .eventContentWrapper {
    display: flex;
    transition: transform 0.5s ease;
    width: 300%;
    height: 100%;
  }
  .eventContent {
    flex: 0 0 33.3333%;
    width: 33.3333%;
    height: 100%;
  }
`;

// HamburgerMenu
export const HamburgerMenuContainer = styled.div`
  position: fixed;
  bottom: 60px;
  right: 30px;
  cursor: pointer;
  z-index: 2;
  &.dark {
    .burgerNav {
      .burgerBox {
        background: ${darkPoint};
        &:has(input:checked) {
          background: ${darkSubColor};
        }
        .burger {
          input:checked ~ div span:nth-of-type(3),
          input:checked ~ div span:nth-of-type(1) {
            background: ${ef};
          }
        }
      }
      .sidebar .list li div {
        background: ${darkPoint};
        p {
          background: ${darkSubColor};
        }
      }
    }
  }
  .bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
  }
  .burgerNav {
    position: relative;
    height: 100%;
    width: 50px;
    height: 50px;
    z-index: 1;
    svg {
      font-size: 30px;
      color: #fff;
      display: block;
    }
    .burgerBox {
      background-color: ${mainColor};
      border-radius: 50%;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      height: 100%;
      &:has(input:checked) {
        background: #f0f0f0;
      }
      .burger {
        position: relative;
        width: 100%;
        height: 100%;
        background: transparent;
        cursor: pointer;
        display: block;
        padding: 15px;
        input {
          display: none;
          &:checked {
            & ~ div {
              width: 25px;
              height: 23px;
              span:nth-of-type(1) {
                transform: rotate(45deg);
                top: 1px;
                left: 4px;
                background: #999;
              }
              span:nth-of-type(2) {
                width: 0%;
                opacity: 0;
              }
              span:nth-of-type(3) {
                transform: rotate(-45deg);
                top: 19px;
                left: 4px;
                background: #999;
              }
            }
          }
        }
        div {
          width: 25px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background: #fff;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
            &:nth-of-type(1) {
              top: 0px;
              transform-origin: left center;
            }
            &:nth-of-type(2) {
              top: 50%;
              transform: translateY(-50%);
              transform-origin: left center;
            }
            &:nth-of-type(3) {
              top: 100%;
              transform-origin: left center;
              transform: translateY(-100%);
            }
          }
        }
      }
    }
    .sidebar {
      .list {
        li {
          position: absolute;
          left: 0;
          pointer-events: none;
          z-index: -99;
          transition: 0.25s ease-in-out;
          width: 100%;
          height: 100%;
          &:nth-child(1) {
            bottom: 85px;
          }
          &:nth-child(2) {
            bottom: 170px;
          }
          opacity: 0;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0074ca;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            position: relative;
            p {
              position: absolute;
              bottom: -15px;
              left: 0;
              width: 100%;
              background: #999;
              text-align: center;
              border-radius: 5px;
              font-size: 10px;
              color: #fff;
            }
          }
        }
        &.open li {
          pointer-events: auto;
          opacity: 1;
          z-index: 1;
          div {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            p {
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    /* bottom: 90px; */
  }
`;

export const DetailEventContainer = styled.div`
  height: 100vh;
  position: relative;
  &.dark {
    background: ${darkBg};
    color: ${ef};
    .btnArea button {
      background: ${darkSubColor};
      color: ${ef};
      &:first-child {
        background: ${darkPoint};
      }
    }
    .selectTypeArea button {
      color: ${ef};
    }
  }
  .list {
    width: 100%;
    height: calc(100vh - 130px);
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 15px;
      .tit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        width: 45px;
        font-weight: 600;
        svg {
          stroke-width: 0.5px;
        }
        span {
          font-size: 12px;
        }
      }
      .content {
        width: calc(100% - 65px);
        .worker {
          display: inline;
        }
        &.memo {
          ul {
            li {
              display: flex;
              flex-wrap: wrap;
              /* align-items: center; */
              gap: 5px;
              margin-bottom: 3px;
              min-height: 10px;
              &:last-child {
                margin-bottom: 0;
              }
              label {
                input[type="checkbox"] {
                  display: none;
                }
                .checkmark {
                  display: block;
                  width: 18px;
                  height: 18px;
                  border: 2px solid #d4d4d4;
                  border-radius: 5px;
                  background-color: transparent;
                  transition: all 0.3s ease;
                  position: relative;
                  &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg) scale(0);
                    opacity: 0;
                    width: 6px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0px 2px 2px 0px;
                    transition: 0.3s;
                  }
                }
                input[type="checkbox"]:checked ~ .checkmark {
                  background: #0074ca;
                  border: 1px solid #d4d4d4;
                  &:after {
                    opacity: 1;
                    transform: translate(-50%, -55%) rotate(45deg) scale(1);
                    border-color: #fff;
                  }
                }
                &:hover .checkmark {
                  background-color: #efefef;
                }
              }
              > span {
                display: inline-block;
                width: calc(100% - 23px);
              }
              .mentions {
                padding-left: 20px;
                display: flex;
                span {
                  width: auto;
                  background: rgb(239, 239, 239);
                  padding: 1px 5px;
                  border-radius: 5px;
                  color: #0074ca;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  .btnArea {
    display: flex;
    justify-content: center;
    gap: 20px;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    button {
      border-radius: ${borderRadius};
      background: ${ef};
      border: none;
      padding: 10px 20px;
      font-size: 14px;
      &:first-child {
        background: ${mainColor};
        color: ${ef};
        cursor: pointer;
      }
    }
  }
  .imageDelBox {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    .dropdownMenu {
      position: absolute;
      display: flex;
      flex-direction: column;
      z-index: 10;
      button {
        padding: 3px 20px;
        border: none;
        background: #efefef;
        font-size: 12px;
        border: 1px solid #efefef;
        border-radius: 0.25em;
        margin: 0.5px;
      }
    }
  }
`;

export const EventInfoDetailList = styled.ul`
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${mainColor};
    border: 2px solid #fff;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  > li {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    gap: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    .tit {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 45px;
      font-weight: 600;
      font-size: 12px;
      svg {
        font-size: 22px;
        stroke-width: 0.5px;
      }
    }
    .content {
      width: calc(100% - 60px);
      font-size: 13px;
      overflow-y: auto;
      word-break: break-all;
      &.place {
        max-height: 36px;
      }
      &.workers {
        max-height: 72px;
        .worker {
          display: inline;
        }
      }
      &.memo {
        max-height: 125px;
        ul {
          li {
            display: flex;
            flex-wrap: wrap;
            /* align-items: center; */
            min-height: 10px;
            gap: 5px;
            margin-bottom: 3px;
            &:last-child {
              margin-bottom: 0;
            }
            label {
              input[type="checkbox"] {
                display: none;
              }
              .checkmark {
                display: block;
                width: 18px;
                height: 18px;
                border: 2px solid #d4d4d4;
                border-radius: 5px;
                background-color: transparent;
                transition: all 0.3s ease;
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%) rotate(45deg) scale(0);
                  opacity: 0;
                  width: 6px;
                  height: 10px;
                  border: solid #fff;
                  border-width: 0px 2px 2px 0px;
                  transition: 0.3s;
                }
              }
              input[type="checkbox"]:checked ~ .checkmark {
                background: #0074ca;
                border: 1px solid #d4d4d4;
                &:after {
                  opacity: 1;
                  transform: translate(-50%, -55%) rotate(45deg) scale(1);
                  border-color: #fff;
                }
              }
              &:hover .checkmark {
                background-color: #efefef;
              }
            }
            > span {
              display: inline-block;
              width: calc(100% - 23px);
            }
            .mentions {
              padding-left: 20px;
              display: flex;
              span {
                width: auto;
                background: rgb(239, 239, 239);
                padding: 1px 5px;
                border-radius: 5px;
                color: #0074ca;
                font-size: 12px;
              }
            }
          }
        }
        &.dDay {
          max-height: 32px;
        }
      }
      &.workers,
      &.place,
      &.memo {
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: ${mainColor};
          border: 2px solid #fff;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }
    }
  }
`;

export const EventGalleryContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px 0 0;
  .dragToUpload {
    height: 30%;
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px dashed #bcdaef;
      border-radius: 0.25em;
      width: 100%;
      height: 100%;
      cursor: pointer;
      svg {
        font-size: 35px;
        margin-bottom: 10px;
        color: #747474;
      }
    }
  }
  .dropImageCollection {
    width: 100%;
    height: 60%;
    .collection {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 25px 15px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${mainColor};
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      .imgBox {
        width: calc((100% - 3 * 15px) / 4);
        height: calc((100% - (2 * 25px)) / 2.5);
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .delBox {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.1);
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            border: none;
            background: transparent;
            color: #fff;
            font-size: 40px;
          }
        }
      }
    }
  }
  .btnArea.gallery {
    justify-content: end;
    button {
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ImageDetailViewerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 9999;
  &.dark {
    background: #000;
    color: #eee;
    button {
      color: #eee;
    }
    .imagesContainer .swiperWrapper .imageContent {
      background: #333;
    }
  }
  .imagesContainer {
    position: relative;
    width: 100%;
    height: 100%;
    .swiperWrapper {
      width: 100%;
      height: 100%;
      > div:not(.imageContent) {
        padding: 30px;
        width: 100%;
      }
      .imageHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        span {
          font-size: 18px;
          font-weight: 700;
        }
        button {
          border: none;
          display: flex;
          background: transparent;
          font-size: 25px;
        }
      }
      .imageContent {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #efefef;
        height: calc(100% - 181px);
        .image {
          width: 100%;
          height: 100%;
          text-align: center;
          position: relative;
          img,
          video {
            object-fit: contain;
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .imageFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info {
          display: flex;
          align-items: center;
          gap: 15px;
          font-size: 13px;
          > div {
            display: flex;
            flex-direction: column;
            gap: 3px;
          }
        }
        .saveButton {
          display: flex;
          border: none;
          background: transparent;
          font-size: 22px;
          align-items: center;
          gap: 5px;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .imagesContainer .swiperWrapper {
      > div:not(.imageContent) {
        padding: 10px;
      }
      .imageHeader span {
        font-size: 16px;
      }
      .imageContent {
        height: calc(100% - 98px);
      }
      .imageFooter {
        .info {
          font-size: 12px;
          gap: 10px;
        }
        .saveButton {
          font-size: 16px;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
`;

export const MobileEventGalleryContainer = styled.div`
  height: calc(100vh - 130px);
  padding: 20px;
  div {
    width: 100%;
  }
  .dropImageCollection {
    height: 60%;
    .collection {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 25px 15px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${mainColor};
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      .imgBox {
        width: calc((100% - 3 * 15px) / 4);
        height: calc((100% - (2 * 25px)) / 2.5);
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .delBox {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.1);
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            border: none;
            background: transparent;
            color: #fff;
            font-size: 40px;
          }
        }
      }
    }
  }
  .dragToUpload {
    height: 30%;
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px dashed #bcdaef;
      border-radius: 0.25em;
      width: 100%;
      height: 100%;
      cursor: pointer;
      svg {
        font-size: 35px;
        margin-bottom: 10px;
        color: #747474;
      }
    }
  }
  .gallery {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: end;
    button {
      padding: 5px 20px;
      font-size: 16px;
      border: none;
      background: #0075ce;
      color: #fff;
      border-radius: ${borderRadius};
    }
  }
  .imageDelBox {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    .dropdownMenu {
      position: absolute;
      z-index: 10;
      button {
        padding: 3px 20px;
        border: none;
        background: #efefef;
        font-size: 12px;
        border: 1px solid #efefef;
        border-radius: 0.25em;
      }
    }
  }
`;

export const SearchEventContainer = styled.div`
  .searchContaier {
    height: calc(100vh - 170px);
    overflow-y: auto;
    &.dark {
      &::-webkit-scrollbar-thumb {
        background-color: ${darkPoint};
        border: 3px solid ${darkBg};
      }
      .searchResultsTable td {
        border: 1px solid ${darkSubColor};
      }
      .searchArea {
        button {
          background: ${darkPoint};
          color: ${ef};
        }
        input {
          border: none;
          box-shadow: none;
          background: transparent;
          border-bottom: 2px solid ${darkSubColor};
          color: #efefef;
          &:focus {
            box-shadow: none;
            border: none !important;
            border-bottom: 2px solid ${darkSubColor} !important;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 9px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${mainColor};
      border: 3px solid #ffffff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .searchArea {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      margin-bottom: 30px;
      div {
        width: 70%;
        position: relative;
        input {
          border: 1px solid #efefef;
          padding: 10px 30px 10px 10px;
          width: 100%;
        }
        span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          cursor: pointer;
          font-size: 20px;
          display: flex;
          align-items: center;
        }
      }
      button {
        border: none;
        background: #efefef;
        padding: 10px;
      }
    }
    .searchResultsTable {
      width: 100%;
      border-collapse: collapse;

      td {
        padding: 7px 0;
        border: 1px solid #f5f5f5;
        font-size: 14px;
      }
      .year,
      .month,
      .day {
        text-align: center;
        font-weight: 600;
      }
      .year {
        width: 65px;
      }
      .month,
      .day {
        width: 45px;
      }
      .day {
        cursor: pointer;
      }
      .event {
        cursor: pointer;
        padding: 7px;
        &.outside {
          background: #0074ca;
          background: ${outsideColor};
        }
      }
    }
    .noData,
    .noSearched {
      text-align: center;
      font-size: 20px;
      margin-top: 100px;
    }
  }
  @media screen and (max-width: 767px) {
    .searchContaier {
      height: calc(100vh - 4em - 20px);
      .searchResultsTable {
        td {
          font-size: 12px;
        }
        .year {
          width: 50px;
        }
        .month,
        .day {
          width: 40px;
        }
      }
    }
  }
`;

import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import Greeting from "./greeting";
import ContextMenu from "../widgets/contextMenu";
import ShortcutItem from "./shortcutItem";

import { QuickLinkContainer } from "../../styled/dashboard";
import { onFullscreen } from "../../../../kiosk/component/function/kiosk";
import { monotiService, specialShortcuts } from "../../function/dashboard";

const QuickLinks = ({
  setting,
  isDragEnabled,
  setActiveIndex,
  deleteQuick,
  editQuick,
  setIsMenuOpen,
  menuRef,
  isMenuOpen,
}) => {
  const navigate = useNavigate();
  const ellipseRef = useRef();

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [positions, setPositions] = useState([]);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!isMobile) {
      calcPositions();
    } else {
      setPositions([]);
    }
  }, [setting.shortcuts, isMobile]);

  const calcPositions = () => {
    const ellipse = ellipseRef.current;
    const ellipseWidth = ellipse.offsetWidth / 2;
    const ellipseHeight = ellipse.offsetHeight / 2;
    const numChildren = setting.shortcuts.length;
    const gapAngle = (2 * Math.PI) / numChildren;

    const newPositions = setting.shortcuts.map((_, index) => {
      const angle = index * gapAngle - Math.PI / 2;

      const x = ellipseWidth + ellipseWidth * Math.cos(angle);
      const y = ellipseHeight + ellipseHeight * Math.sin(angle);
      return { left: x, top: !index ? y + 9 : y };
    });

    setPositions(newPositions);
  };

  const clickQuickLink = (item) => {
    if (!item || !item?.url) {
      toast.error("이동할 수 없는 페이지입니다.");
      return;
    }

    let url = item.url;
    const protocols = ["https", "http", "www"];

    if (url.startsWith("www")) {
      url = `https://${url}`;
    }
    if (protocols.some((prefix) => url.startsWith(prefix))) {
      window.open(url, "_blank");
    } else {
      if (url === "/mypage/kiosk" || url === "/mypage/store") onFullscreen();
      navigate(url);
    }
  };

  // 메뉴 버튼 클릭
  const handleMenuButtonClick = (e, index) => {
    if (isDragEnabled) return;

    const button = e.target.closest("button");

    if (button) {
      const rect = button.getBoundingClientRect();
      setMenuPosition({ x: rect.left, y: rect.top });
    }

    setActiveIndex(index);
    setIsMenuOpen(true);
  };

  const isPresetShortcut = (item, index) => {
    const shortcuts = [...setting.shortcuts];
    const allShortcuts = [...specialShortcuts, ...monotiService];

    const duplicateIndex = shortcuts
      .map((shortcut, i) =>
        shortcut.icon === item.icon && shortcut.url === item.url && shortcut.label === item.label
          ? i
          : -1,
      )
      .filter((i) => i !== -1);

    return (
      allShortcuts.some(
        (shortcut) =>
          shortcut.icon === item.icon && shortcut.url === item.url && shortcut.label === item.label,
      ) && duplicateIndex[0] === index
    );
  };

  return (
    <QuickLinkContainer>
      <div className="ellipse" ref={ellipseRef}>
        {isMenuOpen && (
          <ContextMenu
            setting={setting}
            menuPosition={menuPosition}
            currentMenuItems={[
              { name: "바로가기 수정", action: editQuick },
              { name: "삭제", action: deleteQuick },
            ]}
            menuRef={menuRef}
            setIsMenuOpen={setIsMenuOpen}
          />
        )}
        {!isMobile && <Greeting />}
        {setting.shortcuts.map((item, index) => (
          <ShortcutItem
            key={index}
            position={positions[index]}
            imgSrc={`/img/quickLinks/${item.icon || "not"}.png`}
            label={item.label}
            customContent={!item.icon && <span className="not">{item.label.slice(0, 1)}</span>}
            onClick={() => clickQuickLink(item)}
            showMenuButton={!isPresetShortcut(item, index)}
            onMenuClick={(e) => handleMenuButtonClick(e, index)}
          />
        ))}
      </div>
    </QuickLinkContainer>
  );
};

export default QuickLinks;

import styled from "styled-components";

export const HomepageSettingContainer = styled.div`
  gap: 20px;
  max-width: 1440px;
  margin: 0px auto;
  padding: 0 20px;
  box-sizing: border-box;
  letter-spacing: -1px;
  &.dark {
    ul li {
      background: rgba(0, 0, 0, 0.5);
      &.overlay {
        background: rgba(0, 0, 0, 1);
      }
      &.banner {
        .bannerItem {
          img {
            opacity: 0.5;
          }
          input {
            color: #fff;
          }
          .imgArea {
            background: #000;
          }
          .imgArea + .preview {
            color: #fff;
          }
        }
      }
    }
    .buttons button {
      background: #000;
      color: #fff;
    }
  }
  ul {
    display: flex;
    overflow: hidden;
    gap: 20px;
    li {
      width: calc((100% - 60px) / 4);
      overflow: hidden;
      aspect-ratio: 3 / 2;
      font-size: 20px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      &.overlay {
        min-width: 200px;
        aspect-ratio: 3 / 2;
        background: rgba(255, 255, 255, 0.7);
        font-size: 14px;
      }
      &.dragToUpload {
        label {
          cursor: pointer;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          padding: 20px;
        }
        span {
          display: block;
          text-align: center;
        }
      }
      &.banner {
        position: relative;
        cursor: grab;
        &.sample {
          cursor: default;
        }
        button {
          position: absolute;
          top: 20px;
          right: 20px;
          border: none;
          background: transparent;
          color: #000;
          z-index: 33;
          svg {
            display: block;
          }
        }
        .bannerItem {
          width: 100%;
          height: 100%;
          position: relative;
          .preview {
            position: absolute;
            bottom: 50%;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 700;
            display: inline-block;
            width: 90%;
            text-align: center;
            user-select: none;
          }
          .imgArea {
            background: rgba(255, 255, 255, 0.5);
            width: 100%;
            height: calc(100% - 40px);
          }
          .imgArea + .preview {
            color: rgba(0, 0, 0, 0.5);
          }
          img {
            width: 100%;
            height: calc(100% - 40px);
            opacity: 0.7;
            object-fit: contain;
            user-select: none;
          }
          input {
            border: none;
            border-top: 1px solid #909090;
            background: transparent;
            width: 100%;
            height: 40px;
            padding: 0 20px;
            color: rgba(0, 0, 0, 0.5);
            &:focus {
              border: none !important;
              border-top: 1px solid #909090 !important;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    gap: 20px;
    button {
      background: #e5e5e5;
      border-radius: 5px;
      width: 272px;
      height: 40px;
      font-size: 16px;
      border: none;
      color: #737373;
      &:last-child {
        color: #fff;
        background: rgba(37, 99, 235, 0.7);
      }
    }
  }
  @media screen and (max-width: 1050px) {
    ul {
      flex-wrap: wrap;
      li {
        font-size: 16px;
        width: calc(50% - 10px);
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      flex-direction: column;
      li {
        width: 100%;
        aspect-ratio: 3 / 2;
      }
    }
    .buttons {
      gap: 10px;
      button {
        width: calc(50% - 5px);
      }
    }
  }
`;

export const RegisterFormContainer = styled.div`
  #step1_html {
    .writingList {
      li {
        display: flex;
        align-items: center;
        .isLogoTypeChk {
          display: inline-block;
          margin-bottom: 20px;
        }
        .file_import {
          display: flex;
          align-items: center;
          .preview {
            width: 110px;
            height: 54px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .timeArea {
          display: flex;
          label.btn_check {
            display: flex;
            align-items: center;
          }
          .startArea,
          .endArea {
            margin-right: 20px;
            display: flex;
            align-items: center;
            select {
              width: 40%;
              margin-right: 10px;
              margin-left: 15px;
            }
          }
        }
      }
    }
    .saveAndNext {
      text-align: center;
      button {
        border: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    #step1_html .writingList li .timeArea {
      flex-direction: column;
    }
    #step1_html .writingList li .timeArea .startArea,
    #step1_html .writingList li .timeArea .endArea {
      margin-bottom: 5px;
      margin-right: 0;
    }
    #step1_html .writingList li .timeArea .startArea select,
    #step1_html .writingList li .timeArea .endArea select {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`;

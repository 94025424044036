import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import moment from "moment";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useNavigate } from "react-router";

const News = ({ cmpnyNo, homepageInfo, url }) => {
  const navigate = useNavigate();
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getNotices(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getNotices = async (cmpnyNo) => {
    const url = "/api/noticeList";
    const body = {
      cmpnyNo,
      gbn: "N",
      delYn: "N",
      noticeCl: "공지",
      offset: 0,
      pageNumber: 0,
      pageSize: 3,
      paged: true,
    };
    try {
      const res = await axios.post(url, body);
      let data = res.data.content;
      console.log(data);
      setNotices(data);
    } catch (error) {
      console.log(error);
    }
  };

  return notices && Array.isArray(notices) && notices.length > 0 ? (
    <div className="news">
      <div className="info">News</div>
      <div className="content">
        <div className="more">
          <button onClick={() => navigate(`/shop/${url}/shoppingNotice?page=0`)}>
            <span>
              더 많은 <br />
              소식 보러가기
            </span>
            <div className="icon">
              <GrLinkNext />
            </div>
          </button>
        </div>
        <div className="newsContent">
          {notices.map((item, i) => (
            <>
              <div
                className="box"
                id={i}
                onClick={() => navigate(`/shop/${url}/shoppingNoticeView/${item.noticeNo}`)}>
                <div className="newsInfo">
                  <span className="noticeSj">{item.noticeSj}</span>
                  <span className="noticeCl">{item.noticeCl}</span>
                  <span className="date">{moment(item.createDt).format("YYYY.MM.DD")}</span>
                </div>
              </div>
              {i !== notices.length - 1 && <div className="line"></div>}
            </>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default News;

import styled from "styled-components";

export const DefaultCartContainer = styled.div`
  article.boundary {
    max-width: 1440px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .products {
    font-size: 16px;
    /* border-top: 1px solid #ddd; */
    /* border-bottom: 1px solid #ddd; */
    /* padding: 30px 0; */
    label {
      display: flex;
      gap: 5px;
      align-items: center;
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        padding: 0;
        width: 0;
        height: 0;
      }
      .checkmark {
        position: relative;
        top: 0;
        left: 0;
        display: inline-block;
        height: 25px;
        width: 25px;
        border: 1px solid #d4d4d4;
        /* border-radius: 10px; */
        background-color: transparent;
        transition: all 0.3s ease;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg) scale(0);
          opacity: 0;
          width: 10px;
          height: 15px;
          border: solid #fff;
          border-width: 0px 3px 3px 0px;
          transition: 0.3s;
        }
      }
      input[type="checkbox"]:checked ~ .checkmark {
        background: #03c75a;
        border: 1px solid #03c75a;
        &:after {
          opacity: 1;
          transform: translate(-50%, -65%) rotate(45deg) scale(1);
          border-color: #fff;
        }
      }
      &:hover .checkmark {
        background-color: #efefef;
      }
    }
    li {
      width: 100%;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;
      &.header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* border-bottom: 1px solid #666;
        padding-bottom: 30px;
        margin-bottom: 30px; */
        button {
          padding: 5px 20px;
          border-radius: 10px;
          background: #666;
          color: #fff;
        }
      }
      &:not(.header) {
        font-size: 14px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        .image {
          width: 100px;
          height: 100px;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .productInfo {
          width: calc(100% - 180px);
          > span {
            display: block;
            margin-bottom: 5px;
          }
          .productName {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .brand {
            color: #999;
          }
          .price {
            font-size: 18px;
            font-weight: 700;
          }
          .amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #aaa;
            width: 120px;
            border-radius: 5px;
            span {
              display: inline-block;
              width: 60px;
              text-align: center;
            }
            button {
              width: 30px;
              height: 25px;
              border: none;
              background: transparent;
            }
          }
        }
        > button {
          font-size: 14px;
          background: transparent;
          border-bottom: 1px solid #aaa;
          color: #aaa;
          position: absolute;
          top: 30px;
          right: 0;
        }
      }
    }
  }
  .total {
    padding: 30px 0;
    dl {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 15px;
      dt {
        font-size: 15px;
      }
      dd {
        font-size: 18px;
      }
    }
    button {
      display: block;
      margin: 30px auto 0;
      padding: 15px 40px;
      color: #fff;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      background: #03c75a;
      border-radius: 5px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1050px) {
    .products {
      font-size: 14px;
      label .checkmark {
        width: 20px;
        height: 20px;
      }
      li {
        padding: 20px 0;
      }
      li:not(.header) > button {
        top: 20px;
      }
      li:not(.header) .productInfo .price {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    article.boundary {
      margin-bottom: 100px;
    }
    .products {
      border: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
      border-radius: 10px;
      label {
        .checkmark {
          width: 15px;
          height: 15px;
        }
      }
      li {
        padding: 10px;
      }
      li:not(.header) {
        align-items: flex-start;
        padding-top: 36px;
        label {
          position: absolute;
          top: 10px;
          left: 10px;
        }
        font-size: 12px;
        > button {
          font-size: 12px;
          top: 10px;
          right: 10px;
          border-bottom: none;
        }
        .productInfo {
          > span {
            margin-bottom: 3px;
          }
          .amount button {
            height: 22px;
          }
        }
      }
      li:not(.header) .image {
        width: 60px;
        height: 60px;
      }
      li:not(.header) .productInfo {
        width: calc(100% - 70px);
      }
      li:last-child {
        border-bottom: none;
      }
    }
  }
  #contCart #secTable {
    border-top: none;
  }
  .tb_footer {
    font-size: 14px;
    padding: 20px 0;
  }
`;

export const DefaultContaienr = styled.div`
  article.boundary {
    max-width: 1440px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 767px) {
    article.boundary {
      margin-bottom: 100px;
      .totalSumm {
        width: 100%;
      }
    }
    #contNoticeList tr {
      padding: 10px 0;
    }
  }
`;

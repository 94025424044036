import MypageBanner from "../mypageBanner";
import PromotionForm from "./component/promotion/promotionForm";
import HomePageSettingMenu from "./component/common/homepageSettingMenu";
import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";
import { useSelector } from "react-redux";

export default function Promotion() {
  const setting = useSelector((state) => state?.user?.setting);
  return (
    <Container className={setting?.mode}>
      <PageInfo label="홍보 설정" />
      <div className="content">
        <HomePageSettingMenu />
        <PromotionForm />
      </div>
    </Container>
  );
}

import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setRegisterIndex } from "../../../../../store/compoIndex";
import { HomePageSettingMenuContainer } from "../styled/common";

const HomePageSettingMenu = () => {
  const { pathname } = useLocation();
  const navigater = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:1050px)" });
  const setting = useSelector((state) => state?.user?.setting);

  const onGo = (link) => {
    navigater(link);
    dispatch(setRegisterIndex(0));
  };

  return (
    <HomePageSettingMenuContainer className={setting?.mode}>
      <ul>
        {settingMenu.map((item, i) => (
          <li
            onClick={() => onGo(item.link)}
            key={i}
            className={!pathname.indexOf(item.link) ? "active" : ""}>
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    </HomePageSettingMenuContainer>
  );
};

export default HomePageSettingMenu;

import Pagination from "react-js-pagination";
import BoardList from "../../common/boardList";
import NoticeList from "./component/common/noticeList";
import PageInfo from "./component/common/pageInfo";
import SearchForm from "./component/common/searchForm";

import { FaqMenuContainer, PageContainer } from "./component/styled/notice";

import { useState } from "react";

const FaqPage = () => {
  const [active, setActive] = useState("꿀팁");
  return (
    <>
      <PageContainer>
        <PageInfo
          title="자주하는 질문"
          content={"모노티 이용에 관련된 자주하는 질문을 여기서 확인하세요!"}
        />
        <SearchForm />
        <FaqMenuContainer>
          {faqMenu.map((item) => (
            <li
              className={item.label === active ? "active" : ""}
              onClick={() => setActive(item.label)}>
              {item.label}
            </li>
          ))}
        </FaqMenuContainer>
        <NoticeList
          label="자주하는 질문"
          //  data={temp}
        />
        {/* <Pagination
        activePage={pages.page}
        itemsCountPerPage={pages.limit}
        // totalItemsCount={total}
        totalItemsCount={10}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        // onChange={(e) => changePages({ target: "page", value: e })}
      /> */}
      </PageContainer>
    </>
  );
};

export default FaqPage;

import React from "react";
import { HiMiniEllipsisVertical } from "react-icons/hi2";
import { useMediaQuery } from "react-responsive";

const ShortcutItem = ({
  position,
  imgSrc,
  label,
  customContent,
  onClick,
  showMenuButton = false,
  onMenuClick,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  return (
    <div
      className="shortcutItem"
      style={{
        left: isMobile ? 0 : `${position?.left}px`,
        top: isMobile ? 0 : `${position?.top}px`,
      }}>
      <div className="shortcutItemContent">
        <button onClick={onClick} className="shortcut">
          <img src={imgSrc} alt={label} />
          {customContent}
          <span className="label">{label}</span>
        </button>
        {showMenuButton && (
          <button className="menuButton" onClick={onMenuClick}>
            <HiMiniEllipsisVertical />
          </button>
        )}
      </div>
    </div>
  );
};

export default ShortcutItem;

import styled from "styled-components";

export const SmContainer = styled.main`
  font-family: "Pretendard";
  .info {
    font-size: 48px;
    font-weight: 700;
  }
  .banner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .banners {
      height: 100%;
      .bannerSlide {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        filter: brightness(50%);
        object-fit: cover;
      }
      .text {
        font-size: 48px;
        font-weight: 700;
        color: #fff;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 767px) {
    height: calc(100vh - 70px);
    .banner .banners {
      .bannerSlide {
        display: flex;
        flex-direction: column;
      }
      img {
        height: 50%;
      }
      .text {
        position: relative;
        transform: none;
        color: #1d1d1d;
        font-size: 28px;
        top: 0;
        left: 0;
        margin: auto;
      }
    }
  }
`;

export const SmCmpnyContainer = styled.div`
  padding-top: 100px;
  min-height: calc(100vh - 271px);
  main#contCompany {
    margin: 0;
    .subTop_bnr_01,
    h2,
    #secBrdcrmb {
      display: none;
    }
    h3,
    .company_content,
    .address_btn_wrapper,
    .google_map_sub_wrapper {
      width: 100%;
      font-size: 20px;
    }
    h3 {
      text-align: center;
      font-size: 36px;
    }
    #secMap {
      h3 {
        &::before {
          display: none;
        }
        span {
          padding: 0;
          border: none;
        }
      }
      .addr {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
        button {
          margin: 0;
          background: rgba(0, 0, 0, 0.8);
          color: #fff;
          font-weight: 700;
          padding: 5px;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    main#contCompany {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    main#contCompany {
      h3 {
        font-size: 32px;
      }
      .company_content,
      .address_btn_wrapper,
      .google_map_sub_wrapper {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contCompany {
      margin-bottom: 100px;
      h3 {
        font-size: 26px;
      }
    }
  }
`;

export const SmProductsContainer = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  img {
    user-select: none;
  }
  main#contProductsList {
    margin-top: 0;
    font-family: "Pretendard";
    .subTop_bnr_01 {
      display: none;
    }
    article.boundary {
      width: 1400px;
    }
    #secBrdcrmb {
      display: none;
    }
    .font_color_gray_light {
      padding-bottom: 10px;
      font-size: 18px;
      .fa.fa-bars {
        margin-right: 5px;
      }
      strong {
        color: #000;
      }
    }
    #secList {
      .col_3 {
        position: relative;
        height: 374px;
        border-radius: 15px;
        padding: 15px;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          background: #f6f6f6;
          a:hover {
            color: #000;
          }
        }
        .img {
          aspect-ratio: auto;
          margin-bottom: 30px;
          border-radius: 15px;
          overflow: hidden;
          background: #fff;
          img {
            height: 225px;
            object-fit: cover;
          }
        }
        p.font_16 {
          font-size: 14px;
        }
        p.font_14 {
          font-size: 20px;
        }
      }
    }
    ul.pagination {
      li {
        border: none;
        a {
          color: #bfbfbf;
        }
      }
      li.active,
      li:hover {
        background: transparent;
        a {
          color: #333;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 50px 20px;
      margin: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
    main#contProductsList #secListTop .count,
    main#contProductsList .categorys ul li {
      font-size: 16px;
    }
    main#contProductsList #secList {
      gap: 20px;
      .col_3 {
        width: calc(50% - 10px);
        .img + div p:first-child {
          font-size: 20px;
        }
        .img + div p:last-child {
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsList {
      margin-bottom: 100px;
      #secList {
        gap: 50px;
        .col_3 {
          width: 100%;
          .img + div p:first-child {
            font-size: 20px;
          }
          .img + div p:last-child {
            font-size: 18px;
          }
        }
      }
    }
  }
`;

export const SmPortfolioContainer = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  img {
    user-select: none;
  }
  main#contProductsList {
    margin-top: 0;
    font-family: "Pretendard";
    .subTop_bnr_01 {
      display: none;
    }
    article.boundary {
      width: 1400px;
    }
    #secBrdcrmb {
      display: none;
    }
    #secListTop .list_info {
      padding-bottom: 10px;
      font-size: 18px;
      .fa.fa-bars {
        margin-right: 5px;
      }
      strong {
        color: #000;
      }
    }
    #secList {
      .col_3 {
        position: relative;
        height: 374px;
        border-radius: 15px;
        padding: 15px;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          background: #f6f6f6;
          a:hover {
            color: #000;
          }
        }
        .img {
          aspect-ratio: auto;
          margin-bottom: 30px;
          border-radius: 15px;
          overflow: hidden;
          background: #fff;
          img {
            height: 225px;
            object-fit: cover;
          }
        }
        p.font_16 {
          font-size: 14px;
        }
        p.font_14 {
          font-size: 20px;
        }
      }
    }
    ul.pagination {
      li {
        border: none;
        a {
          color: #bfbfbf;
        }
      }
      li.active,
      li:hover {
        background: transparent;
        a {
          color: #333;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 50px 20px;
      margin: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
    main#contProductsList #secListTop .count,
    main#contProductsList .categorys ul li {
      font-size: 16px;
    }
    main#contProductsList #secList {
      gap: 20px;
      .col_3 {
        width: calc(50% - 10px);
        .img + div p:first-child {
          font-size: 20px;
        }
        .img + div p:last-child {
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsList {
      margin-bottom: 100px;
      #secList {
        gap: 50px;
        .col_3 {
          width: 100%;
          .img + div p:first-child {
            font-size: 20px;
          }
          .img + div p:last-child {
            font-size: 18px;
          }
        }
      }
    }
  }
`;

export const SmNoticeContainer = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  #secBrdcrmb {
    display: none;
  }
  main#contNoticeList {
    margin-top: 0;
    font-family: "Pretendard";
    a:hover {
      color: #333;
    }
    .subTop_bnr {
      display: none;
    }
    #secListTop {
      margin: 20px 0;
      .count {
        font-size: 18px;
        i.fa-bars {
          margin-right: 5px;
        }
        strong {
          color: rgba(192, 165, 165, 1);
        }
      }
    }
    .notices {
      li {
        width: 100%;
        font-size: 24px;
        border-bottom: 1px solid #989898;
        padding: 30px 10px;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        gap: 30px 10px;
        .noticeCl {
          order: 2;
          font-weight: 700;
          width: 120px;
          color: #c4c4c4;
          text-align: right;
        }
        .noticeSj {
          order: 1;
          width: calc(100% - 130px);
          overflow: hidden;
          text-overflow: ellipsis;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .date {
          order: 3;
          color: #c4c4c4;
          width: 130px;
          font-weight: 300;
        }
      }
    }
    ul.pagination {
      li {
        border: none;
        a {
          color: #bfbfbf;
        }
      }
      li.active,
      li:hover {
        background: transparent;
        a {
          color: #333;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 50px 20px;
      margin: 0;
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
    main#contNoticeList .notices li {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
    main#contNoticeList .notices li {
      font-size: 14px;
      gap: 10px 0;
      padding: 10px 0;
      .noticeCl {
        width: 60px;
      }
      .noticeSj {
        width: calc(100% - 70px);
      }
      .date {
        width: 80px;
      }
    }
  }
`;

export const SmSearchContaienr = styled.div``;

export const SmCartContaienr = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  img {
    user-select: none;
  }
  main#contCart {
    margin-top: 0;
    article.boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
      .shop_port_detail_content,
      .shop_port_title {
        width: 100%;
      }
    }
    #secBrdcrmb {
      display: none;
    }
    .subTop_bnr_01 {
      display: none;
    }
    .products {
      font-size: 16px;
      label {
        display: flex;
        gap: 5px;
        align-items: center;
        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          padding: 0;
          width: 0;
          height: 0;
        }
        .checkmark {
          position: relative;
          top: 0;
          left: 0;
          display: inline-block;
          height: 20px;
          width: 20px;
          border: 2px solid rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          background-color: transparent;
          transition: all 0.3s ease;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg) scale(0);
            opacity: 0;
            width: 10px;
            height: 15px;
            border: solid #fff;
            border-width: 0px 3px 3px 0px;
            transition: 0.3s;
          }
        }
        input[type="checkbox"]:checked ~ .checkmark {
          background: #000;
          border: 1px solid transparent;
          &:after {
            opacity: 1;
            transform: translate(-50%, -65%) rotate(45deg) scale(1);
          }
        }
        &:hover .checkmark {
          background-color: #efefef;
        }
      }
      li {
        width: 100%;
        padding: 30px 0;
        border-bottom: 1px solid #000;
        &.header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
            padding: 5px 20px;
            border-radius: 10px;
            background: #000;
            font-size: 14px;
            color: #fff;
          }
        }
        &:not(.header) {
          position: relative;
          display: flex;
          align-items: flex-start;
          gap: 10px;
          .image {
            width: 100px;
            height: 100px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .productInfo {
            width: calc(100% - 180px);
            > span {
              display: block;
              margin-bottom: 5px;
            }
            .productName {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .brand {
              display: none;
            }
            .price {
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 10px;
            }
            .amount {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #333;
              width: 120px;
              span {
                display: inline-block;
                width: 60px;
                text-align: center;
                font-weight: 700;
              }
              button {
                width: 30px;
                height: 25px;
                border: none;
                background: transparent;
              }
            }
          }
          > button {
            font-size: 14px;
            background: transparent;
            border-bottom: 1px solid #000;
          }
        }
      }
    }
    #secTable .tb_footer {
      justify-content: space-evenly;
      .btn_order {
        background: #000;
      }
      .price_detail {
        border-right: none;
      }
      .price_sum dd,
      .font_color_red {
        color: #000;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    main#contCart {
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
      #secTable .tb_footer {
        justify-content: center;
        gap: 20px 40px;
        .price_detail {
          border-bottom: none;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    article.boundary {
      margin-bottom: 100px;
    }
    main#contCart .products li {
      padding: 15px 0;
      font-size: 14px;
      label .checkmark {
        width: 20px;
        height: 20px;
        ::after {
          width: 8px;
          height: 12px;
          border-width: 0 2px 2px 0;
        }
      }
      &:not(.header) {
        .image {
          width: 70px;
          height: 70px;
        }
        .productInfo {
          width: calc(100% - 150px);
          .price {
            font-size: 14px;
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
    main#contCart #secTable .tb_footer {
      .total {
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
      }
      font-size: 14px;
      justify-content: space-between;
    }
  }
`;

import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import ProductFilter from "./common/productFilter";
import Modal from "../../../../../common/modal";
import EditProductModal from "./productForm/modal/editProductModal";
import DetailProduct from "./productForm/modal/detailProduct";
import ImageDetailViewer from "../../../../../common/imageDetailViewer";
import ProductTable from "./common/productTable";

import { ProductFormContainer } from "../styled/product";
import { IoCheckmark } from "react-icons/io5";
import ProductList from "./common/prodoctList";

export default function ProductForm({
  products,
  categories,
  categoriesObj,
  getSyncProduct,
  filtered,
  setFiltered,
}) {
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [pages, setPages] = useState({ limit: 10, page: 1 });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modalType, setModalType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isDetail, setIsDetail] = useState(false);

  useEffect(() => {
    setSelectedProducts([]);
  }, [products, pages]);

  const changePages = ({ target, value }) => {
    setPages((data) => {
      const updatedPages = { ...data, [target]: value };
      if (target === "limit") updatedPages.page = 1;
      return updatedPages;
    });
  };

  const handleCheck = (prd) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.some((item) => item.id === prd.id)
        ? prevSelected.filter((item) => item.id !== prd.id)
        : [...prevSelected, prd],
    );
  };

  const handleAllCheck = (isChecked) => {
    const startIndex = (pages.page - 1) * pages.limit;
    const currentProducts = products.slice(startIndex, startIndex + pages.limit);

    if (isChecked) {
      setSelectedProducts([...currentProducts]);
    } else {
      setSelectedProducts([]);
    }
  };

  const deleteProduct = async () => {
    if (!selectedProducts || !selectedProducts.length) {
      toast.error("선택된 상품이 없습니다.");
      return;
    }

    const url = "/erp/vendorProducts/delete";
    const body = {
      ids: selectedProducts.map((item) => item.id),
      cmpnyNo: user.cmpnyNo,
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      toast.success("선택하신 상품이 삭제되었습니다.");
      await getSyncProduct();
      setSelectedProducts([]);
      setPages({ limit: 10, page: 1 });
    }
  };

  const handleModalState = (type, item) => {
    if (type) setModalType(type);
    if (item) setSelectedProduct(item);

    setOpenModal(!openModal);
  };

  const closeModal = async (flag = false) => {
    if (flag) await getSyncProduct();
    setOpenModal(false);
    setSelectedProduct(null);
  };

  // 모달
  const getModalContent = () => {
    switch (modalType) {
      case "edit":
        return (
          <EditProductModal
            selectedProduct={selectedProduct}
            setIsDetail={setIsDetail}
            categories={categories}
            categoriesObj={categoriesObj}
            closeModal={closeModal}
          />
        );
      case "detail":
        return (
          <DetailProduct
            selectedProduct={selectedProduct}
            closeModal={closeModal}
            categoriesObj={categoriesObj}
            setIsDetail={setIsDetail}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ProductFormContainer className={setting?.mode}>
      {openModal && getModalContent()}
      <div className="products">
        <span className="interlocked">
          <i>
            <IoCheckmark />
          </i>
          내가 연동한 제품 총 {products.length}개
        </span>
        <ProductFilter
          filtered={filtered}
          categories={categories}
          categoriesObj={categoriesObj}
          setFiltered={setFiltered}
          changePages={changePages}
          pages={pages}
          getProduct={getSyncProduct}
          deleteProduct={deleteProduct}
          isProductSync={false}
        />
        {isMobile ? (
          <ProductList
            products={products}
            total={products.length}
            pages={pages}
            changePages={changePages}
            selectedProducts={selectedProducts}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
            categoriesObj={categoriesObj}
            categories={categories}
            handleModalState={handleModalState}
          />
        ) : (
          <ProductTable
            products={products}
            total={products.length}
            pages={pages}
            changePages={changePages}
            selectedProducts={selectedProducts}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
            categoriesObj={categoriesObj}
            categories={categories}
            handleModalState={handleModalState}
          />
        )}
        {isDetail && <ImageDetailViewer images={selectedProduct.media} setIsDetail={setIsDetail} />}
      </div>
    </ProductFormContainer>
  );
}

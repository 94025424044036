import React from "react";
import { formatCommas, orderType } from "../../function/store";

const OrderItem = ({ item, onClick, extraContent, selected, checkedOrders = [] }) => {
  const isCancelled = () => {
    return item?.status === "CANCELLED";
  };

  return (
    <li
      onClick={() => onClick(item)}
      className={selected?.id === item?.id ? "active" : ""}
      style={{
        background: isCancelled() ? (selected?.id === item?.id ? "#b74a4a" : "#FF6F6F") : "",
        color: isCancelled() ? "#fff" : "",
      }}>
      {!extraContent && !checkedOrders.includes(item.id) && <div className="dot"></div>}
      <img src={`/img/store/${item.orderFlag.toLowerCase()}.png`} alt={item.orderFlag} />
      <div className={extraContent ? "inProgressOrderItem" : "orderInfo"}>
        <span className="tit">{`${orderType[item.orderFlag]} ${item.id}`}</span>
        <span className="con">{formatCommas(item?.price)}원</span>
      </div>
      {extraContent}
    </li>
  );
};

export default OrderItem;

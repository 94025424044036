import React from "react";
import PageInfo from "./component/common/pageInfo";
import { PageContainer, QnaFormContainer } from "./component/styled/notice";

const QnaPage = () => {
  const inputs = [
    { label: "회사명", placeholder: "회사명을 입력하세요.", half: true, type: "text" },
    { label: "담당자명", placeholder: "담당자명을 입력하세요.", half: true, type: "text" },
    {
      label: "이메일",
      placeholder: "'@' 를 포함하여 입력해주세요.",
      half: false,
      type: "email",
    },
    {
      label: "연락처",
      placeholder: "'-' 없이 숫자만 입력해주세요.",
      half: false,
      type: "text",
    },
    { label: "담당자 직급/직책", placeholder: "예) 사원, 대리", half: true, type: "text" },
    {
      label: "업종",
      placeholder: "예) 식료품 제조, 연구개발, 배전 및 판매업",
      half: true,
      type: "text",
    },
    { label: "소재지", placeholder: "예) 대전광역시 서구", half: true, type: "text" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("준비 중입니다.");
  };

  const InputField = ({ label, placeholder, half, type }) => (
    <li className={half ? "half" : ""}>
      <div className="tit">{label}</div>
      <div className="cont">
        <input type={type} placeholder={placeholder} />
      </div>
    </li>
  );

  return (
    <PageContainer>
      <PageInfo
        title="이용문의"
        content={"서비스 사용 중 궁금한 점이나 문제를 해결해드립니다.\n모노티를 활용해보세요."}
      />
      <QnaFormContainer onSubmit={handleSubmit}>
        <h5 className="sound_only">이용문의 정보 입력</h5>
        <ul className="list">
          {inputs.map((input, index) => (
            <InputField key={index} {...input} />
          ))}
          <li className="half">
            <div className="tit">사용유형</div>
            <div className="cont">
              <select>
                <option value="A">전체</option>
                <option value="T">제목</option>
                <option value="C">내용</option>
              </select>
            </div>
          </li>
          <li>
            <div className="tit">문의내용</div>
            <div className="cont">
              <textarea rows={4} />
            </div>
          </li>
        </ul>
        <div>
          <button type="submit">요청하기</button>
        </div>
      </QnaFormContainer>
    </PageContainer>
  );
};

export default QnaPage;

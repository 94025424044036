import Pagination from "react-js-pagination";
// import BoardList from "../../common/boardList";
import NoticeList from "./component/common/noticeList";
import PageInfo from "./component/common/pageInfo";
import SearchForm from "./component/common/searchForm";

import { PageContainer } from "./component/styled/notice";

import { useState } from "react";

export default function Notice() {
  // const BoardListProps = {
  //   button: false,
  //   search: true,
  //   title: "공지사항",
  // };

  const temp = Array.from({ length: 10 });
  const [pages, setPages] = useState({ page: 1, limit: 10 });

  return (
    <PageContainer>
      <PageInfo title="공지사항" content={"모노티의 다양한 소식을 알려드립니다."} />
      <SearchForm />
      <NoticeList
        label="공지사항"
        //  data={temp}
      />
      {/* <Pagination
        activePage={pages.page}
        itemsCountPerPage={pages.limit}
        // totalItemsCount={total}
        totalItemsCount={10}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        // onChange={(e) => changePages({ target: "page", value: e })}
      /> */}
    </PageContainer>
  );
}

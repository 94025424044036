import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setRegisterIndex } from "../../../../../store/compoIndex";
import { RegisterMenuContainer } from "../styled/register";
import { useSelector } from "react-redux";

export default function RegisterMenu({ index }) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <RegisterMenuContainer className={setting?.mode}>
      <ul>
        {registerMenu.map((item, i) => (
          <li
            style={{ padding: "0" }}
            className={index === i ? "active" : ""}
            key={i}
            onClick={() => dispatch(setRegisterIndex(i))}>
            <Link to={"/mypage/register"}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </RegisterMenuContainer>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useOutletContext } from "react-router";

const SmBanner = () => {
  const [banner, setBanner] = useState([""]);

  return (
    <div className="banner">
      <div className="bg"></div>
      <Swiper
        className="banners"
        modules={[Navigation]}
        slidesPerView={1}
        loop={true}
        speed={2000}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}>
        {banner.map((item, i) => (
          <SwiperSlide key={i}>
            <div className="swiper-wrapper">
              <div className="bannerSlide" id={i}>
                <img src={`/img/shopTheme/sm/banner.png`} />
                <div className="text">
                  일정을 손쉽게 관리하고
                  <br />
                  모임과 공유해 보세요.
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SmBanner;

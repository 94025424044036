import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { Container } from "./component/styled/common";
import { ReviewContainer } from "./component/styled/review";

import SideMenu from "./component/common/sideMenu";
import StoreReviews from "./component/review/storeReviews";

const Review = () => {
  const user = useSelector((state) => state?.user?.data[0]);

  const [active, setActive] = useState("storeReviews");
  const tabs = {
    storeReviews: "리뷰관리",
  };

  const renderComponent = () => {
    switch (active) {
      case "storeReviews":
        return <StoreReviews active={active} tabs={tabs} />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <ReviewContainer>
        <div className="container">
          <SideMenu active={active} setActive={setActive} tabs={tabs} type="review" />
          <div className="content review">
            <div className="viewer">{renderComponent()}</div>
          </div>
        </div>
      </ReviewContainer>
    </Container>
  );
};

export default Review;

import styled from "styled-components";

export const TabMenu = styled.div`
  &.dark {
    ul {
      color: #eee;
      background: rgba(0, 0, 0, 0.5);
      li.active {
        background: rgba(37, 99, 235, 0.7);
      }
    }
  }
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto 50px;
  ul {
    max-width: 1040px;
    margin: auto;
    box-sizing: border-box;
    letter-spacing: -1px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    border-radius: 5px;
    li {
      transition: 0.3s;
      width: calc(100% / 3);
      display: inline-block;
      text-align: center;
      height: 65px;
      line-height: 65px;
      border-radius: 5px;
      cursor: pointer;
      &.active {
        background: #fff;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      font-size: 14px;
      padding: 0;
      display: flex;
      gap: 10px;
      width: 100%;
      background: transparent;
      li {
        height: 35px;
        line-height: 35px;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
`;

export const ChangeUserInfoFormContainer = styled.div`
  max-width: 1080px;
  padding: 0 20px;
  margin: 0 auto 50px;
  box-sizing: border-box;
  letter-spacing: -1px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  &.dark {
    color: #eee;
    ul li input[type="text"],
    ul li input[type="password"] {
      background: rgba(0, 0, 0, 0.5);
      color: #eee;
    }
    ul li.email button,
    ul li.moblphonNo button {
      background: #000;
      color: #eee;
    }
  }
  > span {
    display: inline-block;
    margin-bottom: 20px;
  }
  input[type="file"] {
    display: none;
  }
  ul {
    li {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      &.imgPath {
        .writing_cont {
          position: relative;
          width: 150px;
          height: 150px;
          border: 1px solid #7d7371;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.7;
          }
          .deleteBtn {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            background: transparent;
            svg {
              display: block;
            }
          }
          .notImage {
            height: 100%;
            font-size: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              display: block;
            }
          }
        }
      }
      &.email,
      &.moblphonNo {
        .writing_cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        input[type="text"] {
          width: calc(100% - 180px);
        }
        button {
          border-radius: 30px;
          background: #fff;
          border: none;
          height: 50px;
          width: 160px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .writing_tit {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 20px;
      }
      input[type="text"],
      input[type="password"] {
        width: 100%;
        border-radius: 10px;
        padding: 0 20px;
        height: 50px;
        border: none;
        background: rgba(255, 255, 255, 0.5);
        &:focus {
          border: none !important;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    > span {
      margin-bottom: 15px;
      font-size: 14px;
    }
    ul li {
      margin-bottom: 15px;
      &.email,
      &.moblphonNo {
        input[type="text"] {
          width: calc(100% - 100px);
        }
        button {
          width: 90px;
          height: 30px;
        }
      }
      .writing_tit {
        font-size: 16px;
        margin-bottom: 5px;
      }
      input[type="text"],
      input[type="password"] {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
      }
    }
  }
`;

export const MembershipWithdrawalContainer = styled.div`
  max-width: 1080px;
  padding: 0 20px;
  margin: 0 auto 50px;
  box-sizing: border-box;
  letter-spacing: -1px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  &.dark {
    color: #eee;
  }
  > span {
    display: inline-block;
    margin-bottom: 20px;
  }
  .viewer {
    span {
      display: block;
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    > span {
      margin-bottom: 15px;
      font-size: 14px;
    }
    font-size: 16px;
  }
`;

import styled from "styled-components";

export const ThemeSettingFormContainer = styled.ul`
  &.dark {
    .themeInfo input[type="radio"] {
      background: rgb(0, 0, 0);
      border: 2px solid rgb(85, 85, 85);
      &:checked {
        background: rgba(37, 99, 235, 0.7);
        border: 0.2em solid rgb(0, 0, 0);
        outline: none;
      }
    }
  }
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0px auto 100px;
  max-width: 1440px;
  padding: 0px 20px;
  text-align: center;
  letter-spacing: -1px;
  li {
    .themeInfo {
      margin-bottom: 20px;
      text-align: center;
      label {
        display: inline-block;
        margin-right: 0;
        width: 100%;
        cursor: pointer;
      }
      input[type="radio"] {
        background: #fff;
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px 0px;
        }
        &:checked {
          background: rgba(37, 99, 235, 0.7);
          border: 0.2em solid #fff;
        }
      }
      span {
        margin-left: 5px;
        display: inline-block;
      }
    }
    .preview {
      width: 100%;
      height: 250px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        object-fit: cover;
        user-select: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 30px;
    li {
      width: 100%;
      .preview {
        height: 350px;
      }
    }
  }
`;

export const PreviewModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .content {
    width: 1000px;
    height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      max-width: 100%;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
  }
  @media screen and (max-width: 1050px) {
    .content {
      width: 90vw;
    }
  }
`;

import React from "react";
import { InstallContainer } from "../styled/dashboard";
import { IoCloseOutline } from "react-icons/io5";

const Install = ({ installApp, mode = "white", setDeferredPrompt = null }) => {
  const handleHideInstallPrompt = () => {
    localStorage.setItem("hideInstallPrompt", "true");
    setDeferredPrompt(false);
  };

  return (
    <InstallContainer className={mode}>
      <div className="content">
        모노티 웹을 바탕화면에 추가하시겠습니까?
        <button onClick={installApp}>추가하기</button>
      </div>
      <div className="close">
        <span onClick={handleHideInstallPrompt}>다시보지않기</span>
        <button onClick={() => setDeferredPrompt(false)}>
          <IoCloseOutline />
        </button>
      </div>
    </InstallContainer>
  );
};

export default Install;

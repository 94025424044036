import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import Widgets from "./component/dashboardPage/widgets";
import DashboardInfo from "./component/dashboardPage/dashboardInfo";
import Install from "./component/dashboardPage/install";
import Loading from "../../../common/loading";

import { Container } from "../styled/mypage";

import { checkDefaultQuick } from "./component/function/dashboard";

import { handleUpdateSetting } from "../../../store/user";
import { hideLoading, showLoading } from "../../../store/loading";

export default function Mypage() {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  const [deferredPrompt, setDeferredPrompt] = useState(true);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [setting, setSetting] = useState({
    shortcuts: [],
    widgets: Array.from({ length: 8 }, (_, i) => ({ id: i.toString(), isActive: true })),
    mode: "white",
  });

  const defaultSetting = {
    shortcuts: [],
    widgets: Array.from({ length: 8 }, (_, i) => ({ id: i.toString(), isActive: true })),
    mode: "white",
  };

  useEffect(() => {
    if (user) {
      handleCreateSession();
      getMberSetting();
    }

    const isInstallPromptHidden = localStorage.getItem("hideInstallPrompt") === "true";

    if (!isInstallPromptHidden) {
      window.addEventListener("beforeinstallprompt", handleInstallPrompt);
    } else {
      setDeferredPrompt(null);
    }

    return () => {
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
    };
  }, []);

  const handleInstallPrompt = (e) => {
    e.preventDefault();
    setDeferredPrompt(e);
  };

  // 새로운 세션 생성
  const handleCreateSession = async () => {
    const sessionData = JSON.parse(sessionStorage.getItem("monotiSessionData"));
    const sessionId = sessionData?.id;

    if (sessionId) {
      await handleSessionEnd(sessionId);
    }

    try {
      const data = {
        userId: user.mberNo,
        os: "web",
        screenId: "W1",
      };
      const response = await axios.post("/erp/session/createSession", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = response.data;
      if (res?.createdAt) {
        sessionStorage.setItem("monotiSessionData", JSON.stringify({ ...res }));
      }
    } catch (error) {
      console.error("세션 생성 오류", error);
    }
  };

  // 세션 종료
  const handleSessionEnd = async (sessionId) => {
    const url = "/erp/session/endSession";
    try {
      const response = await axios.post(
        url,
        { sessionId },
        {
          headers: { "Content-Type": "application/json" },
          timeout: 2000,
        },
      );

      const { success } = response.data;
      if (success) {
        sessionStorage.removeItem("monotiSessionData");
      }
    } catch (error) {
      console.error("세션 종료 요청 실패", error);
    }
  };

  // 앱 설치
  const installApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
      });
    }
  };

  const getMberSetting = async () => {
    dispatch(showLoading());

    const url = "/api/mber-setting";
    const body = {
      mberNo: user?.mberNo,
    };

    try {
      const res = await axios.post(url, body);

      if (res?.data) {
        const updatedShortcuts = checkDefaultQuick([...res.data.shortcuts], company);
        const data = {
          ...res.data,
          shortcuts: updatedShortcuts,
        };
        setSetting(data);
        dispatch(handleUpdateSetting(data));
      } else {
        setSetting({ ...defaultSetting, shortcuts: checkDefaultQuick([], company) });
      }
    } catch (error) {
      console.error("Error fetching member settings", error);
      setSetting({ ...defaultSetting, shortcuts: checkDefaultQuick([], company) });
    } finally {
      dispatch(hideLoading());
    }
  };

  const changeWidgets = () => {
    if (isDragEnabled) submitWidgets();
    else dragEnabledStart();
  };

  const dragEnabledStart = () => {
    setIsDragEnabled(true);

    const target = document.querySelector("#widgets");
    if (target) {
      const keyframes = [
        { transform: "translateX(0px)" },
        { transform: "translateX(3px)" },
        { transform: "translateX(0px)" },
        { transform: "translateX(-3px)" },
        { transform: "translateX(0px)" },
      ];

      const options = {
        duration: 500,
        iterations: Math.floor(Math.random() * 2) + 3,
        easing: "ease-in-out",
      };

      target.scrollIntoView({ behavior: "smooth", block: "nearest" });
      target.animate(keyframes, options);
    }
  };

  const updateSetting = async (updatedFields, successMessage) => {
    const url = "/api/mber-setting";
    const body = {
      ...setting,
      ...updatedFields,
      mberNo: user.mberNo,
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    try {
      const res = await axios.put(url, body);
      if (res?.data) {
        getMberSetting();

        if (successMessage) toast.success(successMessage);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitWidgets = async () => {
    await updateSetting({}, "위젯이 변경되었습니다.");
    setIsDragEnabled(false);
  };

  return (
    <Container className={setting.mode}>
      {deferredPrompt && !isTablet && (
        <Install
          installApp={installApp}
          mode={setting.mode}
          setDeferredPrompt={setDeferredPrompt}
        />
      )}
      <div className="content">
        <DashboardInfo
          changeWidgets={changeWidgets}
          isDragEnabled={isDragEnabled}
          setting={setting}
          updateSetting={updateSetting}
        />
        <Widgets isDragEnabled={isDragEnabled} setting={setting} setSetting={setSetting} />
      </div>
    </Container>
  );
}

import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { CustomInput } from "../../styled/common";
import Pagination from "react-js-pagination";
import { ProductTableContainer } from "../../styled/product";
import { useSelector } from "react-redux";

const ProductTable = ({
  products,
  total,
  pages,
  changePages,
  selectedProducts,
  handleCheck,
  handleAllCheck,
  categoriesObj,
  handleModalState = null,
  isSync = false,
  synced = null,
}) => {
  const setting = useSelector((state) => state?.user?.setting);
  const startIndex = (pages.page - 1) * pages.limit;
  const currentProducts = isSync ? products : products.slice(startIndex, startIndex + pages.limit);

  const isAllChecked =
    currentProducts.length > 0 &&
    selectedProducts.length > 0 &&
    currentProducts.every((product) =>
      selectedProducts.some((selected) => selected.id === product.id),
    );

  const renderProductDetails = (product, categoriesObj) => {
    const price = isSync ? product.basePrice : product.price;

    const details = [
      { key: "media", content: <img src={imgHandler(product?.media)} alt="" /> },
      {
        key: "name",
        content: (
          <span {...(product?.description && { "data-description": product.description })}>
            {product?.name}
          </span>
        ),
      },
      { key: "brand", content: product?.brand },
      { key: "price", content: price ? `${price?.toLocaleString()}원` : "가격문의" },
      { key: "category", content: categoriesObj[product?.categoryId] },
      { key: "specifications", content: product?.specifications },
    ];

    return details.map((detail) => (
      <td key={detail.key}>
        <span className={detail.key}>{detail.content}</span>
      </td>
    ));
  };

  const renderActions = (product, isSyncTable, synced, handleModalState) => {
    if (!isSyncTable) {
      return (
        <div className="buttons">
          <button onClick={() => handleModalState("edit", product)}>수정</button>
          <button onClick={() => handleModalState("detail", product)}>보기</button>
        </div>
      );
    }

    return synced?.[product?.id] ? (
      <span className="sync">
        <AiOutlineCheck />
      </span>
    ) : null;
  };

  return (
    <ProductTableContainer className={setting?.mode}>
      <table>
        <colgroup>
          <col style={{ width: "5%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <thead>
          <tr>
            <th>
              <CustomInput className="block">
                <input
                  type="checkbox"
                  checked={isAllChecked}
                  onChange={(e) => handleAllCheck(e.target.checked)}
                />
                <span className="checkmark"></span>
              </CustomInput>
            </th>
            <th>이미지</th>
            <th>
              <span>제품명</span>
            </th>
            <th>
              <span>브랜드</span>
            </th>
            <th>
              <span>가격</span>
            </th>
            <th>
              <span>카테고리</span>
            </th>
            <th>
              <span>규격</span>
            </th>
            <th>
              <span>{isSync && "연동여부"}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentProducts.length > 0 ? (
            currentProducts.map((product) => (
              <tr>
                <td>
                  <CustomInput className="block">
                    <input
                      type="checkbox"
                      checked={
                        isSync
                          ? selectedProducts.some((item) => item.id === product.id) &&
                            !synced?.[product?.id]
                          : selectedProducts.some((item) => item.id === product.id)
                      }
                      onChange={() => handleCheck(product)}
                    />
                    <span className="checkmark"></span>
                  </CustomInput>
                </td>

                {renderProductDetails(product, categoriesObj)}

                <td>{renderActions(product, isSync, synced, handleModalState)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>상품이 존재하지 않습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        activePage={pages.page}
        itemsCountPerPage={pages.limit}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={(e) => changePages({ target: "page", value: e })}
      />
    </ProductTableContainer>
  );
};

export default ProductTable;

import { useSelector } from "react-redux";
import { CompanyAddFormContainer } from "./styled/homepage";
import { SubmitButton } from "../../styled/mypage";

export default function CompanyAddForm() {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <CompanyAddFormContainer className={setting?.mode}>
      <h5 className="sound_only">사업자 정보 입력</h5>
      <div className="viewer">
        <ul>
          <li className="cmpnyNm">
            <strong>회사명</strong>
            <input type="text" />
          </li>
          <li className="bizrno">
            <strong>사업자번호</strong>
            <input type="text" />
            <button>중복체크</button>
          </li>
          <li className="file">
            <strong>사업자 등록증 첨부</strong>
            <label htmlFor="">
              <span></span>
              <input type="file" />
              <button>파일 찾기</button>
            </label>
          </li>
        </ul>
        <div className="message">
          실제 소유사업자 및 존재 사업자 여부를 관리자가 검토 후 승인 처리합니다.
          <br />
          승인되지 않은 사업자는 홈페이지 생성이 불가하오니, 승인이 지연될 경우 고객센터로 연락
          바랍니다.
        </div>
      </div>
      <SubmitButton>
        <button>
          <span>수정하기</span>
        </button>
      </SubmitButton>
    </CompanyAddFormContainer>
  );
}

import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Swiper } from "swiper/react";
import { useMediaQuery } from "react-responsive";
import { Navigation } from "swiper";
import { useRegex } from "../../../../hooks/useRegex";
import { useState } from "react";
import { ContProductsView } from "../../styled/shopping";
import "./products.css";
import { FiMinus, FiPlus } from "react-icons/fi";

export default function ProductsViewForm({ prduct }) {
  const navigater = useNavigate();
  const { MoneyFormat } = useRegex();
  const { url, theme } = useOutletContext();
  const [count, setCount] = useState(1);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const location = useLocation();

  const searchQuery = location?.state?.keyword || "";
  const selectedQuery = location?.state?.selected;
  const pageQuery = location?.state?.page || 0;
  const moveToUrl = searchQuery
    ? `/shop/${url}/search?keyword=${searchQuery}`
    : `/shop/${url}/productsList?code=${selectedQuery?.code || ""}&brand=${
        selectedQuery?.brand || ""
      }&page=${pageQuery}`;

  const totalPrice = count * prduct[0]?.price;
  const deliveryFee = 3000;

  const increase = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrease = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const createMarkup = () => {
    const detailImgString = prduct[0]?.dtlHtmlCn;
    return { __html: detailImgString };
  };

  const cartMove = () => {
    navigater(`/shop/${url}/cart`);
  };

  const handleCheckout = () => {
    alert("현재는 구매가 불가능합니다.");
    return;

    const product = { ...prduct[0], count };
    navigater(`/shop/${url}/order`, { state: { products: [product] } });
  };

  const isTheme = () => {
    const themes = ["0"];
    return !themes.includes(theme.toString());
  };

  return (
    <div className="sub_page sub_page_mobile_mt">
      <main id="contProductsView">
        <div className="subTop_bnr subTop_bnr_01">{theme === 1 && <span>PRODUCT</span>}</div>
        <article className="boundary pdt_70 pdb_70">
          <section
            id="secBrdcrmb"
            className="font_14 pdb_20 text_right"
            style={{ fontWeight: "400" }}>
            <Link to={`/shop/${url}/productsList`}>
              <span style={{ cursor: "pointer" }}>전체 제품 보기</span>
            </Link>
          </section>
          <section id="secInfo" className="pdb_70">
            <div className="swiper_wrap">
              <div id="mainSwiper" className="swiper">
                <Swiper
                  modules={[Navigation]}
                  id="prdSwiper"
                  slidesPerView={1}
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                  loop={true}
                  speed={500}>
                  <img
                    src={
                      prduct[0]?.media ? imgHandler(prduct[0]?.media) : "/img/function_11_bg.png"
                    }
                    onError={onErrorImg}
                  />
                </Swiper>
              </div>
              <div className="swiper-button-prev swiper-button-white" />
              <div className="swiper-button-next swiper-button-white" />
            </div>
            <div className="totalSumm" style={{ display: "block", marginLeft: "auto" }}>
              <h3 className="font_32">{prduct[0]?.name || ""}</h3>
              <ul className="summ font_18 text_right pdb_30">
                <li className="font_24 pdb_10">
                  {prduct[0]?.price ? (
                    <>
                      판매가: {prduct[0].price.toLocaleString()}
                      <b>원</b>
                    </>
                  ) : (
                    "가격 문의"
                  )}
                </li>
              </ul>
              <div className="options font_18 pdb_30">
                <dl className="opt_amount">
                  <dt>수량</dt>
                  <dd>
                    <div className="control">
                      <button className="font_14" onClick={decrease}>
                        <FiMinus />
                      </button>
                      <span>{count}</span>
                      <button className="font_14" onClick={increase}>
                        <FiPlus />
                      </button>
                    </div>
                    <div className="sms">
                      {/* <button><i className="xi-bell-o font_18"></i> 재입고 알림 SMS신청</button> */}
                    </div>
                  </dd>
                </dl>
                {/* <dl className="opt_selct">
              <dt>상품선택[필수]</dt>
              <dd>
                <select>
                  <option>상품선택[필수]</option>
                  <option>상품선택1</option>
                  <option>상품선택2</option>
                </select>
              </dd>
            </dl>
            <dl className="opt_selct">
              <dt>
              색상선택[필수]</dt>
              <dd>
                <select>
                  <option>색상선택[필수]</option>
                  <option>색상선택2</option>
                  <option>색상선택3</option>
                </select>
              </dd>
            </dl> */}
              </div>
              <ul
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "1.25em",
                }}>
                <li className="pdb_10">배송비 : {MoneyFormat(deliveryFee)}원</li>
              </ul>
              <dl className="total font_30 font600 pdt_20 pdb_30">
                <dt>총 상품금액</dt>
                <dd>
                  {prduct[0]?.price
                    ? `${(totalPrice + deliveryFee).toLocaleString()}원`
                    : "가격 문의"}
                </dd>
              </dl>
              <div className="btns pdt_10">
                {/* <button className="btn_wht font_36"><i className="xi-heart-o"></i></button> */}
                <button className="btn_wht font_24" onClick={cartMove}>
                  장바구니
                </button>
                <button className="btn_blk font_24" onClick={handleCheckout}>
                  바로구매
                </button>
              </div>
            </div>
          </section>
          <section id="secDetail">
            <div class="info">
              <img src="/img/shop_delivery_infomation.jpg" alt="shop delivery infomation" />
            </div>
            <div dangerouslySetInnerHTML={createMarkup()} className="prd_img_wrapper" />
          </section>
          <div className="prd_btn_wrapper">
            <div
              className="content_control"
              style={
                prduct[0]?.dtlHtmlCn
                  ? { width: isMobile || isTheme() ? "100%" : "75%" }
                  : { width: "100%" }
              }>
              <button
                type="button"
                className="btn_board_list white"
                style={{ width: "53.33px", borderRadius: "3px", background: "#4e515d" }}
                onClick={() => navigater(moveToUrl)}>
                목록
              </button>
            </div>
          </div>
        </article>
      </main>
    </div>
  );
}

import React from "react";
import uuid from "react-uuid";

import InputField from "../../../common/inputField";
import ThumbnailUpload from "../../../common/thumbnailUpload";
import CategoryList from "../../../common/categoryList";
import { toast } from "react-toastify";

const EditProductForm = ({
  product,
  setProduct,
  viewCategory,
  setViewCategory,
  setIsDetail,
  categories,
  categoriesObj,
  categoriesRef,
  transformValue,
  handleSubmit,
  closeModal,
}) => {
  const resetCategory = () => {
    setProduct((data) => ({ ...data, categoryId: null }));
  };

  // 이미지 순서 변경 로직
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderArray = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    setProduct((prevProduct) => ({
      ...prevProduct,
      media: reorderArray(prevProduct.media, result.source.index, result.destination.index),
    }));
  };

  // 이미지 추가
  const handleAddImage = (e) => {
    const files = Array.from(e.target.files);
    if (product.media.length + files.length > 10) {
      toast.error("이미지는 최대 10장까지 첨부 가능합니다.");
      return;
    }

    if (files.length > 0) {
      const updatedFiles = files.map((file) => ({
        file,
        path: URL.createObjectURL(file),
        id: uuid(),
      }));

      setProduct((prevData) => ({
        ...prevData,
        media: [...prevData.media, ...updatedFiles],
      }));
    }
  };

  // 이미지 삭제
  const handleDeleteImage = (item) => {
    console.log(product);

    setProduct((prevProduct) => ({
      ...prevProduct,
      media: prevProduct.media.filter((img) => img.id !== item.id),
    }));
  };

  const changeInput = (e) => {
    const { name, value } = e.target;
    setProduct((data) => ({ ...data, [name]: value }));
  };

  // 카테고리 체크
  const handleChangeRadio = (item) => {
    setProduct((data) => ({
      ...data,
      categoryId: item.categoryId,
    }));

    setViewCategory(false);
  };

  return (
    <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
      <div>
        <ul className="form">
          <ThumbnailUpload
            product={product}
            handleAddImage={handleAddImage}
            handleDeleteImage={handleDeleteImage}
            onDragEnd={onDragEnd}
            setIsDetail={setIsDetail}
          />
          <InputField
            label="제품명"
            name="name"
            placeholder="제품명을 입력해주세요."
            required
            onChange={changeInput}
            value={product.name}
          />
          <li>
            <div className="writing_tit">
              <strong className="tit">
                카테고리<em className="point">필수</em>
              </strong>
            </div>
            <div className="writing_cont">
              <CategoryList
                categories={categories}
                product={product}
                handleChangeRadio={handleChangeRadio}
                viewCategory={viewCategory}
                setViewCategory={setViewCategory}
                categoriesRef={categoriesRef}
                categoriesObj={categoriesObj}
                resetCategory={resetCategory}
              />
            </div>
          </li>
          <li className={"half_li"}>
            <div className={`writing_tit`}>
              <strong className="tit">
                소비자가
                <em className="point">필수</em>
              </strong>
            </div>
            <div className="writing_cont">
              <input
                type="number"
                name="price"
                placeholder="소비자가를 입력해주세요."
                onChange={changeInput}
                value={product?.price > 0 ? product.price : null}
                min={0}
              />
            </div>
          </li>
          <InputField
            label="규격"
            name="specifications"
            placeholder="ex) 10x20cm"
            onChange={changeInput}
            half={true}
            value={product.specifications}
          />
          <InputField
            label="브랜드"
            name="brand"
            half
            placeholder="브랜드를 입력해주세요."
            onChange={changeInput}
            value={product.brand}
          />
          <li className={"half_li"}>
            <div className={`writing_tit`}>
              <strong className="tit">수량</strong>
            </div>
            <div className="writing_cont">
              <input
                type="number"
                name="quantity"
                placeholder="상품 수량을 입력해주세요."
                onChange={changeInput}
                value={product?.quantity > 0 ? product.quantity : null}
                min={0}
              />
            </div>
          </li>
          <InputField
            label="상품 설명"
            name="description"
            placeholder="상품 설명을 입력해주세요."
            onChange={changeInput}
            value={product.description}
          />
        </ul>
      </div>
      <div className="actions">
        <button onClick={() => closeModal()}>취소</button>
        <button onClick={handleSubmit}>확인</button>
      </div>
    </div>
  );
};

export default EditProductForm;

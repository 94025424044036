import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MypageBanner from "../mypageBanner";
import ProductAddForm from "./component/product/productAddForm";
import ProductBrandForm from "./component/product/productBrandForm";
import ProductForm from "./component/product/productForm";
import ProductMenu from "./component/product/productMenu";
import ProductSyncForm from "./component/product/productSyncForm";
import HomePageSettingMenu from "./component/common/homepageSettingMenu";
import { setProductIndex } from "../../../store/compoIndex";
import axios from "axios";
import { Container } from "../styled/mypage";

export default function Product() {
  useEffect(() => {
    dispatch(setProductIndex(0));
    getSyncProduct();
    getCategory();
  }, []);

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesObj, setCategoriesObj] = useState({});
  const [synced, setSynced] = useState({});
  const [filtered, setFiltered] = useState({
    minBasePrice: 0,
    maxBasePrice: 0,
    brand: "",
    name: "",
    categoryId: null,
  });

  const user = useSelector((state) => state?.user?.data[0]);
  const index = useSelector((state) => state.compoIndex.product);
  const setting = useSelector((state) => state?.user?.setting);

  const getCategory = async () => {
    const url = "/api/categories";
    const res = await axios.get(url);

    if (res.status === 200) {
      const data = res.data || [];
      const sortedCategories = data.sort((a, b) => {
        return a.path.localeCompare(b.path, undefined, { numeric: true });
      });

      setCategories(sortedCategories);
      setCategoriesObj(
        data.reduce((acc, ele) => {
          acc[ele.categoryId] = ele.categoryName;
          return acc;
        }, {}),
      );
    }
  };

  const getSyncProduct = async () => {
    try {
      const url = "/erp/vendorProducts/get";
      const query = `
        query VendorProducts(
          $cmpnyNo: Int!
          $name: String
          $brand: String
          $categoryId: Int
          $minPrice: Float
          $maxPrice: Float
          $limit: Int
          $offset: Int
        ) {
          vendorProducts(
            filter: {
              cmpnyNo: $cmpnyNo
              name: $name
              brand: $brand
              categoryId: $categoryId
              minPrice: $minPrice
              maxPrice: $maxPrice
              limit: $limit
              offset: $offset
            }
          ) {
            totalCount
            products {
              id
              cmpnyNo
              name
              description
              brand
              specifications
              categoryId
              media
              tags
              quantity
              price
              createdAt
              updatedAt
              cloudProduct {
                id
              }
            }
          }
        }
      `;

      const variables = {
        cmpnyNo: parseInt(user.cmpnyNo, 10),
      };

      Object.entries(filtered).forEach(([key, value]) => {
        if (value) {
          if (key === "minBasePrice" || key === "maxBasePrice") {
            variables[key === "minBasePrice" ? "minPrice" : "maxPrice"] = parseFloat(
              value.toString().replace(/,/g, ""),
            );
          } else {
            variables[key] = key === "categoryId" ? parseInt(value, 10) : value;
          }
        }
      });

      const body = { query, variables };
      const res = await axios.post(url, body);

      if (res.status === 200) {
        const { products } = res.data;
        setProducts(products);
        setSynced(
          products.reduce((acc, ele) => {
            acc[ele.cloudProduct.id] = true;
            return acc;
          }, {}),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className={setting.mode}>
      <div className="content">
        <HomePageSettingMenu />
        <ProductMenu index={index} />
        <Components
          index={index}
          products={products}
          setProducts={setProducts}
          categories={categories}
          categoriesObj={categoriesObj}
          synced={synced}
          getSyncProduct={getSyncProduct}
          filtered={filtered}
          setFiltered={setFiltered}
        />
      </div>
    </Container>
  );
}

function Components({
  index,
  products,
  setProducts,
  categories,
  categoriesObj,
  synced,
  getSyncProduct,
  filtered,
  setFiltered,
}) {
  return (
    <>
      {
        [
          <ProductForm
            getSyncProduct={getSyncProduct}
            categories={categories}
            categoriesObj={categoriesObj}
            products={products}
            filtered={filtered}
            setFiltered={setFiltered}
          />,
          <ProductSyncForm
            categories={categories}
            categoriesObj={categoriesObj}
            synced={synced}
            syncProduct={products}
            getSyncProduct={getSyncProduct}
          />,
          <ProductAddForm categoriesObj={categoriesObj} getSyncProduct={getSyncProduct} />,
          <ProductBrandForm />,
        ][index]
      }
    </>
  );
}

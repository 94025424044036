import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background: rgb(238, 218, 217);
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(238, 218, 217, 1) 0%,
    rgba(247, 191, 176, 1) 35%,
    rgba(238, 218, 217, 1) 100%
  );
  width: 100vw;
  padding-bottom: 150px;
  padding-top: 100px;
  &.dark {
    background: #1e1f21;
    color: #eee;
    .pagination li {
      background: rgb(44, 45, 48);
      &.active,
      &:hover {
        background: rgba(0, 0, 0, 0.7);
        a {
          color: #eee;
        }
      }
    }
    button,
    a {
      color: #eee;
    }
    .ellipse {
      border-color: rgba(255, 255, 255, 0.3);
    }
    .viewer {
      background: rgb(44, 45, 48);
    }
  }
  .viewer {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    padding: 30px;
  }
  .pagination {
    gap: 20px;
    li {
      background: rgba(255, 255, 255, 0.5);
      border: none;
      width: 40px;
      height: 40px;
      &:hover,
      &.active {
        background: #fff;
        a {
          color: #000;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    min-height: 100vh;
    padding-bottom: 100px;
    padding-top: 70px;
    .viewer {
      padding: 20px;
      border-radius: 20px;
    }
    .pagination {
      gap: 10px;
      li {
        width: 30px;
        height: 30px;
        a {
          font-size: 14px;
        }
      }
    }
  }
`;

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  &.dark {
    .modal {
      background: #000;
    }
  }
  .modal {
    position: absolute;
    max-width: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 520px;
    z-index: 33;
  }
  .modalContent {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 40px;
  }
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
  }
  .slideContent {
    flex: 0 0 50%;
    height: 100%;
    position: relative;
    transform: translateX(0);
    flex-basis: 100%;
    flex-shrink: 0;
    transition: 0.3s;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .label {
    font-size: 28px;
    letter-spacing: -1px;
    height: 36px;
    display: inline-block;
  }
`;

export const PageInfoContainer = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  margin-bottom: 100px;
  > button {
    font-size: 30px;
    border: none;
    background: transparent;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 40px;
      font-weight: 700;
      letter-spacing: -1px;
    }
    .overview {
      font-size: 20px;
      text-align: right;
      span {
      }
      .weather {
        display: flex;
        gap: 10px;
        justify-content: end;
        margin-top: 30px;
        align-items: center;
        .icon svg {
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin: 0px auto 50px;
    .info {
      h3 {
        font-size: 20px;
      }
      .overview {
        font-size: 12px;
        .weather {
          margin-top: 10px;
        }
      }
    }
  }
`;

export const SubmitButton = styled.div`
  margin-top: 70px;
  text-align: center;
  button {
    width: 272px;
    height: 40px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: rgba(37, 99, 235, 0.7);
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    .submit {
      margin-top: 30px;
    }
  }
`;

import { useOutletContext, useParams } from "react-router";
import { useEffect, useState } from "react";

import ProductsViewForm from "./component/productsViewForm";
import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

import { CuteProductsView } from "../shopping/component/styled/cuteTheme";
import { ModernProductsView } from "../shopping/component/styled/modernTheme";
import { SimpleProductsView } from "../shopping/component/styled/simpleTheme";
import { ChicProductsView } from "../shopping/component/styled/chicTheme";
import axios from "axios";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";

const ProductsView = () => {
  const { cmpnyNo, theme } = useOutletContext();
  const { prdNo } = useParams();

  const [prduct, setPrduct] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getCmpnyPrductList();
    }
  }, [cmpnyNo]);

  const getCmpnyPrductList = async () => {
    try {
      const url = "/erp/vendorProducts/get";
      const query = `
      query VendorProducts(
        $id: ID
        $cmpnyNo: Int!
      ) {
        vendorProducts(
          filter: {
            id: $id
            cmpnyNo: $cmpnyNo
          }
        ) {
          totalCount
          products {
            id
            cmpnyNo
            name            
            brand
            categoryId
            media            
            quantity
            price
          }
        }
      }
    `;

      const variables = { cmpnyNo: parseInt(cmpnyNo, 10), id: prdNo };
      const body = { query, variables };
      const res = await axios.post(url, body);

      if (res.status === 200) {
        setPrduct(res.data.products);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderComponent = () => {
    switch (theme.toString()) {
      case "0":
        return (
          <DefaultContaienr>
            <ProductsViewForm prduct={prduct} />
          </DefaultContaienr>
        );
      case "1":
        return (
          <ChicProductsView>
            <ProductsViewForm prduct={prduct} />
          </ChicProductsView>
        );
      case "2":
        return (
          <ModernProductsView>
            <ModernBanner />
            <ProductsViewForm prduct={prduct} />
          </ModernProductsView>
        );
      case "3":
        return (
          <CuteProductsView>
            <CuteBanner />
            <ProductsViewForm prduct={prduct} />
          </CuteProductsView>
        );
      case "4":
        return (
          <SimpleProductsView>
            <SimpleBanner />
            <ProductsViewForm prduct={prduct} />
          </SimpleProductsView>
        );
      case "sm":
        return (
          <SimpleProductsView style={{ paddingTop: 100 }}>
            <ProductsViewForm prduct={prduct} />
          </SimpleProductsView>
        );
      default:
        return <ProductsViewForm prduct={prduct} />;
    }
  };

  return <>{renderComponent()}</>;
};

export default ProductsView;

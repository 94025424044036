import { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import axios from "axios";

import DefaultTheme from "./component/defaultTheme";
import ChicTheme from "./component/chicTheme";
import ModernTheme from "./component/modernTheme";
import CuteTheme from "./component/cuteTheme";
import SimpleTheme from "./component/simpleTheme";
import SmTheme from "./component/smTheme";

export default function ShoppingPage() {
  const { cmpnyNo, theme } = useOutletContext();
  const [homepageInfo, setHomepageInfo] = useState();

  useEffect(() => {
    getMySetting();
  }, [cmpnyNo]);

  // 홈페이지 정보 조회
  const getMySetting = async () => {
    if (!cmpnyNo) return;

    const url = "/api/infoSettingLoad";
    const request = { cmpnyNo, delYn: "N" };

    const response = await axios.post(url, request);

    if (!response.data) {
      response.data = {};
      response.data.cmpnyNo = cmpnyNo;
    }

    const homepageInfo = response.data;

    setHomepageInfo(homepageInfo);
  };

  const renderComponent = () => {
    switch (theme.toString()) {
      case "sm":
        return <SmTheme />;
      case "0":
        return <DefaultTheme />;
      case "1":
        return <ChicTheme homepageInfo={homepageInfo} />;
      case "2":
        return <ModernTheme homepageInfo={homepageInfo} />;
      case "3":
        return <CuteTheme homepageInfo={homepageInfo} />;
      case "4":
        return <SimpleTheme homepageInfo={homepageInfo} />;
      default:
        return <DefaultTheme homepageInfo={homepageInfo} />;
    }
  };

  return <div>{renderComponent()}</div>;
}

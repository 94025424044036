import React from "react";

const Logo = ({ logo, handleHome, homepageInfo, cmpnyNm, theme, isMatch }) => (
  <h1 className="shopLogo" onClick={handleHome}>
    {isMatch ? (
      <img src="/img/logo_monoti_shop.png" />
    ) : logo ? (
      <img src={theme === "sm" ? "/img/shopTheme/sm/logo.png" : logo || ""} alt="Logo" />
    ) : (
      <span>{homepageInfo?.logoText ?? cmpnyNm}</span>
    )}
  </h1>
);

export default Logo;

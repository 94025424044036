import { useSelector } from "react-redux";
import { RegisterTitleContainer } from "../styled/register";

export default function RegisterTitle() {
  const setting = useSelector((state) => state?.user?.setting);
  return (
    <RegisterTitleContainer className={setting?.mode}>
      <span>
        기본 메뉴는 <strong>삭제가 불가능합니다.</strong> 변경할 메뉴명은 최대 10자 이내여야 합니다.
        순서 부분을 마우스로 드래그하여 수정합니다.
        <br />
        메뉴는 최대 4개(총7개) 추가 가능하며, 제품 관련 게시판은 자동생성됩니다.
      </span>
    </RegisterTitleContainer>
  );
}

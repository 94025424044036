import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { LuUser } from "react-icons/lu";
import { CiLogin } from "react-icons/ci";
import { ShopSideMenuContainer } from "./styled/sideMenu";

export default function ShopSideMenu({
  clickSideMenu,
  setClickSideMenu,
  user,
  url,
  menus,
  homepageInfo,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const links = {
    0: "company",
    1: "productsList",
    2: "portfolio",
    3: "shoppingNotice",
  };

  const shopMenuItems = [
    user
      ? {
          title: "내 정보",
          icon: LuUser,
          items: [{ link: "/mypage/modifyProfile", label: "정보 수정" }],
        }
      : {
          title: "로그인",
          icon: CiLogin,
          items: [
            { link: "/shop/login", label: "로그인" },
            { link: "/type", label: "회원가입" },
          ],
          action: null,
        },
    {
      title: "마이페이지",
      icon: LuUser,
      items: [
        // { link: `/shop${url ? `/${url}` : ""}/cart`, label: "장바구니" },
        { link: "#", label: "구매내역" },
      ],
      action: null,
    },
    menus &&
      menus.length && {
        title: "메뉴",
        icon: IoMdInformationCircleOutline,
        items: [
          ...menus.map((item) => ({
            link: `/shop/${url}/${links[item.menuType]}`,
            label: item.afterMenuName ? item.afterMenuName : item.beforeMenuName,
          })),
          ...(homepageInfo?.cmpnyNo === 29787
            ? [
                { link: "https://map.naver.com/p/entry/place/13245702", label: "고명한의원" },
                { link: "https://blog.naver.com/ssongwonjang", label: "블로그" },
              ]
            : []),
        ],
      },
    {
      title: "서비스",
      icon: MdOutlineSettingsApplications,
      items: serviceMenu,
      action: null,
    },
  ].filter(Boolean);

  const renderMenuSection = (section) => {
    const Icon = section.icon;

    const handlEChanggo = (e) => {
      e.preventDefault();

      if (!user) {
        navigate("/login");
      } else {
        openMyPage();
      }
    };

    const openMyPage = async () => {
      const url = "/api/domainSettingLoad";
      const req = {
        delYn: "N",
        cmpnyNo: user.cmpnyNo,
      };

      const res = await axios.post(url, req);
      if (res.data.domain) {
        window.open(`https://1472.ai/shop/${res.data.domain}`);
        user && localStorage.setItem("user", JSON.stringify(user));
      } else {
        navigate("/mypage/homepage");
      }
    };

    return (
      <li key={section.title} className="sections">
        <div>
          <div className="tit">
            <Icon />
            <span>{section.title}</span>
          </div>
        </div>
        {section.items && (
          <ul className="sideSection">
            {section.items.map((item, i) =>
              item.link.startsWith("https") ? (
                <li key={i}>
                  <Link to={item.link} target="_blank">
                    {item.label}
                  </Link>
                </li>
              ) : item.label === "e층창고" ? (
                <li key={i}>
                  <Link onClick={(e) => handlEChanggo(e)}>{item.label}</Link>
                </li>
              ) : (
                <li key={i}>
                  <Link
                    to={item.link}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (section.action && typeof section.action === "function") {
                        dispatch(section.action(i));
                      }
                      setClickSideMenu(false);
                    }}>
                    {item.label}
                  </Link>
                </li>
              ),
            )}
          </ul>
        )}
      </li>
    );
  };

  return (
    <ShopSideMenuContainer
      style={{ display: clickSideMenu ? "block" : "none" }}
      onClick={(e) => e.target === e.currentTarget && setClickSideMenu(false)}>
      <div
        className="sideMenuInner"
        onClick={(e) => e.target === e.currentTarget && setClickSideMenu(false)}>
        <div className="sideMenuWrapper">
          <div className="sideMenuContainer">
            <div className="arrowUp" />
            <div className="sideMenuHeader">
              <div className="logo">
                <img src="/img/logo_dark.png" alt="Logo" />
                <span>바로가기</span>
              </div>
              <div className="close">
                <button onClick={() => setClickSideMenu(false)}>
                  <IoMdClose />
                </button>
              </div>
            </div>
            <div className="sideMenuBody">
              <ul>{shopMenuItems.map(renderMenuSection)}</ul>
            </div>
          </div>
        </div>
        <div className="emptyBox" />
      </div>
    </ShopSideMenuContainer>
  );
}

import React from "react";
import { CustomInput } from "../../styled/common";
import { IoCloseOutline } from "react-icons/io5";
import { treeCategoryView } from "../../utils/product";
import { Category } from "../../styled/product";
import { useSelector } from "react-redux";

const CategoryList = ({
  categories,
  product,
  handleChangeRadio,
  viewCategory,
  setViewCategory,
  categoriesRef,
  resetCategory,
  categoriesObj,
}) => {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <Category
      onClick={() => setViewCategory((data) => !data)}
      ref={categoriesRef}
      className={setting?.mode}>
      <div className="selected">
        {product?.categoryId ? (
          <>
            <span>{categoriesObj[product.categoryId]}</span>
            <button onClick={resetCategory}>
              <IoCloseOutline />
            </button>
          </>
        ) : (
          <span className="not_category">카테고리를 선택해주세요.</span>
        )}
      </div>

      {viewCategory && (
        <div className="categories">
          <ul>
            {categories &&
              categories.map((item) => (
                <li
                  key={item.categoryId}
                  className={item.categoryId === product.categoryId ? "active" : ""}
                  style={{ padding: treeCategoryView(item) }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeRadio(item);
                  }}>
                  <CustomInput>
                    <input
                      type="radio"
                      name="categoryId"
                      value={item.categoryId}
                      checked={item.categoryId === product.categoryId}
                      onChange={() => handleChangeRadio(item)}
                    />
                    <span className="checkmark"></span>
                  </CustomInput>
                  <span>{item.categoryName}</span>
                </li>
              ))}
          </ul>
        </div>
      )}
    </Category>
  );
};

export default CategoryList;

import styled from "styled-components";

export const CommunityContainer = styled.div`
  .viewer {
    height: 100%;
    .label {
      font-weight: 700;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      span {
        font-size: 28px;
      }
      button {
        background: #255dac;
        color: #fff;
        font-size: 14px;
        width: 130px;
        border: none;
        height: 100%;
      }
    }
    .viewerContiner {
      height: calc(100% - 235px);
      padding: 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    .container {
      flex-direction: column;
      .content:not(.pickup) {
        width: 100%;
        height: calc(100% - 173px);
        padding: 0 20px 20px 20px;
        .viewer {
          .label {
            font-size: 20px;
            height: 25px;
            span {
              font-size: 20px;
            }
          }
          .viewerContiner {
            height: calc(100% - 200px);
            padding: 15px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      .content:not(.pickup) {
        height: calc(100% - 84px);
        padding: 0;
        .viewer {
          background: transparent;
          box-shadow: none;
          .label,
          .label span {
            font-size: 16px;
            align-items: center;
          }
          .viewerContiner {
            height: calc(100% - 219px);
            padding: 10px 20px;
          }
        }
      }
    }
  }
`;

export const WeeklyCommentContainer = styled.div`
  height: 235px;
  padding: 20px;
  border-bottom: 1px solid #666;
  .comments {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    .box {
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      padding: 15px;
      width: calc(50% - 15px);
      display: flex;
      align-items: center;
      gap: 30px;
      .userIcon {
        width: 65px;
        height: 65px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .feed {
        width: calc(100% - 95px);
        > span {
          font-size: 20px;
          font-weight: 700;
          display: block;
          margin-bottom: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .info {
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          .feedInfo {
            display: flex;
            gap: 15px;
            color: #bbb;
            > div {
              display: flex;
              align-items: center;
              gap: 5px;
              svg {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .moreBotton {
    text-align: center;
    margin-top: 15px;
    button {
      font-size: 15px;
      border: none;
      background: transparent;
      color: #666;
    }
  }
  @media screen and (max-width: 1050px) {
    height: 200px;
    padding: 15px;
    .comments .box {
      padding: 10px;
      .feed > span {
        font-size: 15px;
      }
      .userIcon {
        width: 50px;
        height: 50px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    margin: 10px 20px 0;
    height: 204px;
    .comments {
      gap: 10px;
      flex-direction: column;
      .box {
        width: 100%;
        padding: 7px 10px;
        .userIcon {
          width: 40px;
          height: 40px;
        }
      }
    }
    .moreBotton {
      margin-top: 0px;
      padding: 5px 0;
      button {
        font-size: 14px;
      }
    }
  }
`;

export const GroupContainer = styled.div``;

export const ContactUsContainer = styled.div`
  .contactUs .wrapper .box {
    height: calc((100% - 40px) / 3);
    .questions .respond {
      padding-left: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 1050px) {
    .contactUs .wrapper .box {
      height: calc((100% - 40px) / 2);
    }
  }
  @media screen and (max-width: 767px) {
    .contactUs .wrapper .box {
      height: calc(100% - 30px);
      padding: 10px 0 20px;
      .questions {
        font-size: 14px;
        .respond {
          padding-left: 15px;
        }
      }
    }
  }
`;

export const OwnerMessageContainer = styled.div`
  .ownerMessage .wrapper .box {
    height: calc((100% - 40px) / 4);
  }
  @media screen and (max-width: 1050px) {
    .ownerMessage .wrapper .box {
      height: calc((100% - 40px) / 3);
    }
  }
  @media screen and (max-width: 767px) {
    .ownerMessage .wrapper .box {
      height: calc(100% - 30px);
      padding: 10px 0 20px;
    }
  }
`;

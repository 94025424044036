import styled, { keyframes } from "styled-components";

const dotAnimation = keyframes`
  0% {
    top: 10%;
  }
  100% {
    top: 70%;    
  }
`;

export const ChicConainer = styled.main`
  max-width: 1440px;
  padding: 0 20px;
  min-height: 100vh;
  margin: 0 auto 100px;
  font-family: "Prompt", "Pretendard";
  /* common */
  img {
    user-select: none;
  }
  .info {
    margin-bottom: 60px;
    span {
      font-weight: 600;
      display: block;
      &:first-child {
        display: inline-block;
        font-size: 20px;
        position: relative;
        &::after {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: -5px;
          background: #ff4191;
          transform: translate(100%, -50%);
        }
      }
      &:nth-child(2) {
        font-size: 56px;
      }
    }
  }
  /* common */
  .banner {
    .bannerInfo {
      display: flex;
      align-items: end;
      justify-content: space-between;
      margin-bottom: 65px;
      .tit {
        max-width: calc(100% - 160px);
        font-size: 80px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .scroll {
        display: flex;
        width: 150px;
        align-items: end;
        span {
          font-size: 24px;
        }
        button {
          margin-left: 20px;
          font-size: 30px;
          width: 40px;
          height: 70px;
          border-radius: 20px;
          background: transparent;
          border: 2px solid #000;
          position: relative;
          .dot {
            position: absolute;
            top: 10px;
            width: 10px;
            height: 10px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 10px;
            background: #000;
            animation: ${dotAnimation} 1s linear infinite 1s;
          }
        }
      }
    }
    .banners {
      display: flex;
      max-width: 1400px;
      gap: 20px;
      height: 500px;
      .box {
        &:first-child {
          background: #000;
        }
        &:last-child {
          background: #ff4191;
        }
      }
      .right {
        background: #fff078;
      }
      .left,
      .right {
        width: 50%;
        height: 100%;
      }
      .right {
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .box {
          height: calc(50% - 10px);
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  .homepageInfo {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 180px;
    font-weight: 600;
    .tit {
      p {
        font-size: 130px;
        text-transform: uppercase;
      }
      span {
        font-size: 56px;
        font-weight: 700;
      }
    }
    .desc {
      margin-top: 20px;
      font-size: 20px;
      margin-bottom: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    button {
      width: 570px;
      height: 100px;
      border-radius: 50px;
      font-size: 40px;
      background: transparent;
      border: 1px solid #ff4191;
      color: #ff4191;
      font-weight: 700;
    }
  }
  .productInfo {
    .products {
      display: flex;
      gap: 20px;
      .box {
        width: calc((100% - 40px) / 3);
        height: 350px;
        position: relative;
        transition: 0.3s;
        background: #efefef;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          pointer-events: none;
        }
        span {
          position: absolute;
          top: 20px;
          left: 20px;
          padding: 2px 10px;
          font-weight: 600;
          font-size: 32px;
          z-index: 1;
          max-width: 70%;
          transition: 0.3s;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        &:hover {
          height: 450px;
          &::after {
            background-color: transparent;
          }
          span {
            background: rgba(0, 0, 0, 0.7);
            color: #ffff;
          }
        }
      }
    }
  }
  .portfolio {
    margin: 180px 0;
    .info {
      text-transform: uppercase;
      font-size: 56px;
      font-weight: 600;
      margin-bottom: 70px;
    }
    .box {
      margin: 35px 0;
      display: flex;
      &:nth-child(1) {
        button {
          margin-right: auto;
        }
      }
      &:nth-child(2) {
        flex-direction: row-reverse;
        .contentInfo {
          text-align: right;
        }
        button {
          margin-left: auto;
        }
      }
      img {
        width: 560px;
        height: 350px;
        object-fit: contain;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
      .contentInfo {
        font-size: 20px;
        padding: 50px 70px;
        .tit {
          font-size: 30px;
        }
        button {
          margin-top: 10px;
          border: none;
          display: flex;
          align-items: center;
          gap: 10px;
          background: transparent;
          .icon {
            display: flex;
            font-size: 25px;
          }
        }
      }
    }
  }
  .notice {
    font-weight: 600;
    .notices {
      display: flex;
      justify-content: space-between;
      gap: 130px;
      margin-bottom: 72px;
      li {
        width: calc((100% - 260px) / 3);
        padding: 30px 0 0;
        .noticeInfo {
          width: 100%;
          overflow: hidden;
          font-size: 24px;
          cursor: pointer;
          span {
            &.noticeCl {
              border-bottom: 1px solid #000;
            }
            &.noticeSj {
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              word-break: break-word !important;
              height: 74px;
            }
            &:not(.noticeSj) {
              height: 38px;
              white-space: nowrap;
              display: block;
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .date {
            font-size: 16px;
            color: #6a6a6a;
            display: block;
            margin: 30px 0;
          }
        }
        .img {
          height: 165px;
          width: 100%;
          background: #efefef;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    button {
      width: 570px;
      height: 100px;
      border-radius: 50px;
      font-size: 40px;
      background: transparent;
      border: 1px solid rgb(255, 65, 145);
      color: rgb(255, 65, 145);
      font-weight: 700;
      margin: auto;
      display: block;
    }
  }
  @media screen and (max-width: 1050px) {
    margin: 0 auto;
    .info {
      margin-bottom: 20px;
      span {
        &:first-child {
          font-size: 12px;
        }
        &:nth-child(2) {
          font-size: 32px;
        }
      }
    }
    .banner {
      .bannerInfo {
        margin-bottom: 40px;
        .tit {
          font-size: 40px;
          max-width: calc(100% - 110px);
          word-break: break-word;
        }
        .scroll {
          width: 106px;
          span {
            font-size: 18px;
          }
          button {
            width: 20px;
            height: 50px;
          }
        }
      }
      .banners {
        flex-direction: column-reverse;
        height: auto;
        .left,
        .right {
          width: 100%;
        }
        .left {
          height: 340px;
          gap: 20px;
          .box {
          }
        }
        .right {
          height: 284px;
        }
      }
    }
    .homepageInfo {
      margin-bottom: 60px;
      .tit {
        p {
          font-size: 64px;
        }
        span {
          font-size: 32px;
        }
      }
      .desc {
        font-size: 16px;
      }
      button {
        width: 160px;
        height: 38px;
        font-size: 16px;
      }
    }
    .productInfo {
      .products {
        flex-direction: column;
        gap: 0;
        .box {
          width: 100%;
          height: 100px;
          span {
            font-size: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
          &:hover {
            height: 200px;
          }
        }
      }
    }
    .portfolio {
      margin: 40px 0;
      .box {
        flex-direction: column;
        &:nth-child(2) {
          flex-direction: column;
          .contentInfo {
            text-align: left;
            button {
              margin: 0;
              margin-top: 10px;
            }
          }
        }
        img {
          height: 215px;
          width: 100%;
        }
        .contentInfo {
          padding: 30px 0 15px;
          .tit {
            font-size: 20px;
            font-weight: 600;
          }
          .desc {
            margin: 10px 0;
            font-size: 16px;
          }
          button {
            font-size: 16px;
            font-weight: 600;
            .icon {
              font-size: 16px;
            }
          }
        }
      }
    }
    .notice {
      .notices {
        gap: 20px;
        margin-bottom: 40px;
        li {
          width: calc(50% - 10px);
          .noticeInfo {
            span {
              font-size: 16px;
              &.noticeSj {
                height: 48px;
              }
            }
            .date {
              font-size: 14px;
              margin: 15px 0;
            }
          }
        }
      }
      button {
        width: 160px;
        height: 38px;
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin: 0 0 100px;
  }
`;

export const ChicProductsContainer = styled.div`
  font-family: "Pretendard", "Prompt";
  img {
    user-select: none;
  }
  main#contProductsList {
    margin-top: 0;
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
    }
    .subTop_bnr_01 {
      background-image: url("/img/shopTheme/chic_bg_prd.png");
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-weight: 600;
      color: #ffff;
      height: 480px;
    }
    #secBrdcrmb {
      display: none;
    }
    .font_color_gray_light {
      color: #000;
      font-size: 16px;
      i {
        margin-right: 5px;
      }
    }
    h2 {
      display: none;
    }
    .categorys {
      .categoryInfo {
        font-size: 70px;
        height: 80px;
        span {
          text-transform: uppercase;
        }
      }
      ul {
        box-shadow: none;
        padding: 0;
        li {
          background: #000;
          color: #fff;
          text-align: center;
          width: auto;
          height: 50px;
          padding: 0 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.font700 {
            border: 1px solid #000;
            background: #fff;
            color: #000;
          }
        }
      }
    }
    #secList {
      gap: 20px;
    }
    .pagination {
      gap: 10px;
      li {
        border: 1px solid #000;
        border-radius: 0;
        &.active,
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    main#contProductsList .subTop_bnr_01 {
      height: 310px;
      font-size: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsList {
      .boundary {
        margin-bottom: 100px;
      }
      .categorys {
        .categoryInfo {
          font-size: 40px;
        }
        ul li {
          height: 30px;
        }
      }
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
      #secList .col_3 {
        p.font_18 {
          font-size: 14px;
        }
        p.font_16 {
          font-size: 12px;
          color: #9a9a9a;
        }
        a:hover {
          color: #000;
        }
      }
    }
  }
`;

export const ChicNoticeContainer = styled.div`
  font-family: "Pretendard", "Prompt";
  main#contNoticeList {
    margin-top: 0;
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
    }
    .subTop_bnr_01 {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("/img/shopTheme/chic_bg_notice.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-weight: 600;
      color: #ffff;
      height: 480px;
    }
    #secBrdcrmb {
      display: none;
    }
    h2 {
      display: none;
    }
    .pagination {
      gap: 10px;
      li {
        border: 1px solid #000;
        border-radius: 0;
        &.active,
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }
  .notices {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    li {
      width: calc((100% - 60px) / 3);
      font-size: 24px;
      border: 1px solid #000;
      cursor: pointer;
      .noticeCl {
        background: #000;
        color: #fff;
        padding: 10px 0;
        font-weight: 700;
        text-indent: 10px;
      }
      .noticeSj {
        margin: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 56px;
      }
      .date {
        font-size: 18px;
        color: #6a6a6a;
        margin: 10px;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    main#contNoticeList {
      margin-bottom: 150px;
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
    }
    .notices {
      li {
        width: calc(50% - 15px);
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contNoticeList .boundary {
      margin-bottom: 100px;
    }
    .notices {
      li {
        width: 100%;
        .noticeCl {
          font-size: 14px;
        }
        .noticeSj {
          font-size: 14px;
          height: 32px;
        }
        .date {
          font-size: 12px;
        }
      }
    }
  }
`;

export const ChicCmpnyContainer = styled.div`
  font-family: "Pretendard", "Prompt";
  main#contCompany {
    margin-top: 0;
  }
  article.boundary {
    max-width: 1440px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  img {
    user-select: none;
  }
  .company_address_h1,
  .company_content,
  .address_btn_wrapper,
  .google_map_sub_wrapper {
    width: 100%;
    font-size: 20px;
  }
  .subTop_bnr_01 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("/img/shopTheme/chic_bg_cmpny.png");
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    font-weight: 600;
    color: #ffff;
    height: 480px;
  }
  #secBrdcrmb {
    display: none;
  }
  #secListTop .list_info {
    color: #000;
    i {
      margin-right: 5px;
    }
  }
  h2 {
    display: none;
  }
  #contCompany #secMap h3 {
    &::before {
      display: none;
    }
    span {
      padding: 0;
      border: none;
    }
  }
  h3 span {
    position: relative;
    font-weight: 700;
    /* &::after {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: -5px;
      background: #ff4191;
      transform: translate(100%, -50%);
    } */
  }
  #contCompany #secMap {
    display: flex;
    flex-wrap: wrap;
    .address_btn_wrapper {
      width: 200px;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: column;
      justify-content: center;
      height: 450px;
      margin-right: 20px;
    }
    .google_map_wrapper {
      width: calc(100% - 220px);
    }
  }
  @media screen and (max-width: 1050px) {
    main#contCompany {
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
      .company_address_h1,
      .company_wrapper {
        padding: 0 20px;
      }
      #secMap {
        flex-wrap: nowrap;
        flex-direction: column;
        .address_btn_wrapper {
          width: 100%;
          height: auto;
          display: block;
          margin: 0 0 20px;
        }
        .google_map_wrapper {
          width: calc(100% - 40px);
        }
      }
      h3 {
        font-size: 32px;
      }
      .company_content,
      .address_btn_wrapper,
      .google_map_sub_wrapper {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    article.boundary {
      margin-bottom: 100px;
    }
  }
`;

export const ChicPortfolioContainer = styled.div`
  font-family: "Pretendard", "Prompt";
  img {
    user-select: none;
  }
  main#contProductsList {
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    margin-top: 0;
    .subTop_bnr_01 {
      background-image: url("/img/shopTheme/chic_bg_port.png");
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-weight: 600;
      color: #ffff;
      height: 480px;
    }
    #secBrdcrmb {
      display: none;
    }
    .font_color_gray_light {
      color: #000;
      font-size: 16px;
      i {
        margin-right: 5px;
      }
    }
    h2 {
      display: none;
    }
    .pagination {
      gap: 10px;
      li {
        border: 1px solid #000;
        border-radius: 0;
        &.active,
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    main#contProductsList {
      .boundary {
        margin-bottom: 100px;
      }
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
    }
  }
`;

export const ChicNoticeViewContainer = styled.div`
  font-family: "Pretendard", "Prompt";
  img {
    user-select: none;
  }
  main#noticeView {
    margin-top: 0;
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
    }
    .subTop_bnr_01 {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("/img/shopTheme/chic_bg_notice.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-weight: 600;
      color: #ffff;
      height: 480px;
    }
    #secBrdcrmb {
      display: none;
    }
  }
  @media screen and (max-width: 1050px) {
    main#noticeView {
      .boundary {
        margin-bottom: 100px;
      }
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
    }
  }
`;

export const ChicPortfolioViewContainer = styled.div`
  font-family: "Pretendard", "Prompt";
  img {
    user-select: none;
  }
  main#contProductsList {
    margin-top: 0;
    article.boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
      .shop_port_detail_content,
      .shop_port_title,
      #secBrdcrmb {
        width: 100%;
      }
    }
    .subTop_bnr_01 {
      background-image: url("/img/shopTheme/chic_bg_port.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-weight: 600;
      color: #ffff;
      height: 480px;
    }
  }
  main#noticeView {
    .subTop_bnr_01 {
      height: 310px;
      font-size: 40px;
    }
  }
  @media screen and (max-width: 1050px) {
    main#contProductsList {
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsList {
      .boundary {
        margin-bottom: 100px;
      }
      #secBrdcrmb a p {
        font-size: 14px;
      }
    }
  }
`;

export const ChicProductsView = styled.div`
  font-family: "Pretendard", "Prompt";
  img {
    user-select: none;
  }
  .sub_page {
    margin-top: 0;
  }
  main#contProductsView {
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    .subTop_bnr_01 {
      background-image: url("/img/shopTheme/chic_bg_prd.png");
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-weight: 600;
      color: #ffff;
      height: 480px;
    }
    #secBrdcrmb {
      display: none;
    }
    #secListTop .list_info {
      color: #000;
      i {
        margin-right: 5px;
      }
    }
    h2 {
      display: none;
    }
    #secList {
      gap: 20px;
    }
  }
  @media screen and (max-width: 1400px) {
    main#contProductsList {
      margin-bottom: 150px;
      .boundary {
        width: 100%;
        padding: 50px 20px 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsView {
      .boundary {
        margin-bottom: 100px;
      }
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
    }
  }
`;

export const ChicSearchContaienr = styled.main`
  font-family: "Pretendard", "Prompt";
  img {
    user-select: none;
  }
  main#contSearch {
    margin-top: 0;
    article.boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
      .shop_port_detail_content,
      .shop_port_title,
      #secBrdcrmb {
        width: 100%;
      }
    }
    .subTop_bnr_01 {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("/img/shopTheme/chic_bg_search.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-weight: 600;
      color: #ffff;
      height: 480px;
    }
  }
  @media screen and (max-width: 1050px) {
    main#contSearch {
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
      article.boundary {
        padding: 50px 20px;
        &:last-child {
          margin-bottom: 100px;
        }
        #secBrdcrmb {
          width: 100%;
        }
      }
    }
  }
`;

export const ChicCartContaienr = styled.div`
  font-family: "Pretendard", "Prompt";
  img {
    user-select: none;
  }
  main#contCart {
    margin-top: 0;
    article.boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
      .shop_port_detail_content,
      .shop_port_title {
        width: 100%;
      }
    }
    #secBrdcrmb,
    h2 {
      display: none;
    }
    .subTop_bnr_01 {
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url("/img/shopTheme/chic_bg_cart.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-weight: 600;
      color: #ffff;
      height: 480px;
    }
    .products {
      font-size: 20px;
      label {
        display: flex;
        gap: 5px;
        align-items: center;
        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          padding: 0;
          width: 0;
          height: 0;
        }
        .checkmark {
          position: relative;
          top: 0;
          left: 0;
          display: inline-block;
          height: 25px;
          width: 25px;
          border: 2px solid #d4d4d4;
          border-radius: 5px;
          background-color: transparent;
          transition: all 0.3s ease;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg) scale(0);
            opacity: 0;
            width: 10px;
            height: 15px;
            border: solid #fff;
            border-width: 0px 3px 3px 0px;
            transition: 0.3s;
          }
        }
        input[type="checkbox"]:checked ~ .checkmark {
          background: #000;
          border: 1px solid transparent;
          &:after {
            opacity: 1;
            transform: translate(-50%, -65%) rotate(45deg) scale(1);
          }
        }
        &:hover .checkmark {
          background-color: #efefef;
        }
      }
      li {
        width: 100%;
        padding: 30px 0;
        border-bottom: 1px solid #000;
        &.header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          /* border-bottom: 1px solid #666;
        padding-bottom: 30px;
        margin-bottom: 30px; */
          button {
            padding: 5px 20px;
            border-radius: 10px;
            background: #666;
            color: #fff;
          }
        }
        &:not(.header) {
          font-size: 14px;
          position: relative;
          display: flex;
          align-items: flex-start;
          gap: 10px;
          .image {
            width: 100px;
            height: 100px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .productInfo {
            width: calc(100% - 180px);
            > span {
              display: block;
              margin-bottom: 5px;
            }
            .productName {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .brand {
              display: none;
            }
            .price {
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 10px;
            }
            .amount {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #333;
              width: 120px;
              span {
                display: inline-block;
                width: 60px;
                text-align: center;
                font-weight: 700;
              }
              button {
                width: 30px;
                height: 25px;
                border: none;
                background: transparent;
              }
            }
          }
          > button {
            font-size: 14px;
            background: transparent;
            border-bottom: 1px solid #000;
          }
        }
      }
    }
    #secTable .tb_footer {
      justify-content: space-evenly;
      .btn_order {
        background: #000;
      }
      .price_detail {
        border-right: none;
      }
      .price_sum dd,
      .font_color_red {
        color: #000;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    main#contCart {
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    article.boundary {
      margin-bottom: 100px;
    }
    main#contCart .products li {
      padding: 15px 0;
      font-size: 14px;
      label .checkmark {
        width: 20px;
        height: 20px;
        ::after {
          width: 8px;
          height: 12px;
          border-width: 0 2px 2px 0;
        }
      }
      &:not(.header) {
        .image {
          width: 70px;
          height: 70px;
        }
        .productInfo {
          width: calc(100% - 150px);
          .price {
            font-size: 14px;
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
    #secTable .tb_footer {
      font-size: 14px;
    }
  }
`;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const Products = ({ homepageInfo, cmpnyNo, url }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getProducts(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getProducts = async (cmpnyNo) => {
    try {
      const url = "/erp/vendorProducts/get";
      const query = `
      query VendorProducts(
        $cmpnyNo: Int!
        $limit: Int
        $offset: Int
      ) {
        vendorProducts(
          filter: {
            cmpnyNo: $cmpnyNo
            limit: $limit
            offset: $offset
          }
        ) {
          totalCount
          products {
            id
            name
            media
            price
          }
        }
      }
    `;
      const variables = { cmpnyNo: parseInt(cmpnyNo, 10), limit: 3, offset: 0 };
      const body = { query, variables };

      const res = await axios.post(url, body);
      if (res.status === 200) {
        setProducts(res.data.products);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return products && Array.isArray(products) && products.length > 0 ? (
    <div className="productInfo">
      <div className="info">
        <span>PRODUCTS</span>
        <span>{homepageInfo?.cmpnyNm}의 인기 상품을 보여드릴게요</span>
      </div>
      <div className="products">
        {products.length > 0 &&
          products.map((item) => (
            <div className="box" onClick={() => navigate(`/shop/${url}/productsView/${item.id}`)}>
              <span>{item.name}</span>
              <img src={imgHandler(item.media)} onError={onErrorImg} />
            </div>
          ))}
      </div>
    </div>
  ) : null;
};

export default Products;

import styled from "styled-components";

export const MenuSettingFormContainer = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  width: 100%;
  letter-spacing: -1px;
  &.dark {
    color: #eee;
  }
`;

export const MenuSettingFormTable = styled.table`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-collapse: collapse;
  &.dark {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    tbody tr td,
    thead tr th {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    input[type="number"],
    input[type="text"] {
      background: #000;
      color: #eee;
    }
    tbody tr td label .checkmark {
      background: #000;
      border: 2px solid #555;
    }
  }
  thead tr th,
  tbody tr td {
    text-align: center;
    height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  tbody tr td {
    label .checkmark {
      background: #fff;
    }
    input[type="number"],
    input[type="text"] {
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.5);
      text-align: center;
      height: 44px;
      border: none;
      color: rgba(0, 0, 0, 0.5);
      &:focus {
        border: none !important;
        box-shadow: none;
        outline: none;
      }
    }
    input[type="number"] {
      width: 44px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.dark {
      border-top: none;
      tbody tr td,
      thead tr th {
        background: rgba(0, 0, 0, 0.5);
        &:nth-child(odd) {
          border-right: 1px solid rgba(255, 255, 255, 0.1);
        }
        input[type="text"],
        input[type="number"] {
          background: #333;
        }
      }
    }
    border-top: none;
    thead {
      /* display: none; */
    }
    tbody,
    thead {
      tr {
        width: calc(100vw - 40px);
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
      }
    }
    tbody tr td,
    thead tr th {
      background: rgba(255, 255, 255, 0.5);
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 40px;
      &:nth-child(5),
      &:nth-child(6) {
        border-bottom: none;
      }
      &:nth-child(odd) {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
      }
      input[type="text"],
      input[type="number"] {
        width: 100%;
        max-width: 100px;
        height: 25px;
      }
    }
  }
`;

export const AddMenuContainer = styled.div`
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 50px;
  &.dark {
    background: #000;
    input[type="text"],
    select {
      background: #333;
      color: #fff;
    }
    option {
      background: #000;
    }
    button {
      background: rgba(37, 99, 235, 0.7);
    }
  }
  > span {
    width: 10%;
    text-align: center;
    display: block;
  }
  input[type="text"],
  select,
  button {
    border-radius: 5px;
    height: 44px;
    border: none;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  input[type="text"] {
    width: calc((100% - 300px) / 2);
    background: rgba(255, 255, 255, 0.5);
    &:focus {
      border: none !important;
      box-shadow: none;
      outline: none;
    }
  }
  select {
    width: calc((100% - 300px) / 2);
    background-color: rgba(255, 255, 255, 0.5);

    &:focus {
      outline: none;
      border: none !important;
      box-shadow: none;
    }
    option {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  button {
    width: 100px;
    background: #fff;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 20px;
    font-size: 14px;
    > span,
    button {
      width: 100px;
      height: 25px;
    }
    select,
    input[type="text"] {
      width: calc(100% - 120px);
      height: 25px;
    }
  }
`;

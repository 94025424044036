import { useOutletContext } from "react-router";

import BoardView from "../../../../common/board/boardView";
import ModernBanner from "../../shopping/component/themes/modern/banner";
import CuteBanner from "../../shopping/component/themes/cute/banner";

import { ModernNoticeViewContainer } from "../../shopping/component/styled/modernTheme";
import { CuteNoticeViewContainer } from "../../shopping/component/styled/cuteTheme";
import { ChicNoticeViewContainer } from "../../shopping/component/styled/chicTheme";
import SimpleBanner from "../../shopping/component/themes/simple/banner";
import { DefaultContaienr } from "../../shopping/component/styled/defaultTheme";

const ShoppingNoticeView = () => {
  const { theme } = useOutletContext();

  const NoticeView = () => {
    return (
      <main id="noticeView" className="sub_page sub_page_mobile_mt">
        <div className="subTop_bnr subTop_bnr_01">{theme === 1 && <span>NEWS</span>}</div>
        <article className="boundary pdt_70 pdb_70">
          <BoardView isShop={true} />
        </article>
      </main>
    );
  };

  const renderComponent = () => {
    switch (theme.toString()) {
      case "0":
        return (
          <DefaultContaienr>
            <NoticeView />
          </DefaultContaienr>
        );
      case "1":
        return (
          <ChicNoticeViewContainer>
            <NoticeView />
          </ChicNoticeViewContainer>
        );
      case "2":
        return (
          <ModernNoticeViewContainer>
            <ModernBanner />
            <NoticeView />
          </ModernNoticeViewContainer>
        );
      case "3":
        return (
          <CuteNoticeViewContainer>
            <CuteBanner />
            <NoticeView />
          </CuteNoticeViewContainer>
        );
      case "4":
        return (
          <CuteNoticeViewContainer>
            <SimpleBanner />
            <NoticeView />
          </CuteNoticeViewContainer>
        );
      case "sm":
        return (
          <CuteNoticeViewContainer style={{ paddingTop: 100 }}>
            <NoticeView />
          </CuteNoticeViewContainer>
        );
      default:
        return <NoticeView />;
    }
  };

  return <>{renderComponent()}</>;
};

export default ShoppingNoticeView;

import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { IoMdClose } from "react-icons/io";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { LuUser } from "react-icons/lu";
import { CiLogin } from "react-icons/ci";

import { SlidingSideMenuContainer } from "./styled/sideMenu";

export default function SlidingSideMenu({
  clickSideMenu,
  setClickSideMenu,
  url,
  menus,
  handleLogoutModal,
  theme,
  homepageInfo,
}) {
  const navigate = useNavigate();
  const [active, setActive] = useState("");

  const user = useSelector((state) => state?.user?.data[0]);

  const menuRef = useRef();
  const serviceRef = useRef();

  const links = {
    0: "company",
    1: "productsList",
    2: "portfolio",
    3: "shoppingNotice",
  };

  const clickWindowLink = (blog = false) => {
    const path = blog
      ? "https://blog.naver.com/ssongwonjang"
      : "https://map.naver.com/p/entry/place/13245702?placePath=%252Fhome%253Fentry%253Dplt&searchType=place&lng=127.4307144&lat=36.3321382&c=15.00,0,0,0,dh";
    window.open(path, "_blank");
  };

  const pageNavigation = (path) => {
    setClickSideMenu(false);
    navigate(path);
  };

  const toggleMenu = (type, ref) => {
    if (!ref.current) return;

    const height = ref.current.scrollHeight;

    if (active === type) {
      setActive("");
      ref.current.style.height = "0px";
    } else {
      setActive(type);
      ref.current.style.height = `${height}px`;
      if (menuRef.current && type !== "menus") menuRef.current.style.height = "0px";
      if (serviceRef.current && type !== "monoti") serviceRef.current.style.height = "0px";
    }
  };

  return (
    <SlidingSideMenuContainer className={`theme-${theme} ${clickSideMenu ? "active" : "inactive"}`}>
      <div className="sideMenuInfo">
        <button onClick={() => pageNavigation("/")} className="home">
          <span>MONOTI</span>
        </button>
        <button onClick={() => setClickSideMenu(false)} className="close">
          <IoMdClose />
        </button>
      </div>
      <div className="wrapper">
        <button className="toggle" onClick={() => toggleMenu("menus", menuRef)}>
          <span>메뉴</span>
          <span className="icon">{active === "menus" ? <IoChevronUp /> : <IoChevronDown />}</span>
        </button>
        {menus.length > 0 && (
          <ul ref={(el) => (menuRef.current = el)}>
            {menus.map((item) => (
              <li onClick={() => pageNavigation(`/shop/${url}/${links[item.menuType]}`)}>
                {item.afterMenuName ? item.afterMenuName : item.beforeMenuName}
              </li>
            ))}
            {homepageInfo?.cmpnyNo === 29787 && (
              <>
                <li onClick={() => clickWindowLink()}>고명한의원</li>
                <li onClick={() => clickWindowLink(true)}>블로그</li>
              </>
            )}
          </ul>
        )}
      </div>
      <div className="wrapper">
        <button className="toggle" onClick={() => toggleMenu("monoti", serviceRef)}>
          <span>서비스</span>
          <span className="icon">{active === "monoti" ? <IoChevronUp /> : <IoChevronDown />}</span>
        </button>
        <ul ref={serviceRef}>
          <li onClick={() => pageNavigation("/mypage")}>대시보드</li>
          {serviceMenu.map((item) => (
            <li onClick={() => pageNavigation(item.link)}>{item.label}</li>
          ))}
        </ul>
      </div>
      <ul className="tnb">
        {user ? (
          <>
            <li onClick={() => pageNavigation(`/shop/${url ? `${url}/` : ""}search?keyword=`)}>
              통합검색
            </li>
            {/* <li onClick={() => pageNavigation(`/shop${url ? `/${url}` : ""}/cart`)}>장바구니</li> */}
            <li onClick={() => handleLogoutModal()}>로그아웃</li>
          </>
        ) : (
          <>
            <li onClick={() => pageNavigation("/shop/login")}>로그인</li>
            <li onClick={() => pageNavigation("/type")}>회원가입</li>
          </>
        )}
      </ul>
    </SlidingSideMenuContainer>
  );
}

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { GrLinkPrevious, GrNext } from "react-icons/gr";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/css/pagination";
import { GrLinkNext } from "react-icons/gr";
import { useMediaQuery } from "react-responsive";

const Portfolio = ({ cmpnyNo, homepageInfo, url }) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [portfolio, setPortfolio] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  useEffect(() => {
    if (cmpnyNo) {
      getPortfolio(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getPortfolio = async (cmpnyNo) => {
    const url = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      setPortfolio(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwiperButton = (isPrev) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (isPrev) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  return portfolio && Array.isArray(portfolio) && portfolio.length > 0 ? (
    <div className="portfolio">
      <div className="label">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        PORTFOLIO
      </div>
      <div className="portfolios">
        <div className="info">
          작품
          <span>{homepageInfo?.cmpnyNm}의 다양한 작품들을 보여드릴게요</span>
        </div>
        <div className="content">
          <Swiper
            className="productSwiper"
            modules={[Pagination]}
            slidesPerView={isMobile ? 1 : isTablet ? 2.5 : 4}
            spaceBetween={50}
            loop={true}
            speed={2000}
            ref={swiperRef}>
            {portfolio.map((item) => (
              <SwiperSlide>
                <div
                  className="box"
                  onClick={() => navigate(`/shop/${url}/portfolioView/${item.portNo}`)}>
                  <img src={imgHandler(item.imgUrl)} alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button className="prev" onClick={() => handleSwiperButton(true)}>
            <GrLinkPrevious />
          </button>
          <button className="next" onClick={() => handleSwiperButton(false)}>
            <GrLinkNext />
          </button>
        </div>
        <div className="more" onClick={() => navigate(`/shop/${url}/portfolio?page=0`)}>
          <div className="line"></div>
          <button>더보기</button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Portfolio;

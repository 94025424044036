import React from "react";
import PageInfo from "../pageInfo";
import HomePageSettingMenu from "./component/common/homepageSettingMenu";
import RegisterTitle from "./component/register/registerTitle";
import RegisterMenu from "./component/register/registerMenu";
import RegisterForm from "./component/register/registerForm";
import RegisterIntroForm from "./component/register/registerIntroForm";
import RegisterPortForm from "./component/register/registerPortForm";
import RegisterNoticeForm from "./component/register/registerNoticeForm";

import { useSelector } from "react-redux";
import { Container } from "../styled/mypage";

export default function Register() {
  const index = useSelector((state) => state.compoIndex.register);
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <Container className={setting?.mode}>
      <PageInfo label="정보 등록" />
      <div className="content">
        <HomePageSettingMenu />
        <RegisterTitle />
        <RegisterMenu index={index} />
        <Components index={index} />
      </div>
    </Container>
  );
}

function Components({ index }) {
  return (
    <>
      {
        [<RegisterForm />, <RegisterIntroForm />, <RegisterPortForm />, <RegisterNoticeForm />][
          index
        ]
      }
    </>
  );
}

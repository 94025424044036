import styled from "styled-components";

export const FooterContainer = styled.footer`
  padding: 0;
  .footer_content {
    padding: 60px;
    font-size: 16px;
    > div {
      max-width: 1400px;
      margin: auto;
    }
    &:last-child {
      border-top: 1px solid #ddd;
    }
    .footer_info {
      font-size: 24px;
      font-weight: 700;
      color: #95e0c8;
      margin-bottom: 35px;
      img {
        max-width: 100px;
        max-height: 50px;
        object-fit: contain;
      }
    }
    .footer_list {
      display: flex;
      justify-content: space-between;
      ul {
        width: 30%;
        li {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .external_link {
        width: 100%;
        display: flex;
        font-size: 30px;
        gap: 15px;
        a {
          padding: 5px;
          border-radius: 7px;
          transition: 0.3s;
          background: #95e0c8;
          color: #333333;
          svg {
            display: block;
            filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.5));
          }
          &:hover {
            color: #fff;
            background: #5b8779;
            transform: scale(1.2);
            svg {
            }
          }
        }
        span {
          display: none;
        }
      }
    }
  }
  &.theme-1 {
    background: #000;
    .footer_content {
      display: flex;
      justify-content: flex-end;
      .footer_info,
      .footer_list {
        margin: 0;
      }
      .footer_info {
        width: 300px;
        color: #fff;
      }
      .footer_list {
        width: calc(100% - 300px);
      }
      .footer_list .external_link a {
        background: #fff;
        &:hover {
          color: #333;
        }
      }
    }
  }
  &.theme-2 {
    background: #fff9f1;
    color: #333;
    .footer_content .footer_info {
      color: #333;
    }
    .footer_list .external_link a {
      background: #8c8383;
      color: #fff;
      &:hover {
        background: #a79277;
      }
    }
  }
  &.theme-3 {
    background: #fff5e4;
    color: #555;
    .footer_content .footer_info {
      color: #555;
    }
    .footer_list .external_link a {
      background: #fff9f9;
      color: #555;
      &:hover {
        color: #fff;
        background: #ffc96f;
      }
    }
  }
  &.theme-4 {
    background: #fff;
    color: #000;
    .footer_content {
      border-top: 1px solid #969696;
      .footer_info {
        color: #000;
      }
    }
    .footer_list .external_link a {
      background: #002153;
      color: #fff;
      &:hover {
        background: #000;
      }
    }
  }
  &.theme-sm {
    margin-top: 0;
    background: rgba(0, 0, 0, 0.8);
    .footer_content {
      padding: 30px 20px;
      max-width: 1440px;
      margin: auto;
    }
    .footer_content:last-child {
      border-color: transparent;
    }
  }
  @media screen and (max-width: 1050px) {
    .footer_list {
      font-size: 14px;
    }

    &.theme-B .footer_content {
      display: block;
      .footer_info,
      .footer_list {
        width: 100%;
        margin: auto;
      }
      .footer_info {
        margin-bottom: 35px;
      }
    }
  }
`;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { GrNext } from "react-icons/gr";
import { useNavigate } from "react-router";

const Portfolio = ({ homepageInfo, cmpnyNo, url }) => {
  const navigate = useNavigate();
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getPortfolio(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getPortfolio = async (cmpnyNo) => {
    const url = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 2,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      setPortfolio(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  return portfolio && Array.isArray(portfolio) && portfolio.length > 0 ? (
    <div className="portfolio">
      <div className="info">
        <span>PORTFOLIO</span>
        <span>{homepageInfo?.cmpnyNm}의 포트폴리오를 보여드릴게요</span>
      </div>
      <div className="content">
        {portfolio.map((item) => (
          <div className="box">
            <img src={imgHandler(item.imgUrl)} alt="" />
            <div className="contentInfo">
              <span className="tit">{item.title}</span>
              <button onClick={() => navigate(`/shop/${url}/portfolioView/${item.portNo}`)}>
                <span>MORE</span>
                <div className="icon">
                  <GrNext />
                </div>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default Portfolio;

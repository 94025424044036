import moment from "moment";
import React, { useEffect, useState } from "react";
import { DetailReviewContainer } from "../styled/review";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import UpdatedAt from "./updatedAt";
import Rating from "./rating";
import { FaChevronLeft } from "react-icons/fa";
import { formatCommas } from "../function/store";
import Products from "../common/products";

const DetailReview = ({
  selected,
  setSelected,
  clickReview,
  content,
  setContent,
  isEdit,
  setIsEdit,
  handleImageClick,
  updateReview,
  products,
}) => {
  const user = useSelector((state) => state?.user?.data?.[0]);

  const validationCheck = () => {
    if (!content) {
      toast.error("댓글 내용이 존재하지 않습니다.");
      return false;
    }
    if (content.length > 500) {
      toast.error("최대 500자까지 입력 가능합니다.");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validationCheck()) return;
    await updateReply();
  };

  const updateReply = async () => {
    const isEdit = !!selected?.ownerReply;

    const url = `/erp/ss/${isEdit ? "update" : "create"}OwnerReply`;
    const body = {
      mberNo: user.mberNo,
      content,
      reviewId: selected.id,
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        console.log(res);
        toast.success(`리뷰가 ${isEdit ? "수정" : "작성"}되었습니다.`);
        await clickReview(selected.id);
        updateReview(selected.id, res.data);
      }
    } catch (error) {
      toast.error("잠시 후 다시 시도해주십시오.");
      console.error(error);
    }
  };

  const renderOwnerReply = () => {
    if (!selected.ownerReply || isEdit) {
      return (
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          maxLength={500}
          placeholder="답글을 입력해주세요. (최대 500자)"
        />
      );
    }
    return (
      <div className="cont">
        <span
          dangerouslySetInnerHTML={{
            __html: selected.ownerReply.content.replace(/\\n|\n/g, "<br />"),
          }}
        />
      </div>
    );
  };

  return selected ? (
    <DetailReviewContainer>
      <div className="review">
        <div className="info">
          <div>
            {selected && <Rating rating={selected.rating} />}
            <span className="mberNm">{selected ? `${selected.mberNm}님의` : "전체"} 리뷰</span>
            <UpdatedAt item={selected} />
          </div>
          <button onClick={() => setSelected(null)}>
            <FaChevronLeft />
          </button>
        </div>
        <div className="reviewContent">
          {selected.photos?.length > 0 && (
            <div className="images">
              {selected.photos.map((photo, index) => (
                <img
                  key={index}
                  src={imgHandler(photo)}
                  alt={`review-img-${index}`}
                  onClick={() => handleImageClick(selected.photos, index)}
                />
              ))}
            </div>
          )}
          <span className="cont">{selected.content}</span>
          <div className="products">
            <span>주문상품</span>
            <Products data={products} />
          </div>
        </div>
      </div>
      <div className="ownerReply">
        {renderOwnerReply()}
        <div className="action">
          <button
            onClick={!selected.ownerReply || isEdit ? handleSubmit : () => setIsEdit(true)}
            className={!selected.ownerReply || isEdit ? "active" : "inactive"}>
            {!selected.ownerReply || isEdit
              ? selected.ownerReply
                ? "수정완료"
                : "답글작성"
              : "수정하기"}
          </button>
          {!selected.ownerReply || isEdit ? (
            <span>{`${content.length}`}/500 자</span>
          ) : (
            <UpdatedAt item={selected.ownerReply} />
          )}
        </div>
      </div>
    </DetailReviewContainer>
  ) : null;
};

export default DetailReview;

import moment from "moment";
import React from "react";

const UpdatedAt = ({ item }) => {
  return (
    <span className="createdAt">
      {item?.updatedAt
        ? `${moment(item.updatedAt).format("YYYY-MM-DD HH:mm")} (수정)`
        : moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
    </span>
  );
};

export default UpdatedAt;

import { useMediaQuery } from "react-responsive";
import { Link, useOutletContext } from "react-router-dom";
import Product from "./product";
import Total from "./total";
import { useEffect, useState } from "react";
import Confirmation from "./modal/confirmation";

const temp = Array.from({ length: 3 }).map((_, index) => ({
  id: index + 1,
  image: "prdct_01",
  productName: "LED 60W 라잇 직사각 방등/거실등 660형 삼성모듈사용, 2년 무상 AS",
  price: 60000,
  brand: "노타이틀 부천지사",
  ship: 3000,
  amount: 1,
}));

const defaultModalConfig = {
  isOpen: false,
  type: "",
  title: "",
  message: [],
  handleConfirm: null,
  handleCancel: null,
};

export default function CartForm() {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const { url, theme } = useOutletContext();
  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState([...temp]);
  const [modalConfig, setModalConfig] = useState({ ...defaultModalConfig });
  const [modal, setModal] = useState(null);

  useEffect(() => {
    setModal(getModalContent());
  }, [modalConfig]);

  const getModalContent = () => {
    if (!modalConfig.isOpen) return null;

    const modalProps = {
      title: modalConfig.title,
      message: modalConfig.message,
      handleConfirm: modalConfig.handleConfirm || closeModal,
      handleCancel: modalConfig.handleCancel || closeModal,
      ...modalConfig,
    };

    switch (modalConfig.type) {
      case "confirmation":
        return <Confirmation {...modalProps} />;
      default:
        return null;
    }
  };

  const openModal = (options) => {
    setModalConfig({
      isOpen: true,
      ...options,
    });
  };

  const closeModal = () => {
    setModalConfig(() => ({ ...defaultModalConfig }));
  };

  const toggleCheckbox = (id) => {
    if (selected.includes(id)) {
      setSelected((data) => data.filter((item) => item !== id));
    } else {
      setSelected((data) => [...data, id]);
    }
  };

  const toggleAll = () => {
    if (selected.length === temp.length) {
      setSelected([]);
    } else {
      setSelected(temp.map((item) => item.id));
    }
  };

  const deleteSelected = () => {
    console.log("선택삭제", selected);
    setSelected([]);
  };

  const deleteProduct = (id) => {
    console.log("아이템 직접 삭제", id);
  };

  const changeAmount = (id, increment = true) => {
    setProducts((data) =>
      data.map((item) =>
        item.id === id
          ? {
              ...item,
              amount: increment ? item.amount + 1 : Math.max(1, item.amount - 1),
            }
          : item,
      ),
    );
  };

  return (
    <main id="contCart" className="sub_page sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01">{theme === 1 && <span>CART</span>}</div>
      <article className="boundary pdt_70" style={{ paddingBottom: isMobile ? 100 : 70 }}>
        <h2 className="font_36 pdb_30 font600 text_center">{theme === 2 ? "CART" : "장바구니"}</h2>
        <section id="secBrdcrmb" className="font_14 pdb_20 text_right">
          <Link to={`/shop/${url}`}>
            <i className="xi-home-o font_20"></i>
          </Link>
          <Link to={url ? `/shop/${url}/cart` : `/shop/cart`}>장바구니</Link>
        </section>
        <ul className="products">
          <li className="header">
            <label>
              <input
                type="checkbox"
                checked={selected.length === products.length && products.length > 0}
                onChange={toggleAll}
              />
              <span className="checkmark"></span>
              <span>전체 선택</span>
            </label>
            <button
              onClick={() => {
                if (!selected.length) return;

                openModal({
                  type: "confirmation",
                  title: "선택 삭제",
                  message: [`선택한 ${selected.length}개의 상품을 삭제하시겠습니까?`],
                  handleConfirm: () => deleteSelected(),
                  handleCancel: () => closeModal(),
                });
              }}>
              선택 삭제
            </button>
          </li>
          {products.map((item) => (
            <Product
              item={item}
              selected={selected}
              toggleCheckbox={toggleCheckbox}
              openModal={openModal}
              deleteProduct={deleteProduct}
              changeAmount={changeAmount}
            />
          ))}
        </ul>
        <Total selected={selected} />
      </article>
      {modalConfig?.isOpen && modal}
    </main>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  .joinTypeBox {
    width: 40%;
    text-align: center;
    border: 2px solid #0074ca;
    padding: 50px;
    border-radius: 15px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
    .tit {
      font-size: 25px;
      margin-bottom: 10px;
      p {
        font-weight: 500;
      }
      img {
        width: 100px;
      }
    }
    span {
      font-size: 15px;
    }
    button {
      display: block;
      border: none;
      margin: 20px auto;
      padding: 10px 30px;
      border-radius: 10px;
      font-size: 15px;
      background: #0074ca;
      color: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: normal;
    gap: 30px;
    padding: 50px 20px;
    .joinTypeBox {
      width: 100%;
      padding: 30px;
    }
  }
`;

export const LoginFormContainer = styled.div`
  padding: 50px 0;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  form {
    max-width: 400px;
    padding: 0px 20px;
    box-sizing: border-box;
    letter-spacing: -1px;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    user-select: none;
    > label {
      font-weight: 700;
    }
    input[type="text"],
    input[type="password"] {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #a7a7a7;
      padding: 15px;
    }
    .actions {
      width: 100%;
      font-size: 16px;
      > div {
        width: 100%;
        margin-bottom: 20px;
      }
      .options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        label {
          display: flex;
          align-items: center;
          gap: 10px;
          input[type="checkbox"] {
            display: none;
          }
          .checkmark {
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #d4d4d4;
            border-radius: 5px;
            background-color: transparent;
            transition: all 0.3s ease;
            position: relative;
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg) scale(0);
              opacity: 0;
              width: 6px;
              height: 10px;
              border: solid #fff;
              border-width: 0px 2px 2px 0px;
              transition: 0.3s;
            }
          }
          input[type="checkbox"]:checked ~ .checkmark {
            background: #0074ca;
            border: 1px solid #d4d4d4;
            &:after {
              opacity: 1;
              transform: translate(-50%, -55%) rotate(45deg) scale(1);
              border-color: #fff;
            }
          }
          &:hover .checkmark {
            background-color: #efefef;
          }
        }
      }
    }
    button {
      width: 100%;
      margin-bottom: 20px;
      height: 48px;
      border-radius: 5px;
      background: #000;
      color: #fff;
      border: none;
      font-weight: 700;
      &.kakao {
        background: #fee500;
        color: #000;
      }
    }
    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      span {
        color: #a7a7a7;
        &:first-child,
        &:last-child {
          display: inline-block;
          background: #a7a7a7;
          height: 1px;
          width: calc(50% - 30px);
        }
      }
    }
    .join {
      text-align: center;
      margin-bottom: 0;
      span {
        color: #a7a7a7;
        display: inline-block;
        margin-right: 20px;
      }
      a {
        color: #1d9dd8;
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .loginInfo {
      gap: 15px;
      margin-bottom: 20px;
      span {
        font-size: 18px;
      }
    }
    form {
      font-size: 16px;
      .actions {
        font-size: 14px;
        .options {
          justify-content: end;
        }
      }
    }
  }
`;

import styled from "styled-components";

export const HomepageSubMenuContainer = styled.div`
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0 auto;
  &.dark {
    ul {
      background: rgb(44, 45, 48);
      li.active {
        background: rgba(0, 0, 0, 0.5);
        a {
          color: #fff;
        }
      }
    }
  }
  ul {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    margin-bottom: 70px;
    height: 60px;
    line-height: 50px;
    padding: 5px;
    box-sizing: border-box;
    li {
      display: inline-block;
      cursor: pointer;
      width: 50%;
      text-align: center;
      border-radius: 20px;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: -1px;
      transition: 0.3s;
      a {
        transition: 0.3s;
        color: rgba(80, 80, 80, 0.5);
      }
      &.active {
        background: rgba(250, 250, 250, 0.5);
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      margin-bottom: 20px;
      height: 40px;
      line-height: 30px;
      border-radius: 5px;
      li {
        font-size: 14px;
        border-radius: 5px;
        font-weight: normal;
        &.active {
          a {
            color: #000;
          }
        }
      }
    }
  }
`;

export const HomepageFormContainer = styled.div`
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0 auto;
  font-size: 24px;
  &.dark {
    > div {
      color: #fff;
      table tbody tr td a {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
  > div {
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: -1px;
    a {
      color: rgba(0, 0, 0, 0.5);
    }
    table {
      width: 100%;
      colgroup col {
        &:first-child {
          width: 40%;
        }
        &:last-child {
          width: 100%;
        }
      }
      thead tr th,
      tbody tr td {
        height: 70px;
        text-align: center;
      }
      thead tr th {
      }
      tbody tr td {
        a {
          padding: 10px 20px;
          border-radius: 25px;
          display: inline-block;
          background: #fff;
          margin-right: 30px;
          i {
            margin-right: 5px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    > div {
      table {
        colgroup col {
          &:first-child {
            width: 100%;
          }
          &:last-child {
            width: 100%;
          }
        }
        thead {
          tr th {
            text-align: left;
            height: auto;
            line-height: normal;
            padding-bottom: 20px;
          }
        }
        tbody tr {
          td {
            font-size: 20px;
            display: block;
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: left;
            a {
              padding: 0 20px;
            }
            &:nth-child(even) {
              margin-bottom: 20px;
              height: 40px;
              line-height: 40px;
            }
          }
          &:last-child td {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    > div {
      padding: 20px;
      table {
        thead tr th {
          font-size: 16px;
          padding-bottom: 10px;
        }
        tbody tr {
          td {
            font-size: 12px;
            line-height: 30px;
            height: 30px;
            a {
              margin-right: 5px;
              padding: 0 10px;
            }
            &:nth-child(even) {
              margin-bottom: 15px;
              height: 25px;
              line-height: 25px;
            }
          }

          &:last-child td {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export const CompanyAddFormContainer = styled.div`
  &.dark {
    ul li strong {
      color: rgba(255, 255, 255, 0.7);
    }
    .viewer ul li button {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0 auto;
  font-size: 24px;
  .viewer {
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: -1px;
    ul {
      li {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        button {
          width: 160px;
          height: 63px;
          background: #e5e5e5;
          border-radius: 23px;
          border: none;
          color: rgba(0, 0, 0, 0.5);
        }
        strong {
          display: block;
          font-size: 28px;
          width: 100%;
        }
        input[type="file"] {
          display: none;
        }
        input[type="text"] {
          width: 100%;
          border: none;
          height: 63px;
          border-radius: 20px;
          padding: 0 20px;
          background: rgba(255, 255, 255, 0.5);
          &:focus {
            border: none !important;
            box-shadow: none;
          }
        }
        &.cmpnyNm {
        }
        &.bizrno {
          input[type="text"] {
            width: calc(100% - 180px);
          }
        }
        &.file {
          label {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 20px;
          }
          span {
            display: inline-block;
            max-width: calc(100% - 180px);
            height: 63px;
            width: 500px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 20px;
            padding: 0 20px;
          }
        }
      }
    }
    .message {
      margin-top: 50px;
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    .viewer {
      ul li {
        font-size: 14px;
        margin-bottom: 20px;
        gap: 10px;
        strong {
          font-size: 16px;
        }
        input[type="text"] {
          height: 37px;
          border-radius: 10px;
        }
        button {
          height: 37px;
          width: 96px;
          border-radius: 10px;
        }
        &.bizrno {
          gap: 10px;
          input[type="text"] {
            width: calc(100% - 106px);
          }
        }
        &.file {
          label {
            gap: 10px;
          }
          span {
            border-radius: 10px;
            max-width: none;
            height: 37px;
            width: calc(100% - 106px);
          }
        }
      }
      .message {
        font-size: 10px;
        margin-top: 30px;
      }
    }
  }
`;

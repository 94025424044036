import { IoIosCloudy, IoIosUmbrella, IoMdSnow } from "react-icons/io";
import { IoSunny } from "react-icons/io5";
import { BsCloudDrizzleFill, BsFillCloudsFill } from "react-icons/bs";
import { FaCloudSunRain } from "react-icons/fa";

export const weatherStates = {
  0: { text: "맑음", icon: "2600", color: "#dab202" },
  1: { text: "구름많음", icon: "2601", color: "#0d5781" },
  2: { text: "흐림", icon: "2601", color: "#173747" },
  3: { text: "비", icon: "1F327", color: "#028bda" },
  4: { text: "비/눈", icon: "1F328", color: "#028bda" },
  5: { text: "눈", icon: "2744", color: "#243ab6" },
  6: { text: "소나기", icon: "2614", color: "#8e03aa" },
};

export const holidayList = [
  {
    month: 1,
    day: 1,
    name: "새해",
    icon: "/img/holiday_0101.png",
    desc: '새해 첫날 또는 양력 설, 신정(新正)은 양력 1월 1일, 새해가 밝았음을 기념하는 명절이자 축하의 의미를 담고 있는 날이다. 대한민국에서는 "관공서의 공휴일에 관한 규정"에 따른 법정 공휴일로 지정되어 있다.',
    isPublicHoliday: true,
  },
  {
    month: 2,
    day: 28,
    name: "2·28민주운동 기념일",
    desc: "1960년 2월 28일 당국이 야당의 선거유세장에 가지 못하도록 일요일에 등교조치한 데 반발, 대구시내 고등학생들이 시위를 벌인 사건을 기념한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 3,
    day: 1,
    name: "삼일절",
    icon: "/img/holiday_0301.png",
    desc: "삼일절(三一節)은 대한민국의 국경일로, 일제강점기였던 1919년 3월 1일(음력 1월 29일 토요일) 당시 한반도의 민중들이 일본 제국의 지배에 항거해 한국의 독립을 선언한 것을 기념하는 날이다.",
    isPublicHoliday: true,
  },
  {
    month: 3,
    day: 3,
    name: "납세자의 날",
    desc: "국민의 납세정신 계몽과 세수 증대를 목적으로 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 3,
    day: 8,
    name: "3·8민주의거 기념일",
    desc: "1960년 이승만 정부의 독재와 부정부패, 인권유린에 대항하여 대전에서 최초로 일어난 학생운동",
    isPublicHoliday: false,
  },
  {
    month: 3,
    day: 15,
    name: "3·15의거 기념일",
    desc: "자유당 정권의 3.15부정선거에 반발하여 마산에서 일어난 대규모 시위로, 4.19 혁명의 도화선이 된 사건",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 3,
    name: "4·3희생자 추념일",
    desc: "1948년 4월 3일 남한만의 단독정부 수립에 반대한 남로당 제주도당의 무장봉기와 미 군정의 강압이 계기가 되어 제주도에서 일어난 민중항쟁의 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 5,
    name: "식목일",
    desc: "나무 심기를 통해 쾌적한 생활환경을 조성하고 산림자원 육성을 촉진하기 위해 제정된 날",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 7,
    name: "보건의 날",
    desc: "국민 보건의식을 향상시키고 보건의료 및 복지 분야의 종사자를 격려하기 위하여 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 11,
    name: "대한민국 임시정부 수립 기념일",
    desc: "3·1운동 직후 건립된 대한민국임시정부의 법통과 역사적 의의를 기리기 위해 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 19,
    name: "4·19혁명 기념일",
    desc: "1960년 4월 19일 학생과 시민이 중심 세력이 되어 일으킨 반독재 민주주의 운동을 기념하는 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 20,
    name: "장애인의 날",
    desc: "장애인에 대한 이해와 장애인의 재활 의욕을 고취하고, 복지 증진의 계기를 마련하기 위해 제정한 날",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 21,
    name: "과학의 날",
    desc: "과학기술의 발전을 위하여 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 22,
    name: "정보통신의 날",
    desc: "정보통신의 중요성과 의의를 높이고 정보통신산업의 발전을 다짐하며, 관계종사원의 노고를 위로하려 제정한 날",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 25,
    name: "법의 날",
    desc: "국민의 준법정신을 함양하고 법의 존엄성을 고취하기 위한 날",
    isPublicHoliday: false,
  },
  {
    month: 4,
    day: 28,
    name: "충무공 이순신 탄신일",
    desc: "충무공 이순신 장군의 충의를 길이 빛내고자 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 5,
    day: 1,
    name: "근로자의 날",
    desc: "노동자의 열악한 노동조건을 개선하고 지위를 향상시키기 위해 각국의 노동자들이 연대의식을 다지기 위한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 5,
    day: 5,
    name: "어린이날",
    icon: "/img/holiday_0505.png",
    desc: `어린이날은 어린이(유아, 초등학생)를 기념하는 날로, 세계 여러 나라에서 제정되는 기념일이다.`,
    isPublicHoliday: true,
  },
  {
    month: 5,
    day: 8,
    name: "어버이 날",
    desc: "매년 5월 8일 어버이의 은혜에 감사하고, 어른과 노인을 공경하는 경로효친의 전통적 미덕을 기리는 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 5,
    day: 11,
    name: "동학농민혁명 기념일",
    desc: "1894년 5월 11일 황토현 전승일을 기억하고 부패정치와 외세에 맞서 일어난 동학농민혁명을 기리기 위한 날",
    isPublicHoliday: false,
  },
  {
    month: 5,
    day: 15,
    name: "스승의 날",
    desc: "교권존중과 스승공경의 사회적 풍토를 조성하여 교원의 사기진작과 사회적 지위향상을 위하여 지정된 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 5,
    day: 18,
    name: "5·18민주화운동 기념일",
    desc: "1980년 5월 18일을 전후하여 광주(光州)와 전남(全南) 일원에서 신군부의 집권 음모를 규탄하고 민주주의의 실현을 요구하며 전개한 민중항쟁의 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 5,
    day: 21,
    name: "부부의 날",
    desc: "부부관계의 소중함을 일깨우고 화목한 가정을 일궈 가자는 취지로 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 5,
    day: 31,
    name: "바다의 날",
    desc: "바다 관련 산업의 중요성과 의의를 높이고 국민의 해양사상을 고취하며, 관계 종사원들의 노고를 위로할 목적으로 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 6,
    day: 1,
    name: "의병의 날",
    desc: "의병의 역사적 가치를 일깨워 애국정신을 계승하고자 2010년 5월 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 6,
    day: 5,
    name: "환경의 날",
    desc: "1972년 6월 스웨덴 스톡홀름에서 열린 '유엔인간환경회의'에서 국제사회가 지구환경보전을 위해 공동노력을 다짐하며 제정한 날",
    isPublicHoliday: false,
  },
  {
    month: 6,
    day: 6,
    name: "현충일",
    icon: "/img/holiday_0606.png",
    desc: '현충일(顯忠日)은 "충렬을 드러내는 날"이라는 뜻으로, 매년 6월 6일 민족과 국가의 수호 및 발전에 기여하고 애국애족한 열사들의 애국심과 국토 방위에 목숨을 바치고 나라를 위해 희생된 모든 이들의 충성을 기념하기 위한 법정공휴일이다.',
    isPublicHoliday: true,
  },
  {
    month: 6,
    day: 10,
    name: "6·10민주항쟁 기념일",
    desc: "1979년 12·12사태로 정권을 잡은 전두환 군사정권의 장기집권을 저지하기 위해 일어난 범국민적 민주화운동의 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 6,
    day: 25,
    name: "6·25전쟁일",
    desc: "1950년 6월 25일 새벽에 북한 공산군이 남북군사분계선이던 38선 전역에 걸쳐 불법 남침함으로써 일어난 한반도에서의 전쟁",
    isPublicHoliday: false,
  },
  {
    month: 6,
    day: 28,
    name: "철도의 날",
    desc: "기간교통수단으로서의 철도의 의의를 높이고 종사원들의 노고를 위로하기 위하여 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 8,
    day: 15,
    name: "광복절",
    icon: "/img/holiday_0815.png",
    desc: `광복절(光復節, National Liberation Day)은 영예롭게 회복한(光復) 날(節)이란 뜻으로, 1945년 8월 15일에 일본 제국의 패망으로 한국이 식민지배로부터 해방된 8.15 광복을 기념하고, 3년 뒤인 1948년 8월 15일에 대한민국 정부가 수립된 것을 경축하는 대한민국의 법정 공휴일이다. 1949년 10월 1일 "국경일에 관한 법률"이 제정되어 국경일로 지정되었으며, 3·1절, 제헌절, 개천절, 한글날과 함께 대한민국의 5대 국경일이다.`,
    isPublicHoliday: true,
  },
  {
    month: 9,
    day: 7,
    name: "푸른하늘의 날",
    desc: "대기환경의 중요성을 알리고, 기후변화에 대한 이해와 관심을 높이기 위해 지정한 날",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 1,
    name: "국군의 날",
    desc: "한국군의 위용과 전투력을 국내외에 과시하고 국군장병의 사기를 높이기 위하여 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 2,
    name: "노인의 날",
    desc: "경로효친 사상을 앙양하고, 전통문화를 계승 발전시켜온 노인들의 노고를 치하하기 위해 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 3,
    name: "개천절",
    icon: "/img/holiday_1003.png",
    desc: '개천절(開天節)은 "하늘이 열린 날"이라는 의미로서 단군왕검이 한민족 최초의 국가인 고조선을 건국하여 역사를 개창한 것을 기념하는 날이다. 날짜는 10월 3일로, "관공서의 공휴일에 관한 규정"에 따른 법정 공휴일이면서 5대 국경일이므로 태극기를 게양한다. 과거에는 음력 10월 3일에 기념했지만, 현재는 양력 10월 3일로 바뀌었다.',
    isPublicHoliday: true,
  },
  {
    month: 10,
    day: 9,
    name: "한글날",
    icon: "/img/holiday_1009.png",
    desc: '한글날은 한국 역사상 위대한 인물 중 하나였던 세종대왕이 1446년 반포한 훈민정음의 날을 기념하여 한글 및 그 창제 원리의 독창성과 과학성을 널리 알리고 한글 사랑 의식을 높이기 위한 국경일이다. 매년 10월 9일에 기념한다. 한글날은 "국경일에 관한 법률"에 따른 법정 공휴일이며, 5대 국경일이기에 태극기를 게양하는 날이다.',
    isPublicHoliday: true,
  },
  {
    month: 10,
    day: 5,
    name: "세계 한인의 날",
    desc: "재외동포의 거주국 내 권익 신장과 역량을 강화하고 한민족으로서의 정체성 및 자긍심을 고양하며, 동포 간 화합 및 모국과 동포사회 간 호혜적 발전을 도모하기 위한 날",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 8,
    name: "재향군인의 날",
    desc: "국토방위에 대한 인식을 새롭게 하고 재향군인 상호간의 친목을 도모하기 위하여 지정한 날",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 15,
    name: "체육의 날",
    desc: "국민들이 체육활동을 통해 체력을 증진하고 여가시간을 건전하게 활용할 수 있도록 제정한 날",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 21,
    name: "경찰의 날",
    desc: "건국·구국·호국 경찰로서 역경과 시련을 극복한 경찰사를 되새기고 선진조국 창조의 역군으로서 새로운 결의를 다지기 위해 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 24,
    name: "국제연합일",
    desc: "1945년 10월 24일 미국 샌프란시스코에서 국제연합이 조직된 것을 세계적으로 기념하는 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 28,
    name: "교정의 날",
    desc: "대한민국 법무부 주관으로 교정 관련 종사자들의 사기를 높이고 재소자의 갱생의지를 촉진하는 행사를 개최하는 대한민국의 기념일이다.",
    isPublicHoliday: false,
  },
  {
    month: 10,
    day: 29,
    name: "지방자치의 날",
    desc: "지방자치에 관한 국민의 관심을 높이고, 그 성과를 공유하기 위해 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 11,
    day: 3,
    name: "학생독립운동 기념일",
    desc: "학생들이 일제식민지정책에 항거한 날을 기념하여 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 11,
    day: 11,
    name: "농업인의 날",
    desc: "농민들의 긍지와 자부심을 고취시키고 농업(農業)의 중요성을 되새기는 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 11,
    day: 17,
    name: "순국선열의 날",
    desc: "국권 회복을 위하여 헌신·희생하신 순국선열의 독립정신과 희생정신을 후세에 길이 전하고 위훈을 기리는 날",
    isPublicHoliday: false,
  },
  {
    month: 12,
    day: 3,
    name: "소비자의 날",
    desc: "소비자의 권리 의식을 신장시키고, 소비자 보호에 대한 인식을 높이기 위해 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 12,
    day: 5,
    name: "무역의 날",
    desc: "무역의 균형 발전과 무역입국의 의지를 다지기 위해 제정한 법정기념일",
    isPublicHoliday: false,
  },
  {
    month: 12,
    day: 25,
    name: "크리스마스",
    icon: "/img/holiday_1225.png",
    desc: "성탄절 또는 기독탄신일은 예수 그리스도의 탄생을 기념하는 축일이다. 여호와의 증인 등을 제외하고 대부분의 기독교 종파들은 이 기념일을 지킨다. 날짜는 지역 불문 12월 25일(엄밀하게는 12월 24일 일몰부터 25일 자정까지)이지만, 서방은 그레고리오력 12월 25일, 동방은 율리우스력 12월 25일(=그레고리오력 1월 7일)을 성탄절로 지낸다.",
    isPublicHoliday: true,
  },
  {
    month: 12,
    day: 27,
    name: "원자력 안전 및 진흥의 날",
    desc: "원자력 안전을 고취하고 국내 원자력 분야 종사자의 사기를 진작하기 위해 제정한 법정기념일",
    isPublicHoliday: false,
  },

  /*
   *
   * 데이터 추가
   *
   */
  {
    startDt: "2022-03-09 00:00:00",
    endDt: "2022-03-09 23:59:00",
    name: "20대 대통령 선거일",
    desc: "대한민국 제20대 대통령 선거는 대한민국의 제20대 대통령을 선출하기 위해 실시된 대한민국의 대통령 선거이다. 선거 결과 국민의힘 윤석열 후보가 당선되었다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2022-05-08 00:00:00",
    endDt: "2022-05-08 23:59:00",
    name: "부처님오신날",
    desc: "부처님 오신 날, 또는 석가탄신일은 불교에서 석가모니가 탄생한 날로, 음력 4월 8일이다. 불교의 연중행사 가운데 가장 큰 명절로서, 기념법회·연등놀이·관등놀이·방생·탑돌이 등 각종 기념행사가 열린다. 대한민국뿐만 아니라 중국·일본·인도 등지에서도 연등놀이가 행해진다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2022-01-31 00:00:00",
    endDt: "2022-02-02 23:59:00",
    name: "설날",
    desc: "설날은 음력 새해의 첫날을 기념하는 명절이다. 현대 사회에서 전 세계는 그레고리력을 표준 달력으로 쓰기 때문에 공식적인 새해의 첫날은 양력 1월 1일이다. 따라서 전 세계 대부분의 국가들은 양력 1월 1일을 새해 첫날이자 설날로 기념한다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2022-09-09 00:00:00",
    endDt: "2022-09-11 23:59:00",
    name: "추석",
    desc: "추석 또는 한가위는 음력 8월 15일에 치르는 행사로 설날과 더불어 한국의 주요 연휴이자 민족 최대의 명절이다. 추석은 농경사회였던 예로부터 지금까지 한국인에게 가장 중요한 연중 최대 명절이다. 가배일, 한가위, 팔월 대보름 등으로도 부른다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2022-09-12 00:00:00",
    endDt: "2022-09-12 23:59:00",
    name: "추석(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2022-10-10 00:00:00",
    endDt: "2022-10-10 23:59:00",
    name: "한글날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2023-05-27 00:00:00",
    endDt: "2023-05-27 23:59:00",
    name: "부처님오신날",
    desc: "부처님 오신 날, 또는 석가탄신일은 불교에서 석가모니가 탄생한 날로, 음력 4월 8일이다. 불교의 연중행사 가운데 가장 큰 명절로서, 기념법회·연등놀이·관등놀이·방생·탑돌이 등 각종 기념행사가 열린다. 대한민국뿐만 아니라 중국·일본·인도 등지에서도 연등놀이가 행해진다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2023-05-29 00:00:00",
    endDt: "2023-05-29 23:59:00",
    name: "부처님오신날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2023-01-21 00:00:00",
    endDt: "2023-01-23 23:59:00",
    name: "설날",
    desc: "설날은 음력 새해의 첫날을 기념하는 명절이다. 현대 사회에서 전 세계는 그레고리력을 표준 달력으로 쓰기 때문에 공식적인 새해의 첫날은 양력 1월 1일이다. 따라서 전 세계 대부분의 국가들은 양력 1월 1일을 새해 첫날이자 설날로 기념한다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2023-01-24 00:00:00",
    endDt: "2023-01-24 23:59:00",
    name: "설날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2023-10-02 00:00:00",
    endDt: "2023-10-02 23:59:00",
    name: "임시공휴일",
    desc: "임시공휴일은 원래 공휴일은 아니지만 국가에 중요한 행사가 발생했을 경우 정부가 지정하는 휴일로 국무회의의 심의와 의결을 거쳐 결정된다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2023-09-28 00:00:00",
    endDt: "2023-09-30 23:59:00",
    name: "추석",
    desc: "추석 또는 한가위는 음력 8월 15일에 치르는 행사로 설날과 더불어 한국의 주요 연휴이자 민족 최대의 명절이다. 추석은 농경사회였던 예로부터 지금까지 한국인에게 가장 중요한 연중 최대 명절이다. 가배일, 한가위, 팔월 대보름 등으로도 부른다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2024-04-10 00:00:00",
    endDt: "2024-04-10 23:59:00",
    name: "22대 국회의원 선거일",
    desc: "대한민국 제22대 국회의원 선거는 2024년 4월 10일에 실시될 예정인 대한민국의 국회의원 선거이다. 국회의원 총선거와 더불어 2024년 대한민국 재보궐선거도 동시에 치러질 예정이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2024-05-15 00:00:00",
    endDt: "2024-05-15 23:59:00",
    name: "부처님오신날",
    desc: "부처님 오신 날, 또는 석가탄신일은 불교에서 석가모니가 탄생한 날로, 음력 4월 8일이다. 불교의 연중행사 가운데 가장 큰 명절로서, 기념법회·연등놀이·관등놀이·방생·탑돌이 등 각종 기념행사가 열린다. 대한민국뿐만 아니라 중국·일본·인도 등지에서도 연등놀이가 행해진다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2024-02-09 00:00:00",
    endDt: "2024-02-11 23:59:00",
    name: "설날",
    desc: "설날은 음력 새해의 첫날을 기념하는 명절이다. 현대 사회에서 전 세계는 그레고리력을 표준 달력으로 쓰기 때문에 공식적인 새해의 첫날은 양력 1월 1일이다. 따라서 전 세계 대부분의 국가들은 양력 1월 1일을 새해 첫날이자 설날로 기념한다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2024-02-12 00:00:00",
    endDt: "2024-02-12 23:59:00",
    name: "설날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2024-05-06 00:00:00",
    endDt: "2024-05-06 23:59:00",
    name: "어린이날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2024-09-16 00:00:00",
    endDt: "2024-09-18 23:59:00",
    name: "추석",
    desc: "추석 또는 한가위는 음력 8월 15일에 치르는 행사로 설날과 더불어 한국의 주요 연휴이자 민족 최대의 명절이다. 추석은 농경사회였던 예로부터 지금까지 한국인에게 가장 중요한 연중 최대 명절이다. 가배일, 한가위, 팔월 대보름 등으로도 부른다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2025-05-05 00:00:00",
    endDt: "2025-05-05 23:59:00",
    name: "부처님오신날",
    desc: "부처님 오신 날, 또는 석가탄신일은 불교에서 석가모니가 탄생한 날로, 음력 4월 8일이다. 불교의 연중행사 가운데 가장 큰 명절로서, 기념법회·연등놀이·관등놀이·방생·탑돌이 등 각종 기념행사가 열린다. 대한민국뿐만 아니라 중국·일본·인도 등지에서도 연등놀이가 행해진다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2025-01-28 00:00:00",
    endDt: "2025-01-30 23:59:00",
    name: "설날",
    desc: "설날은 음력 새해의 첫날을 기념하는 명절이다. 현대 사회에서 전 세계는 그레고리력을 표준 달력으로 쓰기 때문에 공식적인 새해의 첫날은 양력 1월 1일이다. 따라서 전 세계 대부분의 국가들은 양력 1월 1일을 새해 첫날이자 설날로 기념한다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2025-05-06 00:00:00",
    endDt: "2025-05-06 23:59:00",
    name: "어린이날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2025-10-05 00:00:00",
    endDt: "2025-10-07 23:59:00",
    name: "추석",
    desc: "추석 또는 한가위는 음력 8월 15일에 치르는 행사로 설날과 더불어 한국의 주요 연휴이자 민족 최대의 명절이다. 추석은 농경사회였던 예로부터 지금까지 한국인에게 가장 중요한 연중 최대 명절이다. 가배일, 한가위, 팔월 대보름 등으로도 부른다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2025-10-08 00:00:00",
    endDt: "2025-10-08 23:59:00",
    name: "추석(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2026-10-05 00:00:00",
    endDt: "2026-10-05 23:59:00",
    name: "개천절(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2026-08-17 00:00:00",
    endDt: "2026-08-17 23:59:00",
    name: "광복절(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2026-05-24 00:00:00",
    endDt: "2026-05-24 23:59:00",
    name: "부처님오신날",
    desc: "부처님 오신 날, 또는 석가탄신일은 불교에서 석가모니가 탄생한 날로, 음력 4월 8일이다. 불교의 연중행사 가운데 가장 큰 명절로서, 기념법회·연등놀이·관등놀이·방생·탑돌이 등 각종 기념행사가 열린다. 대한민국뿐만 아니라 중국·일본·인도 등지에서도 연등놀이가 행해진다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2026-05-25 00:00:00",
    endDt: "2026-05-25 23:59:00",
    name: "부처님오신날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2026-03-02 00:00:00",
    endDt: "2026-03-02 23:59:00",
    name: "삼일절(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2026-02-16 00:00:00",
    endDt: "2026-02-18 23:59:00",
    name: "설날",
    desc: "설날은 음력 새해의 첫날을 기념하는 명절이다. 현대 사회에서 전 세계는 그레고리력을 표준 달력으로 쓰기 때문에 공식적인 새해의 첫날은 양력 1월 1일이다. 따라서 전 세계 대부분의 국가들은 양력 1월 1일을 새해 첫날이자 설날로 기념한다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2026-09-24 00:00:00",
    endDt: "2026-09-26 23:59:00",
    name: "추석",
    desc: "추석 또는 한가위는 음력 8월 15일에 치르는 행사로 설날과 더불어 한국의 주요 연휴이자 민족 최대의 명절이다. 추석은 농경사회였던 예로부터 지금까지 한국인에게 가장 중요한 연중 최대 명절이다. 가배일, 한가위, 팔월 대보름 등으로도 부른다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2027-10-04 00:00:00",
    endDt: "2027-10-04 23:59:00",
    name: "개천절(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2027-08-16 00:00:00",
    endDt: "2027-08-16 23:59:00",
    name: "광복절(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2027-05-13 00:00:00",
    endDt: "2027-05-13 23:59:00",
    name: "부처님오신날",
    desc: "부처님 오신 날, 또는 석가탄신일은 불교에서 석가모니가 탄생한 날로, 음력 4월 8일이다. 불교의 연중행사 가운데 가장 큰 명절로서, 기념법회·연등놀이·관등놀이·방생·탑돌이 등 각종 기념행사가 열린다. 대한민국뿐만 아니라 중국·일본·인도 등지에서도 연등놀이가 행해진다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2027-02-06 00:00:00",
    endDt: "2027-02-08 23:59:00",
    name: "설날",
    desc: "설날은 음력 새해의 첫날을 기념하는 명절이다. 현대 사회에서 전 세계는 그레고리력을 표준 달력으로 쓰기 때문에 공식적인 새해의 첫날은 양력 1월 1일이다. 따라서 전 세계 대부분의 국가들은 양력 1월 1일을 새해 첫날이자 설날로 기념한다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2027-02-09 00:00:00",
    endDt: "2027-02-09 23:59:00",
    name: "설날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2027-09-14 00:00:00",
    endDt: "2027-09-16 23:59:00",
    name: "추석",
    desc: "추석 또는 한가위는 음력 8월 15일에 치르는 행사로 설날과 더불어 한국의 주요 연휴이자 민족 최대의 명절이다. 추석은 농경사회였던 예로부터 지금까지 한국인에게 가장 중요한 연중 최대 명절이다. 가배일, 한가위, 팔월 대보름 등으로도 부른다.",
    isPublicHoliday: true,
  },
  {
    startDt: "2027-10-11 00:00:00",
    endDt: "2027-10-11 23:59:00",
    name: "한글날(대체 휴일)",
    desc: "대체휴일제는 명절이 아닌 공휴일이 일요일과 겹칠 때 그 다음날 평일을 공휴일로 정해 하루를 더 쉬게 하자는 취지의 제도이다.",
    isPublicHoliday: true,
  },
];

export const icons = [
  { src: "artist_palette_3d.png", name: "팔레트" },
  { src: "badminton_3d.png", name: "배드민턴" },
  { src: "bank_3d.png", name: "은행" },
  { src: "baseball_3d.png", name: "야구" },
  { src: "basketball_3d.png", name: "농구" },
  { src: "beer_mug_3d.png", name: "맥주잔" },
  { src: "birthday_cake_3d.png", name: "케이크" },
  { src: "bottle_with_popping_cork_3d.png", name: "병" },
  { src: "bouquet_3d.png", name: "꽃다발" },
  { src: "bowling_3d.png", name: "볼링" },
  { src: "clinking_beer_mugs_3d.png", name: "맥주" },
  { src: "credit_card_3d.png", name: "카드" },
  { src: "flag_in_hole_3d 1.png", name: "골프" },
  { src: "fork_and_knife_with_plate_3d.png", name: "접시" },
  { src: "guide_dog_3d.png", name: "강아지" },
  { src: "hospital_3d.png", name: "병원" },
  { src: "hot_beverage_3d.png", name: "커피" },
  { src: "monorail_3d.png", name: "기차" },
  { src: "newspaper_3d.png", name: "신문" },
  { src: "open_book_3d.png", name: "책" },
  { src: "pill_3d.png", name: "알약" },
  { src: "ping_pong_3d.png", name: "탁구" },
  { src: "popcorn_3d.png", name: "팝콘" },
  { src: "shopping_cart_3d.png", name: "쇼핑 카트" },
  { src: "soccer_ball_3d.png", name: "축구공" },
  { src: "teacup_without_handle_3d.png", name: "찻잔" },
  { src: "woman_with_veil_3d_light.png", name: "여성" },
  { src: "writing_hand_3d_light.png", name: "글" },
];

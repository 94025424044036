import React, { useEffect } from "react";
import { PreviewModalContainer } from "../styled/themeSetting";

const PreviewModal = ({ preview, setPreview }) => {
  return (
    <PreviewModalContainer onClick={() => setPreview(null)}>
      <div className="content">
        <img src={`/img/shopTheme/shop_${preview.label}.png`} alt={preview.label} />
      </div>
    </PreviewModalContainer>
  );
};

export default PreviewModal;

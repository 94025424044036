// 상생 바로가기
export const specialShortcuts = [
  { icon: "sungsimdang", url: "/mypage/kiosk", label: "빵보관소" },
  { icon: "mutualBenefit", url: "/mypage/store", label: "상생매장" },
];

// 모노티 서비스 바로가기
export const monotiService = [
  { icon: "project", url: "/mypage/projectList", label: "프로젝트" },
  { icon: "scheduler", url: "/mypage/scheduler", label: "캘린더" },
  { icon: "group", url: "/group", label: "모임" },
];

// 상생 바로가기 보여주기 여부
export const checkKioskCond = (cmpnys, gbnCond = null) => {
  if (!cmpnys || !Array.isArray(cmpnys)) return false;

  const kioskCompanies = gbnCond ? [10001, 16421] : [10001, 16421, 16786];

  return cmpnys.some(
    (cmpny) => kioskCompanies.includes(cmpny.cmpnyNo) || (gbnCond && cmpny.gbn === gbnCond),
  );
};

// 바로가기 버튼 기본값
export const checkDefaultQuick = (data, company) => {
  const filterKiosk = specialShortcuts.filter((_, index) =>
    checkKioskCond(company, !index ? null : "ss"),
  );

  const defaultShortcut = [...filterKiosk, ...monotiService];

  const filtered = defaultShortcut.filter(
    (requiredShortcut) =>
      !data.some(
        (shortcut) =>
          shortcut.icon === requiredShortcut.icon &&
          shortcut.url === requiredShortcut.url &&
          shortcut.label === requiredShortcut.label,
      ),
  );

  const result = filtered.length > 0 ? [...filtered, ...data] : data;
  return result.filter((item) => item.url !== "/mypage/mutualBenefit");
};

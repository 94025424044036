import React from "react";
import { useRegex } from "../../../../../hooks/useRegex";

const Member = ({ item, editWorkStatus }) => {
  const { TelFormat } = useRegex();

  return (
    <li>
      <div className="dot">
        <div></div>
      </div>
      <div className="mberNm">{item.mberNm}</div>
      <div className="username">{item.userId}</div>
      <div className="moblphonNo">{TelFormat(item.mberTel)}</div>
      <div className="work">
        <div
          className="tabs"
          onClick={() => editWorkStatus(item)}
          style={{
            background: item.isWorking ? "rgba(37, 99, 235, 0.7)" : "#bbb",
          }}>
          <span
            className="glider"
            style={{
              transform: `translate(${item.isWorking ? "100%, -50%" : "0, -50%"})`,
            }}></span>
        </div>
      </div>
    </li>
  );
};

export default Member;

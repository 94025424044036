import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";

import { EventContentBox, EventLayerContainer } from "../styled/scheduler";

import { AiOutlineClose } from "react-icons/ai";
import { TiArrowBackOutline } from "react-icons/ti";
import { LuListTodo } from "react-icons/lu";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";

import {
  isEventCategory,
  calculateModalHeight,
  calculateDday,
  updateTodos,
  transformTodo,
} from "../function/scheduler";

import EventDetailView from "./eventLayer/eventDetailView";
import EventGallery from "./eventLayer/eventGallery";
import { isOutsideCmpny } from "../function/common";

const EventLayer = ({
  eventPosition,
  setShowEvent,
  eventInfo,
  setEventInfo,
  handleModalOpen,
  handleEventComplete,
  getSchedule,
  handleScheduleCopy,
  searchTxt,
  images,
  setImages,
  originalImages,
  setOriginalImages,
  uploadImages,
  getImages,
}) => {
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const user = useSelector((state) => state?.user?.data[0]);
  const [viewType, setViewType] = useState(0);
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    getTodos();
  }, [eventInfo]);

  const isNotDday = eventInfo.dday === "N";
  const transformValue = (viewType * -100) / 2;
  const getEntryRoute = () =>
    `/mypage/${isNotDday ? "calendarEntry" : "ddayEntry"}${
      searchTxt ? `?keyword=${searchTxt}` : ""
    }`;

  const handleViewTypeChange = (newViewType) => {
    setViewType(viewType === newViewType ? 0 : newViewType);
  };

  // 투두 조회
  const getTodos = async () => {
    const url = "/api/todo/by-schedule";
    const body = { scheduleNo: eventInfo.scheduleNo };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      const todos = res.data.rtnModel;
      const updatedTodos = updateTodos(todos, eventInfo.memo);

      setTodos(updatedTodos);
    }
  };

  const isColor = (item) => {
    if (item.gbn === "C") {
      return "0074ca";
    }
    if (item.gbn === "G") {
      return "F46F02";
    }

    return item.color || "efefef";
  };

  const PreviewTitle = () => (
    <div className="previewTit">
      <h3
        className={`${eventInfo.gbn}${isOutsideCmpny(eventInfo, user) ? " outside" : ""}`}
        style={{
          borderLeft: isNotDday && `5px solid #${isColor(eventInfo)}`,
          paddingLeft: isNotDday && "4px",
          display: "flex",
          alignItems: "center",
        }}>
        {eventInfo.icon && (
          <img
            src={`/img/scheduler/scheduler_icon_${eventInfo.icon}`}
            alt=""
            style={{ width: 20 }}
          />
        )}
        <span
          className="title"
          style={{
            textDecoration: eventInfo.completeYn === "Y" && "line-through",
          }}>
          {eventInfo.title}
        </span>
        {!isNotDday && <span>{calculateDday(eventInfo.startDt)}</span>}
      </h3>
      {isNotDday && (
        <span>
          {`${isEventCategory(eventInfo.gbn)}일정`}
          {eventInfo.gbn !== "P" && `: ${eventInfo.orgNm}`}
        </span>
      )}
    </div>
  );

  const EventActions = () => (
    <div className="btnArea">
      {/* {(isSameUser(user, eventInfo) || eventInfo.managerNo === user.mberNo) && ( */}
      {(eventInfo.gbn !== "C" || eventInfo.orgNo === user.cmpnyNo) && (
        <>
          <button
            onClick={() =>
              navigate(getEntryRoute(), {
                state: { eventInfo, todoInfo: isNotDday ? transformTodo(todos) : null },
              })
            }>
            수정
          </button>
          <button onClick={() => handleModalOpen("eventDel")}>삭제</button>
        </>
      )}
      {/* )} */}
      <button onClick={isTodoExists}>복사</button>
      {/* {(isSameUser(user, eventInfo) || isEventAttendees(user, eventInfo)) && ( */}
      {(eventInfo.gbn !== "C" || eventInfo.orgNo === user.cmpnyNo) && (
        <button onClick={() => handleEventComplete(eventInfo.completeYn !== "Y" ? "Y" : "N")}>
          {eventInfo.completeYn !== "Y" ? "완료" : "완료취소"}
        </button>
      )}
      {/* )} */}
    </div>
  );

  const isTodoExists = () => {
    if (!eventInfo.todo || !eventInfo.todo.length) {
      handleScheduleCopy();
    } else {
      handleModalOpen("eventTodoCopy");
    }
  };

  const closeEvent = () => {
    if (images.some((item) => item.path.includes("data:image"))) {
      handleModalOpen("imgUpload");
    } else {
      setShowEvent(false);
    }
  };

  return (
    <EventLayerContainer
      onClick={(e) => e.currentTarget === e.target && closeEvent()}
      className={isDarkMode}>
      <div
        className="previewContent font_14"
        style={{
          top: eventPosition.y,
          left: eventPosition.x,
          height: viewType === 2 ? 500 : calculateModalHeight(user, eventInfo),
          width: viewType === 2 ? 500 : 430,
        }}>
        <button className="close" onClick={closeEvent}>
          <AiOutlineClose />
        </button>
        {isNotDday && (
          <div className="selectTypeArea">
            <button onClick={() => handleViewTypeChange(2)}>
              {viewType === 2 ? <TiArrowBackOutline /> : <MdOutlinePhotoSizeSelectActual />}
            </button>
            {eventInfo.todo.length > 0 && (
              <button onClick={() => handleViewTypeChange(1)}>
                {viewType === 1 ? <TiArrowBackOutline /> : <LuListTodo />}
              </button>
            )}
          </div>
        )}
        {viewType < 2 ? (
          <>
            <PreviewTitle />
            <EventContentBox
              style={{
                height: `calc(100% - ${isNotDday ? 100 : 80}px)`,
                marginBottom: 5,
              }}>
              <div
                className="eventContentWrapper"
                style={{ transform: `translateX(${transformValue}%)` }}>
                <div className="eventContent">
                  <EventDetailView item={eventInfo} todos={todos} getTodos={getTodos} />
                </div>
              </div>
            </EventContentBox>
            <EventActions />
          </>
        ) : (
          <EventGallery
            eventInfo={eventInfo}
            images={images}
            setImages={setImages}
            originalImages={originalImages}
            setOriginalImages={setOriginalImages}
            uploadImages={uploadImages}
            getImages={getImages}
          />
        )}
      </div>
    </EventLayerContainer>
  );
};

export default EventLayer;

import React from "react";
import { useOutletContext } from "react-router";
import { CuteContainer } from "./styled/cuteTheme";
import CuteBanner from "./themes/cute/banner";
import Products from "./themes/cute/products";
import Portfolio from "./themes/cute/portfolio";
import News from "./themes/cute/news";
import Introduce from "./themes/cute/introduce";

const CuteTheme = ({ homepageInfo }) => {
  const { cmpnyNo, url } = useOutletContext();

  return (
    <CuteContainer>
      <CuteBanner cmpnyNo={cmpnyNo} />
      <Introduce homepageInfo={homepageInfo} url={url} />
      <Products cmpnyNo={cmpnyNo} homepageInfo={homepageInfo} url={url} />
      <Portfolio cmpnyNo={cmpnyNo} homepageInfo={homepageInfo} url={url} />
      <News cmpnyNo={cmpnyNo} homepageInfo={homepageInfo} url={url} />
    </CuteContainer>
  );
};

export default CuteTheme;

import { useSelector } from "react-redux";
import { BoardSearchContainer } from "../../components/mypage/homepageSetting/component/styled/register";

const BoardSearch = ({ selectList, setSelected, setKeyword, getData }) => {
  const setting = useSelector((state) => state?.user?.setting);

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  return (
    <BoardSearchContainer className={setting?.mode}>
      <fieldset>
        <legend>검색영역</legend>
        <div className="basic">
          <select name="search_type" id="search_type" onChange={handleSelect}>
            {selectList.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <input
            type="text"
            id="keyword"
            name="keyword"
            placeholder="검색어를 입력하세요."
            onChange={handleKeywordChange}
            onKeyPress={(e) => handleKeyPress(e, getData)}
          />
          <button type="submit">
            <i className="fa fa-search" aria-hid den="true" />
            <span className="sound_only">검색</span>
          </button>
        </div>
      </fieldset>
    </BoardSearchContainer>
  );
};

export default BoardSearch;

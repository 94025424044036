import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRegex } from "../../../../hooks/useRegex";
import { HomepageFormContainer } from "./styled/homepage";
import { useMediaQuery } from "react-responsive";

export default function HomepageForm() {
  useEffect(() => {
    getCompaynyInfo();
    checkedDomain();
  }, []);

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);
  const { BussinessFormat } = useRegex();
  const [companyInfo, setCompanyInfo] = useState({});
  const [res, setRes] = useState({});

  const getCompaynyInfo = async () => {
    const url = "/api/cmpnyInfo";
    const body = {
      cmpnyNo: user.cmpnyNo,
    };
    const result = await axios.post(url, body);
    setCompanyInfo(result.data);
  };

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
      cmpnyNo: user.cmpnyNo,
    };
    const response = await axios.post(url, body);
    setRes(response);
  };

  const openNewTab = () => {
    window.open(`https://1472.ai/shop/${res.data.domain}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <HomepageFormContainer className={setting?.mode}>
      <div className="viewer">
        <table>
          <colgroup>
            <col />
            <col />
          </colgroup>
          <thead>
            {isTablet ? (
              <tr>
                <th colSpan={2}>사업자 번호 목록</th>
              </tr>
            ) : (
              <tr>
                <th>사업자번호</th>
                <th>홈페이지 관리</th>
              </tr>
            )}
          </thead>
          <tbody id="place_tbody">
            {res?.data?.domain !== undefined ? (
              <tr>
                <td>
                  <span>{companyInfo?.cmpnyNm}</span>
                  <span>{`(${BussinessFormat(companyInfo.bizrno)})`}</span>
                </td>
                <td>
                  <Link onClick={openNewTab}>
                    <i className="fa fa-laptop" aria-hidden="true" /> 홈페이지 바로가기
                  </Link>
                  <Link to="/mypage/companyInfo">
                    <i className="fa fa-building-o" aria-hidden="true" /> 기업정보
                  </Link>
                  <Link to="/mypage/homepageSetting">
                    <i className="fa fa-gear" aria-hidden="true" /> 관리
                  </Link>
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  <span>{companyInfo?.cmpnyNm}</span>
                  <span>{`(${BussinessFormat(companyInfo.bizrno)})`}</span>
                </td>
                <td>
                  <Link to="/mypage/makeCompanyDomain">
                    <i className="fa fa-plus" aria-hidden="true" /> 생성
                  </Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </HomepageFormContainer>
  );
}

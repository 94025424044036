import React from "react";
import { PageInfoContainer } from "../styled/notice";

const PageInfo = ({ title, content }) => {
  return (
    <PageInfoContainer>
      <span className="tit">{title}</span>
      <span className="cont">
        {content.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </span>
    </PageInfoContainer>
  );
};

export default PageInfo;

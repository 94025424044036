import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";

import { IoClose } from "react-icons/io5";
import { LuRefreshCw } from "react-icons/lu";

import { ConfirmationModal } from "./modal";
import { Header } from "../styled/common";

const StoreHeader = ({ setActiveMenu }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const user = useSelector((state) => state?.user?.data[0]);

  const [currentTime, setCurrentTime] = useState(
    moment().format(isMobile ? "a h시m분" : "YYYY년 MM월 DD일 A h시 m분"),
  );
  const [isOpen, setIsOpen] = useState(false);
  const [openMadal, setOpenMadal] = useState(false);

  useEffect(() => {
    let animationFrameId;

    const updateCurrentTime = () => {
      setCurrentTime(moment().format(isMobile ? "a h시m분" : "YYYY년 MM월 DD일 A h시 m분"));
      animationFrameId = requestAnimationFrame(updateCurrentTime);
    };

    updateCurrentTime();
    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobile]);

  useEffect(() => {
    getCmpny();
  }, []);

  const changeStatus = () => {
    setOpenMadal(true);
  };

  const handleConfirm = () => {
    toggleOpen();
    setOpenMadal(false);
  };

  const toggleOpen = async () => {
    const url = "/api/cmpnyIntroduce/toggle";
    const body = { cmpnyNo: user?.cmpnyNo, open: !isOpen };

    const res = await axios.put(url, body);
    if (res.status) getCmpny();
  };

  // 회사 정보 조회
  const getCmpny = async () => {
    const url = "/api/cmpny";
    const body = { cmpnyNo: user?.cmpnyNo };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        setIsOpen(res.data.isOpen);
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Header>
        <div>
          <div className="toggle">
            <label className={`switch ${isOpen ? "open" : "close"}`} onChange={changeStatus}>
              <input type="checkbox" checked={!isOpen} />
              <span className="slider"></span>
            </label>
            <span>{isOpen ? "영업중" : "영업종료"}</span>
          </div>
        </div>
        <div className="actions">
          <span>{currentTime}</span>
          <button className="refresh" onClick={() => location.reload()}>
            <LuRefreshCw />
          </button>
          <button className="close" onClick={() => setActiveMenu(null)}>
            <IoClose />
          </button>
        </div>
      </Header>
      {openMadal && (
        <ConfirmationModal
          title={isOpen ? "영업종료" : "영업재개"}
          message={[`영업을 ${isOpen ? "종료" : "재개"}하시겠습니까?`]}
          handleConfirm={handleConfirm}
          handleCancel={() => setOpenMadal(false)}
        />
      )}
    </>
  );
};

export default StoreHeader;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/css/pagination";
import { useMediaQuery } from "react-responsive";

const Products = ({ cmpnyNo, homepageInfo, url }) => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  useEffect(() => {
    if (cmpnyNo) {
      getProducts(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getProducts = async (cmpnyNo) => {
    try {
      const url = "/erp/vendorProducts/get";
      const query = `
      query VendorProducts(
        $cmpnyNo: Int!
        $limit: Int
        $offset: Int
      ) {
        vendorProducts(
          filter: {
            cmpnyNo: $cmpnyNo
            limit: $limit
            offset: $offset
          }
        ) {
          totalCount
          products {
            id
            name
            media
            price
          }
        }
      }
    `;
      const variables = { cmpnyNo: parseInt(cmpnyNo, 10), limit: 20, offset: 0 };
      const body = { query, variables };

      const res = await axios.post(url, body);
      if (res.status === 200) {
        setProducts(res.data.products);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return products && Array.isArray(products) && products.length > 0 ? (
    <div className="product">
      <div className="label">
        PRODUCTS
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
      <div className="products">
        <div className="info">
          상품
          <span>{homepageInfo?.cmpnyNm}의 다양한 상품들을 보여드릴게요</span>
        </div>
        <div className="swiperContent">
          <Swiper
            ref={swiperRef}
            className="productSwiper"
            modules={[Pagination]}
            slidesPerView={isMobile ? 1.7 : isTablet ? 2.7 : 3}
            spaceBetween={isMobile ? 10 : isTablet ? 20 : 60}
            loop={true}
            speed={2000}
            pagination={{ type: "progressbar" }}>
            {products.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="swiper-wrapper">
                  <div className="productSlide" id={i}>
                    <div
                      className="box"
                      onClick={() => navigate(`/shop/${url}/productsView/${item.id}`)}>
                      <img src={imgHandler(item.media)} onError={onErrorImg} />
                      <div className="productInfo">
                        <span>{item.name}</span>
                        <p>{item.price ? `${item.price.toLocaleString()}원` : "가격문의"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  ) : null;
};

export default Products;

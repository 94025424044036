import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setProductIndex } from "../../../../../store/compoIndex";
import { ProductMenuContainer } from "../styled/product";
import { useSelector } from "react-redux";

const ProductMenu = ({ index }) => {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <ProductMenuContainer className={setting?.mode}>
      <ul>
        {productMenu.map((item, i) => (
          <li
            key={i}
            onClick={() => dispatch(setProductIndex(i))}
            className={index === i ? "active" : ""}>
            {item.label}
          </li>
        ))}
      </ul>
    </ProductMenuContainer>
  );
};

export default ProductMenu;

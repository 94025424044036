import React from "react";
import { MdClose } from "react-icons/md";
import { exitFullscreen } from "../kiosk/component/function/kiosk";
import { useNavigate } from "react-router";

import { StoreMenu } from "./component/styled/common";
import { CloseButton, Container } from "../kiosk/component/styled/common";

const Menus = ({ setActiveMenu }) => {
  const navigate = useNavigate();
  const menus = [
    { key: "pickup", tit: "픽업", imagePath: "cafe" },
    { key: "salesCheck", tit: "매출확인", imagePath: "salesCheck_salesCheck" },
    { key: "review", tit: "리뷰", imagePath: null },
    { key: "community", tit: "커뮤니티  ", imagePath: "invoice" },
  ];

  const exitKioskPage = () => {
    exitFullscreen();
    navigate("/mypage");
  };

  return (
    <Container className="bg">
      <CloseButton>
        <button onClick={exitKioskPage}>
          <MdClose />
        </button>
      </CloseButton>
      <StoreMenu>
        {menus.map((item) => (
          <li
            className={item.key}
            onClick={() =>
              item.key === "community" ? alert("준비중입니다.") : setActiveMenu(item.key)
            }>
            <div className="content">
              <div className="image">
                <img src={`/img/store/${item.imagePath}.png`} alt="" />
              </div>
              <span>{item.tit}</span>
            </div>
          </li>
        ))}
      </StoreMenu>
    </Container>
  );
};

export default Menus;

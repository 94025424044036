import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { SmContainer } from "./styled/smTheme";
import SmBanner from "./themes/sm/banner";
import { useMediaQuery } from "react-responsive";

const SmTheme = () => {
  const { cmpnyNo } = useOutletContext();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [footerHeight, setFooterHeight] = useState(0);

  const updateFooterHeight = () => {
    const footerElement = document.querySelector("footer");
    if (footerElement) {
      setFooterHeight(footerElement.offsetHeight);
    }
  };

  useEffect(() => {
    const footerElement = document.querySelector("footer");

    if (footerElement) {
      const updateHeight = () => setFooterHeight(footerElement.offsetHeight);

      const mutationObserver = new MutationObserver(updateHeight);
      mutationObserver.observe(footerElement, { childList: true, subtree: true });

      const resizeObserver = new ResizeObserver(updateHeight);
      resizeObserver.observe(footerElement);

      updateHeight();

      return () => {
        mutationObserver.disconnect();
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <SmContainer style={{ height: isMobile ? null : `calc(100vh - ${footerHeight}px)` }}>
      <SmBanner cmpnyNo={cmpnyNo} />
    </SmContainer>
  );
};

export default SmTheme;

import React from "react";

const Card = ({ index, card, type }) => {
  return (
    <li>
      <div className="bg">
        <img src={`/img/info/${type}_${index + 1}.png`} alt={card.index} />
      </div>
      <div className="text">
        <strong>{card.highlight}</strong>
        {card.details.map((detail, index) => (
          <span key={index}>{detail}</span>
        ))}
      </div>
    </li>
  );
};

export default Card;

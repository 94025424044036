import styled from "styled-components";

export const ReviewContainer = styled.div`
  .viewer {
    height: 100%;
    .sortOptions {
      text-align: right;
      height: 35px;
      line-height: 35px;
      padding: 0 30px;
      button {
        border: none;
        background: transparent;
        font-size: 24px;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .container {
      flex-direction: column;
      .content:not(.pickup) {
        width: 100%;
        height: calc(100% - 173px);
        padding: 0 20px 20px 20px;
        .viewer {
          height: 100%;
          .label {
            font-size: 20px;
            height: 25px;
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      .sidemenu {
        background: #fff;
        height: 84px;
        .store {
          padding: 10px 20px;
          .selected {
            padding: 0 10px;
            font-size: 12px;
            height: 34px;
            width: 130px;
            .icon {
              display: none;
            }
            select {
              font-size: 14px;
              width: 100%;
              background-image: none;
            }
          }
        }
        .tabs {
          padding: 0 20px;
          ul {
            gap: 0;
            li {
              box-shadow: none;
              width: 25%;
              height: 30px;
              font-size: 12px;
              border-radius: 0;
              border-bottom: 3px solid transparent;
              .icon {
                display: none;
              }
              &.active {
                background: none;
                color: #000;
                border-bottom: 3px solid #255dac;
              }
            }
          }
        }
      }
      .content:not(.pickup) {
        height: calc(100% - 84px);
        padding: 0;
        .viewer {
          background: transparent;
          box-shadow: none;
          .salesCheckBox {
            height: 25px;
            display: flex;
            width: 100%;
            padding: 0 20px;
            justify-content: space-between;
            align-items: center;
            .search {
              width: calc(100% - 120px);
              height: 100%;
              margin-left: 0;
              font-size: 12px;
              position: relative;
              input[type="text"] {
                width: 100%;
                height: 100%;
                border: 1px solid #ccc;
                padding: 2px 20px 2px 5px;
                border-radius: 10px;
                &:focus {
                  border: 2px solid #d8ad5f !important;
                  box-shadow: 0 0 0 0.2rem #ffcf78;
                }
              }
              button {
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                border: none;
                background: transparent;
                font-size: 15px;
                svg {
                  display: block;
                }
              }
            }
          }
          .sortOptions {
            height: 20px;
            line-height: 20px;
            padding: 0;
            button {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`;

export const StoreReviewsContainer = styled.div`
  height: 100%;
  .reviewInfo {
    > span {
      display: block;
    }
    .createdAt,
    .rating {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
    .slideContent {
      flex: 0 0 50%;
      transform: translateX(0);
      flex-basis: 100%;
      flex-shrink: 0;
      transition: 0.3s;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 30px 0;
      &:first-child {
        padding: 30px 20px;
      }
    }
  }
  .reviews {
    height: 100%;
    overflow-y: auto;
    padding: 0 10px;
    .notReviews {
      height: 100%;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
  }
  @media screen and (max-width: 1050px) {
    .reviews {
      padding: 0;
    }
    .slideWrapper .slideContent,
    .slideWrapper .slideContent:first-child {
      padding: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    .slideWrapper .slideContent,
    .slideWrapper .slideContent:first-child {
      padding: 10px 20px;
    }
  }
`;

export const ReviewItemCotnainer = styled.li`
  font-size: 18px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 10px;
  min-height: 100px;
  &:first-child {
    border-top: 1px solid #ccc;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .reviewInfo {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 5px 20px;
      margin-bottom: 10px;
      .mberNm {
        font-weight: 700;
        width: 100%;
        font-size: 22px;
      }
      .createdAt {
        color: #444;
      }
    }
  }
  .reviewContent {
    .images {
      margin-bottom: 10px;
      display: flex;
      overflow-x: hidden;
      gap: 10px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 5px;
        object-fit: cover;
        border: 1px solid #bdbdbd;
      }
    }
    .cont {
      width: 100%;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }
  @media screen and (max-width: 1050px) {
    font-size: 16px;
    .reviewInfo {
      > div .mberNm {
        font-size: 18px;
      }
    }
    .reviewContent .images img {
      width: 50px;
      height: 50px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 10px;
    background: #fff;
    font-size: 14px;
    .reviewInfo > div {
      gap: 5px 10px;
      .mberNm {
        font-size: 16px;
      }
      .createdAt,
      .rating {
        font-size: 12px;
      }
    }
  }
`;

export const DetailReviewContainer = styled.div`
  height: 100%;
  color: #666666;
  font-size: 16px;
  .review {
    height: calc(100% - 140px);
    border-bottom: 1px solid #666;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px 30px;
    .info {
      display: flex;
      align-items: baseline;
      flex-direction: column-reverse;
      font-size: 14px;
      gap: 10px;
      margin-bottom: 30px;
      button {
        font-size: 24px;
        border: none;
        background: transparent;
      }
      .mberNm {
        display: block;
        font-weight: 700;
        color: #000;
        font-size: 20px;
      }
    }
    .reviewContent {
      height: calc(100% - 110px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5a5a5a;
        border-radius: 10px;
        border: 2px solid #d9d9d9;
      }
      &::-webkit-scrollbar-track {
        background-color: #d9d9d9;
        border-radius: 10px;
      }
      .images {
        margin-bottom: 20px;
        display: flex;
        overflow-x: hidden;
        gap: 10px;
        img {
          border: 1px solid #bdbdbd;
          object-fit: cover;
          width: 150px;
          height: 150px;
          border-radius: 5px;
        }
      }
      .cont {
        width: 100%;
        display: inline-block;
        white-space: pre-wrap;
        margin-bottom: 30px;
      }
    }
  }
  .products {
    padding: 0;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
    box-sizing: border-box;
    > span {
      display: block;
      font-weight: 700;
      color: rgb(0, 0, 0);
      font-size: 20px;
      margin-bottom: 10px;
    }
    ul {
      border: 1px solid #e7e7ed;
      border-radius: 4px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        &:first-child {
          border-bottom: 1px solid #e7e7ed;
        }
        &:not(.header) .media {
          height: 50px;
          img {
            width: 50px;
            height: 100%;
            object-fit: contain;
          }
        }
        .product {
          text-align: center;
          &.media {
            width: 20%;
          }
          &.name {
            width: 40%;
          }
          &.price {
            width: 20%;
          }
          &.quantity {
            width: 20%;
          }
        }
      }
    }
  }
  .ownerReply {
    padding: 30px 30px 0;
    gap: 30px;
    height: 140px;
    display: flex;
    align-items: center;
    font-size: 18px;
    .cont,
    textarea {
      border: 1px solid #9f9f9f;
      padding: 10px;
      width: calc(100% - 166px);
      height: 100%;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5a5a5a;
        border-radius: 10px;
        border: 2px solid #d9d9d9;
      }
      &::-webkit-scrollbar-track {
        background-color: #d9d9d9;
        border-radius: 10px;
      }
    }
    .cont {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;
      overflow-x: hidden;
      gap: 20px;
      span {
        width: 100%;
        display: block;
        white-space: pre-wrap;
      }
    }
    textarea {
      resize: none;
      &:focus {
        outline: none;
        border: 1px solid #9f9f9f !important;
        box-shadow: none;
      }
    }
    .action {
      width: 136px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      button {
        width: 100%;
        height: 48px;
        border: none;
        border-radius: 10px;
        &.active {
          background: #255dac;
          color: #fff;
        }
        &.inactive {
          background: #9b9b9b;
          color: #fff;
        }
      }
      span {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .review {
      .info {
        margin-bottom: 30px;
        gap: 20px;
        font-size: 14px;
        .mberNm {
          font-size: 16px;
        }
      }
      .products {
        font-size: 14px;
        > span {
          font-size: 16px;
        }
        ul li:not(.header) .media {
          height: 30px;
          img {
            width: 30px;
          }
        }
      }
      .reviewContent {
        height: calc(100% - 122px);
        .images {
          margin-bottom: 20px;
          overflow-x: auto;
          &::-webkit-scrollbar {
            width: 0;
          }
          img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
    .ownerReply {
      > .cont,
      textarea {
        width: calc(100% - 156px);
      }
      gap: 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    .review {
      padding: 0 0 20px;
      .info,
      .reviewContent .images {
        margin-bottom: 30px;
      }
    }
    .ownerReply {
      padding: 20px 0 0;
      gap: 20px;
      font-size: 16px;
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    .review {
      padding: 0 0 10px;
      height: calc(100% - 100px);
      .info {
        gap: 10px;
      }
      .reviewContent {
        height: calc(100% - 102px);
        .cont {
          margin-bottom: 20px;
        }
      }
      .info,
      .reviewContent .images {
        margin-bottom: 20px;
      }
    }
    .ownerReply {
      height: 100px;
      padding: 10px 0 0;
      gap: 10px;
      font-size: 14px;
      flex-wrap: wrap;
      > .cont,
      textarea {
        padding: 5px;
        width: 100%;
        height: calc(100% - 40px);
      }
      .action {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        height: 30px;
        button {
          width: 100px;
          height: 100%;
          font-size: 14px;
        }
      }
    }
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    display: block;
  }
  .star {
    color: #ccc;
  }
  .star.filled {
    color: #ffc107;
  }
`;

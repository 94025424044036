import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

const Products = ({ cmpnyNo, url }) => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (cmpnyNo) {
      getProducts(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getProducts = async (cmpnyNo) => {
    try {
      const url = "/erp/vendorProducts/get";
      const query = `
      query VendorProducts(
        $cmpnyNo: Int!
        $limit: Int
        $offset: Int
      ) {
        vendorProducts(
          filter: {
            cmpnyNo: $cmpnyNo
            limit: $limit
            offset: $offset
          }
        ) {
          totalCount
          products {
            id
            name
            media
            price
          }
        }
      }
    `;
      const variables = { cmpnyNo: parseInt(cmpnyNo, 10), limit: 8, offset: 0 };
      const body = { query, variables };

      const res = await axios.post(url, body);
      if (res.status === 200) {
        setProducts(res.data.products);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return products && Array.isArray(products) && products.length > 0 ? (
    <div className="products">
      <div className="info">Product</div>
      <div className="wrapper">
        {products.map((item, i) => (
          <div className="box" onClick={() => navigate(`/shop/${url}/productsView/${item.id}`)}>
            <div className="image">
              <img src={imgHandler(item.media)} onError={onErrorImg} />
            </div>
            <div className="prdInfo">
              <span>{item.name}</span>
              <span className="price">
                {item.price ? `${item.price.toLocaleString()}원` : "가격문의"}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default Products;

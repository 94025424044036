import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { BsPlus } from "react-icons/bs";
import { toast } from "react-toastify";
import FilePreview from "../common/filePreview";
import { ThumbnailUploadContainer } from "../../styled/common";

const ThumbnailUpload = ({ product, handleAddImage, handleDeleteImage, setIsDetail }) => {
  return (
    <ThumbnailUploadContainer className="thumbnail">
      <div className="writing_tit">
        <strong className="tit">썸네일 이미지 첨부</strong>
        <label>
          <div className="icon">
            <BsPlus />
          </div>
          <input
            type="file"
            id="wk_file"
            name="wk_file"
            accept="image/*, video/*"
            multiple
            onClick={(e) => {
              if (product.media.length > 9) {
                e.preventDefault();
                toast.error("이미지는 최대 10장까지 첨부 가능합니다.");
              }
            }}
            onChange={handleAddImage}
          />
        </label>
      </div>
      <div className="writing_cont">
        {product.media && product.media.length > 0 && (
          <ul className="preview">
            {product.media.map((item) => (
              <li>
                <FilePreview item={item} setIsDetail={setIsDetail} />
                <button onClick={() => handleDeleteImage(item)}>
                  <IoClose />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </ThumbnailUploadContainer>
  );
};

export default ThumbnailUpload;

export const treeCategoryView = (item) => {
  if (item.path.includes("/")) {
    const count = [...item.path].filter((item) => item === "/").length + 1;
    return `5px 10px 5px ${count * 15}px`;
  }
  return "5px 10px";
};

export const formatNumber = (value) => {
  if (!value) return "";

  const numericValue = value.replace(/,/g, "");
  return Number(numericValue).toLocaleString();
};

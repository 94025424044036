import React from "react";
import { MessageContainer } from "./styled/common";

const Message = () => {
  return (
    <MessageContainer>
      <img src="/img/logo.png" />
      <span>
        회원이 되시면 더 많은 혜택을
        <br />
        경험할 수 있습니다
      </span>
    </MessageContainer>
  );
};

export default Message;

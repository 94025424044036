import axios from "axios";
import { useEffect, useState } from "react";
import { useRegex } from "../../../hooks/useRegex";

import NotitleInfo from "./component/notitleInfo";
import SellerInfo from "./component/sellerInfo";
import { FooterContainer } from "./component/footer";

const ShoppingFooter = ({ homepageInfo, theme }) => {
  const [cmpnyInfo, setCmpnyInfo] = useState();

  useEffect(() => {
    if (homepageInfo) {
      getCmpnyInfo(homepageInfo?.cmpnyNo);
    }
  }, [homepageInfo]);

  const getCmpnyInfo = async (cmpnyNo) => {
    const url = "/api/cmpnyInfo";
    const body = {
      cmpnyNo,
    };
    const res = await axios.post(url, body);
    setCmpnyInfo(res.data);
  };

  return (
    <FooterContainer className={`theme-${theme}`}>
      {homepageInfo && (
        <SellerInfo homepageInfo={homepageInfo} cmpnyInfo={cmpnyInfo} theme={theme} />
      )}
      {/* {theme !== "sm" && <NotitleInfo />} */}
    </FooterContainer>
  );
};

export default ShoppingFooter;

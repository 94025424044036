import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router";
import { ScheduleSwiperContainer } from "../../../styled/dashboard";

const CompanySwiper = ({ data = [], perView = 1 }) => {
  const navigate = useNavigate();
  return (
    <ScheduleSwiperContainer>
      <Swiper
        slidesPerView={perView}
        spaceBetween={10}
        //  loop={true}
        className="mySwiper">
        {data.map((item, i) => (
          <SwiperSlide key={i}>
            <div
              className="scheduleContent"
              style={{ borderLeft: `5px solid #bbb` }}
              onClick={() => navigate(`/mypage/company`)}>
              <div className="contentArea" style={{ width: "100%" }}>
                <span>{item.cmpnyNm}</span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </ScheduleSwiperContainer>
  );
};

export default CompanySwiper;

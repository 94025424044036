import { useSelector } from "react-redux";
import { HomepageTipContainer } from "./styled/homepageEnd";

export default function HomepageTip() {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <HomepageTipContainer className={setting?.mode}>
      <div>
        <h3>홈페이지 꿀팁</h3>
      </div>
      <div>
        <ul>
          <li>
            <img src="/img/hp_step_end_1.png" />
            <span>#대문 사진을 바꿔보세요.</span>
            <p>
              시작 화면에 원하는 이미지를 넣을 수 있습니다.
              <br />
              설정 &gt; 메인이미지 화면으로 이동해보세요.
            </p>
          </li>
          <li>
            <img src="/img/hp_step_end_2.png" />
            <span>#로고를 넣어보세요.</span>
            <p>
              로고는 PGN파일만 등록이 가능합니다.
              <br />
              설정 &gt; 로고에서 이미지를 첨부해 주세요.
            </p>
          </li>
          <li>
            <img src="/img/hp_step_end_3.png" />
            <span>#도메인이 있으신가요?</span>
            <p>
              노타이틀에서는 원하는 ID를 도메인 뒤에 적용해드립니다.
              <br />
              설정 &gt; 홍보설정 에서 도메인을 적용해주세요.
            </p>
          </li>
          <li>
            <img src="/img/hp_step_end_4.png" />
            <span>#제품연동을 어떻게 하나요?</span>
            <p>
              설정 &gt; 제품에서 다양한 제품을 보시고, 취급하는 제품을 나의 홈페이지에 연동 할 수
              있습니다.
            </p>
          </li>
        </ul>
      </div>
    </HomepageTipContainer>
  );
}

import React from "react";
import { formatCommas, orderType, renderStatusImage } from "../function/store";

const OrderInfo = ({ selected }) => {
  return (
    <div className="info">
      <div className="infoImage">
        <img src={`/img/store/${selected?.orderFlag?.toLowerCase()}.png`} />
      </div>
      <div className="infoText">
        <span>{`${orderType[selected?.orderFlag]} ${selected?.id}`}</span>
        <span>
          {selected?.products?.length && `메뉴 ${selected?.products?.length}개 - `}
          {formatCommas(selected.price)}원
        </span>
      </div>
      <div className="status">{renderStatusImage(selected?.status)}</div>
    </div>
  );
};

export default OrderInfo;

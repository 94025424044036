import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const CompanyInfo = ({ url, homepageInfo }) => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const removeImages = (html) => {
    if (!html) return "";
    return html.replace(/<img[^>]*>/gi, "");
  };

  return (
    <div className="homepageInfo">
      <div className="tit">
        <p>{url}</p>
        <span>{homepageInfo?.cmpnyNm}</span>
      </div>
      <div
        className="desc"
        dangerouslySetInnerHTML={{ __html: removeImages(homepageInfo?.cmpnyIntroduce) }}></div>
      <div className="company-intro"></div>
      <button onClick={() => navigate(`/shop/${url}/company`)}>소개 보러가기</button>
    </div>
  );
};

export default CompanyInfo;

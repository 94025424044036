import { useOutletContext } from "react-router";

import { ChicNoticeContainer } from "../shopping/component/styled/chicTheme";
import { ModernNoticeContainer } from "../shopping/component/styled/modernTheme";
import { CuteNoticeContainer } from "../shopping/component/styled/cuteTheme";
import { SmNoticeContainer } from "../shopping/component/styled/smTheme";
import { SimpleNoticeContainer } from "../shopping/component/styled/simpleTheme";

import NoticeForm from "./component/noticeForm";
import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";

const ShoppingNotice = () => {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme.toString()) {
      case "0":
        return (
          <DefaultContaienr>
            <NoticeForm />
          </DefaultContaienr>
        );
      case "1":
        return (
          <ChicNoticeContainer>
            <NoticeForm />
          </ChicNoticeContainer>
        );
      case "2":
        return (
          <ModernNoticeContainer>
            <ModernBanner />
            <NoticeForm />
          </ModernNoticeContainer>
        );
      case "3":
        return (
          <CuteNoticeContainer>
            <CuteBanner />
            <NoticeForm />
          </CuteNoticeContainer>
        );
      case "4":
        return (
          <SimpleNoticeContainer>
            <SimpleBanner />
            <NoticeForm />
          </SimpleNoticeContainer>
        );
      case "sm":
        return (
          <SmNoticeContainer>
            <NoticeForm />
          </SmNoticeContainer>
        );
      default:
        return <NoticeForm />;
    }
  };

  return <>{renderComponent()}</>;
};

export default ShoppingNotice;

import RegisterPortViewForm from "./component/register/registerPortViewForm";
import { useSelector } from "react-redux";
import PageInfo from "../pageInfo";
import { Container } from "../styled/mypage";

export default function RegisterPortView() {
  const setting = useSelector((state) => state?.user?.setting);
  return (
    <Container className={setting?.mode}>
      <PageInfo label="포트폴리오" />
      <RegisterPortViewForm />
    </Container>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import useOutsideClick from "../../../../../../../hooks/useOutsideClick";

import { EditModalContainer } from "../../../styled/product";
import { Modal } from "../../../styled/common";

import EditProductForm from "./editProductModal/editProductForm";
import uuid from "react-uuid";
import Confirm from "./editProductModal/confirm";
import { useMediaQuery } from "react-responsive";

const EditProductModal = ({
  selectedProduct,
  setIsDetail,
  categories,
  categoriesObj,
  closeModal,
}) => {
  const categoriesRef = useRef();
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [viewType, setViewType] = useState(0);
  const [product, setProduct] = useState({
    ...selectedProduct,
  });
  const [viewCategory, setViewCategory] = useState(false);

  useOutsideClick(categoriesRef, () => setViewCategory(false));

  useEffect(() => {
    setProduct({
      ...selectedProduct,
      media: selectedProduct.media
        ? selectedProduct.media.map((item) => ({ path: item, id: uuid() }))
        : [],
    });
  }, [selectedProduct]);

  const transformValue = viewType * -100;

  // 파일 업로드
  const handleUpload = async () => {
    try {
      const fileOnly = product.media.filter((item) => item?.file);
      let uploaded = [];

      if (fileOnly.length) {
        const uploadedFiles = await uploadFiles(fileOnly);

        if (uploadedFiles) {
          uploaded = product.media.map((file) => {
            if (file.file) {
              const matchedFile = uploadedFiles.find((item) => item.origin === file.file.name);
              return matchedFile ? { ...matchedFile, path: matchedFile.fileName } : { ...file };
            }
            return { ...file };
          });
        } else {
          console.error("파일 업로드 실패");
          return;
        }
      } else {
        uploaded = product.media;
      }

      return uploaded;
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFiles = async (files) => {
    const form = new FormData();

    files.forEach((item) => {
      form.append("files", item.file, item.file.name);
    });

    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFiles";
    const res = await axios.post(url, form);

    const response = res?.data?.responseData;

    if (response && response.length > 0) {
      const originalFileNames = files.map((item) =>
        item.file.name.split(".").slice(0, -1).join("."),
      );

      return response.map((item, idx) => {
        const uploadedFileName = item.fileName.split("/").pop().split(".").slice(0, -1).join(".");
        const isSameName = originalFileNames.includes(uploadedFileName);

        return {
          ...item,
          origin: isSameName ? files[idx].file.name : undefined,
        };
      });
    }

    return null;
  };

  const handleEdit = async () => {
    let uploadedFiles = null;
    if (product.media && product.media.length > 0) {
      uploadedFiles = await handleUpload();
    }
    updateProduct(uploadedFiles);
  };

  const updateProduct = async (files = []) => {
    try {
      const url = "/erp/vendorProducts/update";
      const body = {
        id: selectedProduct?.id,
        input: {
          cmpnyNo: product?.cmpnyNo,
          cloudProductId: selectedProduct?.cloudProduct?.id || "",
          name: product?.name,
          description: product?.description,
          brand: product?.brand,
          specifications: product?.specifications,
          categoryId: product?.categoryId,
          media: files.map((item) => item.path),
          quantity: product?.quantity ? parseInt(product.quantity) : null,
          price: product?.price ? parseFloat(product.price) : null,
        },
      };

      const res = await axios.post(url, body, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.status === 200) {
        toast.success("상품이 변경되었습니다.");
        await closeModal(true);
      }
    } catch (error) {
      console.error("업데이트 실패:", error.response?.data || error.message, error.stack);
    }
  };

  const handleSubmit = () => {
    if (viewType) {
      handleEdit();
    } else {
      setViewType(1);
    }
  };

  useEffect(() => {
    if (!viewType) {
      return;
    }
    console.log(selectedProduct);
    console.log(product);
  }, [viewType]);

  return (
    <Modal className={setting?.mode}>
      <EditModalContainer
        className={`modal ${setting?.mode}`}
        style={{ height: viewType ? (isMobile ? 174 : isTablet ? 218 : 245) : "min-content" }}>
        <div className="modalContent">
          <div className="modalInfo">
            <h3>상품 수정</h3>
            <button onClick={() => closeModal()}>
              <i className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
          <div className="slideWrapper">
            <EditProductForm
              product={product}
              setProduct={setProduct}
              viewCategory={viewCategory}
              setViewCategory={setViewCategory}
              setIsDetail={setIsDetail}
              categories={categories}
              categoriesObj={categoriesObj}
              categoriesRef={categoriesRef}
              transformValue={transformValue}
              handleSubmit={handleSubmit}
              closeModal={closeModal}
            />
            <Confirm
              transformValue={transformValue}
              setViewType={setViewType}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </EditModalContainer>
    </Modal>
  );
};

export default EditProductModal;

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCompanyIndex } from "../../../../store/compoIndex";
import { CompanyInfoTitleContainer } from "./styled/companyInfo";

export default function CompanyInfoTitle({ index }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <CompanyInfoTitleContainer className={setting?.mode}>
      <span>
        <strong>{user.name}</strong>님, 모노티 캘린더 홈페이지를 통해
        <br />
        홍보와 사업장 관리를 함께 도와드립니다.
      </span>
      <span>홈페이지 제품 연동형과 제품 등록형 홈페이지를 선택 또는 모두 추가할 수 있습니다.</span>
      <span>(사업자번호별 각 1개씩 추가 가능, 사업자번호가 다를 경우 추가 가능)</span>
    </CompanyInfoTitleContainer>
  );
}

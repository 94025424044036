import { useSelector } from "react-redux";
import HomepageEndTitle from "./homepageEndTitle";
import HomepageTip from "./homepageTip";
import { HomepageEndFormContainer } from "./styled/homepageEnd";

export default function HomepageEndForm() {
  const setting = useSelector((state) => state?.user?.setting);
  return (
    <HomepageEndFormContainer className={setting?.mode}>
      <HomepageEndTitle />
      <HomepageTip />
    </HomepageEndFormContainer>
  );
}

import React from "react";
import { useEffect } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
// import Header from "./components/mypage/dashboard/component/header.jsx";
import Footer from "./common/footer.jsx";
import { useDispatch } from "react-redux";
import { handleLogin } from "./store/user.js";
import { useSelector } from "react-redux";
import DashboardHeader from "./common/dashboardHeader.jsx";

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: "400px";
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const AppLogin = () => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    document.title = "모노티";

    window.scrollTo(0, 0);
    changeBodyClassName();

    if (!user) {
      const session = JSON.parse(sessionStorage.getItem("persist:root"));
      const sessionData = session?.user && JSON.parse(session.user);
      const sessionUser = sessionData?.data && sessionData.data[0];

      const local = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : undefined;

      const userInfo = sessionUser || local;

      if (userInfo) {
        dispatch(handleLogin(userInfo));
        localStorage.removeItem("user");
      } else {
        navigate("/login");
      }
    }
  }, [
    pathname,
    // user
  ]);

  const isShowComponent = (name) => {
    const domains = [
      "scheduler",
      "calendarEntry",
      "ddayEntry",
      "detailEvent",
      "notify",
      "project",
      "mypage/search",
      "kiosk",
      "store",
    ];
    // const mypages = ["/mypage", "/mypage/"];
    if (domains.some((excludeName) => name.includes(excludeName))) {
      return false;
    }
    return true;
  };

  return (
    <Provider store={store}>
      <CustomToast
        position="top-center"
        theme="light"
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
      />
      {isShowComponent(pathname) && <DashboardHeader />}
      <Outlet />
      {isShowComponent(pathname) && <Footer />}
    </Provider>
  );
};

export default AppLogin;

import styled from "styled-components";

export const SalesCheckContainer = styled.div`
  .viewer {
    height: calc(100% - 100px);
    .sortOptions {
      text-align: right;
      height: 35px;
      line-height: 35px;
      padding: 0 30px;
      button {
        border: none;
        background: transparent;
        font-size: 24px;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .container {
      flex-direction: column;
      .content:not(.pickup) {
        width: 100%;
        height: calc(100% - 173px);
        padding: 0 20px 20px 20px;
        .viewer {
          height: 100%;
          .label {
            font-size: 20px;
            height: 25px;
            span {
              font-size: 20px;
            }
          }
          .viewerContiner {
            height: calc(100% - 200px);
            padding: 15px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      .content:not(.pickup) {
        height: calc(100% - 84px);
        padding: 0;
        .viewer {
          background: transparent;
          box-shadow: none;
          .salesCheckBox {
            height: 25px;
            display: flex;
            width: 100%;
            padding: 0 20px;
            justify-content: space-between;
            align-items: center;
            .search {
              width: calc(100% - 120px);
              height: 100%;
              margin-left: 0;
              font-size: 12px;
              position: relative;
              input[type="text"] {
                width: 100%;
                height: 100%;
                border: 1px solid #ccc;
                padding: 2px 20px 2px 5px;
                border-radius: 10px;
                &:focus {
                  border: 2px solid #d8ad5f !important;
                  box-shadow: 0 0 0 0.2rem #ffcf78;
                }
              }
              button {
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                border: none;
                background: transparent;
                font-size: 15px;
                svg {
                  display: block;
                }
              }
            }
          }
          .sortOptions {
            height: 20px;
            line-height: 20px;
            padding: 0;
            button {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`;

export const PendingCountContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  border-radius: 30px;
  transition: 0.3s;
  margin-bottom: 30px;
  &.hasPending {
    background: #f34e5b;
    border: 1px solid #f34e5b;
    color: #fff;
  }
  &.noPending {
    background: #fff;
    border: 1px solid #000;
    color: #000;
  }
  .info {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
    .icon {
      font-size: 40px;
      display: flex;
    }
    span {
      display: block;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 70px);
    }
  }
  @media screen and (max-width: 1050px) {
    position: absolute;
    width: calc(75% - 30px);
    height: 50px;
    top: 105px;
    right: 20px;
    font-size: 16px;
    padding: 0 30px;
    .info {
      gap: 10px;
      .icon {
        font-size: 24px;
      }
      span {
        max-width: calc(100% - 34px);
      }
    }
  }

  @media screen and (max-width: 767px) {
    height: 34px;
    top: 60px;
    right: 20px;
    font-size: 12px;
    width: calc(100% - 180px);
    padding: 0 14px;
    margin-bottom: 0;
    .info {
      span {
        font-weight: normal;
        max-width: calc(100% - 24px);
      }
      .icon {
        font-size: 14px;
      }
    }
  }
`;

export const SalesCheckInfoContainer = styled.div`
  .info {
    padding: 0 30px;
    border-bottom: 1px solid #666666;
    height: 70px;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      width: 100px;
    }
    .search {
      width: auto;
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 20px;
      > div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .date {
        height: 33px;
        background: #e7e7ed;
        width: 170px;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        button {
          background: #e7e7ed;
          border: none;
          border-radius: 5px;
          svg {
            display: block;
          }
        }
      }
      select {
        width: 200px;
        padding: 0 5px;
        border: 1px solid #666666;
        border-radius: 5px;
        height: 33px;
        &:focus {
          border: 1px solid #666666 !important;
          box-shadow: none;
          outline: none;
        }
      }
      > button {
        width: 50px;
        height: 33px;
        border: none;
        background: rgb(37, 93, 172);
        color: #fff;
        border-radius: 5px;
        svg {
          display: block;
          margin: auto;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .info {
      font-size: 22px;
    }
  }
  @media screen and (max-width: 767px) {
    .info {
      font-size: 16px;
      line-height: normal;
      margin: 0 20px 10px;
      padding: 10px 0;
      gap: 10px;
      flex-wrap: wrap;
      height: auto;
      > span {
        width: 100%;
      }
      .search {
        font-size: 16px;
        width: 100%;
        flex-wrap: wrap;
        gap: 5%;
        .status .label {
          display: none;
        }
        > button,
        select,
        .date {
          height: 25px;
        }
        .date,
        .status {
          width: 40%;
        }
        > button {
          width: 10%;
        }
      }
    }
  }
`;

export const CalendarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  .calendar {
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    background: #fff;
    padding: 10px;
    height: 350px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    .fc {
      a {
        /* color: #ff3b30; */
      }
      .fc-toolbar {
        justify-content: space-around;
        color: #ff3b30;
        button {
          cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
        }
      }
      .fc-toolbar-title {
        font-size: 18px;
      }
      .fc-button {
        background: transparent;
        border: none;
        color: #000;
        box-shadow: none;
        outline: none;
        &:focus {
          box-shadow: none;
        }
      }
      .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        min-height: auto;
      }
      .fc-daygrid-day-top {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .selected-date {
        background: #000;
        border-radius: 50%;
        background: #ffe7e6;
        color: #ff3b30;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        padding: 3px;
      }
      .fc-daygrid-day.fc-day-today {
        background: transparent;
        a {
          font-weight: 700;
        }
        /* .fc-daygrid-day-top .fc-daygrid-day-number {
          border-radius: 50%;
          background: #ffe7e6;
          color: #ff3b30;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          padding: 3px;
        } */
      }
    }
    .fc-theme-standard .fc-scrollgrid {
      border: none;
    }
    .fc-theme-standard td,
    .fc-theme-standard th {
      border: none;
    }
  }
`;

export const SalesListTable = styled.div`
  height: calc(100% - 120px);
  padding: 20px 30px;
  &.settlementForm {
    height: calc(100% - 240px);
  }
  ul {
    border: 1px solid #ddd;
    max-height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    li {
      &:first-child {
        background: #ddd;
        font-weight: 700;
        font-size: 18px;
      }
      padding: 20px 0;
      font-size: 16px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: space-between;
      div {
        display: inline-block;
        text-align: center;
      }
      .date,
      .tel,
      .status {
        width: 15%;
      }
      .details {
        width: 20%;
      }
      .name {
        width: 10%;
      }
      .status {
        span {
          display: inline-block;
          padding: 5px 10px;
          border-radius: 5px;
          font-weight: 700;
          &.pending {
            color: #f1541d;
            background: #fef6f4;
          }
          &.completed {
            color: #429b8c;
            background: #e7f5f2;
          }
        }
      }
      .period,
      .account {
        width: 20%;
      }

      .amount {
        width: 15%;
      }
      .more,
      .check {
        width: 5%;
        min-height: 1px;
        button {
          font-size: 24px;
          border: none;
          background: transparent;
          svg {
            display: block;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: calc(100% - 110px);
    padding: 10px 20px;
    &.settlementForm {
      height: calc(100% - 170px);
    }
    ul {
      border: none;
      li {
        font-size: 14px;
        border: 1px solid #666;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 15px 10px;
        flex-direction: column;
        align-items: baseline;
        position: relative;
        gap: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          background: transparent;
          font-weight: normal;
          font-size: 14px;
        }
        .box {
          width: 100%;
          text-align: left;
          span {
            &.tit {
              width: 60px;
              font-weight: 700;
              padding: 0;
              display: inline-block;
            }
            &.con {
              width: calc(100% - 80px);
              padding: 0;
              display: inline-block;
            }
          }
          &.status .con span {
            padding: 0px 10px;
          }
        }
        label {
          position: absolute;
          bottom: 15px;
          right: 10px;
        }
        .more {
          position: absolute;
          top: 15px;
          right: 10px;
        }
      }
    }
  }
`;

export const StatisticsContainer = styled.div`
  height: calc(100% - 195px);
  padding: 30px;
  .selectChart {
    margin-bottom: 30px;
    font-size: 18px;
    span {
      margin-right: 65px;
      font-weight: 700;
    }
    select {
      width: 200px;
      border: none;
      border-bottom: 1px solid #939393;
      color: #9d9d9d;
      padding: 5px;
      &:focus {
        outline: none;
        box-shadow: none;
        border: none !important;
        border-bottom: 1px solid #000 !important;
      }
    }
  }
  .chart {
    width: 100%;
    height: calc(100% - 64px);
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px 10px 20px;
    height: calc(100% - 85px);
    .selectChart {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      span {
        margin-right: 0;
      }
      select {
        width: 100px;
        font-size: 14px;
        padding: 0 5px 5px;
      }
    }
    .chart {
      height: calc(100% - 34px);
    }
  }
`;

export const PaymentTotalContainer = styled.div`
  height: 50px;
  font-weight: 700;
  text-align: right;
  padding: 0 30px;
  width: 100%;
  @media screen and (max-width: 1050px) {
    font-size: 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

export const OrderListContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 70px);
  ul.orders {
    border: 1px solid #e7e7ed;
    border-radius: 4px;
    max-height: calc(100% - 70px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }

    .header {
      font-size: 16px;
      font-weight: 700;
      background: #f7f7f7;
      border-bottom: 1px solid #e7e7ed;
    }
    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #e7e7ed;
      &:last-child {
        border-bottom: none;
      }
      &.none {
        width: 100%;
        height: calc(100% - 55px);
        font-size: 20px;
        justify-content: center;
      }
      &.inactive {
        .toggle button {
          transform: rotate(90deg);
        }
      }
      &.active {
        .toggle button {
          transform: rotate(-90deg);
        }
      }
      &.header .toggle {
        height: 1px;
      }
      > div {
        padding: 15px 0;
      }
      .toggle {
        width: 15%;
        svg {
          display: block;
        }
        button {
          transition: 0.3s;
          border: none;
          background: transparent;
        }
      }
      .index {
        width: 15%;
      }
      .status {
        width: 20%;
        span {
          display: inline-block;
          padding: 2px 7px;
          font-weight: 700;
          font-size: 12px;
          border-radius: 5px;
        }
        &.PENDING span {
          background: #dedede;
        }
        &.CONFIRMED span,
        &.READY span {
          color: #429b8c;
          background: #e7f5f2;
        }
        &.CANCELLED span {
          color: #f1541d;
          background: #fef6f4;
        }
        &.COMPLETED span {
          color: #f92fa0;
          background: #f3e7f2;
        }
        &.PICKUP span {
          color: #42459b;
          background: #e7e8f5;
        }
      }
      .createdAt {
        width: 30%;
      }
      .price {
        width: 20%;
      }
      .products {
        padding: 0;
        width: 100%;
        overflow: hidden;
        transition: 0.3s;
        box-sizing: border-box;
        .tit {
          text-align: left;
          font-size: 18px;
          font-weight: 700;
          display: block;
          padding: 30px 30px 5px;
          border-top: 1px solid #e7e7ed;
          &.cancelReason {
            padding: 30px;
          }
        }
        ul {
          border: 1px solid #e7e7ed;
          border-radius: 4px;
          margin: 0 30px 30px;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            &:not(.header) .media {
              height: 50px;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .product {
              text-align: center;
              &.media {
                width: 20%;
              }
              &.name {
                width: 40%;
              }
              &.price {
                width: 20%;
              }
              &.quantity {
                width: 20%;
              }
            }
          }
        }
      }
    }
  }
  ul.pagination {
    gap: 5px;
    li {
      cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
    }
    a {
      color: rgba(0, 0, 0, 0.5);
      cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
      transition: 0.2s;
    }
    li:hover,
    li.active {
      background: transparent;
      border-color: #e2e2e2;
      font-weight: normal;
      a {
        color: rgb(243, 78, 91);
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 10px 20px;
    height: calc(100% - 76px);
    ul.orders {
      .header {
        font-size: 12px;
      }
      > li {
        font-size: 12px;
        > div {
          padding: 10px 0;
        }
        .toggle {
          width: 10%;
        }
        .createdAt {
          width: 35%;
        }
        .products {
          .tit {
            font-size: 14px;
            padding: 15px 15px 5px;
          }
          ul {
            margin: 0px 15px 15px;
            li .product.media {
              width: 25%;
            }
            li .product.quantity {
              width: 10%;
            }
          }
        }
      }
    }
  }
`;

export const SelectedProductContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 250px;
  height: 100px;
  padding: 0 15px;
  z-index: 3;
  pointer-events: "none";
  background: #6976eb;
  color: #fff;
  font-size: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
  &.inactive {
    opacity: 0;
  }
  &.active {
    opacity: 1;
  }
  strong {
    font-weight: normal;
  }
  span {
    margin-top: 5px;
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.53);
  }
  .doughnut {
    width: 65px;
    height: 65px;
  }
`;

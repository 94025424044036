import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import uuid from "react-uuid";

import HomePageSettingMenu from "../common/homepageSettingMenu";
import RegisterTitle from "./registerTitle";
import RegisterMenu from "./registerMenu";
import ImageUploadAdapterPlugin from "../utils/ImageUploadAdapter";
import Modal from "../../../../../common/modal";
import CustomModal from "../../../../../common/customModal";

import { setRegisterIndex } from "../../../../../store/compoIndex";

export default function RegisterPhotoUploadForm({ fileBoxImages }) {
  const navigater = useNavigate();
  const index = useSelector((state) => state.compoIndex.register);
  const ckeditorInstanceRef = useRef(null);
  const [content, setContent] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState();
  const [serverTitleImg, setServerTitleImg] = useState();
  const user = useSelector((state) => state?.user?.data[0]);
  const dispatch = useDispatch();
  const params = useParams();
  const portNo = params.portNo;

  useEffect(() => {
    if (portNo) {
      getPortfolio();
    }

    if (Array.isArray(fileBoxImages) && fileBoxImages.length > 0) {
      const imageHtml = fileBoxImages
        .map((item) => {
          return `<figure class="image"><img src="${imgHandler(item.filePath)}"></figure>`;
        })
        .join("");
      const thumbnail = fileBoxImages[0]?.filePath;

      setContent(imageHtml);
      setServerTitleImg(thumbnail);
    }
  }, []);

  const getPortfolio = async () => {
    const apiUrl = "/api/portMgmtList";
    const body = {
      portNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 12,
      paged: true,
    };
    const response = await axios.post(apiUrl, body);
    const res = response.data.content;
    setTitle(res[0].title);
    setContent(res[0].contents);
    setServerTitleImg(res[0].imgUrl);
  };

  const handleEditorChange = (_, editor) => {
    const newContent = editor.getData();
    setContent(newContent);
  };

  const saveAndNext = async () => {
    const images = extractImages(content);
    await uploadImage(images);
  };

  const extractImages = (content) => {
    const imgRegex = /<img.*?src=['"](data:image\/\w+;base64,.*?)['"].*?>/g;
    const matches = content.match(imgRegex);
    const images = [];

    if (matches) {
      matches.forEach((match) => {
        const dataUriMatch = match.match(/src=['"](data:image\/\w+;base64,.*?)['"]/);
        if (dataUriMatch && dataUriMatch[1]) {
          images.push(dataUriMatch[1]);
        }
      });
    }

    return images;
  };

  const uploadImage = async (images) => {
    console.log(images);
    let formData = new FormData();
    images.forEach((image, index) => {
      let blob = dataURItoBlob(image);
      let extension = blob.type.split("/")[1];
      formData.append("files", blob, `${uuid()}.${extension}`);
    });
    formData.append("ids", "N");
    formData.append("mberNo", user.mberNo);

    let titleImg = "";
    fetch("/api/uploadFiles", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        return data.responseData;
      })
      .then((imageDatas) => {
        let imageUrls = [];
        imageDatas.map((item) => imageUrls.push(item.fileName));
        titleImg = imageUrls[0];
        const reqContent = replaceImagesWithUrls(content, imageUrls);
        setContent(reqContent);
        return reqContent;
      })
      .then((reqContent) => lastSubmit(reqContent, titleImg))
      .catch((error) => {
        console.error("업로드 중 오류 발생:", error);
      });
  };

  const replaceImagesWithUrls = (content, imageUrls) => {
    let updatedContent = content;
    const dataUriRegex = /src=['"]data:image\/[^;'"]+;base64,([^'"]+)['"]/g;
    let matches = updatedContent.match(dataUriRegex);
    imageUrls.forEach((imageUrl, index) => {
      updatedContent = updatedContent.replace(matches[index], `src="${imgHandler(imageUrl)}"`);
    });
    setContent(updatedContent);
    return updatedContent;
  };

  const lastSubmit = async (reqContent, titleImg) => {
    let url = "/api/portfolio";
    let request = {
      cmpnyNo: Number(user.cmpnyNo),
      notitleYn: "Y",
      title: title,
      contents: reqContent,
      useYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
      imgUrl: titleImg ? titleImg : serverTitleImg,
    };
    if (portNo) {
      request.portNo = Number(portNo);
    }

    const res = await axios.put(url, request);
    if (res.data.success === true) {
      if (portNo) {
        navigater("/mypage/register", { state: { editPort: true } });
      } else {
        navigater("/mypage/register", { state: { uploadPort: true } });
      }
      dispatch(setRegisterIndex(2));
    }
  };

  const openModal = () => {
    if (!checkValidation()) {
      return;
    }
    setModalOpen(true);
  };

  const checkValidation = () => {
    if (!title) {
      toast.error("제목을 입력해 주세요.");
      return false;
    }
    if (!content) {
      toast.error("내용을 입력해 주세요.");
      return false;
    }
    return true;
  };

  const moveToPage = () => {
    navigater("/mypage/register");
    dispatch(setRegisterIndex(2));
  };

  return (
    <div className="inner_content">
      <HomePageSettingMenu />
      <RegisterTitle />
      <RegisterMenu index={index} />
      <div className="hp_step_content">
        <div className="writing_cont" style={{ marginBottom: "1rem" }}>
          <div className="inputxt_normal">
            <input
              type="text"
              placeholder="제목을 입력하세요."
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={handleEditorChange}
          config={{
            extraPlugins: [ImageUploadAdapterPlugin],
            toolbar: ["bold", "italic", "|", "link", "imageUpload", "mediaEmbed"],
            uiSettings: {
              height: "500px",
            },
          }}
          ref={ckeditorInstanceRef}
        />
        {/* <div className="hp_step_gall_write">
          <ul className="hp_step_gall_img_wrap">
            <li>
              <div className="hp_gall_add_wrap">
                <input type="text" readOnly="readonly" defaultValue />
                <label className="hp_gall_img_add">
                  + 대표 이미지 업로드
                  <input type="file" />
                </label>
              </div>
              <div className="hp_gall_img_wrap">
              </div>
              <div className="inputxt_normal">
                <select name="hp_gall_select_cate" id="hp_gall_select_cate">
                  <option value>카테고리 A</option>
                  <option value>카테고리 B</option>
                  <option value>카테고리 C</option>
                </select>     
                <input type="text" placeholder="제목을 입력하세요." />
                <textarea rows={10} placeholder="이미지 설명글을 입력하세요." style={{resize: 'none', outline: 'none', borderColor: '#ccc'}} defaultValue={""} />
              </div>                       
            </li>
            <li>
              <div className="hp_gall_add_wrap">
                <input type="text" readOnly="readonly" defaultValue />
                <label className="hp_gall_img_add">
                  + 본문 이미지 업로드
                  <input type="file" />
                </label>
              </div>
              <div className="hp_gall_img_wrap">
              </div>
              <div className="inputxt_normal">
                <textarea rows={10} placeholder="이미지 설명글을 입력하세요." style={{resize: 'none', outline: 'none', borderColor: '#ccc'}} defaultValue={""} />
              </div>                       
            </li>
          </ul>
          <button className="hp_step_gall_img_add">이미지 추가하기</button>
        </div> */}
        <div className="btnarea_center mt20 port_btn_wrapper" style={{ marginTop: "2rem" }}>
          <button
            style={{ border: "none" }}
            onClick={() => navigater(-1)}
            className="btn_normal_cancel port_btn">
            <span>취소</span>
          </button>
          <button
            style={{ border: "none" }}
            onClick={openModal}
            className="btn_normal_save port_btn">
            <span>{portNo ? "수정" : "저장"}</span>
          </button>
          <button style={{ border: "none" }} onClick={moveToPage} className="btn_normal port_btn">
            <span>목록</span>
          </button>
        </div>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={() => setModalOpen(false)}
        html={
          <CustomModal
            title={`포트폴리오`}
            content={`포트폴리오를 ${portNo ? "수정" : "등록"} 하시겠습니까?`}
            handleConfirm={saveAndNext}
            handleCancel={() => setModalOpen(false)}
          />
        }
        w="300px"
        h="auto"
      />
    </div>
  );
}

import { useOutletContext } from "react-router";

import CuteBanner from "../shopping/component/themes/cute/banner";
import ModernBanner from "../shopping/component/themes/modern/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";
import SearchForm from "./component/searchForm";

import { ModernSearchContaienr } from "../shopping/component/styled/modernTheme";
import { ChicSearchContaienr } from "../shopping/component/styled/chicTheme";
import { SimpleSearchContaienr } from "../shopping/component/styled/simpleTheme";
import { CuteSearchContaienr } from "../shopping/component/styled/cuteTheme";
import { SmSearchContaienr } from "../shopping/component/styled/smTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";

const Search = () => {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme.toString()) {
      case "0":
        return (
          <DefaultContaienr>
            <SearchForm />
          </DefaultContaienr>
        );
      case "1":
        return (
          <ChicSearchContaienr>
            <SearchForm />
          </ChicSearchContaienr>
        );
      case "2":
        return (
          <ModernSearchContaienr>
            <ModernBanner />
            <SearchForm />
          </ModernSearchContaienr>
        );
      case "3":
        return (
          <CuteSearchContaienr>
            <CuteBanner />
            <SearchForm />
          </CuteSearchContaienr>
        );
      case "4":
        return (
          <SimpleSearchContaienr>
            <SimpleBanner />
            <SearchForm />
          </SimpleSearchContaienr>
        );
      case "sm":
        return (
          <SmSearchContaienr>
            <SearchForm />
          </SmSearchContaienr>
        );
      default:
        return <SearchForm />;
    }
  };

  return <>{renderComponent()}</>;
};

export default Search;

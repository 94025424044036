import styled from "styled-components";

export const ShopSideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  .sideMenuInner {
    max-width: 100%;
    width: 1420px;
    height: 100%;
    position: relative;
    margin: auto;
    padding: 0 40px;
    display: flex;
    right: -10px;
    justify-content: space-between;
  }
  .sideMenuWrapper {
    min-width: 300px;
    position: absolute;
    top: 60px;
    right: 0;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #000;
  }
  .sideMenuContainer {
    position: relative;
    max-width: 300px;
    .arrowUp {
      position: absolute;
      top: 1px;
      transform: translateY(-100%);
      right: 9px;
      width: 0;
      height: 0;
      border-bottom: 15px solid rgb(239, 239, 239);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
    }
    .sideMenuHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background: rgb(239, 239, 239);
      border-bottom: 2px solid #efefef;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .logo {
        font-size: 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 30px;
        }
      }
      .close {
        button {
          font-size: 25px;
          border: none;
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .sideMenuBody {
      padding: 20px;
      max-height: 450px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 9px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 3px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-button:vertical:start:decrement {
        display: block;
        width: 3px;
      }
      &::-webkit-scrollbar-button:vertical:end:decrement {
        display: block;
        width: 3px;
      }
      .tableEditAddBtnArea {
        padding: 20px;
      }
      .tit {
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 22px;
        padding: 10px;
        background: #efefef;
        border-radius: 10px 10px 0 0;
        span {
          font-size: 18px;
        }
      }
      .sections {
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .sideSection {
        font-size: 16px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        padding: 10px;
        border: 1px solid #efefef;
        border-radius: 0 0 10px 10px;
        li {
          width: calc(50% - 5px);
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .sideMenuInner {
      width: 96%;
    }
  }
  @media screen and (max-width: 1050px) {
    .sideMenuInner {
      padding: 0;
    }
    .sideMenuWrapper {
      right: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .sideMenuInner {
      width: 90%;
    }
  }
`;

export const SlidingSideMenuContainer = styled.div`
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999;
  color: #000;
  transform: translateX(100%);
  transition: 0.3s;
  letter-spacing: -1px;
  font-size: 18px;
  &.active {
    transform: translateX(0);
  }
  &.theme-1 {
    background: #000;
    color: #fff;
    button {
      color: #fff;
    }
  }
  &.theme-2 {
    background: #fff9f1;
    .sideMenuInfo button.home {
      font-family: "Prata", "Pretendard";
    }
  }
  &.theme-3 {
    background: #ffc96f;
  }
  .sideMenuInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    img {
      width: 30px;
      height: 30px;
    }
    button {
      border: none;
      background: transparent;
      &.home {
        font-size: 20px;
        font-weight: 600;
      }
      &.close {
        font-size: 25px;
        svg {
          display: block;
          margin: auto;
        }
      }
    }
  }
  .wrapper {
    .toggle {
      width: 100%;
      margin: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: transparent;
      font-weight: 700;
    }
    ul {
      height: 0;
      overflow: hidden;
      margin-bottom: 10px;
      transition: 0.3s;
      li {
        margin: 10px;
        cursor: pointer;
      }
    }
  }
  .tnb {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
    li {
      cursor: pointer;
    }
  }
`;

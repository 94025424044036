import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import MypageBanner from "../mypageBanner";
import RegisterTitle from "./component/register/registerTitle";
import RegisterMenu from "./component/register/registerMenu";
import HomePageSettingMenu from "./component/common/homepageSettingMenu";
import BoardView from "../../../common/board/boardView";

import { setRegisterIndex } from "../../../store/compoIndex";
import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";

export default function RegisterNoticeView() {
  const index = useSelector((state) => state.compoIndex.register);
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);
  const dispatch = useDispatch();

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("register")) {
      dispatch(setRegisterIndex(3));
    }
  });

  return (
    <Container className={setting?.mode}>
      <PageInfo label="공지사항" />
      <div className="content">
        <HomePageSettingMenu />
        <RegisterTitle />
        <RegisterMenu index={index} />
        <BoardView userName={user?.userName} cmpnyNo={user?.cmpnyNo} />
      </div>
    </Container>
  );
}

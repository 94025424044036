import React from "react";
import { FaStar } from "react-icons/fa6";
import { RatingContainer } from "../styled/review";

const Rating = ({ rating }) => {
  return (
    <RatingContainer className="rating">
      {[...Array(5)].map((_, index) => (
        <span key={index} className={index < rating ? "star filled" : "star"}>
          <FaStar />
        </span>
      ))}
    </RatingContainer>
  );
};

export default Rating;

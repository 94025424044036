import React, { useEffect, useState } from "react";
import FilePreview from "../../common/filePreview";
import { DetailProductContainer } from "../../../styled/product";
import moment from "moment";
import { Modal } from "../../../styled/common";
import { SubmitButton } from "../../../../../styled/mypage";
import { useSelector } from "react-redux";

const DetailProduct = ({ closeModal, selectedProduct, categoriesObj, setIsDetail }) => {
  const setting = useSelector((state) => state?.user?.setting);

  const fields = [
    { name: "상품명", key: "name", type: "string" },
    { name: "브랜드", key: "brand", type: "string" },
    { name: "상품설명", key: "description", type: "string" },
    { name: "규격", key: "specifications", type: "string" },
    { name: "가격", key: "price", type: "number" },
    { name: "상품재고", key: "quantity", type: "number" },
    // {name:'태그',key:"tags"},
  ];

  const isExist = (data) => {
    console.log(data);
    if (!data) return false;
    if (!data.length) return false;
    return true;
  };

  return (
    <Modal className={setting?.mode}>
      <DetailProductContainer className={`modal ${setting?.mode}`}>
        <div className="modalContent">
          <div className="modalInfo">
            <h3>상품 확인</h3>
            <button onClick={() => closeModal()}>
              <i className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
          <ul>
            {isExist(selectedProduct?.media) && (
              <li className="media">
                <span className="tit">상품 이미지</span>
                <div className="content">
                  {selectedProduct.media.map((item) => (
                    <FilePreview item={{ path: item }} setIsDetail={setIsDetail} />
                  ))}
                </div>
              </li>
            )}
            {fields.map((item) => (
              <li>
                <span className="tit">{item?.name}</span>
                <span className="content">
                  {item?.type === "string"
                    ? selectedProduct?.[item?.key] || "-"
                    : `${selectedProduct?.[item?.key].toLocaleString() || 0}`}
                </span>
              </li>
            ))}
            <li>
              <span className="tit">카테고리</span>
              <span className="content">{categoriesObj[selectedProduct?.categoryId]}</span>
            </li>
            <li className="tags">
              {isExist(selectedProduct?.tags)}
              <span className="tit">태그</span>
              <div className="content">
                -
                {/* {selectedProduct.tags.map((item, idx) => (
                  <span>
                    {item}
                    {idx !== selectedProduct.tags.length - 1 && ", "}
                  </span>
                ))} */}
              </div>
            </li>
            <li>
              <span className="tit">상품생성날짜</span>
              <span className="content">
                {moment(selectedProduct.createdAt).format("YYYY-MM-DD")}
              </span>
            </li>
            <li>
              <span className="tit">상품연동날짜</span>
              <span className="content">
                {moment(selectedProduct.updatedAt).format("YYYY-MM-DD")}
              </span>
            </li>
          </ul>
          <div className="actions">
            <button onClick={() => closeModal()}>확인</button>
          </div>
        </div>
      </DetailProductContainer>
    </Modal>
  );
};

export default DetailProduct;

const FilePreview = ({ item, setIsDetail }) => {
  console.log(item);
  if (!item || !item.path) return null;

  const { file, path } = item;
  const isBlob = path.startsWith("blob:");
  const isImage = isBlob
    ? file?.type?.startsWith("image/")
    : /\.(jpg|jpeg|png|gif|bmp|webp|svg|ico|tiff|tif|heic|heif)$/i.test(path);
  const isVideo = isBlob ? file?.type?.startsWith("video/") : /\.(mp4|webm|ogg)$/i.test(path);

  return isImage ? (
    <img src={imgHandler(path)} alt="uploaded preview" onClick={() => setIsDetail(true)} />
  ) : isVideo ? (
    <video autoPlay>
      <source src={imgHandler(path)} type={file?.type || "video/mp4"} />
    </video>
  ) : null;
};

export default FilePreview;

import React from "react";

export const mainSection = [
  {
    key: "group",
    title: "모임",
    info: "협력과\n소통의 공간",
    details: "동네 소모임부터\n대규모 행사까지 손쉽게 관리",
    path: "/group",
  },
  {
    key: "scheduler",
    title: "캘린더",
    info: "다양한 일정과\n쉬운 이벤트 관리",
    details: "모임 내 중요한 일정들을\n손쉽게 계획하고 쉽게 공유",
    path: "/mypage/scheduler",
  },
  {
    key: "project",
    title: "프로젝트",
    info: "목표와 작업을\n체계적으로 관리",
    details: "프로젝트 목표 설정, 작업 분배,\n-진행 상황 추적 등 쉽게 처리",
    path: "/mypage/projectList",
  },
  {
    key: "shop",
    title: "e층창고",
    info: "내 마음대로 만드는\n나만의 홈페이지",
    details: "편리한 인터페이스와\n다양한 카테고리 제공",
    path: "/shop",
  },
  {
    key: "subscribe",
    title: "구독",
    info: "어디서도 볼 수 없는 모노티의\n특별한 구독 서비스",
    details: "템플렛, 디자인모드, 호스팅 등 주문 예약 기능을 저렴하게",
    path: "/info/subscribe",
  },
];

export const websiteCreationCards = [
  {
    highlight: "오직 모노티에서만",
    details: ["맞춤형 광고 의뢰 가능", "결제 시스템 제공", "회사 이름만 적어도 Ok"],
  },
  {
    highlight: "타사대비 저렴한 수수료",
    details: ["앱 수익의 단 5%만"],
  },
];

export const storeServiceCards = [
  {
    highlight: "오직 모노티에서만",
    details: ["픽업오더 이용 가능", "모노티 플랫폼 입점 기회", "배달 오더 기능 이용 가능"],
  },
  {
    highlight: "모노티 플랫폼 입점 혜택",
    details: [
      "맞춤형 광고 의뢰 가능",
      "매장 홍보 지원 서비스 제공",
      "실시간 매출 분석 리포트 제공",
    ],
  },
  {
    highlight: "타사 대비 저렴한 수수료",
    details: ["앱 수익의 단 5%만"],
  },
];

import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  user-select: none;
  cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
  button,
  a,
  input,
  label,
  textarea,
  select,
  body {
    cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
  }
  position: relative;
  &.bg {
    background: #1e1e1e;
  }
  .container {
    width: 100%;
    height: calc(100vh - 85px);
    display: flex;
  }
  .sidemenu {
    width: 350px;
    height: 100%;
    background: #f7f7f7;
  }
  .content {
    width: calc(100% - 350px);
    height: 100%;
    font-size: 26px;
    &:not(.pickup) {
      background: #f7f7f7;
      padding: 20px;
    }

    .viewer {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      overflow: hidden;
      border-radius: 20px;
      background: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      height: calc(-50px + 100vh);
    }
  }
`;

export const SideMenuContainer = styled.div`
  width: 350px;
  height: 100%;
  background: rgb(247, 247, 247);
  font-size: 26px;
  .store {
    width: 100%;
    padding: 20px 30px 20px;
    font-weight: 700;
    span {
      margin-bottom: 20px;
      display: block;
    }
    .selected {
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      border-radius: 38px;
      text-align: center;
      padding: 0 30px;
      gap: 10px;
      .icon {
        font-size: 25px;
        svg {
          display: block;
        }
      }
      select {
        border: none;
        width: calc(100% - 35px);
        background: url(/img/arrow_down.png) 100% center no-repeat;
        padding-left: 5px;
        font-size: 18px;
        &:focus {
          outline: none;
          border: none !important;
          box-shadow: none;
        }
      }
    }
  }
  .tabs {
    ul li {
      height: 100px;
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 0 0 0 33px;
      font-weight: 700;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      margin: 20px;
      border-radius: 20px;
      &.active {
        background: #255dac;
        color: #fff;
      }
      .icon {
        width: 50px;
        height: 50px;
        display: inline-block;
        touch-action: none;
        font-weight: 700;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
    height: 173px;
    .store {
      padding: 20px;
      > span {
        display: none;
      }
      .selected {
        width: calc((100% - 60px) / 4);
        height: 58px;
        font-size: 16px;
        padding: 0 20px;
        select {
          width: 100%;
          background-size: 10px;
        }
        .icon {
          display: none;
        }
      }
    }
    .tabs {
      padding: 0 20px 20px 20px;
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        li {
          width: calc((100% - 60px) / 4);
          overflow: hidden;
          font-size: 20px;
          margin: 0;
          padding: 0;
          justify-content: center;
          height: 55px;
          gap: 15px;
          .icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    background: #fff;
    height: 84px;
    .store {
      padding: 10px 20px;
      .selected {
        padding: 0 10px;
        font-size: 12px;
        height: 34px;
        width: 130px;
        select {
          font-size: 12px;
        }
      }
    }
    .tabs {
      padding: 0 20px;
      ul {
        gap: 0;
        li {
          box-shadow: none;
          width: 25%;
          height: 30px;
          font-size: 12px;
          border-radius: 0;
          border-bottom: 3px solid transparent;
          .icon {
            display: none;
          }
          &.active {
            background: none;
            color: #000;
            border-bottom: 3px solid #255dac;
          }
        }
      }
    }
  }
`;

export const Header = styled.header`
  height: 85px;
  background: #000;
  color: #fff;
  position: relative;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
  .toggle {
    width: 100px;
    .switch {
      cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
      display: block;
      width: 100%;
      height: 50px;
      background: #fff;
      border-radius: 50px;
      position: relative;
      &.close {
        background: #ccc;
      }
    }
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + .slider {
      position: absolute;
      top: 50%;
      left: 5px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition: 0.3s;
      transform: translate(calc(100% + 10px), -50%);
      background: rgb(62, 80, 218);
    }
    input[type="checkbox"]:checked + .slider {
      transform: translateY(-50%);
      background: #000;
    }
    span {
      font-size: 18px;
      display: block;
      text-align: center;
    }
  }
  button {
    cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
    background: transparent;
    color: #fff;
    border: none;
  }
  .actions {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: -1px;
    display: flex;
    gap: 20px;
    align-items: center;
    .refresh {
      font-size: 50px;
      display: flex;
      svg {
        stroke-width: 3px;
      }
    }
    .close {
      font-size: 70px;
      display: flex;
      svg {
        stroke-width: 10px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: 50px;
    padding: 0 20px;
    .toggle {
      width: auto;
      display: flex;
      gap: 10px;
      align-items: center;
      .switch {
        width: 60px;
        height: 30px;
      }
      input[type="checkbox"] + .slider {
        width: 24px;
        height: 24px;
        left: 3px;
        transform: translate(calc(100% + 6px), -50%);
      }
      input[type="checkbox"]:checked + .slider {
        transform: translateY(-50%);
      }
    }
    .actions {
      font-size: 16px;
      font-weight: 500;
      .refresh {
        font-size: 22px;
      }
      .close {
        font-size: 30px;
      }
    }
  }
`;

export const StoreMenu = styled.ul`
  @font-face {
    font-family: "JSArirangHON-Regular";
    src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/JSArirangHON-RegularA1.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }
  font-family: "JSArirangHON-Regular", sans-serif;
  display: flex;
  flex-wrap: wrap;
  gap: 1.3889vw;
  font-size: 5rem;
  width: 100%;
  height: 100vh;
  position: relative;
  justify-content: center;
  overflow: hidden;
  li {
    border-radius: 20px;
    transition: 0.3s;
    .content {
      position: relative;
      width: 100%;
      height: 100%;
    }
    &:hover {
      transform: scale(1.1);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      z-index: 3;
    }
    span {
      color: #333;
      position: absolute;
      width: 100%;
      transform: translateX(-50%);
      left: 50%;
      bottom: 4.8828vh;
      text-align: center;
      word-wrap: break-word;
    }
    img {
      max-width: 70%;
      height: 24.4141vh;
      object-fit: contain;
      position: absolute;
      left: 50%;
      top: 4.8828vh;
      transform: translateX(-50%);
    }
    &:nth-child(1),
    &:nth-child(2) {
      height: 49.5117vh;
    }
    &:nth-child(3),
    &:nth-child(4) {
      height: calc(100vh - 49.5117vh - 1.3889vw);
    }
    &:nth-child(1) {
      width: calc(60.5556vw - 5vw);
      background: #ede3fe;
      height: 49.5117vh;
    }
    &:nth-child(2) {
      width: calc(37.9861vw - 5vw);
      background: #fff3b2;
      height: 49.5117vh;
    }

    &:nth-child(3) {
      width: calc(38.75vw - 5vw);
      background-color: #e2b6a7;
      background-image: url("/img/store/menu.png");
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      .content {
        /* span {
          top: 2.0833vw;
          left: 2.0833vw;
          text-align: left;
          transform: none;
          font-size: 4.25vw;
        }
        .customers {
          position: absolute;
          bottom: 2.0833vw;
          right: 2.0833vw;
          color: #333;
          font-size: 6.33vw;
        } */
      }
    }
    &:nth-child(4) {
      width: calc(59.8611vw - 5vw);
      background: #c2e4ff;
    }
  }
  @media screen and (min-width: 1401px) {
    li.client .content {
      span {
        font-size: 90px;
      }
      .customers {
        font-size: 110px;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    li span {
      font-size: 4.3rem;
    }
  }
  @media screen and (max-width: 820px) {
    li {
      span {
        bottom: 3.3704vh;
      }
      &:nth-child(1),
      &:nth-child(4) {
        width: calc(100vw - 10vw);
        height: 30.2778vh;
        img {
          height: 13.8889vh;
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: calc((100% - 1.3889vw) / 2 - 5vw);
        height: 37.5vh;
        img {
          height: 13.8889vh;
        }
      }
      &:nth-child(3) .content {
        span {
          font-size: 35px;
        }
        .customers {
          font-size: 45px;
        }
      }
    }
  }
  @media screen and (max-width: 530px) {
    li span {
      font-size: 40px;
    }
  }
`;

export const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 15px;
    box-shadow: rgba(17, 17, 26, 0.2) 0px 7px 29px 0px;
    width: 605px;
    font-size: 26px;
    overflow: hidden;
    min-height: 300px;
    letter-spacing: -1px;
    button {
      cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
    }
    .modalHeader {
      height: 84px;
      line-height: 84px;
      background: #255dac;
      padding: 0 40px;
      color: #fff;
      h2 {
        display: inline-block;
        width: calc(100% - 40px);
      }
      button {
        font-size: 40px;
        border: none;
        background: transparent;
        color: #fff;
      }
    }
    .modalContent {
      height: calc(100% - 84px);
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .message {
        span {
          display: block;
        }
      }
    }
    .modalActions {
      height: 70px;
      line-height: 70px;
      width: 100%;
      text-align: center;
      button {
        height: 100%;
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 767px) {
    > div {
      width: 90%;
      font-size: 14px;
      min-height: 80px;
      .modalHeader {
        height: 40px;
        line-height: 40px;
        padding: 0px 20px;
        h2 {
          width: calc(100% - 16px);
        }
        button {
          font-size: 20px;
        }
      }
      .modalContent {
        padding: 20px;
        height: calc(100% - 40px);
      }
      .modalActions {
        height: 30px;
        line-height: 30px;
      }
    }
  }
`;

export const Alert = styled.div`
  height: 300px;
  .message {
    height: calc(100% - 75px);
  }
  .modalActions {
    button {
      border: none;
      width: 215px;
      border-radius: 38px;
      background: #255dac;
      color: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    height: 170px;
    .message {
      height: calc(100% - 50px);
    }
  }
`;

export const Select = styled.div`
  .modalContent.open {
    .choiceOption {
      height: 360px;
    }
    .selected button svg {
      transform: rotate(-180deg);
    }
  }
  height: 780px;
  .modalContent {
    gap: 20px;
  }
  .selected,
  .customInput {
    height: 72px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 0 25px;
    font-size: 24px;
  }
  .selected {
    border-radius: 38px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    button {
      border: none;
      background: transparent;
      font-size: 30px;
      svg {
        transition: 0.3s;
        display: block;
      }
    }
  }
  .customInput {
    display: block;
    border-radius: 38px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    &:focus {
      border: none !important;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      background: #ddd;
    }
  }
  .choiceOption {
    position: absolute;
    top: calc(100% + 10px);
    left: 0%;
    width: 100%;
    z-index: 22;
    height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    ul li {
      padding: 20px 25px;
      &.active {
        background: #dddddd;
      }
    }
  }
  .modalActions {
    margin-top: auto;
    button {
      width: 175px;
      background: transparent;
      border-radius: 38px;
      &:first-child {
        border: 1px solid #000;
      }
      &:last-child {
        margin-left: 30px;
        background: #255dac;
        color: #fff;
        border: none;
      }
    }
  }
`;

export const Choice = styled.div`
  height: 780px;
  .choiceOption {
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      li {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 38px;
        height: 72px;
        position: relative;
        font-weight: 700;
        &.active {
          background: #dddddd;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .modalActions {
    button {
      width: 175px;
      background: transparent;
      border-radius: 38px;
      &:last-child {
        margin-left: 30px;
        background: #255dac;
        color: #fff;
        border: none;
      }
    }
  }
`;

export const Confirmation = styled.div`
  height: 300px;
  .message {
    height: calc(100% - 75px);
  }
  .modalActions {
    button {
      width: 50%;
      border: 1px solid #272833;
      &:first-child {
        border-radius: 38px 0 0 38px;
        background: transparent;
      }
      &:last-child {
        background: #272833;
        color: #fff;
        border-radius: 0 38px 38px 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: 170px;
  }
`;

export const Selection = styled.div`
  height: 430px;
  .stepper {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 25px 55px;
    border: 1px solid #000;
    border-radius: 38px;
    span {
      width: calc(100% - 60px);
    }
    button {
      width: 30px;
      height: 30px;
      font-size: 30px;
      display: flex;
      border: none;
      background: transparent;
    }
  }
  .modalActions {
    button {
      width: 175px;
      background: transparent;
      border-radius: 38px;
      &:last-child {
        margin-left: 50px;
        background: #272833;
        color: #fff;
      }
    }
  }
`;

export const CustomInput = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 25px;
  width: 25px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    width: 0;
    height: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid #666;
    border-radius: 5px;
    background-color: transparent;
    transition: all 0.3s ease;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
      opacity: 0;
      width: 9px;
      height: 17px;
      border: solid #fff;
      border-width: 0px 3px 3px 0px;
      transition: 0.2s;
    }
  }
  input:checked ~ .checkmark {
    background: #255dac;
    border-color: #255dac;
    &:after {
      opacity: 1;
      transform: translate(-50%, -65%) rotate(45deg) scale(1);
      border-color: #fff;
    }
  }
  &:hover .checkmark {
    background-color: #efefef;
  }
`;

export const Interaction = styled.div`
  display: flex;
  gap: 15px;
  color: #bbb;
  width: 150px;
  overflow: hidden;
  font-size: 12px;
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      display: block;
    }
  }
`;

export const SwiperListContainer = styled.div`
  height: calc(100% - 50px);
  .wrapper {
    height: 100%;
    .box {
      border-bottom: 1px solid #666;
      overflow: hidden;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        padding-top: 0;
      }
      .slideContent {
        width: calc(100% - 150px);
      }
      .writer {
        font-size: 16px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }
      }
      .questions {
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
      }
    }
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      &.swiper-pagination-bullet-active {
        background: #525252;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    height: calc(100% - 40px);
  }
  @media screen and (max-width: 767px) {
    .wrapper .box {
      align-items: baseline;
      flex-direction: column;
      .slideContent {
        width: 100%;
        .writer {
          margin-bottom: 15px;
          font-size: 14px;
        }
      }
    }
  }
`;

export const SwiperPerViewContainer = styled.div`
  height: calc(100% - 50px);
  .wrapper {
    height: 100%;
    .contentSlide {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      height: calc(100% - 50px);
      padding: 10px;
      .box {
        width: calc((100% - 30px) / 3);
        height: calc((100% - 15px) / 2);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 20px;
        overflow: hidden;
        .image {
          width: 100%;
          height: calc(100% - 55px);
          background: #efefef;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .info {
          font-size: 20px;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            display: inline-block;
            width: calc(100% - 85px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
          }
          button {
            width: 85px;
            height: 25px;
            font-size: 14px;
            background: #e7e7e7;
            border: none;
            border-radius: 38px;
          }
        }
      }
    }
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      &.swiper-pagination-bullet-active {
        background: #525252;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    height: calc(100% - 40px);
    .wrapper .contentSlide .box {
      width: calc((100% - 15px) / 2);
    }
  }
  @media screen and (max-width: 767px) {
    .wrapper .contentSlide {
      height: calc(100% - 30px);
      .box {
        width: 100%;
        height: 100%;
        .image {
          height: calc(100% - 45px);
        }
        .info {
          padding: 10px 20px;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
`;

export const SelectDate = styled.div`
  margin-left: auto;
  > button {
    background: #255dac;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 31px;
    padding: 5px 20px;
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900;
  }
  .calendarContent {
    position: relative;
    .reset {
      height: 50px;
      position: absolute;
      top: 10px;
      left: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        font-weight: 700;
        background: rgb(42, 51, 65);
        color: rgb(255, 255, 255);
        border-radius: 10px;
        padding: 2px 10px;
        border: none;
      }
    }
  }
  .calendar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: min-content;
    height: min-content;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    overflow: hidden;
    .rdrDefinedRangesWrapper,
    .rdrDateDisplayWrapper {
      display: none;
    }
    .rdrDateRangeWrapper {
      font-size: 20px;
      font-weight: 700;
    }
    .rdrDateDisplayItem input,
    .rdrMonthAndYearPickers select,
    .rdrWeekDay,
    .rdrDay button {
      font-weight: 700;
    }
  }
`;

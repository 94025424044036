import React from "react";
import { formatCommas } from "../function/store";

const Products = ({ data }) => {
  return (
    <ul>
      <li className="header">
        <div className="product media">상품이미지</div>
        <div className="product name">상품명</div>
        <div className="product price">상품가격</div>
        <div className="product quantity">개수</div>
      </li>
      {data.map((product) => (
        <li>
          <div className="product media">
            <img
              src={imgHandler(JSON.parse(product.media))}
              alt={product?.name}
              onError={onErrorImg}
            />
          </div>
          <div className="product name">{product.name}</div>
          <div className="product price">{formatCommas(product?.price)}원</div>
          <div className="product quantity">{product.quantity}</div>
        </li>
      ))}
    </ul>
  );
};

export default Products;

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

import { onClickLogout } from "../../../store/user";

import Modal from "../../../common/modal";
import ShopSideMenu from "./component/shopSideMenu";
import SlidingSideMenu from "./component/slidingSideMenu";
import Logo from "./component/common/logo";
import Gnb from "./component/common/gnb";
import Tnb from "./component/common/tnb";
import { HeaderContainer } from "./component/styled/header";

const ShoppingHeader = ({ url, homepageInfo, theme, isMatch, menus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [modalOpen, setModalOpen] = useState(false);
  const [cmpnyNm, setCmpnyNm] = useState("");
  const [logo, setLogo] = useState("");
  const [clickSideMenu, setClickSideMenu] = useState(false);

  useEffect(() => {
    if (homepageInfo?.cmpnyNo && !homepageInfo?.logoText && !homepageInfo?.logoImage) {
      getCmpnyNm();
    }
    if (homepageInfo?.logoImage) {
      getLogo();
    }
  }, [homepageInfo]);

  const getLogo = async () => {
    setLogo(await imgHandler(homepageInfo?.logoImage));
  };

  const handleHome = () => {
    navigate(`/shop/${url ? url : ""}`);
  };

  const handleLogoutModal = () => {
    if (clickSideMenu) setClickSideMenu(false);
    setModalOpen(!modalOpen);
  };

  const onClickModalCheck = async () => {
    await dispatch(onClickLogout());
    setModalOpen(false);
    navigate(`/shop${url ? `/${url}` : ""}`);
  };

  const getCmpnyNm = async () => {
    const url = "/api/cmpnyNmLoad";
    const body = {
      cmpnyNo: homepageInfo?.cmpnyNo,
    };
    const response = await axios.post(url, body);
    setCmpnyNm(response.data.cmpnyNm);
  };

  const onClickCancel = () => {
    setModalOpen(false);
    navigate(`/shop/${url}`);
  };

  const links = {
    0: "company",
    1: "productsList",
    2: "portfolio",
    3: "shoppingNotice",
  };

  const LogoutModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">로그아웃</h2>
        <button onClick={() => handleLogoutModal()} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          로그아웃 하시겠습니까?
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}>
          <button
            onClick={() => onClickCancel()}
            className="hp_step_setting_cate_save"
            style={{
              padding: "10px 20px",
              margin: "0px",
              background: "#6C757D",
            }}>
            취소
          </button>
          <button
            onClick={() => onClickModalCheck()}
            className="hp_step_setting_cate_save"
            style={{
              padding: "10px 20px",
              margin: "0px",
            }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        modalState={modalOpen}
        handleModalState={handleLogoutModal}
        html={LogoutModalForm()}
        w="300px"
        h="auto"
      />
      <HeaderContainer
        style={{ position: theme !== "sm" ? "relative" : "fixed" }}
        className={!isMatch ? `theme-${theme}` : ""}>
        <div className="inner">
          <Logo
            logo={logo}
            handleHome={handleHome}
            homepageInfo={homepageInfo}
            cmpnyNm={cmpnyNm}
            theme={theme}
            isMatch={isMatch}
          />
          <Gnb
            menus={menus}
            links={links}
            url={url}
            theme={theme}
            isMatch={isMatch}
            homepageInfo={homepageInfo}
          />
          <Tnb
            handleLogoutModal={handleLogoutModal}
            url={url}
            homepageInfo={homepageInfo}
            setClickSideMenu={setClickSideMenu}
            theme={theme}
            isMatch={isMatch}
          />
        </div>
      </HeaderContainer>
      {theme !== "sm" && !isMobile ? (
        <ShopSideMenu
          setClickSideMenu={setClickSideMenu}
          clickSideMenu={clickSideMenu}
          user={user}
          url={url}
          menus={menus}
          homepageInfo={homepageInfo}
        />
      ) : (
        <SlidingSideMenu
          homepageInfo={homepageInfo}
          handleLogoutModal={handleLogoutModal}
          setClickSideMenu={setClickSideMenu}
          clickSideMenu={clickSideMenu}
          url={url}
          menus={menus}
          theme={theme}
        />
      )}
    </>
  );
};

export default ShoppingHeader;

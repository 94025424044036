import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import moment from "moment";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

const News = ({ cmpnyNo, url }) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [notices, setNotices] = useState([]);
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  useEffect(() => {
    if (cmpnyNo) {
      getNotices(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getNotices = async (cmpnyNo) => {
    const url = "/api/noticeList";
    const body = {
      cmpnyNo,
      gbn: "N",
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 12,
      paged: true,
    };
    try {
      const res = await axios.post(url, body);
      let data = res.data.content;

      if (Array.isArray(data) && data.length > 0) {
        const originalData = [...data];
        while (data.length < 12) {
          data = data.concat(originalData.slice(0, 12 - data.length));
        }
      } else {
        data = [];
      }

      setNotices(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwiperButton = (isPrev) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (isPrev) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  return notices && Array.isArray(notices) && notices.length > 0 ? (
    <div className="news">
      <div className="info">News</div>
      <Swiper
        ref={swiperRef}
        className="newsSwiper"
        modules={[Navigation, Autoplay]}
        slidesPerView={isMobile ? 1.5 : isTablet ? 2.4 : 3}
        spaceBetween={isMobile ? 20 : isTablet ? 50 : 100}
        navigation={true}
        loop={true}
        speed={500}
        autoplay={{ delay: 4500, disableOnInteraction: false }}>
        {notices.map((item, i) => (
          <SwiperSlide key={i}>
            <div className="swiper-wrapper">
              <div
                className="newsSlide"
                id={i}
                onClick={() => navigate(`/shop/${url}/shoppingNoticeView/${item.noticeNo}`)}>
                <div className="newsInfo">
                  <span>{item.noticeSj}</span>
                  <span>{moment(item.createDt).format("YYYY.MM.DD")}</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="actions">
        <button onClick={() => handleSwiperButton(true)}>
          <GrLinkPrevious />
        </button>
        <button>
          <GrLinkNext onClick={() => handleSwiperButton(false)} />
        </button>
      </div>
    </div>
  ) : null;
};

export default News;

import React from "react";
import PlanComparison from "./plan/planComparison";
import PlanList from "./plan/planList";

const Plan = () => {
  return (
    <section>
      <div className="inner">
        <div className="info">
          <span>모노티 구독 요금</span>
          <strong>템플릿, 디자인 모드, 호스팅 주문 및 예약 기능을 저렴하게!</strong>
        </div>
        <PlanList />
        <PlanComparison />
      </div>
    </section>
  );
};

export default Plan;

import React from "react";
import { PlanContainer } from "../../styled/info";
import { FaCheck } from "react-icons/fa6";

const PlanList = () => {
  const plans = {
    free: {
      name: "free",
      description: "간편한 것들만 모아서 ",
      monthlyPrice: 0,
      features: ["내맘대로 정하는 디자인", "내맘대로 정하는 디자인", "내맘대로 정하는 디자인"],
      label: "무료로 시작",
    },
    plus: {
      name: "plus",
      description: "시작하는 사장님들은",
      price: 10000,
      monthlyPrice: 4500,
      features: ["내맘대로 정하는 디자인", "내맘대로 정하는 디자인", "내맘대로 정하는 디자인"],
      label: "플러스",
    },
    premium: {
      name: "premium",
      description: "상생매장 사장님들을 위한",
      price: 20000,
      monthlyPrice: 9900,
      features: ["내맘대로 정하는 디자인", "내맘대로 정하는 디자인", "내맘대로 정하는 디자인"],
      label: "프리미엄",
    },
  };

  const PlanItem = ({ value }) => {
    return (
      <li className={value.name}>
        <p>{value.description}</p>
        <strong>{value.name}</strong>
        <div className="price">
          {value.price && <p className="origin">{value.price.toLocaleString()}원</p>}
          <div>
            {value.monthlyPrice ? (
              <span>
                <strong>{value.monthlyPrice.toLocaleString()}</strong>원/월
              </span>
            ) : (
              <span>
                <strong>{value.monthlyPrice}</strong>원
              </span>
            )}
          </div>
        </div>
        <ul className="feature">
          {value.features.map((feature, index) => (
            <li>
              <span className="icon">
                <FaCheck />
              </span>
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <button>{value.label}</button>
      </li>
    );
  };

  return (
    <PlanContainer>
      <ul>
        {Object.entries(plans).map(([key, value]) => (
          <PlanItem value={value} />
        ))}
      </ul>
      <span>표시된 가격은 부가세 별도입니다.</span>
      <button>매달 정기 결제</button>
    </PlanContainer>
  );
};

export default PlanList;

import styled from "styled-components";

export const CompanyInfoTitleContainer = styled.div`
  margin: 0px auto 100px;
  max-width: 1440px;
  padding: 0px 20px;
  text-align: center;
  &.dark {
    span {
      color: #fff;
    }
  }
  span {
    font-size: 20px;
    letter-spacing: -1px;
    display: block;
    color: rgba(0, 0, 0, 0.5);
    &:first-child {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 0px auto 50px;
    span {
      font-size: 10px;
      &:first-child {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
  }
`;

export const CompanyInfoFormContainer = styled.div`
  &.dark {
    ul li strong {
      color: rgba(255, 255, 255, 0.7);
    }
    ul li button {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
  .form {
    max-width: 1440px;
    margin: 0px auto 100px;
    padding: 0 20px;
  }
  ul {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: -1px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    li {
      width: 100%;
      strong {
        margin-bottom: 20px;
        display: block;
      }
      &.cmpnyNm,
      &.rprNm {
        width: calc(50% - 15px);
      }
      input[type="text"] {
        width: 100%;
        border: none;
        height: 63px;
        border-radius: 20px;
        padding: 0 20px;
        background: rgba(255, 255, 255, 0.5);
        &:focus {
          border: none !important;
          box-shadow: none;
        }
      }
      button {
        width: 160px;
        height: 63px;
        background: #e5e5e5;
        border-radius: 23px;
        border: none;
        color: rgba(0, 0, 0, 0.5);
      }
      &.bizrno input[type="text"],
      &.adres input[type="text"]:first-child {
        width: calc(100% - 180px);
        margin-right: 20px;
      }
      &.adres input[type="text"]:last-child {
        margin-top: 20px;
      }
    }
  }
  .submit {
    margin-top: 70px;
    text-align: center;
    button {
      width: 272px;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: #fff;
      background: rgba(37, 99, 235, 0.7);
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      /* padding: 20px; */
      font-size: 14px;
      gap: 20px;
      li {
        strong {
          margin-bottom: 10px;
          font-size: 16px;
        }
        button {
          height: 37px;
          width: 96px;
          border-radius: 10px;
        }
        input[type="text"] {
          height: 37px;
          border-radius: 10px;
        }
        &.bizrno input[type="text"],
        &.adres input[type="text"]:first-child {
          width: calc(100% - 106px);
          margin-right: 10px;
        }
        &.adres input[type="text"]:last-child {
          margin-top: 10px;
        }
        &.cmpnyNm,
        &.rprNm {
          width: calc(50% - 10px);
        }
      }
    }
  }
`;

import React from "react";

import { MobileOrderItems } from "../../styled/pickup";
import { calculateStatus, calculateTime } from "../../function/store";

import Progress from "../../common/progress";
import Requests from "../../common/requests";
import OrderMenu from "../../common/orderMenu";
import OrderActions from "./orderActions";
import OrderControls from "./orderControls";

import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import OrderInfo from "../../common/orderInfo";
import CustomerInfo from "../../common/customerInfo";

const OrderItem = ({
  item,
  index,
  pickupBy,
  rejectOrder,
  editPickupBy,
  acceptOrder,
  active,
  toggleItem,
  selected,
  contentRefs,
  openModal,
  completedOrder,
  setSelected,
  updateOrder,
  checkedOrders = [],
}) => {
  const isSelected = (item) => selected?.id === item.id;

  return (
    <MobileOrderItems key={item.id} className={active}>
      {!checkedOrders.includes(item.id) && <div className="dot"></div>}
      <div className="orderInfo" style={{ color: item.status === "CANCELLED" && "#FF6F6F" }}>
        <OrderInfo selected={item} />
        {active === "READY" && (
          <div className={`orderProgress ${calculateStatus(item)}`}>
            <div className="progressRemaining">{calculateTime(item)}분</div>
          </div>
        )}
      </div>
      <OrderActions
        item={item}
        active={active}
        index={index}
        editPickupBy={editPickupBy}
        pickupBy={pickupBy}
        openModal={openModal}
        completedOrder={completedOrder}
      />
      <div
        className="toggleButton"
        style={{ marginBottom: !isSelected(item) && active === "PICKUP" ? 0 : 10 }}>
        <button onClick={() => toggleItem(item)}>
          <span>주문정보</span>
          <div className="icon">{isSelected(item) ? <AiFillCaretUp /> : <AiFillCaretDown />}</div>
        </button>
      </div>
      <div
        className="orderItemContent"
        ref={(el) => (contentRefs.current[index] = el)}
        style={{
          maxHeight: isSelected(item) ? "200vh" : "0",
          marginBottom: isSelected(item) ? 10 : 0,
        }}>
        <Progress selected={selected} />
        <Requests remark={selected?.reqTerm} />
        <CustomerInfo selected={selected} />
        {isSelected(item) && <OrderMenu products={selected.products} />}
      </div>
      <OrderControls
        active={active}
        item={item}
        rejectOrder={rejectOrder}
        acceptOrder={acceptOrder}
        openModal={openModal}
        pickupBy={pickupBy}
        index={index}
        setSelected={setSelected}
        selected={selected}
        updateOrder={updateOrder}
      />
    </MobileOrderItems>
  );
};

export default OrderItem;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { GroupInner, GroupSection } from "./component/styled/common";

import { getSetting } from "../../store/scheduler";
import { handleLogin } from "../../store/user";

import LoginForm from "../login/component/loginForm";

const GroupLogin = () => {
  const user = useSelector((state) => state?.user?.data[0]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(handleLogin(user));
      navigate("/group/myGroup");
    }
  }, []);

  // 캘린더 설정 조회
  const getSchedulerSetting = async (mber) => {
    const url = "/api/toktokSettingList";
    const body = { mberNo: mber.mberNo };
    const res = await axios.post(url, body);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
      navigate("/group/myGroup");
    }
  };

  return (
    <GroupSection>
      <GroupInner>
        <LoginForm getSchedulerSetting={getSchedulerSetting} />
      </GroupInner>
    </GroupSection>
  );
};

export default GroupLogin;

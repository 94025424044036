import styled from "styled-components";

const darkBg = "#111111";
const darkSubColor = "#2b2b2b";
const darkPoint = "#533b70";
const darkPointColor = "#bb86fc";
const ef = "#efefef";
const mainColor = "#0074ca";
const borderRadius = "0.25em";
const aniTime = "0.2s";

export const AddrSearchModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 3;
  text-align: center;
  .modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    background: #fff;
    width: 400px;
    button {
      margin-top: 30px;
      border: none;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    .modalContent {
      width: 100%;
    }
  }
`;

export const SideFormList = styled.li`
  &.dark {
    &:hover {
      background: #000;
    }
  }
  cursor: pointer;
  border-bottom: 1px solid ${ef};
  &:hover {
    background: ${ef};
  }
  label {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    padding: 7px 0;
    cursor: pointer;
  }
`;

// ScheduleForm
export const ScheduleFormContainer = styled.div`
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  ul {
    width: 100%;
    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 10px;
      align-items: center;
      position: relative;
      .tit {
        width: 45px;
        font-size: 24px;
        padding-top: 5px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 12px;
        }
        svg {
          stroke-width: 0.5px;
        }
        &.palette {
          display: flex;
          align-items: center;
          position: relative;
          padding-top: 0;
          .current {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid ${ef};
            cursor: pointer;
          }
          .colors {
            transition: 0.3s;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            opacity: 0;
            z-index: -3;
            height: 0;
            overflow: hidden;
            input[type="radio"] {
              appearance: none;
              width: 25px;
              height: 25px;
              background: #000;
              border-radius: 50%;
              margin-right: 10px;
              border: 1px solid ${ef};
              cursor: pointer;
              z-index: -3;
            }
            &.open {
              left: 50px;
              z-index: 1;
              width: 210px;
              height: 28px;
              opacity: 1;
              overflow: visible;
            }
          }
        }
      }
      .content {
        width: calc(100% - 65px);
        &.title {
          position: relative;
          .iconSelect {
            width: auto;
            background: transparent;
            color: #000;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 24px;
            cursor: pointer;
          }
          .selectedIcon {
            width: 25px;
            position: absolute;
            left: 0;
            top: 45%;
            transform: translateY(-50%);
          }
          input {
            border-radius: 0;
            outline: none;
            border: none !important;
            border-bottom: 2px solid ${ef} !important;
            font-weight: 600;
            font-size: 16px;
            &:focus {
              box-shadow: none;
              border: none;
            }
          }
          .searchBg {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 3;
            ul {
              position: absolute;
              background: #fff;
              font-size: 14px;
              top: 200px;
              left: 50px;
              border: 1px solid #efefef;
              border-top: none;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
              padding: 5px 0;
              max-height: 287px;
              overflow-y: scroll;
              &.dark {
                background: #000;
                border: 1px solid #000;
                &::-webkit-scrollbar-thumb {
                  background-color: ${darkPoint};
                  border: 2px solid #000;
                }
                li {
                  background: #000;
                  .icon:not(.arrow) {
                    background: #363636;
                  }
                  &:hover {
                    background: #282828;
                  }
                }
              }
              &::-webkit-scrollbar {
                width: 8px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: ${mainColor};
                border: 2px solid #fff;
              }
              &::-webkit-scrollbar-track {
                background-color: transparent;
              }
              li {
                background: #fff;
                padding: 2px 10px;
                cursor: pointer;
                margin-bottom: 0;
                justify-content: flex-start;
                gap: 15px;
                position: relative;
                .icon {
                  display: flex;
                  border-radius: 50%;
                  color: #7e7e7e;
                  &:not(.arrow) {
                    background: #efefef;
                    padding: 5px;
                  }
                  &.arrow {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 17px;
                  }
                }
                &:hover {
                  background: #efefef;
                }
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
        &.date {
          &.allTime .dtBox input[type="date"] {
            width: 48%;
          }
          .dtBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            /* 커스텀 */
            input[type="date"] {
              width: calc((100% / 2) - 140px);
              position: relative;
              &::after {
                content: attr(day);
                position: absolute;
                top: 50%;
                right: 14px;
                transform: translateY(-50%);
                font-size: 13px;
              }
            }
            input[type="date"]::-webkit-calendar-picker-indicator {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: transparent;
              color: transparent;
              cursor: pointer;
            }
            .timeBox {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 120px;
              border: 1px solid ${ef};
              border-radius: ${borderRadius};
              select {
                width: 49%;
                background: none;
                border: none;
                text-align: center;
                padding: 7px 14px;
                height: calc(100%);
                &:focus {
                  box-shadow: none;
                  border: none;
                }
              }
            }
          }
          .timeCheckBox {
            margin-right: 10px;
            label {
              cursor: pointer;
              input[type="checkbox"] {
                margin-right: 10px;
              }
            }
          }
        }
        &.category {
          display: flex;
          gap: 1%;
          select {
            &:first-child {
              width: 24.5%;
            }
            &:first-child {
              width: 49.5%;
            }
          }
        }
        &.workers {
          .readOnly {
            height: auto;
            min-height: 37px;
          }
        }
        &.addr {
          display: flex;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          div {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            button {
              padding: 0 20px;
              background: ${ef};
              font-size: 22px;
              color: #000;
              margin-left: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                display: block;
              }
            }
          }
        }
        textarea {
          height: 200px;
        }
        .memoContent {
          height: 250px;
          padding: 20px 14px;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 5px;
          border: 1px solid ${ef};
          border-radius: ${borderRadius};
          position: relative;
          transition: 0.3s;
          cursor: text;
          label:hover .checkmark {
            background: transparent;
            cursor: default;
          }
          &.active {
            box-shadow: 0 0 5px #9ed4ff;
            border: 1px solid #558ab7 !important;
          }
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: ${mainColor};
            border: 2px solid #fff;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          > div {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            flex-wrap: wrap;
          }
          textarea {
            border: none !important;
            padding: 0;
            overflow: hidden;
            height: 20px;
            box-sizing: border-box;
            height: auto;
            &:focus {
              border: none !important;
              box-shadow: none;
            }
          }
          .mentions {
            position: absolute;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 5px;
            z-index: 33;
            width: auto;
            margin-top: 5px;
            li {
              padding: 3px 10px;
              margin-bottom: 5px;
              cursor: pointer;
              &:hover {
                background: #f5f5f5;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .select-mention {
            display: flex;
            gap: 10px;
            li {
              width: auto;
              gap: 5px;
              background: #efefef;
              padding: 0 5px;
              border-radius: 5px;
              span {
                font-weight: 500;
                font-size: 12px;
                color: #0074ca;
              }
              button {
                width: 15px;
                height: 15px;
                background: #bbb;
                svg {
                  display: block;
                  margin: auto;
                }
              }
            }
          }
        }
        &.todoList {
          .todos .todoItem {
            margin: 10px 0;
            display: flex;
            gap: 10px;
            button {
              background: ${ef};
              color: #000;
              width: 50px;
            }
          }
        }
        &.public {
          .radio-input input {
            display: none;
          }
          .radio-input {
            position: relative;
            display: flex;
            align-items: center;
            background-color: #fff;
            color: #000000;
            overflow: hidden;
            border: 1px solid ${ef};
            border-radius: ${borderRadius};
          }
          .radio-input label {
            width: 50%;
            padding: 7px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
          }
          .selection {
            display: none;
            position: absolute;
            height: 100%;
            width: 50%;
            z-index: 0;
            left: 0;
            top: 0;
            transition: 0.15s ease;
          }
          .radio-input label:has(input:checked) {
            color: #fff;
          }
          .radio-input label:has(input:checked) ~ .selection {
            background-color: ${mainColor};
            display: inline-block;
          }
          .radio-input label:nth-child(1):has(input:checked) ~ .selection {
            transform: translateX(0%);
          }
          .radio-input label:nth-child(2):has(input:checked) ~ .selection {
            transform: translateX(100%);
          }
        }
        &.project {
          display: flex;
          align-items: center;
          gap: 1%;
          position: relative;
          &.dark {
            input[type="text"] {
              border: 1px solid rgb(43, 43, 43);
              color: rgb(239, 239, 239);
            }
            ul {
              background: #000;
              border: 1px solid #000;
              &::-webkit-scrollbar-thumb {
                background-color: ${darkPoint};
                border: 2px solid #000;
              }
              li {
                background: #000;
                .icon:not(.arrow) {
                  background: #363636;
                }
                &:hover {
                  background: #282828;
                }
              }
            }
          }
          ul {
            position: absolute;
            top: 100%;
            z-index: 33;
            font-size: 14px;
            width: calc(99% - 150px);
            border: 1px solid rgb(239, 239, 239);
            background: #fff;
            max-height: 200px;
            overflow-y: auto;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            padding: 5px 0;
            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: ${mainColor};
              border: 2px solid #fff;
            }
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
            li {
              &:first-child {
                justify-content: center;
              }
              background: #fff;
              padding: 2px 10px;
              cursor: pointer;
              margin-bottom: 0;
              justify-content: flex-start;
              gap: 15px;
              position: relative;
              .icon {
                display: flex;
                border-radius: 50%;
                color: #7e7e7e;
                &:not(.arrow) {
                  background: #efefef;
                  padding: 5px;
                }
                &.arrow {
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  font-size: 17px;
                }
              }
              &:hover {
                background: #efefef;
              }
              &:last-child {
                border-bottom: none;
              }
            }
          }
          input {
            width: calc(99% - 150px);
            border: 1px solid rgb(239, 239, 239);
            outline: none;
            padding: 7px 14px;
            line-height: 21px;
            box-sizing: border-box;
            vertical-align: top;
            resize: none;
            border-radius: 0.25em;
            height: 37px;
            background: transparent;
          }
          button {
            width: 150px;
          }
        }
      }
    }
  }
  .btnArea {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    .delBtn {
      display: block;
      background: ${ef};
      color: ${darkBg};
      margin-left: 10px;
    }
  }

  @media screen and (min-width: 1050px) and (max-width: 1400px) {
    width: 60%;
  }
  @media screen and (min-width: 1051px) and (max-width: 1150px) {
    .content.date .dtBox input[type="date"] {
      font-size: 13px;
      padding: 7px 5px;
      &::after {
        top: 49%;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 10px;
    ul li {
      .content.date .dtBox {
        flex-wrap: wrap;
        gap: 5px 0;
        > span {
          display: none;
        }
        input[type="date"] {
          width: calc(100% - 90px);
          &::after {
            font-size: 10px;
          }
        }
        .timeBox {
          width: 80px;
        }
      }
    }
  }
`;

// ScheduleSideForm
export const ScheduleSideFormContainer = styled.div`
  height: calc(-64px + 100vh);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${mainColor};
    border: 2px solid #fff;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &.dark {
    border-left: 1px solid ${darkSubColor};
    background: ${darkBg};
    &::-webkit-scrollbar-thumb {
      background-color: ${darkPoint};
      border: 2px solid ${darkBg};
    }
    ul {
      li {
        border-bottom: 1px solid ${darkSubColor};
        &:first-child {
          background: ${darkSubColor};
        }
        input[type="checkbox"] {
          accent-color: ${darkPointColor};
        }
      }
    }
    .participants {
      > div {
        background: ${darkSubColor};
      }
      ul li {
        background: transparent;
      }
    }
  }
  width: 40%;
  padding: 20px;
  box-sizing: border-box;
  border-left: 1px solid ${ef};
  position: relative;
  transition: ${aniTime};
  transform: translateX(100%);
  &.active {
    transform: translateX(0);
  }
  .sideFormHeader {
    font-size: 16px;
    margin-bottom: 28px;
    cursor: pointer;
    svg {
      display: inline-block;
    }
    span {
      display: block;
      text-align: center;
    }
  }
  form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    input[type="text"] {
      width: 70%;
      margin-right: 10px;
    }
  }
  .section {
    &.grp ul li div:nth-child(2) {
      width: 90%;
    }
  }
  .searchContent,
  .participants {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }
  ul {
    /* height: calc(100vh - 320px); */
    /* margin-bottom: 30px; */
    overflow-y: auto;
    margin-bottom: 15px;
    li {
      &.tit {
        background: ${ef};
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 7px 0;
      }
      div {
        &:nth-child(1) {
          min-width: 10%;
        }
        &:nth-child(2) {
          min-width: 45%;
        }
        &:nth-child(3) {
          min-width: 45%;
        }
      }
    }
  }
  .btnArea {
    text-align: center;
    margin-top: 30px;
    button {
      margin: 0 5px;
    }
  }
  .participants {
    margin: 15px 0;
    > div {
      background: rgb(239, 239, 239);
      font-weight: 700;
      text-align: center;
      padding: 7px 0px;
    }
  }

  @media screen and (max-width: 1050px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 1;
    height: 100vh;
  }
  @media screen and (max-width: 767px) {
    form button {
      padding: 10px 15px;
    }
  }
`;

// AddSchedule
export const AddScheduleContainer = styled.div`
  min-height: 100vh;
  &.dark {
    background: ${darkBg};
    color: ${ef};
    input[type="text"],
    input[type="date"],
    input[type="time"],
    textarea,
    select,
    .readOnly {
      border: 1px solid ${darkSubColor};
      color: ${ef};
      &::placeholder {
        color: #737373;
      }
      &:focus {
        box-shadow: 0 0 5px ${darkPointColor};
        border: 1px solid ${darkPoint} !important;
      }
    }
    ul li .content .memoContent {
      border: 1px solid ${darkSubColor};
      color: ${ef};
      input:checked ~ .checkmark {
        background: ${darkPoint};
        border: none;
        ::after {
          border-color: #fff;
        }
      }
      &.active {
        box-shadow: 0 0 5px ${darkPointColor};
        border: 1px solid ${darkPoint} !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${darkPoint};
        border: 2px solid #000;
      }
    }
    .timeBox select:focus {
      box-shadow: none;
      border: none !important;
    }
    .content.title input:focus {
      box-shadow: none;
      border: none !important;
      border-bottom: 2px solid ${darkSubColor} !important;
    }
    select option {
      background: ${darkSubColor};
      color: ${ef};
      &:hover {
        background: #000;
      }
    }
    button {
      background: ${darkPoint};
      &.delBtn {
        color: ${ef};
        background: ${darkSubColor};
      }
    }
    .content.title input {
      border-bottom: 2px solid ${darkSubColor} !important;
    }
    .content.date .dtBox .timeBox {
      border: 1px solid ${darkSubColor};
    }
    .content.date .timeCheckBox label input[type="checkbox"] {
      accent-color: ${darkPointColor};
    }
    .content.addr div button {
      background: ${darkPoint};
      color: ${ef};
    }
    .addScheduleBody ul li .tit svg {
      stroke-width: 0.5px;
    }
    .addScheduleBody ul li .content.public .radio-input {
      background: ${darkSubColor};
      color: #fff;
      border: 1px solid ${darkSubColor};
    }
    .addScheduleBody ul li .content.public .radio-input label:has(input:checked) ~ .selection {
      background: ${darkPoint};
    }
    .addScheduleBody ul li .content.todoList .todos .todoItem button {
      background: ${darkSubColor};
      color: #fff;
    }
    .addScheduleBody ul li .content.title .iconSelect {
      color: #fff;
    }
  }
  /* 페이지 내 공통 */
  input[type="text"],
  input[type="date"],
  input[type="time"],
  textarea,
  select,
  .readOnly {
    width: 100%;
    border: 1px solid ${ef};
    outline: none;
    padding: 7px 14px;
    line-height: 21px;
    box-sizing: border-box;
    vertical-align: top;
    resize: none;
    border-radius: ${borderRadius};
    height: 37px;
    background: transparent;
  }
  button {
    padding: 0;
    width: 90px;
    height: 37px;
    border-radius: ${borderRadius};
    color: #fff;
    border: none;
    background: ${mainColor};
  }
  /* 페이지 내 공통 */
  .addScheduleBody {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media screen and (max-width: 767px) {
    button {
      width: 60px;
      font-size: 12px;
    }
  }
`;

export const IsDetails = styled.div`
  margin: 10px 0;
  span {
    display: flex;
    align-items: center;
    margin-left: 65px;
    cursor: pointer;
    svg {
      margin-left: 5px;
    }
  }
`;

export const IconContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 2;
  .iconArea {
    width: 200px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #fff;
    position: relative;
    top: 85px;
    left: 50%;
    padding: 35px 15px 15px;
    background: ${ef};
    border-radius: 30px;
    .close {
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 15px;
      border: none;
      background: transparent;
      width: auto;
      height: auto;
      color: #000;
    }
    .icon {
      position: relative;
      img {
        width: 30px;
        max-width: none;
        cursor: pointer;
      }
      &:hover::after {
        content: attr(data-name);
        width: 150%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -80%);
        border-radius: 8px;
        text-align: center;
        font-size: 10px;
        background: #666;
        color: #fff;
      }
    }
    .removeIconBtn {
      background: #fff;
      color: #000;
      font-size: 20px;
      width: 100%;
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 1050px) {
    .iconArea {
      top: 120px;
      left: 100%;
      transform: translateX(-100%);
    }
  }
`;

export const SelectedScopeContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  .contentArea {
    background: #fff;
    padding: 40px 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.25em;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    > span {
      font-size: 24px;
      font-weight: 600;
    }
    .checkArea {
      margin: 30px 0;
      label {
        display: block;
        font-size: 16px;
        margin-bottom: 5px;
        input {
          margin-right: 10px;
          appearance: none;
          width: 17px;
          height: 17px;
          border: 1px solid #000;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #000;
            width: 0;
            height: 0;
            transition: 0.3s;
          }
          &:checked {
            &::after {
              width: 17px;
              height: 17px;
            }
          }
        }
      }
    }
    .btnArea {
      display: flex;
      gap: 5px;
      button {
        &:first-child {
          background: ${ef};
          color: #000;
        }
      }
    }
  }
`;

import React from "react";
import { StartSubscriptionContainer } from "../styled/info";
import { TiArrowDownThick } from "react-icons/ti";

const StartSubscription = () => {
  return (
    <StartSubscriptionContainer>
      <div className="inner">
        <div className="info">
          <span>
            어디서도 볼 수 없는 모노티의
            <br />
            구독 서비스를 이용해 보세요!
          </span>
        </div>
        <div className="price">
          <img className="icon" src="/img/arrow_gradation_bottom.png" />
          <span>
            <strong>4,500</strong>원
          </span>
        </div>
        <div className="details">어디에도 없는 홈페이지 구독 서비스</div>
        <button>구독 시작하기</button>
      </div>
    </StartSubscriptionContainer>
  );
};

export default StartSubscription;

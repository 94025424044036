import React from "react";
import { RegisterItemContainer } from "../../styled/register";
import { CustomInput } from "../../styled/common";

const RegisterItem = ({ title, label, checked, onCheckboxChange, children, className }) => {
  return (
    <RegisterItemContainer className={className}>
      <div className="info">
        <span className="title">{title}</span>
        {label && (
          <div>
            <CustomInput className="block">
              <input
                type="checkbox"
                onChange={(e) => onCheckboxChange(e.target.checked)}
                checked={checked}
              />
              <span className="checkmark"></span>
              <span className="label">{label}</span>
            </CustomInput>
          </div>
        )}
      </div>
      <div className="content">{children}</div>
    </RegisterItemContainer>
  );
};

export default RegisterItem;

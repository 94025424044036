import React, { useEffect, useState } from "react";
import { DetailsInfoContainer } from "../../styled/pickup";
import { orderType } from "../../function/store";
import { useSelector } from "react-redux";
import axios from "axios";
import { IoIosNotificationsOutline } from "react-icons/io";
import OrderInfo from "../../common/orderInfo";
import moment from "moment";

const DetailsInfo = ({
  selected,
  openModal,
  rejectOrder,
  acceptOrder,
  updateOrder,
  setSelected,
  completedOrder,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const [pickupBy, setPickupBy] = useState(10);

  useEffect(() => {
    setPickupBy(10);
    console.log(selected);
  }, [selected]);

  const editPickupBy = (type = false) => {
    setPickupBy((time) => (type ? time + 10 : Math.max(time - 10, 10)));
  };

  const renderActions = (viewType) => {
    switch (viewType) {
      case "CANCELLED":
        return null;

      case "PICKUP":
        return (
          <div className="time">
            <span>주문시간: {moment(selected.createdAt).format("a h시m분")}</span>
            <span>완료시간: {moment(selected.updatedAt).format("a h시m분")}</span>
          </div>
        );

      case "READY":
      case "COMPLETED":
      case "CONFIRMED":
        return (
          <div className="actions">
            <button onClick={() => setSelected(false)}>취소</button>
            <button
              className="notify"
              onClick={() =>
                openModal({
                  type: "confirmation",
                  title: "상품 준비 완료",
                  message: ["상품 준비 완료 알림을 고객님께 보내시겠습니까?"],
                  handleConfirm: () => completedOrder(selected.id),
                })
              }>
              <span className="icon">
                <IoIosNotificationsOutline />
              </span>
              상품 준비 완료 알림
            </button>
            <button
              onClick={() =>
                openModal({
                  type: "confirmation",
                  title: "픽업 완료",
                  message: ["해당 주문을 완료하시겠습니까?"],
                  handleConfirm: () => updateOrder(selected.id),
                })
              }>
              픽업완료
            </button>
          </div>
        );

      case "PENDING":
        return (
          <div className="actions">
            <button onClick={() => rejectOrder(selected)}>거절</button>
            <div className="stepper">
              <button onClick={() => editPickupBy()}>
                <i className="xi-caret-down"></i>
              </button>
              <span className="current">{pickupBy}분</span>
              <button onClick={() => editPickupBy(true)}>
                <i className="xi-caret-up"></i>
              </button>
            </div>
            <button
              onClick={() =>
                openModal({
                  type: "confirmation",
                  title: "주문 수락",
                  message: ["주문을 수락하시겠습니까?"],
                  handleConfirm: () => acceptOrder(selected.id, pickupBy),
                })
              }>
              수락
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <DetailsInfoContainer className={selected?.status}>
      {selected ? (
        <>
          <OrderInfo selected={selected} />
          {renderActions(selected.status)}
        </>
      ) : (
        <strong>선택된 주문이 없습니다.</strong>
      )}
    </DetailsInfoContainer>
  );
};

export default DetailsInfo;

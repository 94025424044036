import styled from "styled-components";

export const HomepageEndFormContainer = styled.div`
  max-width: 1440px;
  margin: 50px auto;
  padding: 0 20px;
  box-sizing: border-box;
  letter-spacing: -1px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 767px) {
    margin: 30px 0 0;
  }
`;

export const HomepageEndTitleContainer = styled.div`
  &.dark {
    color: #eee;
    ul {
      border: 1px solid #000;
      li {
        color: #eee;
        border-bottom: 1px solid #000;
        span:first-child {
          background: #000;
        }
      }
    }
    .buttons a {
      background: rgba(0, 0, 0, 0.5);
      color: #eee;
    }
  }
  > p {
    text-align: center;
    margin-bottom: 30px;
  }
  > span {
    display: block;
    font-size: 16px;
    text-align: center;
    margin-bottom: 50px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 30px;
    li {
      width: 50%;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      &:nth-child(3),
      &:nth-child(4) {
        border-bottom: none;
      }
      a {
        color: rgba(0, 0, 0, 0.5);
      }
      span {
        display: inline-block;
        text-align: center;
        &:first-child {
          width: 40%;
          background: rgba(255, 255, 255, 0.5);
        }
        &:last-child {
          width: 60%;
        }
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    a {
      width: 272px;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
      border: none;
      color: rgba(0, 0, 0, 0.5);
      background: rgba(255, 255, 255, 0.5);
      font-size: 16px;
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    > p {
      margin-bottom: 15px;
    }
    > span {
      font-size: 12px;
      margin-bottom: 30px;
    }
    ul {
      li {
        font-size: 14px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        &:nth-child(3) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
    }
    .buttons a {
      font-size: 12px;
    }
  }
`;

export const HomepageTipContainer = styled.div`
  &.dark {
    color: #eee;
  }
  h3 {
    text-align: center;
    font-size: 30px;
    margin: 50px 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    li {
      width: calc(50% - 15px);
      text-align: center;
      img {
        display: block;
        margin: auto;
      }
      span {
        display: block;
        margin: 30px 0;
        font-weight: 700;
      }
      p {
        font-size: 18px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    h3 {
      font-size: 18px;
    }
    ul li p {
      font-size: 12px;
    }
  }
`;

import React from "react";

import { SubscribeContainer, SectionContaier } from "./styled/info";

import Plan from "./subscribe/plan";
import CardList from "./subscribe/common/cardList";
import { storeServiceCards, websiteCreationCards } from "../main/component/function/common";
import StartSubscription from "./subscribe/startSubscription";

const InfoSubscribe = () => {
  return (
    <SubscribeContainer>
      <Plan />
      <CardList
        cards={websiteCreationCards}
        title={"홈페이지 생성이 어려우시다고요?\n단, 3초만에 가능합니다"}
        type="websiteCreation"
      />
      <CardList
        cards={storeServiceCards}
        title={"상생매장 사장님을 위한\n모노티의 특별한 서비스"}
        type="storeService"
      />
      <StartSubscription />
    </SubscribeContainer>
  );
};

export default InfoSubscribe;

import styled from "styled-components";

export const PromotionFormContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 0 20px;
  box-sizing: border-box;
  letter-spacing: -1px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  &.dark {
    color: #eee;
    td {
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      input[type="text"] {
        background: rgba(0, 0, 0, 0.5);
        color: #eee;
      }
    }
    .hosting > div {
      background: rgba(0, 0, 0, 0.5);
      a {
        background: #000;
        color: #eee;
      }
    }
  }
  table {
    margin: 50px 0 30px;
    width: 100%;
    border-collapse: collapse;
    td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      height: 84px;
      line-height: 84px;
      span {
        display: block;
        text-align: center;
      }
      input[type="text"] {
        width: 100%;
        height: 60px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        border: none;
        padding: 0 20px;
        &:focus {
          border: none !important;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .hosting {
    > span {
      display: block;
      text-align: center;
      margin-bottom: 30px;
    }
    > div {
      display: flex;
      max-width: 1065px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.5);
      a {
        color: rgba(0, 0, 0, 0.5);
        width: calc((100% - 20px) / 3);
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #fff;
        border-radius: 5px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    table {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      td {
        display: block;
        height: auto;
        line-height: normal;
        width: calc(100vw - 40px);
        &:nth-child(odd) {
          border-bottom: none;
          border-top: none;
          padding-top: 15px;
          font-size: 16px;
          padding-bottom: 10px;
        }
        &:nth-child(even) {
          border-top: none;
          padding-bottom: 15px;
        }
        span {
          text-align: left;
          font-weight: 700;
        }
        input[type="text"] {
          height: 40px;
        }
      }
    }
    .hosting {
      > span {
        font-size: 16px;
      }
      > div {
        font-size: 14px;
        a {
          background: transparent;
          height: auto;
          line-height: normal;
          padding: 5px 0;
        }
      }
    }
  }
`;

import React, { useState } from "react";
import { OrdersSection } from "../styled/pickup";
import DetailsInfo from "./desktop/detailsInfo";
import DetailsContent from "./desktop/detailsContent";
import { calculateStatus, calculateTime } from "../function/store";
import TabMenu from "../common/tabMenu";
import OrderList from "./desktop/orderList";

const tabs = [
  { key: "PENDING", label: "신규 주문" },
  { key: "PICKUP", label: "완료" },
];

const DesktopOrderView = ({
  categorized,
  selectOrder,
  openModal,
  selected,
  setSelected,
  rejectOrder,
  acceptOrder,
  updateOrder,
  completedOrder,
  active,
  setActive,
  checkedOrders,
}) => {
  return (
    <>
      <div className="sidemenu">
        <TabMenu tabs={tabs} active={active} setActive={setActive} />
        <OrdersSection>
          <OrderList
            orders={categorized[active]}
            message="신규 주문이 존재하지 않습니다."
            onClick={selectOrder}
            selected={selected}
            checkedOrders={checkedOrders}
          />
          <OrderList
            title={`진행중 ${categorized.READY.length}건`}
            message="진행중인 주문이 존재하지 않습니다."
            orders={categorized.READY}
            onClick={selectOrder}
            selected={selected}
            renderExtraContent={(item) => (
              <div className={`orderProgress ${calculateStatus(item)}`}>
                <div className="progressRemaining">{calculateTime(item)}분</div>
              </div>
            )}
          />
        </OrdersSection>
      </div>
      <div className="content pickup">
        <DetailsInfo
          selected={selected}
          openModal={openModal}
          rejectOrder={rejectOrder}
          acceptOrder={acceptOrder}
          updateOrder={updateOrder}
          setSelected={setSelected}
          completedOrder={completedOrder}
        />
        <DetailsContent selected={selected} />
      </div>
    </>
  );
};

export default DesktopOrderView;

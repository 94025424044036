import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import axios from "axios";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageUploadAdapterPlugin from "../homepageSetting/component/utils/ImageUploadAdapter";

import MypageBanner from "../mypageBanner";
import { CreateReleaseNotesContainer } from "./component/styled/dashboard";
import Modal from "../../../common/modal";
import { toast } from "react-toastify";
import uuid from "react-uuid";

const CreateReleaseNotes = () => {
  const location = useLocation();
  const ckeditorInstanceRef = useRef(null);
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    cmpnyNo: 16421,
    noticeDe: moment().format("YYYY-MM-DD"),
    noticeSj: "",
    noticeCn: "",
    delYn: "N",
    gbn: "DEV",
    createMberId: user.userName,
    updateMberId: user.userName,
  });
  const releaseNote = location.state?.releaseNote;

  useEffect(() => {
    if (releaseNote) {
      setFormData((prev) => ({
        ...prev,
        noticeNo: releaseNote.noticeNo,
        noticeDe: releaseNote.noticeDe,
        noticeSj: releaseNote.noticeSj,
        noticeCn: releaseNote.noticeCn,
      }));
    }
  }, []);

  const handleEditorChange = (_, editor) => {
    const newContent = editor.getData();
    setFormData((data) => ({ ...data, noticeCn: newContent }));
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">등록</h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          업데이트 내용을 등록하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={handleConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const handleConfirm = async () => {
    const images = extractImages(formData.noticeCn);
    await uploadImage(images);
  };

  // 이미지 데이터 추출
  const extractImages = (noticeCn) => {
    const imgRegex = /<img.*?src=['"](data:image\/\w+;base64,.*?)['"].*?>/g;
    const matches = noticeCn.match(imgRegex);
    const images = [];

    if (matches) {
      matches.forEach((match) => {
        const dataUriMatch = match.match(/src=['"](data:image\/\w+;base64,.*?)['"]/);
        if (dataUriMatch && dataUriMatch[1]) {
          images.push(dataUriMatch[1]);
        }
      });
    }

    return images;
  };

  // 입력값 저장
  const handleSubmit = async (noticeCns) => {
    const url = "/api/notice";
    const body = {
      ...formData,
    };
    if (noticeCns) {
      body.noticeCn = noticeCns;
    }

    const res = await axios.put(url, body);
    if (res.data.success) {
      toast.success(`업데이트 내용이 등록되었습니다.`);
      navigate("/releaseNotes");
      setModalOpen(false);
    }
  };

  // 서버 저장
  const uploadImage = async (images) => {
    console.log(images);
    if (images.length === 0) {
      await handleSubmit(formData.noticeCn);
      return;
    }

    try {
      let form = new FormData();

      images.forEach((image, index) => {
        let blob = dataURItoBlob(image);
        let extension = blob.type.split("/")[1];
        form.append("files", blob, `${uuid()}.${extension}`);
      });
      form.append("ids", "N");
      form.append("mberNo", user.mberNo);

      const response = await fetch("/api/uploadFiles", {
        method: "POST",
        body: form,
      });
      const data = await response.json();

      const imageDatas = data.responseData;

      let imageUrls = imageDatas.map((item) => item.fileName);
      let reqContent = replaceImagesWithUrls(formData.noticeCn, imageUrls);

      await handleSubmit(reqContent);
    } catch (error) {
      console.error("업로드 중 오류 발생:", error);
    }
  };

  // Blob 형태 변경
  const replaceImagesWithUrls = (noticeCn, imageUrls) => {
    let updatedContent = noticeCn;
    if (!updatedContent) {
      return "";
    }
    const dataUriRegex = /src=['"]data:image\/[^;'"]+;base64,([^'"]+)['"]/g;
    let matches = updatedContent.match(dataUriRegex);

    imageUrls.forEach((imageUrl, index) => {
      updatedContent = updatedContent.replace(matches[index], `src="${imgHandler(imageUrl)}"`);
    });

    return updatedContent;
  };

  return (
    <>
      <MypageBanner />
      <CreateReleaseNotesContainer>
        <div className="content">
          <input
            type="text"
            placeholder="공지사항 제목을 입력하세요."
            name="title"
            onChange={(e) => setFormData((data) => ({ ...data, noticeSj: e.target.value }))}
            value={formData.noticeSj}
          />
          <CKEditor
            editor={ClassicEditor}
            data={formData.noticeCn}
            onChange={handleEditorChange}
            config={{
              extraPlugins: [ImageUploadAdapterPlugin],
              alignment: {
                options: ["center"],
              },
              toolbar: ["bold", "italic", "imageUpload"],
              uiSettings: {
                height: "500px",
              },
            }}
            ref={ckeditorInstanceRef}
          />
          <div className="btnArea">
            <button
              onClick={() => setModalOpen(true)}
              style={{ color: "#fff", background: "#0074ca" }}>
              등록
            </button>
          </div>
        </div>
      </CreateReleaseNotesContainer>
      <Modal
        modalState={modalOpen}
        handleModalState={() => setModalOpen(false)}
        html={ModalForm()}
        w="300px"
        h="auto"
      />
    </>
  );
};

export default CreateReleaseNotes;
